/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
} from "reactstrap";

import { ShowToastMessage } from "../../../components/toastr";
import {
  PREF_TAB_FREEFLOW,
  PREF_TAB_MULTIPLE,
  PREF_TAB_YESNO,
  PREF_TYPE_FREEFLOW,
  PREF_TYPE_MULTIPLE,
  PREF_TYPE_YESNO,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "../../../helpers/app.constants";
import "../../../assets/css/preference.css";
import {
  BsHandThumbsUp,
  BsHandThumbsDown,
  BsPlusCircle,
  BsPlusCircleFill,
  BsX,
  BsXCircleFill,
} from "react-icons/bs";
import { CgSoftwareUpload } from "react-icons/cg";
import {
  deletePreference,
  filterPreference,
  addNewPreference,
  updatePreference,
} from "../../../services/api/preference.service";
import pereferences from "../../../assets/images/pereferences.webp";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import uploadImage from "../../../assets/images/image.png";
import { Loader } from "../../../components/loader";
import ReactImageUploading from "react-images-uploading";
import { Link, useLocation } from "react-router-dom";
import { getFilterSubcategory } from "../../../services/api/subcategory.service";
import { FormHelperText } from "@mui/material";

const PreferenceList = () => {
  const location = useLocation();

  const { categoryId, categoryName, prefCardId } = location?.state;
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [tabIndex, setTabIndex] = useState(PREF_TAB_YESNO);
  const [selCategoryId, setCategoryId] = useState(categoryId);
  const [selSubCategoryId, setSubCategoryId] = useState("0");
  const [selSubCategoryName, setSubCategoryName] = useState("");

  const [allCategories, setCategories] = useState([]);
  const [prefCount, setPrefCounts] = useState({
    YesNo: 0,
    MultipleChoice: 0,
    FreeFlow: 0,
  });

  const [isAddEditInfoModalOpen, setAddEditInfoModalOpen] = useState(false);
  const [addModaltabIndex, setAddModalTabIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [likeDislikeTitle, setLikeDisLike] = useState("");
  const [mcQuestion, setMcQuestion] = useState("");

  const [freeflowText, setFreeflowText] = useState("");
  const [addPrefInfo, setAddPrefInfo] = useState({
    Mode: "",
    PrefId: "",
    CategoryId: "",
    SubCategoryId: "",
    SubCategoryName: "",
    tab1Disabled: false,
    tab2Disabled: false,
    tab3Disabled: false,
    imageUrl: "",
  });
  const [inputValues, setInputValues] = useState({});
  const [counter, setCounter] = useState(0);
  const [categoryErr, setCategoryErr] = useState(false);

  const fillSubCategories = useCallback(async () => {
    const response = await getFilterSubcategory(selCategoryId);
    if (response) {
      if (response.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error Loadng the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCategories(response.data);
      setSubCategoryId("0");
    }
  }, []);

  const refreshTabOnSubCategoryChange = useCallback(
    async (selSubCategoryIdTab) => {
      setTabIndex(PREF_TAB_YESNO);
      refreshPreferences(selSubCategoryIdTab, tabIndex);
    },
    []
  );

  const refreshPreferences = useCallback(
    async (selSubCategoryIdPreferences, tabIndexPre) => {
      setLoading(true);
      if (selSubCategoryIdPreferences !== "") {
        var type = "";
        if (tabIndexPre === PREF_TAB_YESNO) {
          type = PREF_TYPE_YESNO;
        } else if (tabIndexPre === PREF_TAB_MULTIPLE) {
          type = PREF_TYPE_MULTIPLE;
        } else if (tabIndexPre === PREF_TAB_FREEFLOW) {
          type = PREF_TYPE_FREEFLOW;
        }

        filterPreference(selCategoryId, selSubCategoryIdPreferences, type).then(
          (response) => {
            if (response.error) {
              var errorMessage = response.msg
                ? response.msg
                : "Error Loadng the data";
              ShowToastMessage(
                errorMessage,
                TOAST_ERROR,
                toast.POSITION.BOTTOM_RIGHT
              );
            }
            setRecords(response.data);
            setPrefCounts(response.PrefCount);
          }
        );
      }
      setLoading(false);
    },
    []
  );

  const loadPreferenceData = useCallback(async () => {
    if (selCategoryId !== "") {
      var type = "";
      if (tabIndex === PREF_TAB_YESNO) {
        type = PREF_TYPE_YESNO;
      } else if (tabIndex === PREF_TAB_MULTIPLE) {
        type = PREF_TYPE_MULTIPLE;
      } else if (tabIndex === PREF_TAB_FREEFLOW) {
        type = PREF_TYPE_FREEFLOW;
      }
      const response = await filterPreference(
        selCategoryId,
        selSubCategoryId,
        type
      );
      if (response) {
        if (response.error) {
          var errorMessage = response.msg
            ? response.msg
            : "Error Loadng the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
        setRecords(response.data);
        setPrefCounts(response.PrefCount);
      }
      setLoading(false);
    }
  }, [tabIndex]);

  const deleteItem = async (prefId) => {
    const response = await deletePreference(prefId);
    if (response) {
      if (response.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error deleting the preference item";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        ShowToastMessage("Successfully deleted the Preference", TOAST_SUCCESS);
        refreshPreferences(selSubCategoryId, tabIndex);
      }
    }
  };

  const renderListing = (tabIndexData) => {
    let recordList = [];
    if (tabIndexData === PREF_TAB_YESNO) {
      records.map((record, idx) => {
        return recordList.push(
          <div id={"div_yn_" + idx} className="row like_wrap">
            <div className="col-md-9">
              <div className="outer_wrap">
                <div className="do_you_link_left">
                  <img
                    className="img-pref"
                    src={record.image ? record.image : pereferences}
                    alt=""
                  />
                </div>
                <div
                  className="do_you_link_right"
                  style={{ paddingLeft: "10px" }}
                >
                  <p>{record.title}</p>
                  <div className="form-check-inline">
                    <BsHandThumbsUp size={19} id={"div_yn_yes_" + idx} />
                    <Label
                      className="form-check-label"
                      check
                      htmlFor={"div_yn_yes_" + idx}
                    >
                      Yes
                    </Label>
                  </div>
                  <div className="form-check-inline">
                    <BsHandThumbsDown size={19} id={"div_yn_no_" + idx} />
                    <label
                      className="form-check-label"
                      htmlFor={"div_yn_no_" + idx}
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-right delete_box">
              <img
                title="Edit this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={editIcon}
                onClick={(e) => openEditPrefModal(record._id)}
                alt=""
              />
              <img
                title="Delete this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={deleteIcon}
                onClick={(e) => deleteItem(record._id)}
                alt=""
              />
            </div>
          </div>
        );
      });
    } else if (tabIndexData === PREF_TAB_MULTIPLE) {
      records.map((record, idx) => {
        var options = record.mcOption;
        return recordList.push(
          <div id={"div_mc_" + idx} className="row like_wrap multi_choice">
            <div className="col-md-9">
              <div className="outer_wrap">
                <div className="do_you_link_left">
                  <img
                    className="img-pref"
                    src={record.image ? record.image : pereferences}
                    alt=""
                  />
                </div>
                <div
                  className="do_you_link_right"
                  style={{ paddingLeft: "10px" }}
                >
                  <p>{record.title}</p>
                  {options &&
                    Object.keys(options).map((c, oIdx) => {
                      return (
                        <FormGroup
                          key={oIdx}
                          className="form-check-inline"
                          check
                        >
                          <Input
                            className="form-check-input"
                            name={"mcOption_" + idx}
                            id={"mcOption_" + idx + "_" + oIdx}
                            type="radio"
                          />{" "}
                          <Label htmlFor={"mcOption_" + idx + "_" + oIdx} check>
                            {" "}
                            {options[c]}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-md-3 text-right delete_box">
              <img
                title="Edit this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={editIcon}
                onClick={(e) => openEditPrefModal(record._id)}
                alt=""
              />
              <img
                title="Delete this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={deleteIcon}
                onClick={(e) => deleteItem(record._id)}
                alt=""
              />
            </div>
          </div>
        );
      });
    } else if (tabIndexData === PREF_TAB_FREEFLOW) {
      records.map((record, idx) => {
        return recordList.push(
          <div
            id={"div_ff_" + { idx }}
            className="row like_wrap free_flow"
            key={idx}
          >
            <div className="col-md-9">
              <div className="outer_wrap">
                <div className="do_you_link_left">
                  <img
                    className="img-pref"
                    src={record.image ? record.image : pereferences}
                    alt=""
                  />
                </div>
                <div
                  className="do_you_link_right"
                  style={{ paddingLeft: "10px" }}
                >
                  <p>{record.title}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-right delete_box">
              <img
                title="Edit this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={editIcon}
                onClick={(e) => openEditPrefModal(record._id)}
                alt=""
              />
              <img
                title="Delete this Preference"
                className="img-fluid pr-15 pb-4 pointer"
                src={deleteIcon}
                onClick={(e) => deleteItem(record._id)}
                alt=""
              />
            </div>
          </div>
        );
      });
    } else {
      recordList.push(
        <div id={"div_unknownpref_0"} className="row like_wrap no-data-content">
          {"No Data Available"}
        </div>
      );
    }
    if (records.length === 0) {
      recordList.push(
        <div id={"div_0"} className="row like_wrap no-data-content">
          {"No Data Available"}
        </div>
      );
    }
    return recordList;
  };

  const openAddPrefModal = () => {
    setSubCategoryId("0");
    var subCatName = selSubCategoryName;
    if (selSubCategoryId === 0 || selSubCategoryName === "All") subCatName = "";
    setAddModalTabIndex(0);
    clearForm();
    setAddPrefInfo({
      Mode: "Add",
      CategoryId: selCategoryId,
      SubCategoryId: "0",
      SubCategoryName: subCatName,
      tab1Disabled: false,
      tab2Disabled: false,
      tab3Disabled: false,
    });
    setImages([]);
    setAddEditInfoModalOpen(true);
  };

  const closeAddEditInfoModal = () => {
    setLikeDisLike("");
    setAddEditInfoModalOpen(false);
    setCategoryErr(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const handleSubCategoryChange = (e) => {
    var selectedOption = e.target.selectedOptions[0];
    setSubCategoryId(selectedOption.value);
    setSubCategoryName(selectedOption.text);
  };

  const handleSubCategoryChangeAdd = (e) => {
    var selectedOption = e.target.selectedOptions[0];
    setAddPrefInfo({
      ...addPrefInfo,
      SubCategoryId: selectedOption.value,
    });
  };

  const openEditPrefModal = (prefId) => {
    var subCatName = selSubCategoryName;
    if (selSubCategoryId === 0 || selSubCategoryName === "All") subCatName = "";
    var editedRecord = records.filter((val, i) => val._id === prefId);
    if (editedRecord.length < 0) {
      ShowToastMessage("Error retrieving preference data", TOAST_ERROR);
    } else {
      var record = editedRecord[0];
      var tab1Disabled = true;
      var tab3Disabled = true;
      var tab2Disabled = true;

      if (record.type === "yesNo") {
        setAddModalTabIndex(0);
        setLikeDisLike(record.title);
        tab1Disabled = false;
      } else if (record?.type === "multipleChoice") {
        setMcQuestion(record.title);
        var options = record.mcOption;
        setInputValues({ 0: "" });
        var counterItem;
        if (options != null && options.length > 0) {
          counterItem = 0;
          var optionValues = {};
          Object.keys(options).forEach((c, idx) => {
            optionValues[c] = options[c];
            counterItem++;
          });
          setInputValues({ ...optionValues });
        }
        setCounter(counterItem);
        setAddModalTabIndex(1);
        tab2Disabled = false;
      } else if (record.type === "freeFlow") {
        setFreeflowText(record.title);
        setAddModalTabIndex(2);
        tab3Disabled = false;
      }
      setAddPrefInfo({
        Mode: "Edit",
        PrefId: record._id,
        CategoryId: record.categoryId,
        SubCategoryId: record.subcategoryId == null ? 0 : record.subcategoryId,
        SubCategoryName: subCatName,
        imageUrl: record.image,
        tab1Disabled: tab1Disabled,
        tab2Disabled: tab2Disabled,
        tab3Disabled: tab3Disabled,
      });
      setImages([]);
      setAddEditInfoModalOpen(true);
    }
  };

  const clearForm = () => {
    setMcQuestion("");

    setLikeDisLike("");
    setFreeflowText("");
    setImages([]);
    setCounter(1);
    setInputValues({ 0: "" });
  };

  const callbackSaveAction = (showTabId) => {
    setAddEditInfoModalOpen(false);
    clearForm();
    if (tabIndex !== showTabId) setTabIndex(showTabId);
    else refreshPreferences(selSubCategoryId, tabIndex);
  };

  const getPreferenceObject = (prefType) => {
    var title = "";

    if (prefType === PREF_TYPE_YESNO) {
      title = likeDislikeTitle;
    } else if (prefType === PREF_TYPE_MULTIPLE) {
      title = mcQuestion;
    } else if (prefType === PREF_TYPE_FREEFLOW) {
      title = freeflowText;
    }

    var image = images.length > 0 ? images[0] : null;
    var subCategory =
      addPrefInfo.SubCategoryId === 0 ? null : addPrefInfo.SubCategoryId;
    var request = {
      categoryId: addPrefInfo.CategoryId,
      type: prefType,
      title: title,
    };
    if (image != null) {
      request.image = image.data_url;
    }
    if (subCategory != null) {
      request.subcategoryId = subCategory;
    }
    if (prefType === PREF_TYPE_MULTIPLE) {
      var optionValues = [];
      Object.keys(inputValues).forEach((c, idx) =>
        optionValues.push(inputValues[c])
      );
      request.mcOption = optionValues;
    }

    return request;
  };

  const handleOptionTextChange = (e, idx) => {
    var values = inputValues;
    values[idx] = e.target.value;
    setInputValues({ ...values });
  };

  const handleAddOptionClick = () => {
    var ctr = counter + 1;
    setCounter(counter + 1);
    const values = inputValues;
    values[ctr] = "";
    setInputValues(values);
  };

  const removeMcOption = (i) => {
    setCounter(counter - 1);

    let newValues = { ...inputValues };

    delete newValues[i];

    setInputValues(newValues);
  };

  const savePreference = (prefType) => {
    var request = getPreferenceObject(prefType);
    var showTab = "";

    if (prefType === PREF_TYPE_YESNO) {
      showTab = PREF_TAB_YESNO;
    } else if (prefType === PREF_TYPE_MULTIPLE) {
      showTab = PREF_TAB_MULTIPLE;
    } else if (prefType === PREF_TYPE_FREEFLOW) {
      showTab = PREF_TAB_FREEFLOW;
    } else {
      showTab = PREF_TAB_YESNO;
    }

    if (addPrefInfo.Mode === "Add") {
      addNewPreference(request)
        .then((res) => {
          if (res.error) {
            var errorMessage = res.msg
              ? res.msg
              : "Error creating the preference";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
          } else {
            var successMsg = res.msg
              ? res.msg
              : "Added Preference Successfully";
            ShowToastMessage(
              successMsg,
              TOAST_SUCCESS,
              toast.POSITION.BOTTOM_RIGHT
            );
            callbackSaveAction(showTab);
          }
        })
        .catch((error) => {
          ShowToastMessage(error, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
        });
    } else {
      request._id = addPrefInfo.PrefId;
      updatePreference(request)
        .then((res) => {
          if (res.error) {
            var errorMessage = res.msg
              ? res.msg
              : "Error updating the preference";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
          } else {
            var successMsg = res.msg
              ? res.msg
              : "Updated Preference Successfully";
            ShowToastMessage(
              successMsg,
              TOAST_SUCCESS,
              toast.POSITION.BOTTOM_RIGHT
            );
            callbackSaveAction(showTab);
          }
        })
        .catch((error) => {
          ShowToastMessage(error, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
        });
    }
    return request;
  };

  const handleImageUploading = () => {
    return (
      <div className="row like_wrap">
        <ReactImageUploading
          value={images}
          onChange={onChange}
          maxNumber={1}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="col-md-12 text-center">
              {imageList.length > 0 &&
                imageList.map((image, index) => (
                  <div className="text-center update_img" key={index}>
                    <img
                      alt=""
                      className="img-fluid pr-15"
                      src={image.data_url}
                    />
                    <BsX
                      title="Remove this image"
                      size={26}
                      onClick={() => onImageRemove(index)}
                      className="pointer"
                    />
                  </div>
                ))}
              {imageList.length <= 0 && (
                <div className="text-center update_img">
                  <img
                    alt=""
                    className="img-fluid pr-15"
                    src={
                      addPrefInfo.imageUrl === "" ||
                      addPrefInfo.imageUrl == null
                        ? uploadImage
                        : addPrefInfo.imageUrl
                    }
                  />
                </div>
              )}
              <a
                className="text-center link-common"
                onClick={onImageUpload}
                {...dragProps}
              >
                <CgSoftwareUpload className="img-fluid pr-8" fontSize={40} />
                Upload Image
              </a>
            </div>
          )}
        </ReactImageUploading>
      </div>
    );
  };
  useEffect(() => {
    setLoading(true);
    fillSubCategories();
    setCategoryId(categoryId);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selCategoryId !== "") {
      loadPreferenceData();
    }
  }, [tabIndex]);

  useEffect(() => {
    setLoading(true);
    setTabIndex(PREF_TAB_YESNO);
    refreshTabOnSubCategoryChange(selSubCategoryId);
  }, [selSubCategoryId]);
  return (
    <React.Fragment>
      <Container className="container">
        <div className="row dashboard_tittle_box">
          <div className="col-md-4">
            <h3>Preferences</h3>
            <h6 className="pref_import_tittle">
              Home{" "}
              <span>
                <Link
                  className="link-common"
                  to="/admin/preference"
                  state={{ fromCardId: prefCardId }}
                >
                  Preferences
                </Link>
              </span>
              <span className="pref_subtitle">{categoryName} Preferences</span>
            </h6>
          </div>
          <div className="col-md-8 text-right">
            <label className="p-2">Select Sub Category</label>
            <select
              name="category_list"
              title="Choose a Category"
              id="category_list"
              className="select-lg"
              onChange={handleSubCategoryChange}
            >
              <option id="optionAll" value="0">
                All
              </option>
              {allCategories
                .filter((el) => !el.subcategoryName.includes("General"))
                .map((catg, idx) => (
                  <option id={"option_" + idx} value={catg._id} key={catg._id}>
                    {catg.subcategoryName}
                  </option>
                ))}
            </select>
            <button
              id="open_popup"
              className="add_preference_btn"
              onClick={openAddPrefModal}
            >
              <BsPlusCircle /> Add Preference Prompt
            </button>

            <Modal
              id="addNewPrefModal"
              isOpen={isAddEditInfoModalOpen}
              toggle={closeAddEditInfoModal}
            >
              <ModalHeader toggle={closeAddEditInfoModal}>
                <label>
                  {addPrefInfo.Mode} {addPrefInfo.SubCategoryName} Preference
                  Prompt
                </label>
              </ModalHeader>
              <ModalBody>
                {addPrefInfo.Mode !== "Edit" ? (
                  <div className="col-md-12 p-0 add-preference">
                    <select
                      name="category_list"
                      title="Choose a Category"
                      id="category_list"
                      className="select-lg"
                      onChange={handleSubCategoryChangeAdd}
                    >
                      <option id="optionAll" value="0" disabled selected>
                        Please Select Sub category
                      </option>
                      {allCategories.map((catg, idx) => (
                        <option
                          id={"option_" + idx}
                          value={catg._id}
                          key={catg._id}
                        >
                          {catg.subcategoryName}
                        </option>
                      ))}
                    </select>
                    {categoryErr && (
                      <FormHelperText
                        sx={{ color: "tomato", paddingLeft: "2px" }}
                      >
                        Please select sub category
                      </FormHelperText>
                    )}
                  </div>
                ) : null}
                <div className="col-md-12 p-0">
                  <Tabs
                    id="addPrefTab"
                    className="row tabs_wrap"
                    selectedIndex={addModaltabIndex}
                    onSelect={(idx) => setAddModalTabIndex(idx)}
                  >
                    <TabList className="nav nav-tabs">
                      <Tab
                        className="nav-item"
                        disabled={addPrefInfo.tab1Disabled}
                      >
                        <a className="nav-link">Yes/No</a>
                      </Tab>
                      <Tab
                        className="nav-item"
                        disabled={addPrefInfo.tab2Disabled}
                      >
                        <a className="nav-link">Multiple Choice</a>
                      </Tab>
                      <Tab
                        className="nav-item"
                        disabled={addPrefInfo.tab3Disabled}
                      >
                        <a className="nav-link free_flow_tab">Free Flow</a>
                      </Tab>
                    </TabList>
                    <TabPanel id="yesno_addpref">
                      {handleImageUploading()}
                      <div className="row">
                        <div className="col-md-12 question_tittle_box">
                          <h6>Enter a Like or Dislike question title</h6>
                          <input
                            value={likeDislikeTitle}
                            onChange={(e) => setLikeDisLike(e.target.value)}
                            type="text"
                            name="likeDislikeTitle"
                            id="likeDislikeTitle"
                            placeholder="Example: Do you like outside seating?"
                          />
                          <div style={{ clear: "both", textAlign: "center" }}>
                            <button
                              id="btn_submit_yesno"
                              className="clear-both submit_preference"
                              onClick={(e) => {
                                if (addPrefInfo.SubCategoryId === "0") {
                                  setCategoryErr(true);
                                } else {
                                  setCategoryErr(false);
                                  savePreference(PREF_TYPE_YESNO);
                                }
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel id="multich_addpref">
                      {handleImageUploading()}
                      <div className="row">
                        <div className="col-md-12 question_tittle_box">
                          <h6>Enter a multiple choice type of question</h6>
                          <input
                            type="text"
                            name="mcQuestion"
                            id="mcQuestion"
                            value={mcQuestion}
                            onChange={(e) => setMcQuestion(e.target.value)}
                            placeholder="How do you like your steak cooked?"
                          />
                          <div className="mc-option-header">
                            <span className="float-left">
                              Multiple Choice :
                            </span>
                            <span
                              className="float-right pointer"
                              onClick={handleAddOptionClick}
                            >
                              {" "}
                              <BsPlusCircleFill
                                className="pointer"
                                size={18}
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "5px",
                                  color: "#e747b2",
                                }}
                              />
                              Add Option
                            </span>
                          </div>
                          <div className="clear-both add-row-collection">
                            {Object.keys(inputValues).map((c, idx) => {
                              return (
                                <div key={idx}>
                                  {idx === 0 && (
                                    <div className="add_row">
                                      <h2>Option 1</h2>
                                      <input
                                        type="text"
                                        name="mcOption0"
                                        id="mcOption0"
                                        value={inputValues[0]}
                                        onChange={(e) =>
                                          handleOptionTextChange(e, 0)
                                        }
                                      />
                                    </div>
                                  )}
                                  {idx > 0 && (
                                    <div className="add_row_multiple">
                                      <h2>Option {idx + 1}</h2>
                                      <input
                                        type="text"
                                        name={"mcOption" + (idx + 1)}
                                        id={"mcOption" + (idx + 1)}
                                        value={inputValues[c]}
                                        onChange={(e) =>
                                          handleOptionTextChange(e, c)
                                        }
                                      />
                                      <span>
                                        <BsXCircleFill
                                          className="pointer"
                                          onClick={() => removeMcOption(c)}
                                          size={18}
                                          style={{ color: "#e747b2" }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <div style={{ clear: "both", textAlign: "center" }}>
                            <button
                              id="btn_submit_mc"
                              className="clear-both submit_preference"
                              onClick={(e) => {
                                if (addPrefInfo.SubCategoryId === "0") {
                                  setCategoryErr(true);
                                } else {
                                  setCategoryErr(false);
                                  savePreference(PREF_TYPE_MULTIPLE);
                                }
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel id="freefl_addpref">
                      {handleImageUploading()}
                      <div className="row">
                        <div className="col-md-12 question_tittle_box">
                          <h6>
                            Enter a question that allows a customer to enter a
                            free flow response
                          </h6>
                          <input
                            type="text"
                            name="freeflowText"
                            id="freeflowText"
                            value={freeflowText}
                            onChange={(e) => setFreeflowText(e.target.value)}
                            placeholder="Example: After you check in to the hotel what do you like to do?"
                          />
                          <div style={{ clear: "both", textAlign: "center" }}>
                            <button
                              id="btn_submit_ff"
                              className="clear-both submit_preference"
                              onClick={(e) => {
                                if (addPrefInfo.SubCategoryId === "0") {
                                  setCategoryErr(true);
                                } else {
                                  setCategoryErr(false);
                                  savePreference(PREF_TYPE_FREEFLOW);
                                }
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </ModalBody>
              <ModalFooter>
                <p> </p>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        <div className="div card-body">
          <Tabs
            className="row tabs_wrap"
            selectedIndex={tabIndex}
            onSelect={(idx) => setTabIndex(idx)}
          >
            <h2 className="pt-3">
              Sub Category:{" "}
              {selSubCategoryName === "" ? "All" : selSubCategoryName}
            </h2>

            <TabList className="nav nav-tabs">
              <Tab className="nav-item">
                <a className="nav-link">
                  Yes/No <span className="tabs-number">{prefCount.YesNo}</span>
                </a>
              </Tab>
              <Tab className="nav-item">
                <a className="nav-link">
                  Multiple Choice{" "}
                  <span className="tabs-number">
                    {prefCount.MultipleChoice}
                  </span>
                </a>
              </Tab>
              <Tab className="nav-item">
                <a className="nav-link">
                  Free Flow{" "}
                  <span className="tabs-number">{prefCount.FreeFlow}</span>
                </a>
              </Tab>
            </TabList>
            <TabPanel id="yesno_pref" className="tab-content">
              {selCategoryId !== "" &&
                !loading &&
                tabIndex === PREF_TAB_YESNO && (
                  <div id="div_yesno_list" className="tab-content-wrapper">
                    {renderListing(PREF_TAB_YESNO)}
                  </div>
                )}
              {loading && <Loader />}
            </TabPanel>
            <TabPanel id="mc_pref">
              {selCategoryId !== "" &&
                !loading &&
                tabIndex === PREF_TAB_MULTIPLE && (
                  <div id="div_mc_list multi-radio">
                    {renderListing(PREF_TAB_MULTIPLE)}
                  </div>
                )}
              {loading && <Loader />}
            </TabPanel>
            <TabPanel id="ff_pref">
              {selCategoryId !== "" &&
                !loading &&
                tabIndex === PREF_TAB_FREEFLOW && (
                  <div id="div_free_list">
                    {renderListing(PREF_TAB_FREEFLOW)}
                  </div>
                )}
              {loading && <Loader />}
            </TabPanel>
          </Tabs>
        </div>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};
export default PreferenceList;
