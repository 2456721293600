import React from "react";
import { Route, Routes } from "react-router-dom";
import { ROLE_SUPERADMIN } from "../helpers/app.constants";
import Home from "../views/superadmin/home";
import Company from "../views/superadmin/company/company";
import { Header } from "./layout/header";
import { SideBar } from "./layout/sidebar";
import Preference from "../views/superadmin/preference/preference";
import Customer from "../views/superadmin/customer/customer";
import MicroFeedback from "../views/superadmin/feedback";
import Settings from "../views/superadmin/settings/settings";
import Profile from "../views/superadmin/settings/profile";
import { getUserInfo, getUserName, getUserRole } from "../helpers/helper";

import AddCompany from "../views/superadmin/company/addcompany";
import EditCompany from "../views/superadmin/company/editcompany";

import EditCustomer from "../views/superadmin/customer/editcustomer";
import PreferenceList from "../views/superadmin/preference/preferencelist";
import { ToastContainer } from "react-toastify";
import sha256 from "sha256";

const SuperAdminMainLayout = () => {
  const userName = getUserName();
  const userRole = getUserRole();
  const userInfo = getUserInfo();

  if (userInfo) {
    const userhash = sha256(userInfo._id);
    window.Intercom("boot", {
      app_id: "fxdppbui",
      name: userInfo?.ownerManagerName,
      email: userInfo?.email,
      user_id: userInfo?._id,
      "Owner email": userInfo?.ownerManagerEmail,
      "Owner phone": userInfo?.ownerManagerMobileNo,
      "company name": userInfo?.name,
      "Company type": userInfo?.categoryId?.find((item, idx) => idx === 0)
        ?.categoryName,
      phone: userInfo.mobileNo,
      "Company website": userInfo?.website,
      "Company industry": userInfo?.categoryId?.find((item, idx) => idx === 0)
        ?.categoryName,
      user_hash: userhash,
    });
    window.Intercom("update");
  }
  return (
    <div className="main-content toggle">
      <Header name={userName} role={userRole} image={userInfo?.image} />
      <div className="main-layout d-flex">
        <SideBar userRole={ROLE_SUPERADMIN} />
        <div className="main">
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="company">
              <Route index element={<Company />} />
              <Route path="addcompany" element={<AddCompany />} />
              <Route path="editcompany/:companyid" element={<EditCompany />} />
            </Route>
            <Route path="home" element={<Home />} />
            <Route path="preference">
              <Route index element={<Preference />} />
              <Route path="list" element={<PreferenceList />} />
            </Route>

            <Route path="customer">
              <Route index element={<Customer />} />
              <Route
                path="editcustomer/:customerid"
                element={<EditCustomer />}
              />
            </Route>
            <Route path="feedback" element={<MicroFeedback />} />
          </Routes>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SuperAdminMainLayout;
