/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import filterIcon from "../../../assets/images/fillters.png";
import "../../../assets/css/feedback.css";
import closeIcon from "../../../assets/images/close-icon.png";
import { ModalBody, ModalHeader, Modal, ModalFooter } from "reactstrap";
import { getFeedback } from "../../../services/api/category.service";
import userImg from "../../../assets/images/avtar.webp";
import {
  getAllTags,
  addNewTagsFeedback,
  deleteFeedbackTags,
} from "../../../services/api/customer.service";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR } from "../../../helpers/app.constants";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Loader } from "../../../components/loader";
import {
  Autocomplete,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MdAddCircleOutline } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FiCalendar } from "react-icons/fi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Feedback = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selection, setSelection] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueAddTags, setValueAddTags] = useState("");
  const [newTagVal, setNewTagVal] = useState("");
  const [errAlrt, setErrAlrt] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openTags, setOpenTags] = useState(false);
  const [tagsPopover, setTagsPopover] = useState(null);
  const [tagsfiltr, setTagsFiltr] = useState([]);
  const [filterStrings, setFilterStrings] = useState([]);
  const [feedbackSearch, setFeedbackSearch] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDateEnd, setEndDateEnd] = useState("");
  const [open, setOpen] = useState(false);
  const [array, setArray] = useState([]);
  const [filterModalOpen, setfilterModalOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    arrivalStart: "",
    arrivalEnd: "",
  });

  const TagsRemove = (idx) => {
    tagsfiltr.splice(idx, 1);
    setTagsFiltr([...tagsfiltr]);
    fetchCustomers(tagsfiltr);
  };

  const dltTags = (itm) => {
    if (array.indexOf(itm.tagId._id) !== -1) {
      array.splice(array.indexOf(itm.tagId._id), 1);
      setArray([...array]);
    } else {
      array.push(itm.tagId._id);
      setArray([...array]);
    }
  };

  const deleteFeedbackTag = useCallback(async (id, tagslist) => {
    setLoading(true);
    const res = await deleteFeedbackTags(id?.row?.id, tagslist);
    if (res) {
      setOpenTags(false);
      setArray([]);
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      fetchCustomers().then(() => {
        setLoading(false);
      });
    }
  }, []);

  const AddTagsHandle = () => {
    setLoading(true);
    if (newTagVal === "" && valueAddTags === "") {
      setErrAlrt(true);
    } else {
      addNewTagsFeedback(
        selection[0],
        valueAddTags?.id,
        valueAddTags?.id !== undefined ? "" : newTagVal
      ).then(() => {
        fetchTags()
          .then(() => {
            fetchCustomers();
          })
          .then(() => {
            setInputValue("");
            setNewTagVal("");
            setSelection([]);
          });
      });
      setOpen(!open);
      setErrAlrt(false);
    }
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };
  const handleChangeEndDateEnd = (newValue) => {
    setEndDateEnd(newValue);
  };
  const closeAddModal = () => {
    setAddModalOpen(false);
  };
  const closefilterModal = () => {
    setfilterModalOpen(false);
  };

  const feedbackData = customerList
    ?.filter(
      (itm) =>
        itm?.name?.toLowerCase()?.includes(feedbackSearch?.toLowerCase()) ||
        itm?.feedback?.toLowerCase()?.includes(feedbackSearch?.toLowerCase())
    )
    .filter((items) =>
      filterData.arrivalStart !== ""
        ? new Date(new Date(items?.createdAt).toLocaleDateString()).valueOf() >=
            new Date(
              new Date(dayjs(filterData.arrivalStart)).toLocaleDateString()
            ).valueOf() &&
          new Date(new Date(items?.createdAt).toLocaleDateString()).valueOf() <=
            new Date(
              new Date(dayjs(filterData.arrivalEnd)).toLocaleDateString()
            ).valueOf()
        : "2022-12-24T21:11:54"
    )
    .map((item) => {
      return {
        ...item,
      };
    });

  const fetchCustomers = useCallback(async (tags) => {
    setLoading(true);
    const res = await getFeedback(tags);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      const dataList = res?.data?.map((customer, index) => {
        return {
          id: customer?._id,
          customerimage: customer?.userId?.image,
          name: customer?.userId?.name,
          feedback: customer.feedback,
          createdAt: new Date(customer.createdAt).toLocaleDateString(),
          time: new Date(customer.createdAt).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          tags:
            customer.tags === undefined
              ? [
                  {
                    tagId: {
                      _id: "637df0390dd85a0f216be4c2",
                      tag: "Null",
                    },
                  },
                ]
              : customer.tags,
        };
      });
      const sortData = dataList?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setCustomerList(sortData);
    }
    setLoading(false);
  }, []);

  const fetchTags = async () => {
    await getAllTags().then((res) => {
      if (res) {
        if (res.error) {
          var errorMessage = res.msg ? res.msg : "Error Loading the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        } else {
          const dt = res?.data
            ?.filter(
              (itm, index) =>
                index ===
                res?.data?.findIndex(
                  (other) => itm.tagId?.tag === other.tagId?.tag
                )
            )
            .map((el) => ({
              label: el?.tagId?.tag,
              id: el?.tagId?._id,
            }));
          setTags(dt);
        }
      }
    });
    setLoading(false);
  };

  const HandleTags = (handleTagData) => {
    setTagsPopover(handleTagData);
    setOpenTags(true);
  };

  const ApplyFilter = () => {
    setFilterData({
      ...filterData,
      arrivalStart: endDate,
      arrivalEnd: endDateEnd,
    });
    fetchCustomers(tagsfiltr);
  };

  useEffect(() => {
    const filterString = tagsfiltr
      ?.filter((val, id, arrayData) => {
        return arrayData.indexOf(val) === id;
      })
      .map((itm) => ({
        ...tags?.find((tg) => tg.id === itm),
      }));
    setFilterStrings(filterString);
  }, [tagsfiltr]);

  useEffect(() => {
    fetchCustomers();
    fetchTags();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (data) => {
        return (
          <>
            <img
              src={data?.row.customerimage || userImg}
              className="users-img feedback"
              alt=""
            />
            <div className="MuiDataGrid-cellContent feedback-name">
              <span>{data?.row?.name}</span>
              <span>
                {data?.row.createdAt} {data?.row?.time}
              </span>
            </div>
          </>
        );
      },
      sortable: false,
    },

    {
      field: "feedback",
      headerName: "Feedback",
      width: 650,
      renderCell: (data) => {
        return (
          <>
            <div className="MuiDataGrid-cellContent feedback-cell">
              {data?.row?.feedback}
            </div>
          </>
        );
      },
      sortable: false,
    },
    {
      field: "tags",
      headerName: "Tags",
      renderCell: (tagData) => {
        let tagDataLength = tagData?.row?.tags?.length;
        return (
          <>
            <div>
              {tagDataLength > 0 ? (
                <span
                  className="tags-cell"
                  style={
                    tagDataLength === 1
                      ? { cursor: "pointer" }
                      : { cursor: "initial" }
                  }
                  onClick={() => {
                    if (tagDataLength === 1) HandleTags(tagData);
                  }}
                >
                  {tagData?.row?.tags[0]?.tagId?.tag}
                </span>
              ) : null}
              {tagDataLength > 1 ? (
                <Button
                  variant="outlined"
                  onClick={() => HandleTags(tagData)}
                  className="custom-mui-button tags-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                    marginLeft: "5px",
                  }}
                >
                  +{tagDataLength - 1}
                </Button>
              ) : null}
              <Dialog open={openTags} className="tags-dialog">
                <DialogTitle>
                  Tags<span className="apply-alert">Click a tag to remove</span>
                </DialogTitle>
                <DialogContent>
                  <>
                    <List className="tags-listing">
                      {tagsPopover?.row?.tags?.map((item, index, arr) => {
                        return (
                          <ListItem
                            sx={{
                              display: "inline-block",
                              width: "fit-content",
                              margin: "4px 6px",
                              padding: "0",
                            }}
                            key={index}
                            onClick={() => dltTags(item)}
                          >
                            <ListItemText
                              className="tags-cell"
                              primary={item?.tagId?.tag}
                              onClick={({ target }) =>
                                target.classList.toggle("added-tag-remove")
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                </DialogContent>
                <DialogActions>
                  {array?.length > 0 ? (
                    <Button
                      className="custom-mui-button"
                      onClick={() => deleteFeedbackTag(tagsPopover, array)}
                    >
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setOpenTags(false);
                      setArray([]);
                    }}
                    className="custom-mui-button"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      },
      width: 170,
      sortable: false,
    },
  ];
  return (
    <>
      <div className="container">
        <div className="customer_listing_top customer_feedback_top pt-5">
          <div className="row dashboard_tittle_box">
            <div className="col-md-4">
              <CustomBreadcrumbs title="Micro Feedback" />
            </div>
            <div className="col-md-8 right_align display_flex d-lg-flex d-md-block justify-content-end align-items-center gap-3 feedback-header">
              <TextField
                id="search-feedback"
                label=""
                value={feedbackSearch}
                className="search-customer-feedback"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e) => setFeedbackSearch(e.target.value)}
                sx={{ height: "37px", width: "auto" }}
              />
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  gap: "5px",
                }}
              >
                <MdAddCircleOutline style={{ minWidth: "15px" }} />
                Add Tags
              </Button>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="add-tags-modal"
              >
                {selection[0]?.length > 0 ? (
                  <DialogTitle> Add Tags</DialogTitle>
                ) : (
                  <DialogTitle>No customer Selected</DialogTitle>
                )}
                <DialogContent>
                  {selection[0]?.length > 0 ? (
                    <>
                      <Autocomplete
                        disablePortal
                        id="combo-box"
                        options={tags}
                        sx={{ width: "100%" }}
                        // onChange={(options) => onTagsChange(options)}
                        onChange={(event, newValue) => {
                          setValueAddTags(newValue);
                          document.getElementsByClassName(
                            "MuiAutocomplete-popperDisablePortal"
                          )[0].style.display = "none";
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          setNewTagVal(newInputValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.label === undefined ? "" : option.label
                        }
                        clearOnBlur={false}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                          </Box>
                        )}
                        open={inputValue.length > 2}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </>
                  ) : (
                    <Typography color="tomato">
                      Please select a customer
                    </Typography>
                  )}
                  {errAlrt ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "tomato",
                        paddingTop: "10px",
                      }}
                    >
                      Error: No tags added
                    </Typography>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setErrAlrt(false);
                      setNewTagVal("");
                      setValueAddTags("");
                    }}
                    sx={{ fontSize: "16px", color: "#808080" }}
                  >
                    Cancel
                  </Button>
                  {selection[0]?.length > 0 ? (
                    <Button
                      onClick={AddTagsHandle}
                      sx={{
                        background: "#04736D !important",
                        fontSize: "14px",
                        color: "#FEFFFF",
                        borderRadius: 0,
                        maxWidth: "100px",
                        width: "100%",
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </DialogActions>
              </Dialog>
              <Button
                variant="outlined"
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  background: "#fff !important",
                  color: "#6C757D !important",
                  border: "1px solid #CED4DA !important",
                }}
                onClick={() => setOpenFilters(true)}
              >
                <img alt="" className="img-fluid pe-2" src={filterIcon} />
                Filters
              </Button>

              <Dialog
                open={openFilters}
                onClose={() => setOpenFilters(false)}
                className="filters-modal"
              >
                <DialogTitle
                  sx={{ padding: "8px", color: "#02736D", fontSize: "18px" }}
                >
                  {" "}
                  Filters
                </DialogTitle>
                <Button
                  onClick={() => setOpenFilters(false)}
                  sx={{
                    maxWidth: "20px",
                    position: "absolute",
                    right: 0,
                    color: "#808080",
                    fontSize: "18px",
                  }}
                >
                  x
                </Button>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <label>
                    Tags
                    <span className="apply-alert">
                      Please 'Apply' after selecting tags
                    </span>
                  </label>
                  <div className="tags-filter-section">
                    {tags?.map((item, indx) => {
                      return (
                        <p
                          key={indx}
                          className="tags-cell filter"
                          onClick={() => {
                            if (tagsfiltr?.some((el) => el === item.id)) {
                              setTagsFiltr(tagsfiltr);
                            } else {
                              setTagsFiltr((prev) => [...prev, item.id]);
                            }
                          }}
                        >
                          {item.label}
                        </p>
                      );
                    })}
                  </div>
                  <label>Feedback added on:</label>
                  <Box sx={{ display: "flex" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label=""
                        className="date-pickers-customers"
                        inputFormat="MM/DD/YYYY"
                        value={endDate}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: FiCalendar,
                        }}
                        sx={{ display: "inline-block" }}
                      />
                      <span style={{ color: "#6C757D", padding: "10px" }}>
                        To
                      </span>

                      <DesktopDatePicker
                        label=""
                        className="date-pickers-customers"
                        inputFormat="MM/DD/YYYY"
                        value={endDateEnd}
                        onChange={handleChangeEndDateEnd}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: FiCalendar,
                        }}
                        sx={{ display: "inline-block" }}
                      />
                    </LocalizationProvider>
                  </Box>
                </FormControl>
                <DialogActions sx={{ justifyContent: "flex-start" }}>
                  <Button
                    onClick={ApplyFilter}
                    sx={{
                      background: "#035450!important",
                      color: "#fff",
                      borderRadius: 0,
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    onClick={() => {
                      setEndDate("");
                      setEndDateEnd("");
                      setOpenFilters(false);
                      setFilterData({
                        arrivalStart: "",
                        arrivalEnd: "",
                        unlockStart: "",
                        unlockEnd: "",
                      });
                      setTagsFiltr([]);
                      fetchCustomers();
                    }}
                    sx={{
                      background: "#E13773 !important",
                      color: "#fff",
                      borderRadius: 0,
                    }}
                  >
                    Reset Filters
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        <Box sx={{ flexGrow: 1 }} className="customers-header">
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={12}>
              {filterStrings.length > 0 ? (
                <div>
                  <span className="tags-filter-label">Tags: </span>
                  {filterStrings.map((itm, idx) => {
                    return (
                      <p key={idx} className="tags-cell filter listing">
                        {itm.label}{" "}
                        <span
                          onClick={() => {
                            TagsRemove(idx);
                          }}
                        >
                          x
                        </span>
                      </p>
                    );
                  })}
                </div>
              ) : null}
              <div
                className="feedbacktable_table"
                style={{ height: 1000, width: "100%" }}
              >
                {!loading && (
                  <DataGrid
                    rows={feedbackData}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelection([newSelectionModel]);
                    }}
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                  />
                )}
                {loading && <Loader />}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Modal
        id="addtagmodal"
        isOpen={isAddModalOpen}
        toggle={closeAddModal}
        className="perks_modal add_tag_modal"
      >
        <ModalHeader>
          <div className="close_modal_box close_popup">
            <a className="link-common" onClick={closeAddModal} href="#!">
              <img className="img-fluid close_icon" src={closeIcon} alt="" />
            </a>
          </div>
          <label>Add Tags</label>
        </ModalHeader>
        <ModalBody>
          <div className="perks_modal_body">
            <div className="col-md-12">
              <input
                list="Tags"
                name="tag"
                id="tag"
                className="form-control"
                placeholder="Enter Tags"
              />
              <datalist id="Tags">
                <option value="Food" />
                <option value="Airline" />
                <option value="Budget" />
                <option value="Date" />
                <option value="Location" />
              </datalist>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="cancel_button">Cancel</button>
          <button className="save_button">Add</button>
        </ModalFooter>
      </Modal>

      <Modal
        id="filtermodal"
        isOpen={filterModalOpen}
        toggle={closefilterModal}
        className="perks_modal filter_modal"
      >
        <ModalHeader>
          <div className="close_modal_box close_popup">
            <a className="link-common" onClick={closefilterModal} href="#!">
              <img
                className="img-fluid close_icon"
                src={closeIcon}
                alt="closeicon"
              />
            </a>
          </div>
          <label>Filters</label>
        </ModalHeader>
        <ModalBody>
          <div className="perks_modal_body">
            <div className="col-md-12">
              <label>Tags</label>
              <select name="cars" id="cars" className="form-select">
                <option value="Food">Food</option>
                <option value="Cruise">Cruise</option>
                <option value="Date">Date</option>
                <option value="Location">Location</option>
                <option value="Budget">Budget</option>
                <option value="Airline">Airline</option>
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="reset_button">Reset Filters</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Feedback;
