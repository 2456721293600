import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/styles.css";
import "../../assets/css/register.css";
import loginbg from "../../assets/images/login_bg.webp";
import { registerUser } from "../../services/api/auth.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../helpers/app.constants";
import { ShowToastMessage } from "../../components/toastr";
import { Helmet } from "react-helmet-async";
import { getCategories } from "../../services/api/company.service";

const Register = () => {
  const initialValues = {
    name: "",
    ownerManager: "",
    email: "",
    role: "",
    ownerManagerName: "",
    mobileNoCC: "",
    mobileNo: "",
    website: "",
    ownerManagerEmail: "",
    categoryId: "",
    ownerManagerMobileNoCC: "",
    ownerManagerMobileNo: "",
    googlePlaceId: "",
    googlelocationId: "",
    googlelocationname: "",
    ownerManagerTitle: "",
    image: "",
    address: "",
  };
  const [defaultCategory, setDefaultCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const [formValues, setFormValues] = useState(initialValues);

  const [checkedState, setcheckedState] = useState("Owner");
  const [travel_checked, setTravel_checked] = useState(false);
  const [categoryType, setCategoryType] = useState("hotel");
  formValues.categoryId = "61c450e2735e4c195c32b11e";
  const navigate = useNavigate();


  const fetchCategories = async () => {
    const res = await getCategories();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCategories(res.data);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    data.categoryId = [data.categoryId];
    const register = await registerUser(data, navigate);
    if (register != null) {
      if (!register.error) {
        ShowToastMessage(
          "Successfully Registered",
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        ShowToastMessage(
          `${register.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
    } else {
      var errorMessage =
        register.error && register.msg ? register.msg : `${register.msg}`;
      ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
      return false;
    }
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setDefaultCategory(value);
    setCategoryType(
      e?.target[e?.target.selectedIndex]
        ?.getAttribute("data-tag")
        ?.toLowerCase()
    );
    formValues.categoryId = value;
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container">
        <div id="signup_bar">
          <div className="top-btn-links col-md-12">
            <Link to={process.env.REACT_APP_URL}>Back to Home</Link>
          </div>
          <div className="admin_login col-md-12">
            <div className="steps_row admin_login_inner">
              <h1>Create A New Free Business Account</h1> 
              {/* <span className="steps"> 
                <span className="gray">Step:</span>{" "} 
                <span>(1)Create An Account</span> of{" "} 
                <a href="#!"> (2)Claim A Business</a> 
              </span> */} 
            </div>
            <div className="admin_login_inner col-md-12">
              <div className="left_login_panel col-md-6 col-12">
                <img className="img-fluid" src={loginbg} alt="" />
              </div>
              <div className="right_login_panel col-md-6 col-12">
                <p style={{ color: "#000;", opacity: "1" }}>
                  Complete the following information to setup a free new account for your company 
                </p>
                <form onSubmit={handleSubmit}>
                  <label>
                    Company Email
                    <input name="email" placeholder="Enter Company Email" />
                  </label>
                  <label>
                    Company Name
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Company Name"
                    />
                  </label>
                  <label className="tel">
                    Company Phone Number
                    <input
                      type="tel"
                      name="mobileNo"
                      placeholder="Enter Phone Number"
                    />
                  </label>
                  <label>
                    Select Company Type You Represent
                    <select
                      name="categoryId"
                      id="Business"
                      className="form-select"
                      value={defaultCategory}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Please Select</option>
                      {categories &&
                        categories.map((category) => (
                          <option
                            key={category._id}
                            value={category._id}
                            data-tag={category.categoryName}
                          >
                            {category.categoryName}
                          </option>
                        ))}
                    </select>
                  </label>
                  {categoryType === "travel agency" && (
                    <div className="confirmation">
                      <input
                        name="travelAgencyStatus"
                        type="checkbox"
                        value={travel_checked}
                        onChange={(event) => {
                          setTravel_checked(event.target.checked);
                        }}
                        className="confirmation_input"
                        required
                      />
                      <label id="travelAgencyStatus">
                        I confirm I am a travel agent or agency and would like a
                        special view of the platform
                      </label>
                    </div>
                  )}

                  <div className="input_flex justify-content-start">
                    <label className="checkbox">
                      <input
                        type="radio"
                        name="ownerManager"
                        value="Owner"
                        checked={checkedState === "Owner"}
                        onChange={() => setcheckedState("Owner")}
                      />
                      <span>Owner</span>
                    </label>

                    <label className="checkbox">
                      <input
                        type="radio"
                        name="ownerManager"
                        value="Manager"
                        checked={checkedState === "Manager"}
                        onChange={() => setcheckedState("Manager")}
                      />
                      <span>Manager</span>
                    </label>
                  </div>
                  <label className="submit">
                    <input id="submit" type="submit" name="submit" value="Create Free Account & Claim Business" /> 
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Register;
