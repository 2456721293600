import { Button, Spinner } from "reactstrap";
import React from "react";

export const SpinButton = (props) => {
  const { className, type, text, showSpinner } = props;
  return (
    <Button className={className} type={type}>
      {text}
      {showSpinner && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </Button>
  );
};
