import imageCompression from "browser-image-compression";

export const handleImagechange = async (e) => {
  const imageFile = e.target.files[0];
  const options = {
    maxSizeMB: 0.6,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return await getBase64(compressedFile);
  } catch (error) {
    console.log(error);
  }

  function getBase64(filen) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(filen);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
};
