import React from "react";
import Button from "@mui/material/Button";
import { MdAddCircleOutline } from "react-icons/md";
import { Box, Grid, InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import "../../../assets/css/locations.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";

const Locations = () => {
  const handleSubmitOne = () => {
    console.log("handleSubmitOne");
  };
  const handleSubmitTwo = () => {
    console.log("handleSubmitTwo");
  };
  return (
    <>
      <div className="container pt-5 locations-wrapper">
        <Box sx={{ flexGrow: 1 }} className="customers-header">
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={8} md={8} lg={6}>
              <CustomBreadcrumbs title="Locations (Geofence)" />
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              lg={6}
              sx={{ display: "flex", gap: "16px", justifyContent: "end" }}
              className="search-section"
            >
              <Button
                variant="outlined"
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  gap: "5px",
                }}
              >
                <MdAddCircleOutline style={{ minWidth: "15px" }} />
                Add Location
              </Button>
            </Grid>
          </Grid>

          <Grid container className="details_box">
            <Grid item xs={12} sx={{ p: 1 }}>
              <Alert className="text-message" severity="info">
                Get a text message when a customer arrives at your location -
                coming soon
              </Alert>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <iframe
                title="myFrame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114386.0423924976!2d-80.18740637190162!3d26.37296616418702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d923c2ef36fb27%3A0xd23ea6eeca6095c9!2sBoca%20Raton%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1667983964416!5m2!1sen!2sin"
                height="400"
                width="100%"
                sx={{ mb: 4 }}
              ></iframe>
              <Typography variant="subtitle1" gutterBottom>
                Location Detail:
              </Typography>

              <ValidatorForm onSubmit={() => handleSubmitOne()}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Street Adddress:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>City:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>State:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Zip Code:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="outlined"
                      className="custom-mui-button add-tags-btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      className="custom-mui-button cancel-btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <iframe
                title="myFrame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114964.38943664465!2d-80.29949938545238!3d25.78254531250117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b0a20ec8c111%3A0xff96f271ddad4f65!2sMiami%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1667987451178!5m2!1sen!2sin"
                width="100%"
                height="400"
                sx={{ mb: 4 }}
              ></iframe>
              <Typography variant="subtitle1" gutterBottom>
                Location Detail:
              </Typography>
              <ValidatorForm onSubmit={() => handleSubmitTwo()}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Street Adddress:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>City:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>State:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Zip Code:</InputLabel>
                    <TextValidator
                      id="outlined-basic"
                      variant="outlined"
                      label=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="outlined"
                      className="custom-mui-button add-tags-btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      className="custom-mui-button cancel-btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Locations;
