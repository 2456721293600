import { Grid } from "@mui/material";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import Smartlook from 'smartlook-client';
import { getUserInfo } from "../../helpers/helper";
import { useEffect } from "react";

const Home = () => {
  const userInfo = getUserInfo(); // Declare userInfo using let

  const initializeSmartlook = () => {
    // Check if userInfo is defined and has the required properties
    if (userInfo && userInfo.name && userInfo.email) {
      Smartlook.init('4a4a03b16daead3b6578042051bf69eeea4545e7');
      Smartlook.identify('-', {
        name: userInfo.name,
        email: userInfo.email,
      });
    } else {
      console.error('Error: userInfo is not defined or does not contain the required properties.');
    }
  };

  useEffect(() => {
    if(userInfo){
      initializeSmartlook();
    }
  }, [userInfo]);

  return (
    <div className="container pt-5">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <CustomBreadcrumbs title="Dashboard" />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
