import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const apiToken = sessionStorage.getItem("userToken");
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error?.response?.data);
  }
);

const { get, post, put, patch, delete: destroy } = apiClient;
export { get, post, put, patch, destroy };
