import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "../../assets/css/customer_modal.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {
  getPreferenceCategorySubcategory,
  getPreferenceTabsDatas,
} from "../../services/api/customer.service";
import { TOAST_ERROR } from "../../helpers/app.constants";
import { toast } from "react-toastify";
import { ShowToastMessage } from "../toastr";
import { Loader } from "../loader";
import { useAuth } from "../../hooks/useAuth";
import { getUnlockedPreferencesForUser } from "../../services/api/preference.service";

const color = {
  "&.Mui-checked": {
    color: "#04726d",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Preference = ({ rowData }) => {
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = useState([]);
  const [tabDatas, setTabDatas] = useState([]);
  const [catgIndex, setCatgIndex] = useState(0);
  const [subcatgIndx, setSubcatgIndx] = useState(0);
  const [catgId, setCatgId] = useState("");
  const [tab, setTab] = useState("company");
  const [loading, setLoading] = useState(true);
  const [isPreferenceLocked, setIsPreferenceLocked] = useState(false);
  const auth = useAuth();
  const user = auth?.user ? JSON.parse(auth.user) : null;
  const companyId = user._id

  const subCategories = category?.map((catg) => {
    return catg?.subcategory;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePreferenceSubcatgsDatas = async (subCatgId, i) => {
    setSubcatgIndx(i);
    let data = {
      categoryId: catgId ? catgId : category[0]?._id,
      subcategoryId: subCatgId
        ? subCatgId
        : category[catgIndex]?.subcategory[0]?._id,
      userId: rowData?.userId?._id,
      tab: tab,
    };
    const res = await getPreferenceTabsDatas(data);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setTabDatas(res.data);
    }
  };

  const getPreferencesForTab = (unlockedPreferencesData, tab) => {
    const companyId = user._id;
    let preferences;
  
    const companyPreference = unlockedPreferencesData.preferenceData.find(
      (pref) => pref.companyId === companyId
    );
    if (!companyPreference) {
      return null;
    }
  
    if (tab === "company") {
      preferences = companyPreference.companyPreferences[0];
    } else if (tab === "user") {
      preferences = companyPreference.userPreferences[0];
    } else if (tab === "admin") {
      preferences = companyPreference.adminPreferences[0];
    }
  
    return preferences;
  };

  const fetchPreferenceCategorySubcategory = async () => {
    setLoading(true);
    let data = {
      userId: rowData?.userId?._id,
      tab: "company",
    };

    try {
      const unlockedPreferencesRes = await getUnlockedPreferencesForUser(companyId, data.userId);

      if (unlockedPreferencesRes && unlockedPreferencesRes.data) {

        const res = await getPreferenceCategorySubcategory(data);
        if (res && !res.error) {
          setCategory(res.data);

          let preferences = getPreferencesForTab(unlockedPreferencesRes.data, data.tab);

          const isPreferenceLocked = preferences?.lock;
          if (isPreferenceLocked) {
            setIsPreferenceLocked(true)
            // Check lock preferences and set tab datas accordingly
            getPreferenceTabsDatas({
              categoryId: res?.data[0]?._id,
              subcategoryId: res?.data[0]?.subcategory[0]?._id,
              userId: rowData?.userId?._id,
              tab: data.tab,
            }).then((tabRes) => {
              setTabDatas(isPreferenceLocked ? tabRes.data : []);
            });
          } else {
            setIsPreferenceLocked(false)
            setTabDatas([]);
          }
        } else {
          var errorMessage = res.msg ? res.msg : "Error Loading the data";
          ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePreferenceTabsDatas = async (tab1) => {
    setLoading(true);
    setTab(tab1);
    setValue(0);
    setCatgIndex(0);
    setSubcatgIndx(0);
    let data1 = {
      userId: rowData?.userId?._id,
      tab: tab1 ? tab1 : "company",
    };

    try {
      const unlockedPreferencesRes = await getUnlockedPreferencesForUser(companyId, data1.userId);

      if (unlockedPreferencesRes && unlockedPreferencesRes.data) {
        const res1 = await getPreferenceCategorySubcategory(data1);

        if (res1) {
          if (res1.error) {
            var errorMessage = res1.msg ? res1.msg : "Error Loading the data";
            ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
          }

          if (res1.data) {
            setCategory(res1.data);

            let data = {
              categoryId: category[0]?._id,
              subcategoryId: category[0]?.subcategory[0]?._id,
              userId: rowData?.userId?._id,
              tab: tab1 ? tab1 : tab,
            };

            let preferences = getPreferencesForTab(unlockedPreferencesRes.data, data.tab);
            const isPreferenceLocked = preferences?.lock;

            if (isPreferenceLocked) {
              setIsPreferenceLocked(true);

              const res = await getPreferenceTabsDatas(data);
              if (res) {
                if (res.error) {
                  var errorMessage1 = res.msg ? res.msg : "Error Loading the data";
                  ShowToastMessage(errorMessage1, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
                }

                if (res) {
                  setTabDatas(res.data);
                }
              }
            } else {
              setIsPreferenceLocked(false);
              setTabDatas([]);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handlePreferenceCatgsDatas = async (catgID, i, subcatgID) => {
    setLoading(true);
    setCatgIndex(i);
    setSubcatgIndx(0);
    setCatgId(catgID)
    let data = {
      categoryId: catgID ? catgID : category[0]?._id,
      subcategoryId: subcatgID,
      userId: rowData?.userId?._id,
      tab: tab,
    };
    const res = await getPreferenceTabsDatas(data);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setTabDatas(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPreferenceCategorySubcategory();
    setCatgIndex(0);
    setValue(0);
    setSubcatgIndx(0);
  }, []);

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="company"
          className="Radio-btns"
          onChange={(e) => handlePreferenceTabsDatas(e.target.value)}
        >
          <FormControlLabel
            value="company"
            control={<Radio sx={color} />}
            label="Company Prompts"
          />
          <FormControlLabel
            value="user"
            control={<Radio sx={color} />}
            label="Customer Provided"
          />
          <FormControlLabel
            value="admin"
            control={<Radio sx={color} />}
            label="Vault Preference"
          />
        </RadioGroup>
      </FormControl>
      {!loading ? (
        <>
          <Box
            component="div"
            sx={{ p: 1, mt: 1, display: "flex", flexDirection: "row" }}
          >
            {category?.map((catg, i) => {
              return (
                <>
                  {isPreferenceLocked ?
                    <Typography
                      key={catg?._id}
                      className="box-chips"
                      style={{ marginRight: 13 }}
                      onClick={() => {
                        handlePreferenceCatgsDatas(
                          catg?._id,
                          i,
                          catg?.subcategory[0]._id
                        );
                      }}
                    >
                      {catg?.categoryName} <span>{catg?.count}</span>
                    </Typography> : null}
                </>
              );
            })}
          </Box>
          <div className="preference-main-div">
            {isPreferenceLocked ? <Box
              component="div"
              sx={{ p: 1, mt: 1, border: "solid 1px lightgray" }}
            >
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    textColor="secondary"
                    indicatorColor="secondary"
                    className="sub-tabs"
                  >
                    {subCategories[catgIndex]?.map((itm, i) => {
                      return (
                        <Tab
                          onClick={() =>
                            handlePreferenceSubcatgsDatas(itm?._id, i)
                          }
                          label={itm?.subcategoryName}
                          {...a11yProps(i)}
                          disabled={itm?.count === 0 ? true : false}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={subcatgIndx}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      width: "100%",
                      flexWrap: "wrap",
                      gap: "2%",
                    }}
                  >
                    {tabDatas &&
                      tabDatas?.map((itm) => {
                        return (
                          <Box key={itm._id} className="Preferences-TabPanel">
                            {itm?.preferencereply?.length > 0 &&
                              itm?.type === "yesNo" && (
                                <Typography
                                  sx={{
                                    borderRadius: "4px",
                                    backgroundColor:
                                      itm?.preferencereply[0]?.like === false
                                        ? "#FDF2F6"
                                        : "#E2EEED",
                                    color: "black",
                                    p: 1,
                                    fontSize: "14px",
                                  }}
                                >
                                  <Typography
                                    className="customer-modal-icons"
                                    component="span"
                                    style={{
                                      color:
                                        itm?.preferencereply[0]?.like === false
                                          ? "#E83371"
                                          : "#01726C",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {itm?.preferencereply[0]?.like ? (
                                      <ThumbUpIcon />
                                    ) : (
                                      <ThumbDownAltIcon />
                                    )}
                                  </Typography>{" "}
                                  {itm?.title}
                                </Typography>
                              )}

                            {itm?.preferencereply?.length > 0 &&
                              itm?.type === "multipleChoice" && (
                                <Typography
                                  sx={{
                                    borderRadius: "4px",
                                    color: "black",
                                    backgroundColor: "#E2EFEE",
                                    p: 1,
                                    fontSize: "14px",
                                  }}
                                >
                                  <Typography
                                    className="customer-modal-icons"
                                    component="span"
                                    style={{ color: "#00736D" }}
                                  >
                                    <MoreHorizIcon />
                                  </Typography>{" "}
                                  {itm?.title}
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      mt: "8px",
                                      backgroundColor: "white",
                                      borderRadius: 0.5,
                                    }}
                                    style={{ padding: "2px 7px" }}
                                  >
                                    {itm?.preferencereply[0]?.selectedChoice}
                                  </Typography>
                                </Typography>
                              )}

                            {itm?.preferencereply?.length > 0 &&
                              itm?.type === "freeFlow" && (
                                <Typography
                                  sx={{
                                    borderRadius: "4px",
                                    color: "black",
                                    backgroundColor: "#E2EFEE",
                                    p: 1,
                                    fontSize: "14px",
                                  }}
                                >
                                  <Typography
                                    className="customer-modal-icons"
                                    component="span"
                                    style={{ color: "#00736D" }}
                                  >
                                    <TextSnippetIcon />
                                  </Typography>{" "}
                                  {itm?.title}
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      mt: "8px",
                                      backgroundColor: "white",
                                      borderRadius: 0.5,
                                    }}
                                    style={{ padding: "2px 7px" }}
                                  >
                                    {itm?.preferencereply[0]?.quickNote}
                                  </Typography>
                                </Typography>
                              )}
                          </Box>
                        );
                      })}
                  </Box>
                </TabPanel>
              </Box>
            </Box> : <p>User has locked this preference category.</p>}
          </div>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};
export default Preference;