import React, { useCallback, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Input,
  Card,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";

import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import "../../../assets/css/preference.css";
import { Loader } from "../../../components/loader";
import { getCategoriesWithSubAndPrefCount } from "../../../services/api/preference.service";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";
import {
  addSubcategory,
  deleteSubcategory,
} from "../../../services/api/subcategory.service";
import { confirm } from "react-confirm-box";

const Preference = () => {
  const [loading, setLoading] = useState(true);
  const [addPRefData, setAddPrefData] = useState([]);
  const [isAddSubCatModalOpen, setAddSubCatModalOpen] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [deleteCancelLabel, setDeleteCancelLabel] = useState("Delete");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [prefCardId, setPrefCard] = useState(null);

  useEffect(() => {
    setLoading(true);
    getInfoForAddPref();
  }, []);

  const getInfoForAddPref = useCallback(async () => {
    const response = await getCategoriesWithSubAndPrefCount();
    if (response) {
      if (response.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error Loadng the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setAddPrefData(response.data);
    }
    setLoading(false);
  }, []);

  const toggle = (Id) => {
    if (prefCardId === Id) {
      setPrefCard(null);
    } else {
      setPrefCard(Id);
      setAddSubCatModalOpen(false);
    }
  };
  const handleCard = (isOpen) => {
    if (isOpen) toggleDelete(false);
  };
  const handleAddSubCat = (catgId) => {
    setAddSubCatModalOpen(true);
  };
  const handleDeleteSubCat = (data) => {
    var showDelete = !showDeleteIcon;
    toggleDelete(showDelete);
  };
  const toggleDelete = (showDelete) => {
    setShowDeleteIcon(showDelete);
    if (showDelete) {
      setDeleteCancelLabel("Cancel");
    } else {
      setDeleteCancelLabel("Delete");
    }
  };
  const handleDeleteSubCategory = async (id) => {
    if (showDeleteIcon) {
      var options = {
        closeOnOverlayClick: false,
        labels: {
          confirmable: "Delete",
          cancellable: "Cancel",
        },
      };
      const result = await confirm(
        "Do you wish to delete this Sub Category?",
        options
      );
      if (result) {
        deleteSubcategory(id)
          .then((response) => {
            if (response.error) {
              var errorMessage = response.msg
                ? response.msg
                : "Error deleting the sub category item";
              ShowToastMessage(
                errorMessage,
                TOAST_ERROR,
                toast.POSITION.BOTTOM_RIGHT
              );
            } else {
              getInfoForAddPref();
              ShowToastMessage(
                "Successfully deleted the sub category",
                TOAST_SUCCESS
              );
            }
          })
          .catch((err) => {
            var errorMessage = err
              ? err
              : "Error deleting the sub category item";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
          });
      }
    }
  };
  const handleSaveSubCat = async (catgId) => {
    const response = await addSubcategory(catgId, subcategoryName);
    if (response) {
      if (response.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error adding the sub category item";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        ShowToastMessage(
          "Successfully created the sub category",
          TOAST_SUCCESS
        );
        setAddSubCatModalOpen(false);
        setSubcategoryName("");
        getInfoForAddPref();
      }
    }
  };
  const handleCancelSave = () => {
    setAddSubCatModalOpen(false);
    setSubcategoryName("");
  };

  return (
    <React.Fragment>
      <Container className="container">
        <div className="row dashboard_tittle_box">
          <div className="col-md-6">
            <h3>Preferences</h3>
            <h6 className="pref_import_tittle">
              Home <span>Preferences</span>
            </h6>
          </div>
        </div>
        <div className="preference_table">
          {!loading &&
            sortBy(addPRefData, ["categoryName"]).map((record, idx) => {
              return (
                <Card
                  id={"Card_" + idx}
                  style={{ marginBottom: "1rem" }}
                  key={idx}
                >
                  <CardHeader data-event={idx} data-type="collapseBanner">
                    <div className="category_of_preferences_left">
                      <div className="category_of_preferences_box">
                        <div className="category_content_left_box">
                          <img
                            className="img-fluid"
                            src={record.image}
                            style={{ width: "70%" }}
                            alt=""
                          />
                        </div>
                        <div className="category_content_right_box display_grid">
                          <span>{record.categoryName}</span>
                          <Link
                            className="link-common"
                            to="/admin/preference/list"
                            state={{
                              categoryId: record._id,
                              categoryName: record.categoryName,
                              prefCardId: idx,
                            }}
                          >
                            <h5 className="pt-1 mb-0">
                              {record.count} Preference Prompts
                            </h5>
                          </Link>
                          <h5
                            className="pt-0"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => toggle(idx)}
                          >
                            {record.subcategory.length} Sub Categories
                          </h5>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse
                    isOpen={prefCardId === idx}
                    onEntering={(e) => handleCard(prefCardId === idx)}
                  >
                    <CardBody id={"CardBody_" + idx}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h2 className="float-left">
                            {record?.categoryName} Sub Categories
                          </h2>
                          <div className="clear-both">
                            <div className="food_category_buttons">
                              {sortBy(record.subcategory, [
                                (o) => o.subcategoryName !== "General",
                                (o) => o.subcategoryName,
                              ]).map((subR, sIdx) => (
                                <label
                                  className={
                                    subR.subcategoryName !== "General"
                                      ? showDeleteIcon
                                        ? "show-deleteicon"
                                        : ""
                                      : "general"
                                  }
                                  id={"sc_" + subR._id}
                                  onClick={(e) => {
                                    if (subR.subcategoryName !== "General") {
                                      handleDeleteSubCategory(subR._id);
                                    }
                                  }}
                                  key={sIdx}
                                >
                                  {subR.subcategoryName}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="float-right add-subcat-buttons d-flex">
                          <button
                            className="float-left action_button mb-0 mr-2"
                            onClick={(e) => handleAddSubCat(record._id)}
                          >
                            Add
                          </button>
                          <button
                            className="float-left action_button m-0"
                            onClick={(e) => handleDeleteSubCat(record._id)}
                          >
                            {deleteCancelLabel}
                          </button>
                        </div>
                      </div>

                      <div className={isAddSubCatModalOpen ? "" : " hidden"}>
                        <h2 className="float-left pt-3 w-100">
                          Add Sub Categories
                        </h2>
                        <Input
                          id={"txt_addsubcat_" + idx}
                          name={"txt_addsubcat_" + idx}
                          type="textarea"
                          value={subcategoryName}
                          onChange={(e) => setSubcategoryName(e.target.value)}
                          className="float-left text_area"
                        />
                        <span>
                          <button
                            className="save-button float-right"
                            onClick={(e) => handleSaveSubCat(record._id)}
                          >
                            Save
                          </button>
                          <button
                            className="save-button float-right me-3"
                            onClick={handleCancelSave}
                          >
                            Cancel
                          </button>
                        </span>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })}
          {loading && <Loader />}
        </div>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};
export default Preference;
