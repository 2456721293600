import React, { useEffect } from "react";
import { Spinner } from "reactstrap";

export const LoaderWhite = (props) => {
  useEffect(() => {
    const sidebar = document.getElementById("mySidebar");
    const loader = document.getElementById("main_loader");
    if (sidebar.style.display === "none") {
      loader.style.width = "100%";
    } else {
      loader.style.width = "calc(100% - 260px)";
    }
  }, []);
  return (
    <div className="main_loader" id="main_loader">
      <Spinner
        className="load-spinner"
        color="light"
        as="span"
        animation="border"
      ></Spinner>
    </div>
  );
};
