import { get, post } from "./config";

export const getAllCategories = async () => {
  return get("/category/superadmin/allCategory")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCategory = async (catgId) => {
  return get("/category/superadmin/singleCategory", { _id: catgId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getFeedback = async (tagsfiltr) => {
  return post("/microfeedback/admin/getFeedbacksOfCompany", {
    tagId: tagsfiltr === undefined ? [] : tagsfiltr,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
