import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import React from "react";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { BsCheckLg } from "react-icons/bs";
import "../../../assets/css/billing.css";
import time from "../../../assets/images/time.svg";
import { getUserInfo } from "../../../helpers/helper";
import { Link } from 'react-router-dom';

const Billing = () => {
  const { travelAgencyStatus } = getUserInfo();

  return (
    <>
      <div className="billing_container pt-5 container">
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={12} md={8} lg={6}>
            <CustomBreadcrumbs title="Billing" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div
              className="billing_top_section warning_section"
              style={{
                justifyContent: "start",
                gap: "20px",
                marginTop: "10px",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Our Pricing Plan
              </Typography>
              <div className="time_warning">
                <img src={time} alt="" className="time_image" />
                All accounts free during introductory period
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="billing_top_section ">
              <Typography variant="h6" gutterBottom>
                Introductory pricing for all sized companies
              </Typography>
              {!travelAgencyStatus && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#097B75",
                    textTransform: "none",
                    fontSize: "15px",
                    padding: "2px 10px",
                  }}
                  className="custom-mui-button invite_link_btn"
                >
                  Manage Account
                </Button>
              )}
            </div>
          </Grid>
          {(travelAgencyStatus ? travelAgentStore : store).map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={item.price}>
                <Card
                  variant="outlined"
                  className={
                    item.recommended
                      ? "billing_card recommended"
                      : "billing_card"
                  }
                  style={{ height: '100%' }}
                >
                  <div className="recommended_section">
                    <Typography variant="h5" gutterBottom>
                      {item.title}
                    </Typography>
                    {item.recommended && (
                      <Button
                        sx={{
                          backgroundColor: "#fff !important",
                          textTransform: "none",
                          fontSize: "11px !important",
                          padding: "3px 4px !important",
                          color: "#04726d !important",
                        }}
                        className="custom-mui-button "
                      >
                        {travelAgencyStatus ? "COMING SOON" : " RECOMMENDED"}
                      </Button>
                    )}
                  </div>

                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ color: "#000000" }}
                    className={
                      item.recommended
                        ? "billing_gray_text recommended"
                        : "billing_gray_text"
                    }
                  >
                    {item.subTitle}
                  </Typography>
                  <Typography variant="h3" style={{ paddingTop: "25px" }}>
                    {item.price}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ paddingBottom: "25px", color: "#808080" }}
                    className={
                      item.recommended
                        ? "billing_gray_text recommended"
                        : "billing_gray_text"
                    }
                  >
                    {item.month}
                  </Typography>
                  <CardContent className="card_content">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={item.className === "upgrade-btn" ? true : false}
                      className={
                        item.recommendedbtn
                          ? "billing_btn custom-mui-button recommended"
                          : item.className === "upgrade-btn"
                            ? ".billing_container .card_content .upgrade-btn"
                            : "billing_btn custom-mui-button"
                      }
                    >
                      {item.btnText}
                    </Button>
                    <div>
                      <ul>
                        {item.points.map((listItem, idx) => {
                          return (
                            <div
                              className={
                                item.recommended
                                  ? "billing_points recommended"
                                  : "billing_points "
                              }
                              key={idx}
                            >
                              <BsCheckLg />
                              <li>{listItem.title}</li>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {!travelAgencyStatus && <div>
          <Paper className="p-4 mt-5 text-center footer-text">Contact us at <Link to="support@hereiswhatilike.com" >
            support@hereiswhatilike.com</Link> to customize a plan to meet the specific needs of your business and operation.*</Paper>
        </div>}
      </div>
    </>
  );
};
const store = [
  {
    title: "Lite",
    subTitle: "Up to 25 Unlocked Customers",
    price: "$ Free",
    month: "/month",
    btnText: "Current Plan",
    recommendedbtn: "recommended",
    points: [
      { title: "Real-Time Customer Updates" },
      {
        title: "Up to 3 Staff",
      },
      {
        title: "Receive Micro Feedback",
      },
      { title: "Send Perks" },
    ],
  },
  {
    title: "Plus",
    subTitle: "Up to 5000 Unlocked Customers",
    price: "$99",
    month: "/month",
    btnText: "Upgrade Plan",
    recommended: "recommended",
    recommendedbtn: "recommended",
    points: [
      { title: "Real-Time Customer Updates" },
      {
        title: "Up to 25 Staff",
      },
      {
        title: "Unlimited Micro Feedback",
      },
      { title: "Send Unlimited Perks" },
      { title: "Preferred Search Placement" },
      { title: "Direct Booking Link" },
      { title: "Customized Profile & CMS" }
    ],
  },
  {
    title: "Premium",
    subTitle: "Up to 15,000 Unlocked Customers",
    price: "$500 +",
    month: "/month",
    btnText: "Contact Sales",
    points: [
      { title: "Real-Time Customer Updates" },
      {
        title: "Up to 100 Staff",
      },
      {
        title: "Unlimited Micro Feedback",
      },
      { title: "Send Unlimited Perks" },
      { title: "Preferred Search Placement" },
      { title: "Direct Booking Link" },
      { title: "Customized Profile & CMS" }
    ],
  },
  {
    title: "Large Enterprise",
    subTitle: "Unlimited Unlocked Customers*",
    price: "$2500 +",
    month: "/month",
    btnText: "Contact Sales",
    points: [
      { title: "Real-Time Customer Updates" },
      {
        title: "Unlimited Staff",
      },
      {
        title: "Unlimited Micro Feedback",
      },
      { title: "Send Unlimited Perks" },
      { title: "Preferred Search Placement" },
      { title: "Direct Booking Link" },
      { title: "Customized Profile & CMS" }
    ],
  },
];

const travelAgentStore = [
  {
    title: "Lite",
    subTitle: "Unlimited Unlocked Customers",
    price: "$ Free",
    month: "/month",
    btnText: "Current Plan",
    recommendedbtn: "recommended",
    points: [
      { title: "Select 10 Destinations" },
      {
        title: "Preferred Profile",
      },
      {
        title: "Ads in Search Results",
      },
    ],
  },
  {
    title: "Plus",
    subTitle: "Unlimited Unlocked Customers",
    price: "$ 14.95",
    month: "/month",
    btnText: "Upgrade Plan",
    recommended: "recommended",
    recommendedbtn: "recommended",
    className: "upgrade-btn",
    points: [
      { title: "Select 25 Destinations" },
      {
        title: "Preferred Profile Placement",
      },
      { title: "Highlighted Profile" },
      {
        title: "Ads in Search Results",
      },
      { title: "Receive Leads" },
      { title: "Direct Booking Link" },
      { title: "Customized Profile & CMS" },
    ],
  },
];

export default Billing;
