/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import "../../../assets/css/add_company.css"; // company page css
import {
  getCategories,
  addCompany,
  getPlaceAutoCompleteAPI,
  checkPlaceDetailsExistsAPT,
  getPlaceAutoCompleteForHotel,
} from "../../../services/api/company.service";
import importImg from "../../../assets/images/uplod.png";
import searchIcon from "../../../assets/images/search.png";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/loader";
import { v4 as uuidv4 } from "uuid";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

const AddCompany = () => {
  let navigate = useNavigate();
  const initialValues = {
    name: "",
    ownerManager: "",
    email: "",
    role: "",
    ownerManagerName: "",
    mobileNoCC: "",
    mobileNo: "",
    website: "",
    ownerManagerEmail: "",
    categoryId: "",
    ownerManagerMobileNoCC: "",
    ownerManagerMobileNo: "",
    googlePlaceId: "",
    googlelocationId: "",
    googlelocationname: "",
    ownerManagerTitle: "",
    image: "",
    highlightCompany: false,
    topBrandCompany: false,
    address: "",
    city: "",
    state: "",
    country: "",
    shortBio: "",
    zipcode: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectType, setSelectType] = useState("Owner");
  const [isSubmit, setIsSubmit] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [fixedValue, setFixedValue] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState();
  const [searchByGoogle, setSearchGoogle] = useState(false);
  const [typeManual, setTypeManual] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [travel_checked, setTravel_checked] = useState(false);
  const [wasCheckboxChecked, setWasCheckboxChecked] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  /* For Location search */
  const [citydrop, setCityDrop] = useState(false);
  const [citydrop1, setCityDrop1] = useState(false);
  const [cmpydrop, setCmpyDrop] = useState(false);
  const [locations, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [companyName, setCompanyName] = useState("");
  const [labelCompany, setLabelCompany] = useState("");
  const [companyFilter, setCompanyFilter] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const [fetchedImg, setFetchedImage] = useState("");
  const [fetchedBaseFormat, setFetchedBaseFormat] = useState("");
  const [locations1, setLocation1] = useState("");
  const [city, setCity] = useState("");
  const [state, setStates] = useState("");
  const [country, setCountry] = useState("");

  /* New Changes in Google place API */
  const [uniqueIds1, setUniqueId1] = useState("");
  const [uniqueIds2, setUniqueId2] = useState("");
  const [placesDetails, setPlaceDetails] = useState([]);
  const apikey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [cityloading, setCityLoading] = useState(false);
  const [cmpyloading, setCmpyLoading] = useState(false);
  const [cityloading1, setCityLoading1] = useState(false);
  const [placesDetails1, setPlaceDetails1] = useState([]);
 

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setDefaultCategory(value);
    const selecedCompanyType = optionList.filter((item) => item._id === value);
    setFixedValue(selecedCompanyType);
    setSubCategory(selecedCompanyType);
    const catgtype =  e?.target[e?.target.selectedIndex]?.getAttribute("data-tag")?.toLowerCase();
    if (catgtype === "travel agency") {
      setShowCheckbox(true);
      setTravel_checked(wasCheckboxChecked);
    } else {
      setShowCheckbox(false);
      setTravel_checked(false);
    }
    setShowCheckbox(catgtype === "travel agency");
    formValues.categoryId = value;
  };

  const topOption = (category, depth = 0, parentId = null) => {
    const { _id, categoryName, subcategoryName, subCategory = [] } = category;
    const children = subCategory.flatMap((child) =>
      child.subcategoryName === "General"
        ? topOption(child, depth + 2, _id)
        : topOption(child, depth + 1, _id)
    );
    const option = {
      _id,
      categoryName: categoryName ? categoryName : subcategoryName,
      subCategory,
      depth,
      parentId,
    };
    return [option].concat(children);
  };

  const optionList = categories
    .flatMap((item) => topOption(item))
    .filter((item) => item.depth !== 2);
  const GeneralOptionsList = categories
    ?.flatMap((category) => topOption(category))
    .filter((item) => item.depth === 2);

  const refreshUniqueId1 = () => {
    setUniqueId1(uuidv4());
  };

  const refreshUniqueId2 = () => {
    setUniqueId2(uuidv4());
  };

  // onchange city location
  const getCitySearchDetails = async (srchwrd) => {
    setCityLoading(true);
    let srchkey = srchwrd.trim().toLowerCase();

    const cityDatas = {
      input: srchkey,
      sessionToken: uniqueIds1,
      type: "geocode",
      location: "",
      radius: "",
      strictbounds: false,
      pd: "0",
      requestFrom: "1",
    };

    const resCity = await getPlaceAutoCompleteAPI(cityDatas);
    if (resCity) {
      if (!resCity.error && resCity.data) {
        setPlaceDetails(resCity.data);
        setCityDrop(true);
        setCityLoading(false);
      }
    }
  };

  // company name---city detail
  const getCityBasedPlaceDetails = async (placeid, selection) => {
    if (placeid) {
      if (selection === "cmpy") {
        const searchValues1 = {
          place_id: placeid,
          fields:
            "name,place_id,address_components,geometry,formatted_address,international_phone_number,website,photos",
          sessionToken: uniqueIds2,
          requestFrom: "1",
          pd: "3",
        };

        const resCmpy = await checkPlaceDetailsExistsAPT(searchValues1);
        const hasKeyExist = "result" in resCmpy.data;

        var placeDetails = {};

        if (hasKeyExist) {
          placeDetails = resCmpy.data.result;
        } else {
          placeDetails = resCmpy.data.placeDetailResponse;
        }
        if (placeDetails) {
          if (placeDetails?.photos?.[0]?.photo_reference) {
            processImage(placeDetails?.photos?.[0]?.photo_reference);
          } else {
            setFetchedImage("");
          }
          setCompanyName(placeDetails?.name);
          setPlaceId(placeDetails?.place_id);
          formValues.name = placeDetails?.name;
          setLabelCompany(placeDetails?.name);

          formValues.address = placeDetails.formatted_address;

          const hasPhoneKeyExist = "international_phone_number" in placeDetails;

          if (hasPhoneKeyExist) {
            var str = placeDetails?.international_phone_number;
            formValues.mobileNoCC = str.substring(0, str.indexOf(" "));
            formValues.mobileNo = str.substring(str.indexOf(" ") + 1);
          } else {
            formValues.mobileNoCC = "";
            formValues.mobileNo = "";
          }

          if (placeDetails?.website) {
            var url = placeDetails?.website;
            var urlonly = new URL(url).origin; //origin
            formValues.website = urlonly + "/";
          }

          const address_component1 = placeDetails.address_components;

          const ObjIdToFind1 = "postal_code";
          const isObjectPresent1 = address_component1.find(
            (o) => o.types[0] === ObjIdToFind1
          );
          if (!isObjectPresent1) {
            formValues.zipcode = "";
          }

          address_component1.map((element) => {
            if (element.types[0] === "postal_code") {
              var zip = element.long_name;
              formValues.zipcode = zip;
            }
            return element;
          });
        }
        setUniqueId2("");
        refreshUniqueId2();
      } else if (selection === "city") {
        const searchValues2 = {
          place_id: placeid,
          fields:
            "name,place_id,address_components,formatted_address,geometry,vicinity",
          sessionToken: uniqueIds1,
          requestFrom: "1",
          pd: "2",
        };

        const res = await checkPlaceDetailsExistsAPT(searchValues2);
        const hasKey = "result" in res?.data;

        let placeDetailsResponse1 = "";

        if (hasKey) {
          placeDetailsResponse1 = res.data.result;
        } else {
          placeDetailsResponse1 = res.data.placeDetailResponse;
        }

        setCoordinates(placeDetailsResponse1.geometry.location);

        /* For Setting the city, state, country */

        const address_component1 = placeDetailsResponse1.address_components;

        const ObjIdToFind = "locality";
        const isObjectPresent = address_component1.find(
          (o) => o.types[0] === ObjIdToFind
        );
        if (!isObjectPresent) {
          setCity("");
          formValues.city = "";
        }

        address_component1.map((element) => {
          if (element.types[0] === "country") {
            let countryNa = element.long_name;
            setCountry(countryNa);
            return (formValues.country = countryNa);
          }

          if (element.types[0] === "locality") {
            let cityNa = element.long_name;
            setCity(cityNa);
            return (formValues.city = cityNa);
          }

          if (element.types[0] === "administrative_area_level_1") {
            let stateNa = element.long_name;
            setStates(stateNa);
            return (formValues.state = stateNa);
          }
          return element;
        });

        setUniqueId1("");
        refreshUniqueId1();
      }
    }
  };

  // onchange company name
  const getCmpySearchDetails = async (srchwrd2) => {
    setCmpyLoading(true);
    let newLocation = "";
    if (srchwrd2?.length >= 3) {
      if (locations.split(",").length > 1) {
        newLocation = " ," + locations.split(",").slice(0, -1).toString();
      } else {
        newLocation = " ," + locations;
      }
    }

    let srchkey2 = srchwrd2.trim().toLowerCase();

    let cateType = null;
    const cmpyDatas = {
      input: srchkey2 + newLocation,
      sessionToken: uniqueIds2,
      type: cateType,
      location: coordinates?.lat + "," + coordinates?.lng,
      radius: "50000",
      strictbounds: true,
      pd: "1",
      requestFrom: "1",
    };

    const resCmpys = await getPlaceAutoCompleteForHotel(cmpyDatas);
    if (resCmpys) {
      if (!resCmpys?.error && resCmpys?.data) {
        setCompanyFilter(resCmpys.data);
        setCmpyDrop(true);
        setCmpyLoading(false);
      }
    }
  };

  const getLocationSearchDetails = async (srchwrd) => {
    if (srchwrd.length > 3 && srchwrd.length % 2 !== 0) {
      setCityLoading1(true);
      let srchkey = srchwrd.trim().toLowerCase();

      const cityDatas = {
        input: srchkey,
        sessionToken: uniqueIds1,
        type: "geocode",
        location: "",
        radius: "",
        strictbounds: false,
        pd: "0",
        requestFrom: "1",
      };

      const resLocas = await getPlaceAutoCompleteAPI(cityDatas);
      if (!resLocas.error && resLocas?.data) {
        setPlaceDetails1(resLocas?.data);
        setCityDrop1(true);
        setCityLoading1(false);
      }
    }
  };
  const processImage = async (photo) => {
    if (photo) {
      var proxyURL = "https://cors.hereiswhatilike.com/",
        imageLookupURL = `https://maps.googleapis.com/maps/api/place/photo?photo_reference=${photo}&key=${apikey}&sessiontoken=${uniqueIds2}&maxwidth=700&maxheight=700`;

      // eslint-disable-next-line no-unused-vars
      const imageURLQuery = await fetch(proxyURL + imageLookupURL)
        .then((r) => r.blob())
        .then((data) => {
          const image = URL.createObjectURL(data);
          setFetchedImage(image);
          // convert to base 64
          var reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function () {
            var base64data = reader.result;
            setFetchedBaseFormat(base64data);
          };
        })
        .catch((e) => {
          console.log(e);
          return e;
        });
    }
  };

  const saveCompany = async (formValues) => {
    setLoading(true);
    let main_Category = subCategory
      .filter((item) => (item.depth === 0 ? item : null))
      .map((item) => item._id);

    let sub_Category = subCategory
      .filter((item) => (item.depth === 1 ? item : null))
      .map((item) => item._id);

    const newGeneral = GeneralOptionsList.filter((item) =>
      main_Category.includes(item.parentId)
    ).map((item) => item._id);

    formValues.categories = main_Category;
    formValues.subcategories = [...newGeneral, ...sub_Category];
    formValues.travelAgencyStatus = travel_checked;

    const res = await addCompany(formValues);

    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
        setLoading(false);
      } else {
        var successMessage = "Company created successfully!";
        ShowToastMessage(
          successMessage,
          TOAST_SUCCESS,
          toast.POSITION.TOP_RIGHT
        );
        setLoading(false);
        setTimeout(function () {
          navigate("/admin/company");
        }, 2000);
      }
    }
  };

  const fetchCategories = async () => {
    const res = await getCategories();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCategories(res.data);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Company name is required!";
      ShowToastMessage(errors.name, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
    }

    if (!values.categoryId) {
      errors.categoryId = "Company type is required!";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "shortBio") {
      // replace space with next js's space variable
      let shortBio = value?.replaceAll("\n", " ");
      setFormValues({ ...formValues, shortBio });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const uploadImage = async (e) => {
    const targetfile = e.target.files[0];
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const base64 = await convertBase64(targetfile);
    setBaseImage(base64);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (searchByGoogle) {
      if (!companyName) {
        formValues.name = "";
        setCompanyName("");
        setLabelCompany("");
        formValues.email = "";
        formValues.mobileNo = "";
        formValues.website = "";
        formValues.address = "";
        setPlaceId("");
      }
    }
    fetchCategories();
    refreshUniqueId1();
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      formValues.ownerManager = selectType;
      formValues.role = "company";
      formValues.image = baseImage;
      formValues.googlePlaceId = placeId;
      formValues.googlelocationId = locationId;
      formValues.googlelocationname = locations1;

      // for images
      if (baseImage && baseImage !== "") {
        formValues.image = baseImage;
      } else if (fetchedImg && fetchedImg !== "") {
        formValues.image = fetchedBaseFormat;
      } else {
        delete formValues.image;
      }

      //for country, state, city
      formValues.city = city;
      formValues.state = state;
      formValues.country = country;

      saveCompany(formValues);
    }
  }, [formErrors]);

  return (
    <div className="company_add_top company_container">
      <div
        className="Scroll"
        onClick={() => {
          setCityDrop(false);
          setCmpyDrop(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row dashboard_tittle_box">
              <div className="col-md-8">
                <h3>Add/Edit Company</h3>
                <h6 className="import_tittle">
                  Companies <span>Add Company</span>
                </h6>
              </div>

              {(typeManual || isSearched) && (
                <div className="col-md-4 text-right">
                  <button type="submit" className="save_button">
                    Save
                  </button>
                  <Link to={"../../Company"}>
                    <button className="cancel_button">cancel</button>
                  </Link>
                </div>
              )}
            </div>
            <div className="row edit_from cmpy-location-srch">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="citybasedSearch">Enter city location</label>
                  <input
                    type="text"
                    className="form-control"
                    id="citybasedSearch"
                    aria-describedby="emailHelp"
                    placeholder="Enter City Name"
                    autoComplete="off"
                    onChange={(evt) => {
                      setLocation(evt.target.value);
                      getCitySearchDetails(evt.target.value);
                    }}
                    value={locations}
                    onClick={() => setCityDrop(true)}
                  />
                </div>
              </div>

              {citydrop && (
                <div className="autocomplete-dropdown-container">
                  {cityloading ? (
                    <div>Loading...</div>
                  ) : (
                    <ul>
                      {placesDetails &&
                        placesDetails.map((item) => (
                          <li
                            key={item?.place_id}
                            onClick={(i) => {
                              setLocation(item?.description);
                              setLocation1(item?.description);
                              setLocationId(item?.place_id);
                              getCityBasedPlaceDetails(
                                item?.place_id === undefined
                                  ? item?.placeId
                                  : item?.place_id,
                                "city"
                              );
                              setCityDrop(false);
                            }}
                          >
                            {item?.description}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              )}

              {!searchByGoogle && (
                <div className="col-md-6">
                  <button
                    type="button"
                    className={typeManual ? "serch_btn1" : "serch_btn"}
                    onClick={() => {
                      setSearchGoogle(true);
                      setTypeManual(false);
                      formValues.name = "";
                      setCompanyName("");
                      setLabelCompany("");
                      setPlaceId("");
                      formValues.email = "";
                      formValues.mobileNo = "";
                      formValues.mobileNoCC = "";
                      formValues.website = "";
                      formValues.address = "";
                      formValues.shortBio = "";
                      formValues.ownerManagerName = "";
                      formValues.ownerManagerEmail = "";
                      formValues.ownerManagerMobileNo = "";
                      formValues.ownerManagerTitle = "";
                      formValues.ownerManagerMobileNoCC = "";
                      formValues.zipcode = "";
                      refreshUniqueId2();
                    }}
                  >
                    Search By Google
                  </button>
                </div>
              )}

              {searchByGoogle && (
                <div className="col-md-6 position_relative google_serch">
                  <div className="form-group">
                    <label htmlFor="companybasedSearch">Search by Google</label>
                    <input
                      type="text"
                      className="form-control"
                      id="companybasedSearch"
                      aria-describedby="emailHelp"
                      placeholder="Search by Company Name"
                      autoComplete="off"
                      onChange={(evt) => {
                        setCompanyName(evt.target.value);
                        getCmpySearchDetails(evt.target.value);
                      }}
                      value={companyName}
                      onClick={() => setCmpyDrop(true)}
                    />
                    <img
                      alt=""
                      className="img-fluid pr-15 pb-4"
                      src={searchIcon}
                    />
                  </div>

                  {cmpydrop && (
                    <div className="company-selection-sec">
                      {cmpyloading ? (
                        <div>Loading...</div>
                      ) : (
                        <ul>
                          {companyFilter &&
                            companyFilter.map((item) => (
                              <li
                                key={item?.place_id}
                                onClick={(i) => {
                                  setCompanyName(item?.description);
                                  setPlaceId(item?.place_id);
                                  formValues.name = item?.description;
                                  setLabelCompany(item?.description);
                                  getCityBasedPlaceDetails(
                                    item?.place_id === undefined
                                      ? item?.placeId
                                      : item?.place_id,
                                    "cmpy"
                                  );
                                  setIsSearched(true);
                                  setCmpyDrop(false);
                                }}
                                className="listStyle"
                              >
                                {item?.description}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="row edit_from pt-0">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Select Company Type
                  </label>
                  <select
                    name="categoryId"
                    id="Business"
                    className="form-select"
                    value={defaultCategory}
                    onChange={handleCategoryChange}
                    ariaLabel
                  >
                    <option value="">Please Select</option>
                    {categories &&
                      categories.map((category) => (
                        <option
                          key={category._id}
                          value={category._id}
                          data-tag={category.categoryName}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className={searchByGoogle ? "type_btn1" : "type_btn"}
                  onClick={() => {
                    setTypeManual(true);
                    setSearchGoogle(false);
                    setIsSearched(false);
                    formValues.name = "";
                    setCompanyName("");
                    setLabelCompany("");
                    setPlaceId("");
                    formValues.mobileNo = "";
                    formValues.ownerManagerMobileNoCC = "";
                    formValues.website = "";
                    formValues.address = "";
                    formValues.email = "";
                    formValues.mobileNo = "";
                    formValues.mobileNoCC = "";
                    formValues.website = "";
                    formValues.address = "";
                    formValues.shortBio = "";
                    formValues.ownerManagerName = "";
                    formValues.ownerManagerEmail = "";
                    formValues.ownerManagerMobileNo = "";
                    formValues.ownerManagerTitle = "";
                    formValues.ownerManagerMobileNoCC = "";
                    formValues.zipcode = "";
                    refreshUniqueId2();
                    setFetchedImage("");
                  }}
                >
                  Type Manual
                </button>
              </div>
            </div>
            <div className="row company_detil_wrap">
              <div className="col-md-6">
                {searchByGoogle && isSearched && (
                  <h3>{labelCompany} Company Details:</h3>
                )}
                {typeManual && <h3>Fill Company Details:</h3>}
              </div>
              <div className="col-md-6">
                {searchByGoogle && isSearched && <span>By Google API:</span>}
                {typeManual && <span>By Manual:</span>}
              </div>
            </div>
          </div>

          {(typeManual || isSearched) && (
            <div className="container">
              {!loading && (
                <div className="details_box add-company-wrap">
                  <div className="row edit_from">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Company Name<span className="frmErr">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="companyName"
                          name="name"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.name}
                          onChange={handleChange}
                        />
                        <p className="frmErr">{formErrors.name}</p>
                      </div>
                      <input
                        type="hidden"
                        name="role"
                        value="company"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 d-flex">
                      <div className="form-check pr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="ownerManager"
                          id="exampleRadios1"
                          defaultValue="Owner"
                          defaultChecked
                          onChange={handleChange}
                          onClick={(e) => setSelectType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Owner
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="ownerManager"
                          id="exampleRadios2"
                          defaultValue="Manager"
                          onChange={handleChange}
                          onClick={(e) => setSelectType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Manager
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email<span className="frmErr"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="emailId"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.email}
                          onChange={handleChange}
                        />
                        <p className="frmErr">{formErrors.email}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          {selectType} Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          name="ownerManagerName"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.ownerManagerName}
                          onChange={handleChange}
                        />
                        <p className="frmErr">{formErrors.ownerManagerName}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group position_relative">
                        <label htmlFor="exampleInputEmail1">
                          Phone Number<span className="frmErr"></span>
                        </label>
                        <div className="row">
                          <div className="col-md-3">
                            <select
                              name="mobileNoCC"
                              id="mobileCC"
                              className="form-select"
                              onChange={handleChange}
                              ariaLabel
                              value={formValues.mobileNoCC}
                            >
                              <option value="+1">+1</option>
                              <option value="+44">+44</option>
                              <option value="+52">+52</option>
                              <option value="+91">+91</option>
                            </select>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              name="mobileNo"
                              aria-describedby="emailHelp"
                              placeholder=""
                              autoComplete="off"
                              value={formValues.mobileNo}
                              onChange={handleChange}
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <p className="frmErr">{formErrors.mobileNo}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          {selectType} Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ownerEmail"
                          name="ownerManagerEmail"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.ownerManagerEmail}
                          onChange={handleChange}
                        />
                        <p className="frmErr">{formErrors.ownerManagerEmail}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Company Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="website"
                          name="website"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.website}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group position_relative">
                        <label htmlFor="exampleInputEmail1">
                          {selectType} Number
                        </label>
                        <div className="row">
                          <div className="col-md-3">
                            <select
                              name="ownerManagerMobileNoCC"
                              id="ownerManagerMobileNoCC"
                              className="form-select"
                              onChange={handleChange}
                              ariaLabel
                            >
                              <option value="+1">+1</option>
                              <option value="+44">+44</option>
                              <option value="+52">+52</option>
                              <option value="+91">+91</option>
                            </select>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              id="ownerPhone"
                              name="ownerManagerMobileNo"
                              aria-describedby="emailHelp"
                              placeholder=""
                              autoComplete="off"
                              value={formValues.ownerManagerMobileNo}
                              onChange={handleChange}
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <p className="frmErr">
                              {formErrors.ownerManagerMobileNo}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Company Type<span className="frmErr">*</span>
                        </label>
                        <select
                          name="categoryId"
                          id="Business"
                          className="form-select"
                          value={defaultCategory}
                          onChange={handleCategoryChange}
                          ariaLabel
                        >
                          <option selected>Please Select</option>
                          {categories &&
                            categories.map((category) => (
                              <option key={category._id} value={category._id} data-tag={category.categoryName}>
                                {category.categoryName}
                              </option>
                            ))}
                        </select>
                        <p className="frmErr">{formErrors.categoryId}</p>
                      </div>
                      {showCheckbox && (
                        <div className="confirmation">
                          <input
                            name="travelAgencyStatus"
                            type="checkbox"
                            checked={travel_checked}
                            onChange={(event) => {
                              setTravel_checked(event.target.checked);
                              setWasCheckboxChecked(event.target.checked);
                            }}
                            className="confirmation_input"
                            required
                          />
                          <label id="travelAgencyStatus">
                            I confirm I am a travel agent or agency and would like a
                            special view of the platform
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          {selectType} Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ownerTitle"
                          name="ownerManagerTitle"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          value={formValues.ownerManagerTitle}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10 cmpy-location-srch">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Sub Category Type<span className="frmErr">*</span>
                        </label>
                        <Autocomplete
                          className="autoComplete"
                          multiple
                          id="multiselect"
                          options={optionList}
                          value={subCategory}
                          onChange={(event, newValue) => {
                            const selectedValue = newValue.map(
                              (item) => item._id
                            );
                            const selectedParentId = newValue.map(
                              (item) => item.parentId
                            );
                            const getParentId = optionList.filter((itm) =>
                              selectedParentId.includes(itm._id)
                            );

                            setSubCategory([
                              ...fixedValue,
                              ...newValue.filter(
                                (item) => fixedValue.indexOf(item) === -1
                              ),
                              ...getParentId.filter(
                                (itm) => !selectedValue.includes(itm._id)
                              ),
                            ]);
                          }}
                          disableCloseOnSelect
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) => option.categoryName}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={
                                  option.depth === 0
                                    ? `${option.categoryName} / Genreral`
                                    : option.categoryName
                                }
                                {...getTagProps({ index })}
                                disabled={fixedValue.indexOf(option) !== -1}
                              />
                            ))
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                                sx={{ ml: 2 * option.depth }}
                              />
                              {option.categoryName}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="" fullWidth />
                          )}
                        />
                      </div>
                    </div>

                    {citydrop1 && (
                      <div className="autocomplete-dropdown-container">
                        {cityloading1 ? (
                          <div>Loading...</div>
                        ) : (
                          <ul>
                            {placesDetails1 &&
                              placesDetails1.map((item) => (
                                <li
                                  key={item?.place_id}
                                  onClick={(i) => {
                                    setLocation1(item?.description);
                                    setLocationId(item?.place_id);
                                    getCityBasedPlaceDetails(
                                      item?.place_id === undefined
                                        ? item?.placeId
                                        : item?.place_id,
                                      "city"
                                    );
                                    setCityDrop1(false);
                                  }}
                                >
                                  {item?.description}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Description</label>
                        <textarea
                          className="form-control"
                          id="shortdesc"
                          name="shortBio"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          rows={3}
                          onChange={handleChange}
                          value={formValues?.shortBio}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="citybasedSearch1">Location</label>

                        <input
                          type="text"
                          className="form-control"
                          id="citybasedSearch1"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          onChange={(evt) => {
                            setLocation1(evt.target.value);
                            getLocationSearchDetails(evt.target.value);
                          }}
                          value={locations1}
                          onClick={() => setCityDrop1(true)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group position_relative">
                        <label htmlFor="exampleInputEmail1">
                          Update Company Image
                        </label>
                        <input
                          type="file"
                          className="form-control pl-60"
                          id="cmpImg"
                          name="image"
                          aria-describedby="emailHelp"
                          placeholder="Drop File Here Or Browse"
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                        <img
                          className="img-fluid uplod_img"
                          src={importImg}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Address <span className="frmErr">*</span>
                        </label>

                        <textarea
                          className="form-control"
                          id="streetNo"
                          name="address"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          rows={3}
                          onChange={handleChange}
                          value={formValues.address}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {!baseImage && fetchedImg && (
                        <div className="col-md-6">
                          <div className="form-group position_relative">
                            <label htmlFor="exampleInputEmail1">
                              Existing Company Image
                            </label>{" "}
                            &nbsp;&nbsp;
                            <img
                              src={fetchedImg}
                              width={70}
                              height={70}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row edit_from pt-0 pt-10">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Zipcode</label>
                        <input
                          type="text"
                          className="form-control"
                          id="zipcode"
                          name="zipcode"
                          aria-describedby="emailHelp"
                          placeholder=""
                          autoComplete="off"
                          onChange={handleChange}
                          value={formValues.zipcode}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="checkbox-container">
                        <div
                          className="form-group position_relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          &nbsp;&nbsp;
                          <input
                            className="check-box"
                            name="topBrandCompany"
                            type="checkbox"
                            checked={formValues.topBrandCompany}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                topBrandCompany: e.target.checked,
                              });
                            }}
                          />
                        </div>
                        <div className="checkbox-label">
                          <span className="checkbox-span">
                            We are global brand and /or do not have a specific
                            arrival location
                          </span>
                          <span>
                            (Customers would typically search for our company by
                            name rather than specific location or city)
                          </span>
                        </div>
                      </div>
                      <div
                        className="checkbox-container"
                        style={{ marginTop: 16 }}
                      >
                        <div
                          className="form-group position_relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          &nbsp;&nbsp;
                          <input
                            className="check-box"
                            name="highlightCompany"
                            type="checkbox"
                            checked={formValues.highlightCompany}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                highlightCompany: e.target.checked,
                              });
                            }}
                          />
                        </div>
                        <span
                          className="checkbox-span"
                          style={{ marginLeft: 18 }}
                        >
                          Highlight
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading && <Loader />}
            </div>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddCompany;
