import React from "react";
import "../../assets/css/claimbusiness.css";
import closeIcon from "../../assets/images/close-icon.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function TermsAndConditionsModal({
  open,
  setOpen,
  handleModal,
  handlePrivacyModal,
}) {
  return (
    <div>
      <Modal id="claimmodal" isOpen={open} scrollable={true}>
        <ModalHeader>
          <div className="close_modal_box close_popup">
            <a className="link-common" onClick={() => setOpen(false)}>
              <img
                className="img-fluid close_icon"
                src={closeIcon}
                alt="icon"
              />
            </a>
            {"    "}
            <p className="text-center">
              By continuing, you agree to company's{" "}
              <a href="#!" onClick={handleModal}>
                Terms and conditions
              </a>{" "}
              and acknowledge our{" "}
              <a href="#!" onClick={handlePrivacyModal}>
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </ModalHeader>
        <ModalBody className="modal-dialog-scrollable">
          <div className="section-block-text">
            <p>
              <strong>Terms of Service Agreement</strong>
            </p>
            <p>Last Modified: May 1, 2023 </p>
            <p>
              This Terms of Services Agreement (this “Agreement”) contains the
              terms under which COMPANY provides our Product to you and
              describes how the Product may be accessed and used. The entire
              selection of products, services and tools offered on our Platform
              are our “Product”.
            </p>
            <p>
              You must read, agree, and accept all terms and conditions
              contained in this Agreement and expressly referenced in this
              Agreement, including COMPANY’s Privacy Policy, before you
              subscribe for our Product.{" "}
            </p>
            <p>
              As you will be using the Product on behalf of an entity or other
              organization, you agree to these Terms on behalf of that entity or
              organization and you represent that you have such authority. “You”
              and “your” will refer to that entity or organization. You are also
              responsible for any interactions on the Product with your
              customers, students, subscribers, or users (collectively,
              “End-Users”){" "}
            </p>
            <p>
              When you subscribe to our Product, or otherwise use or access the
              Platform, you agree to be bound by these Terms and applicable
              laws, rules, and regulations. You may be asked to click “I accept”
              at the appropriate place prior to your purchase of access to our
              Product. If you do not agree to these Terms, please do not use our
              Product in any way.
            </p>
            <h2>
              <strong>1. About</strong>
            </h2>{" "}
            <p>
              <b>
                <i>1.1. Preference management.</i>
              </b>{" "}
              Company is a preference management Product where End-Users
              personalize their experiences with preferred companies by sharing
              insight into their likes and dislikes. The preferences are set and
              managed in a personal secure digital vault in the Product. After
              the preferences are set, End-Users can share them with the
              companies they desire so they can receive tailored products and
              services. End-Users can also share their experiences with specific
              companies by sending private messages directly to management of
              companies. Company offers you access to End-Users’ preferences in
              order to generally refine your provision of services and tailor
              their experiences.
            </p>
            <p>
              <b>
                <i>1.2. Access to End-Users’ preferences.</i>
              </b>{" "}
              End-Users are owners of their preferences. If End-Users share
              their preferences about Your company on the Product, You will be
              able to access their personal preference vaults and review their
              likes and dislikes after receiving explicit permission from them.
              You will also have the opportunity to interact with End-Users if
              they wish to engage with You on the Product and share insight into
              their preferences or experiences.
            </p>
            <p>
              <b>
                <i>1.3. Inviting End-Users on the Product.</i>
              </b>{" "}
              You have the opportunity to invite End-Users to share their
              experiences on the Product. Please note that this invitation does
              not render exclusivity of the Product to the invited End-Users and
              they can share preferences about other companies on the Product as
              well.{" "}
            </p>
            <p>
              <b>
                <i>1.4 Placement of your profile.</i>
              </b>{" "}
              We offer the placement of your company profile on our Product
              dashboard as incentive if the following conditions are met: Your
              company shares a provided{" "}
            </p>
            <p>
              referral link from within your company account or from customized
              and branded printed materials with a QR included.
            </p>
            <h2>
              <strong>2. Subscription Fees</strong>
            </h2>
            <p>
              <b>
                <i>2.1. Fees for our Product.</i>
              </b>{" "}
              You agree to pay COMPANY any fees for each Product you purchase or
              use in accordance with the pricing and payment terms presented to
              you for that Product. The processing of payments will be subject
              to the terms, conditions and privacy policies of the applicable
              payment processor, in addition to this Agreement. If you have
              elected to pay the fees by credit card, you represent and warrant
              that the credit card information you provide is correct and you
              will promptly notify COMPANY of any changes to such information.
              Fees paid by you are non-refundable, except as provided in these
              Terms or when required by law. Included in your fee may be
              technical support in respect of the Product and it is only
              provided to COMPANY customers. In no event will COMPANY credit,
              refund, or reimburse you for a foreign exchange fee charged by
              your credit card or for any difference in fees due to currency
              conversion.
            </p>
            <p>
              <b>
                <i>2.2. Subscriptions.</i>
              </b>{" "}
              Our Product is billed on a subscription basis (“Subscriptions”).
              This means that you will be billed in advance on a{" "}
            </p>
            <p>
              recurring, periodic basis (each period is called a “billing
              cycle”). Billing cycles are typically monthly or annual, depending
              on what subscription plan you select when purchasing a
              Subscription. Payment will be charged to your chosen payment
              method at confirmation of purchase and at the start of every new
              billing cycle. Your Subscription will automatically renew at the
              end of each billing cycle unless you cancel your account through
              your online account management page or by contacting us at
              support@hereiswhatilike.com prior to the end of the billing cycle.
              You may cancel auto-renewal on your Subscription at any time, in
              which case your Subscription will continue until the end of that
              billing cycle before terminating. You may cancel auto-renewal on
              your Subscription immediately after the Subscription starts if you
              do not want it to renew.
            </p>
            <p>
              If you initially sign up for a plan that includes a free or
              discounted trial period, and you do not cancel that account before
              the stated trial period expires, you will be billed for the full
              price of the plan starting on the day the trial period ends. If
              you cancel prior to the processing of your first invoice following
              the trial period, you will not be charged.
            </p>
            <p>
              COMPANY may change the price for Subscriptions from time to time
              and will communicate any price changes to You in advance and, if
              applicable, how to accept those changes. Price changes for
              Subscriptions will take effect at the start of the next
              subscription period following the date of the price change. As
              permitted by local law, You accept the new price by continuing to
              use Your Subscription after the price change takes effect. If You
              do not agree with the price changes, You have the right to reject
              the change by canceling Your Subscription before the price change
              goes into effect. Please therefore make sure you read any such
              notification of price changes carefully.
            </p>
            <p>
              Any change (including any upgrade, downgrade or other
              modification) to any plan by you in a trial period will end the
              trial immediately. You will be billed for your first term period
              (i.e. the first annual or monthly period) immediately upon
              upgrading. For any upgrade or downgrade in plan level after any
              trial period, your credit, debit, or other payment card will
              automatically be charged the new rate on your next billing cycle.
              You will be billed immediately for the prorated{" "}
            </p>
            <p>
              difference for the current billing cycle for any upgrade to any
              higher priced plan during that billing cycle. If a plan downgrade
              causes a credit to your account, this credit will be used toward
              your next billing cycle(s). This credit will not be refunded.
            </p>
            <p>
              <b>
                <i>2.3. Taxes.</i>
              </b>{" "}
              Our prices listed do not include any taxes, levies, duties or
              similar governmental assessments of any nature such as
              value-added, sales, use, or withholding taxes, assessable by any
              jurisdiction (collectively, “Taxes”) unless otherwise indicated.
              You are responsible for paying Taxes associated with your purchase
              and keeping your billing information up to date.
            </p>
            <p>
              (a) United States Sales Tax. If we have a legal obligation to pay
              or collect sales tax for which you are responsible, we will
              calculate the sales tax based upon the billing information we have
              about you and charge you that amount (which, if your billing
              information is incomplete or inaccurate, may be the highest
              prevailing rate then in effect), unless you provide us with a
              valid tax exemption certificate acceptable to the appropriate
              taxing authority. If you provide us with a tax exemption
              certificate, you represent and warrant that it accurately reflects
              your tax status and that you will keep such document current and
              accurate. If we subsequently determine in our sole discretion that
              your tax exemption document is valid, we will refund the sales tax
              collected.
            </p>
            <p>
              (b) Non-United States Sales Tax. If applicable, we will charge you
              VAT, GST, or any other sales, consumption or use taxes that arise
              in connection with your purchases of our Product unless you
              provide us with a tax identification number that entitles you to
              an exemption, a valid tax exemption certificate or other
              documentary proof issued by an appropriate taxing authority that
              tax should not be charged. If you are located in a jurisdiction
              with multiple sales, consumption, or use taxes, we may charge you
              the highest prevailing rate if your billing information is
              incomplete or inaccurate. If you are required by law to withhold
              any Taxes from your payments to COMPANY, you must provide COMPANY
              with an official tax receipt or other appropriate documentation to
              support such payments.
            </p>
            <p>
              <b>
                <i>2.4. Price Changes.</i>{" "}
              </b>
              COMPANY may change the fees charged to you for the Services at any
              time, provided that, for Product billed on a subscription basis,
              the change will become effective only at the end of the
              then-current billing cycle of your Subscription. COMPANY will
              provide you with advance notice of any change in fees and you will
              have the opportunity to cancel your Subscription as set forth in
              Section 2.2.
            </p>
            <p>
              <b>
                <i>2.5 Refund Policy.</i>
              </b>{" "}
              COMPANY offers to companies under a 15-day no obligation
              cancellation policy. The no obligation cancellation begins
              immediately upon account activation of a paid subscription. The
              15-day no obligation cancellation period only applies to new
              paying customers and does not apply to existing customers that
              have a subscription plan. COMPANY will also not honor a free trial
              period for customers who were once paying subscribers, canceled,
              and then decided to reinstate their subscription to COMPANY
              product.
            </p>
            <p>
              At any time during the 15-day, potential subscriber (customer) can
              cancel their account and will be refunded in full with a minor
              service $25 charge.
            </p>
            <p>
              If you wish to cancel your account you may wish to request a
              refund and company may choose to review your Send us an email to
              support@hereiswhatilike.com within thirty (30) days of your
            </p>
            <p>
              sign-up letting us know that you are canceling your plan. After
              that, all payments are nonrefundable and there are no refunds or
              credits for partially used subscription periods. As mentioned
              above, following any cancellation, you will continue to have
              access to the Product through the end of your current billing
              period. We reserve the right to refuse refunds to anyone who
              abuses this Refund Policy.
            </p>
            <p>
              <b>
                <i>2.6 Authorized Payment Method.</i>
              </b>{" "}
              You must keep a valid payment method on file with COMPANY to pay
              for all fees. COMPANY will charge applicable Fees to any valid
              payment method that you authorize (“Authorized Payment Method”),
              and COMPANY will continue to charge the Authorized Payment Method
              for applicable fees until this Agreement is terminated, and any
              and all outstanding fees have been paid in full.
            </p>
            <p>
              <b>
                <i>2.7 Delinquencies.</i>
              </b>{" "}
              If COMPANY is not able to process payment of any fees using an
              Authorized Payment Method, COMPANY will provide you with a notice
              regarding the payment failure (a “Payment Failure Notice”). If the
              outstanding fees are not paid within 14 days of COMPANY providing
              a Payment Failure Notice, COMPANY may (in its sole discretion)
              suspend and revoke your access to the Product or certain services
              within the Product. Your full access to the Product will be
              reactivated upon your payment of any outstanding fees, plus the
              fees applicable to your next billing cycle.
            </p>
            <p>
              If the outstanding fees are not paid within 30 days of COMPANY
              providing a Payment Failure Notice, COMPANY may (in its sole
              discretion) terminate your access to the Product or certain
              services within the Product. Your full access to the Product will
              be reactivated upon your payment of any outstanding fees, plus the
              fees applicable to your next billing cycle. You may not be able to
              access the Product or certain services within the Product during
              any period of suspension or after termination which may impact
              your ability to access the Product.
            </p>
            <h2>
              <strong>3. Data Privacy & Security</strong>
            </h2>
            <p>
              <b>
                <i>
                  3.1. Your Responsibility to Comply with Data Protection,
                  Security and Privacy Laws.
                </i>{" "}
              </b>{" "}
              By using our Product, you agree and warrant that you are solely
              responsible for complying with all applicable data protection,
              security and privacy laws and regulations, including any notice
              and consent requirements. This includes without limitation the
              collection and processing by you of any personal data, sending
              marketing or other email, and when using cookies and similar
              technologies on your sites. If applicable law requires, you must
              provide and make available to your End Users a legally compliant
              privacy policy and cookie policy. You must capture valid consent,
              both for you and us, for any cookies or similar technologies used
              on or through your site where required.
            </p>
            <h2>
              <strong>4. Intellectual Property</strong>
            </h2>
            <p>
              <b>
                <i>4.1. Product License.</i>
              </b>{" "}
              Neither the Terms nor your use of the Product grants you ownership
              in the Product or any content you access through the Product. The
              Terms also do not grant you any right to use COMPANY’s trademarks
              or other brand elements. We or our licensors own all
            </p>
            <p>
              right, title, and interest in and to the Product, and all related
              technology and intellectual property rights. Subject to the Terms,
              we grant you a limited, revocable, non-exclusive,
              non-sublicensable, non-transferrable license to access and use the
              Product solely in accordance with the Terms. Except as provided in
              this Section 4.1, you obtain no rights from us, our affiliates, or
              our licensors to the Product, including any related intellectual
              property rights. Neither you nor any of your End-Users will use
              the Product in any manner or for any purpose other than as
              expressly permitted by the Terms. Neither you nor any of your
              End-Users will, or will attempt to (a) modify, distribute, alter,
              tamper with, repair, or otherwise create derivative works of any
              Product; (b) reverse engineer, disassemble, or decompile the
              Product or apply any other process or procedure to derive the
              source code of any software included in the Product (except to the
              extent applicable law doesn’t allow this restriction); (c) access
              or use the Product in a way intended to avoid incurring fees or
              exceeding usage limits or quotas; or (d) resell or sublicense the
              Product. You will not misrepresent or embellish the relationship
              between us and you (including by expressing or implying that we
              support, sponsor, endorse, or contribute to you or your business
              endeavors). You will not imply any relationship or affiliation
              between us and you.
            </p>
            <h2>
              <strong>5. Acceptable Use</strong>
            </h2>
            <p>
              You are responsible for your conduct and communications with
              End-Users while using the Product. You must comply with the
              following requirements when using the Product. If we become aware
              of any conduct that falls outside the bounds of what is acceptable
              under this policy, we will take all appropriate action, including
              removing it and reporting it, as well as suspending or terminating
              your account. We also take steps to prevent uses of our Product
              that are contrary to the spirit of this policy.
            </p>
            <p>
              5.1. You may not purchase, use, or access the Product for the
              purpose of building a product or service that is competitive to
              COMPANY or for any other competitive purposes.
            </p>
            <p>
              5.2. You may not use the Product to commit an unlawful activity;
              or use the Product for activities where use or failure of the
              Product could lead to physical damage, death, mental harm, or
              personal injury.
            </p>
            <p>
              5.3. You may not misuse our Product by interfering with its normal
              operation or attempting to access it using a method other than
              through the interfaces and instructions that we provide. 5.4. You
              may not store or transmit any viruses, malware, or other types of
              malicious software, or links to such software, through the
              Product. You may not use the Product to infringe the intellectual
              property rights of others.
            </p>
            <p>
              5.5. You may not misuse our Product by interfering with its normal
              operation or attempting to access it using a method other than
              through the interfaces and instructions that we provide.
            </p>
            <p>
              5.6. Unless authorized by COMPANY in writing, you may not use any
              manual or automated system or software to extract or scrape data
              from websites or other interfaces through which we make our
              Product available. You may not probe, scan, or test the
              vulnerability or security of the Product or any COMPANY system or
              network.
            </p>
            <h2>
              <strong>6. User Requirements & Account Management</strong>
            </h2>
            <p>
              <b>
                <i>6.1. Legal Status.</i>
              </b>{" "}
              If you are an individual, you may only use the Product if you have
              the power to form a contract with COMPANY. If you do not have the
              power to form a contract, you may not use the Product. If you are
              not an individual, you warrant that you are validly formed and
              existing under the laws of your jurisdiction of formation, that
              you have full power and authority to enter into these Terms, and
              that you have duly authorized your agent to bind you to these
              Terms.
            </p>
            <p>
              <b>
                <i>6.2. Embargoes.</i>
              </b>{" "}
              Use of our Product for any dealings, engagement, or sale of
              goods/services linked directly or indirectly with jurisdictions
              that COMPANY has deemed high risk, such as Cuba, Iran, North
              Korea, Syria, Russia, and the Crimea, Donetsk, and Luhansk
              Regions, is prohibited. You are not permitted to use our Product
              if you are, or you are owned or controlled directly or indirectly
              by, any person, group, entity, or nation, named on any list issued
              by the Department of the Treasury’s Office of Foreign Asset
              Control (“OFAC”), or any similar list or by any law, order, rule,
              or regulation or any Executive Order of the President of the
              United States, including Executive Order 13224, as a "Specially
              Designated National and Blocked Person”, terrorist, or other
              banned or blocked person (collectively, a “Prohibited Person”). By
              using our Product you represent and warrant that (i) you are not
              (nor are you owned or controlled, directly or indirectly, by any
              person, group, entity, or nation that is) acting directly or
              indirectly for or on behalf of any Prohibited Person; and (ii) you
              have not conducted nor will you conduct business nor have engaged
              nor will you engage in any transaction or dealing with any
              Prohibited Person in violation of the U.S. Patriot Act or any OFAC
              rule or regulation, including, without limitation, the making or
              receiving of any contribution of funds, goods, or services to or
              for the benefit of a Prohibited Person in violation of the U.S.
              Patriot Act or any OFAC rule or regulation. Furthermore, it is
              your responsibility to ensure that: (a) your end users do not use
              the Product in violation of any export restriction or embargo by
              the United States; and (b) you do not provide access to the
              Product to any Prohibited Persons.
            </p>
            <p>
              <b>
                <i>6.3. Compliance with Laws.</i>
              </b>{" "}
              You must always use the Product in compliance with, and only as
              permitted by, applicable laws. There are various federal, state,
              and international specific laws, requirements, guidelines, and/or
              standards (collectively, “Laws”) that apply or may potentially
              apply to your business, websites, and/or mobile applications,
              including, but not limited to those related to privacy and data
              security (such as the California Consumer Privacy Act (CCPA) or
              the EU General Data Protection Regulation (GDPR)), the
              accessibility of websites and/or mobile applications, taxes, the
              Health Insurance Portability and Accountability Act (HIPAA), and
              subscriptions and auto-renewals. COMPANY is not HIPAA compliant
              and currently we have no plans to become so. Accordingly, you may
              not use the Product to collect, store, or process any protected
              health information subject to HIPAA, any applicable health privacy
              regulation, or any other applicable law governing the processing,
              use, or disclosure of health information.{" "}
            </p>
            <p>
              Any information provided by COMPANY in help articles, examples, or
              the like are provided for informational purposes only and are
              provided without any warranty, express or implied, including as to
              their legal effect and completeness. COMPANY cannot provide you
              with any legal or tax advice and encourages you to consult with
              legal and tax counsel and website accessibility consultants of
              your own selection and at your own expense to ensure your{" "}
            </p>
            <p>
              compliance with all applicable Laws. You agree that you are solely
              and exclusively responsible for the entirety of your actions while
              using the Product including compliance with Laws and following
              your published policies.
            </p>
            <p>
              <b>
                <i>6.4. Keep Your Password Secure.</i>{" "}
              </b>{" "}
              If COMPANY has issued an account to you in connection with your
              use of the Product, you are responsible for safeguarding your
              password and any other credentials used to access that account.
              You, and not COMPANY, are responsible for any activity occurring
              in your account, whether or not you authorized that activity. User
              Accounts for company managers, staff and employees may not be
              shared and may only be used by one individual or organization for
              which they are assigned to.
            </p>
            <p>
              <b>
                <i>6.5. Keep Your Details Accurate.</i>
              </b>{" "}
              You must keep your email address and, where applicable, your
              contact details and payment details associated with your account
              current and accurate. Backup your. To the extent permitted by
              applicable law, COMPANY will not be liable for any failure to
              store, or for loss or corruption of your details.
            </p>
            <p>
              <b>
                <i>6.6. Account Inactivity.</i>
              </b>{" "}
              COMPANY may terminate your account and delete any content
              contained in it if there is no account activity (such as a log in
              event or payment) for over 12 months. However, we will attempt to
              warn you by email before terminating your account to provide you
              with an opportunity to log in to your account so that it remains
              active.{" "}
            </p>
            <h2>
              <strong>7. Termination of Services</strong>
            </h2>
            <p>
              <i>7.1. By You.</i> You can terminate your Subscription and delete
              your account at any time through your account management page if
              available or via email to support@hereiswhatilike.com. Such
              termination and deletion will result in the deactivation or
              disablement of your account and access to it, and the deletion of
              content you collected through use of the Product plus deletion of
              any manager or staff users you provided access to within your
              company account. Terminations are confirmed immediately and you
              will not be charged again for that Subscription unless you
              purchase a new one. If you terminate a Subscription in the middle
              of a billing cycle, you will not receive a refund unless you are
              terminating for any of the following reasons: (a) we have
              materially breached these Terms and failed to cure that breach
              within 30 days after you have so notified us in writing; (b) a
              refund is required by law; or (c) we, in our sole discretion,
              determine a refund is appropriate. For clarity, we will not grant
              a refund where you have used our Product, unless the termination
              is due to our material, uncured breach or a refund is required by
              law.
            </p>
            <p>
              <i>7.2. By COMPANY.</i> COMPANY may terminate your Subscription
              for any reason at the end of a billing cycle by providing at least
              30 days’ prior written notice to you and you will not be charged
              for the next billing cycle. COMPANY may terminate your
              Subscription for any reason by providing at least 90 days’ written
              notice to you and will provide a refund for any period of time you
              did not use in that billing cycle. COMPANY may immediately suspend
              performance or terminate your Subscription for any of the
              following reasons: (a) you have materially breached these Terms,
              in our sole discretion, and failed to cure that breach within 15
              days after we have notified you in writing of such breach; or (b)
              you cease your business operations or become subject to insolvency
              proceedings and the proceedings are not dismissed within 90 days.
              Additionally, COMPANY may immediately limit, suspend, or terminate
              the Product to you: (i){" "}
            </p>
            <p>
              if, in our sole discretion, you fail to comply with these Terms;
              (ii) if, in our sole discretion, you use the Product in a way that
              causes legal liability to us or disrupts others’ use of the
              Product; or (iii) we are investigating suspected misconduct by
              you. Also, if we limit, suspend, or terminate the Product you
              receive, depending upon the reason, we will endeavor to give you
              advance notice and an opportunity to obtain a copy of your content
              from that Service. However, there may be time sensitive situations
              where COMPANY may decide that we need to take immediate action
              without notice. COMPANY will use commercially reasonable efforts
              to narrow the scope and duration of any limitation or suspension
              under this Section as is needed to resolve the issue that prompted
              such action. COMPANY has no obligation to retain your content upon
              termination of the applicable Service.
            </p>
            <h2>
              <strong>8. Updates</strong>
            </h2>
            <p>
              <b>
                <i>8.1. Changes to Terms.</i>
              </b>{" "}
              You acknowledge and agree that COMPANY may change these Terms at
              any time for a variety of reasons, such as to reflect changes in
              applicable law or updates to Product, and to account for new
              Products or functionality. The most current version will always be
              posted on our website. If an amendment is material, as determined
              in COMPANY’s sole discretion, we may notify you by email and/or
              posting it to our website or upon your login to your account.
              Changes will be effective no sooner than the day they are publicly
              posted, and apply to all access to and use of the Product
              thereafter. However, any changes to the dispute resolution
              provisions will not apply to any disputes for which the parties
              have actual notice before the date the change is posted. If you do
              not want to agree to any changes made to the Terms, you should
              stop using the Product, because by continuing to use the Product
              you indicate your agreement to be bound by the updated terms.
            </p>
            <p>
              <b>
                <i>8.2. Changes to the Product and Services.</i>
              </b>{" "}
              We are continually changing and improving our Product. COMPANY may
              add, alter, or remove functionality from a Service it provides to
              you at any time without prior notice. COMPANY may also limit,
              suspend, or discontinue a Service provided to you at our
              discretion. If we discontinue a Service, we will give you
              reasonable advance notice to provide you with an opportunity to
              obtain a copy of your content from that Service. COMPANY may
              remove content from the Product it provides you at any time in our
              sole discretion, although we will endeavor to notify you before we
              do that if it materially impacts you and if practicable under the
              circumstances.
            </p>
            <h2>
              <strong>9. Limitation of Liability and Disclaimer</strong>
            </h2>
            <p>
              <b>
                <i>9.1. Limitation of Liability.</i>{" "}
              </b>{" "}
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY
              OF EACH OF COMPANY, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
              SUPPLIERS, AND LICENSORS ARISING OUT OF OR IN CONNECTION WITH THE
              PRODUCT AND THESE TERMS WILL NOT EXCEED THE AMOUNT YOU PAID TO
              COMPANY FOR USE OF THE PRODUCT AT ISSUE DURING THE 12 MONTHS PRIOR
              TO THE EVENT GIVING RISE TO THE LIABILITY. COMPANY IS NOT AN
              EDUCATIONAL INSTITUTION, MARKETPLACE, OR CONTENT PROVIDER. OUR
              USERS, ENTREPRENEURS, CUSTOMERS, AND PARTNERS ARE NOT OUR
              EMPLOYEES. WE ARE NOT RESPONSIBLE FOR ANY INTERACTIONS{" "}
            </p>
            <p>
              BETWEEN YOU AND YOUR END-USERS, OTHER THAN PROVIDING THE
              TECHNOLOGY FOR YOU. WE ARE IN NO WAY LIABLE FOR ANY DISPUTES,
              CLAIMS, LOSSES, INJURIES, OR DAMAGE OF ANY KIND THAT MAY ARISE OUT
              OF YOUR RELATIONSHIP WITH YOUR END-USER, INCLUDING ANY END-USERS
              RELIANCE UPON ANY INFORMATION OR CONTENT THAT YOU PROVIDE.
            </p>
            <p>
              <b>
                <i>9.2. Disclaimers.</i>
              </b>{" "}
              COMPANY acknowledges that the Product may be unavailable from time
              to time for various reasons. EXCEPT AS EXPRESSLY PROVIDED IN THESE
              TERMS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE PRODUCT
              AND ANY INFORMATION, GUIDANCE, OR RECOMMENDATIONS THEREIN ARE
              PROVIDED “AS IS” AND COMPANY DOES NOT MAKE WARRANTIES OF ANY KIND,
              EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THOSE OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT OR ANY REPRESENTATIONS REGARDING AVAILABILITY,
              RELIABILITY, OR ACCURACY OF THE PRODUCT.
            </p>
            <p>
              <b>
                <i>9.3 Consumers.</i>
              </b>{" "}
              We acknowledge that the laws of certain jurisdictions provide
              legal rights to consumers that may not be overridden by contract
              or waived by those consumers. If you are such a consumer, nothing
              in these Terms limits any of those consumer rights.
            </p>
            <h2>
              <strong>10. Indemnification</strong>
            </h2>
            <p>
              <b>
                <i>10.1. General.</i>
              </b>{" "}
              You agree that you are solely and exclusively responsible for the
              way You engage with End-Users on the Product. To the fullest
              extent permitted by law, you will indemnify and hold harmless
              COMPANY and its affiliates, officers, agents, licensors, and
              employees from all liabilities, damages, and costs (including
              settlement costs and reasonable attorneys’ fees) arising out of a
              third party claim regarding or in connection with (a) your or your
              end users’ use of the Product; (b) breach of these Terms or
              violation of applicable law by you, your users, websites, mobile
              applications, or business; or (c) a dispute between you and your
              users.
            </p>
            <p>
              <b>
                <i>10.2. Intellectual Property.</i>
              </b>{" "}
              You will defend COMPANY and its affiliates, officers, agents,
              licensors, and employees from all liabilities, damages, and costs
              (including settlement costs and reasonable attorneys’ fees)
              arising out of a third party claim alleging that any of your
              content infringes or misappropriates that third party’s
              intellectual property rights, and will pay the amount of any
              adverse final judgment or settlement.
            </p>
            <p>
              <b>
                <i>10.3. Process.</i>
              </b>{" "}
              We will (a) endeavor to give you prompt written notice of the
              claim; (b) permit you to control the defense and settlement of the
              claim; and (c) reasonably cooperate with you in the defense and
              settlement of the claim. In no event will you agree to any
              settlement of any claim that requires a commitment by us, without
              our written consent.
            </p>
            <h2>
              <strong>11. Dispute Resolution & Governing Law.</strong>
            </h2>
            <p>
              <b>
                <i>11.1. Resolving Disputes.</i>
              </b>{" "}
              If you have a concern, we want to first try to address it without
              needing a formal legal case. Before filing a claim against
              COMPANY, you agree to try to resolve the dispute informally by
              contacting support@hereiswhatilike.com. We’ll try to resolve the
              dispute informally by contacting you via email. If a dispute is
              not resolved within 30 days of submission, you or COMPANY may
              bring a formal proceeding.
            </p>
            <p>
              <b>
                <i>11.2. Judicial Forum for Disputes.</i>{" "}
              </b>
              You and COMPANY agree that any judicial proceeding to resolve
              claims relating to these Terms or the Product will be brought in
              the federal or state courts of West Palm Beach, Florida, subject
              to the mandatory arbitration provisions below. Both you and
              COMPANY consent to venue and personal jurisdiction in such courts.
            </p>
            <p>
              <b>
                <i>11.3. Governing Law.</i>
              </b>{" "}
              These Terms will be governed by Hungarian law except for its
              conflicts of laws principles.{" "}
            </p>
            <h2>
              <strong>12. Other </strong>
            </h2>
            <p>
              <b>
                <i>12.1. Assignment.</i>
              </b>{" "}
              You may not assign these Terms or your rights to use our Product
              without COMPANY’s prior written consent, which may be withheld in
              our sole discretion. COMPANY may assign these Terms at any time
              without notice to you.
            </p>
            <p>
              <b>
                <i>12.2. Entire Agreement.</i>
              </b>{" "}
              These Terms and the Privacy Policy constitute the entire agreement
              between you and COMPANY, and they supersede any other prior or
              contemporaneous agreements, terms and conditions, written or oral
              concerning its subject matter.
            </p>
            <p>
              <b>
                <i>12.3. Independent Contractors.</i>
              </b>{" "}
              The relationship between you and COMPANY is that of independent
              contractors, and not legal partners, employees, or agents of each
              other.
            </p>
            <p>
              <b>
                <i>12.4. No Waiver.</i>
              </b>{" "}
              A party’s failure or delay to enforce a provision under these
              Terms is not a waiver of its right to do so later.
            </p>
            <p>
              <b>
                <i>12.5. Severability.</i>
              </b>{" "}
              If any provision of these Terms is determined to be unenforceable
              by a court of competent jurisdiction, that provision will be
              severed and the remainder of terms will remain{" "}
            </p>
          </div>
          <br />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default TermsAndConditionsModal;
