import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { adminData, superadminData, travelAgencySidebar } from "../../helpers/sidebar.data";
import { ROLE_ADMIN, ROLE_SUPERADMIN } from "../../helpers/app.constants";
import { UncontrolledCollapse } from "reactstrap";

export const SideBar = ({ userRole, travelAgencyStatus }) => {
  
  const checkedAdminData = travelAgencyStatus ? travelAgencySidebar : adminData ;

  const closeSidebar = () => {
    if (window.screen.width < 1024) {
      const sidebar = document.getElementById("mySidebar");
      sidebar.classList.toggle("sidebar-active");
    }
  };

  const sidebarData = (val, idx) => {
    return (
      <li key={idx}>
        <NavLink to={val.link} onClick={() => closeSidebar()}>
          <div className="icon">{val.icon}</div>
          <div className="title">{val.title}</div>
        </NavLink>
      </li>
    );
  };

  useEffect(() => {
    closeSidebar();
  }, []);

  return (
    <React.Fragment>
      <UncontrolledCollapse toggler="#toggler" horizontal defaultOpen>
        <div id="mySidebar" className="sidebar">
          <div className="sidebar-fixed">
            <div className="navigation-title">Navigation</div>
            <ul>
              {userRole === ROLE_SUPERADMIN &&
                superadminData.map((val, idx) => sidebarData(val, idx))}
              {userRole === ROLE_ADMIN &&
                checkedAdminData?.map((val, idx) => sidebarData(val, idx))}
            </ul>
          </div>
        </div>
      </UncontrolledCollapse>
    </React.Fragment>
  );
};
