export const userInfo = {
  name: "superadmin",
  role: "SUPERADMIN",
};

export const ROLE_SUPERADMIN = "admin";
export const ROLE_ADMIN = "company";
export const ROLE_STAFF = "staff";

export const TOAST_SUCCESS = "success";
export const TOAST_ERROR = "error";

export const PREF_TAB_YESNO = 0;
export const PREF_TAB_MULTIPLE = 1;
export const PREF_TAB_FREEFLOW = 2;

export const PREF_TYPE_YESNO = "yesNo";
export const PREF_TYPE_MULTIPLE = "multipleChoice";
export const PREF_TYPE_FREEFLOW = "freeFlow";
