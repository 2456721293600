import {
  Alert,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import a1 from "../../../assets/images/Black-1.webp";
import a2 from "../../../assets/images/Black-2.jpg";
import a3 from "../../../assets/images/Black-3.jpg";
import a4 from "../../../assets/images/Silver-1.jpg";
import a5 from "../../../assets/images/Silver-2.jpg";
import a6 from "../../../assets/images/Silver-3.jpg";
import a7 from "../../../assets/images/Gold-1.jpg";
import a8 from "../../../assets/images/Gold-2.jpg";
import a9 from "../../../assets/images/Gold-3.jpg";
import "../../../assets/css/store.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,
  width: "90%",
  p: 2,
  backgroundColor: "background.paper",
  height: "auto",
  maxHeight: "100%",
};
const style_large = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  width: "100%",
  p: 2,
  backgroundColor: "background.paper",
};
const Store = () => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [title, setTitle] = useState("");
  const handleCancelModal = () => {
    setPreviewVisible(false);
    setImgSrc("");
  };

  const handlePreviewModal = (event, data) => {
    event.preventDefault();
    setPreviewVisible(true);
    setImgSrc(data?.image);
    setTitle(data?.title);
  };
  return (
    <div className="store_container pt-5 container">
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} lg={6}>
          <CustomBreadcrumbs title="Store" />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, mb: 1 }}>
          <Alert className="text-message" severity="info" style={{fontSize: 16}}> 
           <b>Make it easy for existing customers to download the app at your
            physical location and scan a unique QR that positions your company
            profile on the home page </b>
          </Alert>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        {store.map((item, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key={idx}
              textAlign="center"
            >
              <Card variant="outlined">
                <CardContent style={{ padding: "15px 20px" }}>
                  <Typography
                    ariant="subtitle1"
                    component="div"
                    textAlign="center"
                  >
                    {item.title}
                  </Typography>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="250"
                    image={item.image}
                    style={
                      item.title === "Postcard"
                        ? `${idx}` === "1"
                          ? {
                              objectFit: "contain",
                              backgroundColor: "#d1d1d1",
                              marginBlock: "15px",
                            }
                          : {
                              objectFit: "contain",
                              backgroundColor: "#f5f5f5",
                              marginBlock: "15px",
                            }
                        : {
                            marginBlock: "15px",
                          }
                    }
                    onClick={(event) => {
                      handlePreviewModal(event, item);
                    }}
                  />
                  <Link
                    href="https://form.jotform.com/231476584106054" 
                    underline="none"
                    target="_blank" 
                    rel="noopener"
                    className="custom-mui-button invite_link_btn"
                  >
                    Order
                  </Link>
                </CardContent>
              </Card>
              <Modal
                open={previewVisible}
                onClose={handleCancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="backdrop_modal"
                disableEnforceFocus={false}
                style={{ overflow: "auto" }}
              >
                <Box sx={title === "Postcard" ? style : style_large}>
                  <span className="close_btn" onClick={handleCancelModal}>
                    X
                  </span>

                  <CardMedia
                    component="img"
                    alt="green iguana"
                    image={imgSrc}
                  />
                </Box>
              </Modal>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const store = [
  {
    id: 1,
    title: "Business cards",
    image: a1,
  },
  {
    id: 2,
    title: "Postcard",
    image: a2,
  },
  {
    id: 3,
    title: "Sticker",
    image: a3,
  },
  {
    id: 4,

    title: "Business cards",
    image: a4,
  },

  {
    id: 5,
    title: "Postcard",
    image: a5,
  },
  {
    id: 6,
    title: "Sticker",
    image: a6,
  },
  {
    id: 7,
    title: "Business cards",
    image: a7,
  },

  {
    id: 8,
    title: "Postcard",
    image: a8,
  },
  {
    id: 9,
    title: "Sticker",
    image: a9,
  },
];

export default Store;
