import { Grid } from "@mui/material";
import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { changePassword, changeStaffPassword } from "../../../services/api/password.service";
import { getUserInfo } from "../../../helpers/helper";

import "../../../assets/css/login.css";
const Password = () => {
  const navigate = useNavigate();
  const [pwdType, setPwdType] = useState("password");
  const [confpwdType, setconfpwdType] = useState("password");
  const [passwordError, setPasswordError] = useState("");
  const [formValues, setFormValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formValuesError, setFormValuesError] = useState({
    newPasswordErr: false,
    confirmPasswordErr: false,
  });

  const {role, email} = getUserInfo();
  console.log(role, email, "sfsdafsfsdfsadfasfdasfas");

  const OnSubmit = async (e) => {
    e.preventDefault();
    let staffResetData = {
      email: email,
      newPassword: formValues?.newPassword,
      confirmPassword: formValues?.confirmPassword,
    }
    if (formValues.newPassword === "") {
      setFormValuesError({ newPasswordErr: true });
    } else if (formValues.confirmPassword === "") {
      setFormValuesError({ confirmPasswordErr: true });
    }
    if (formValues.newPassword !== "" && passwordError === ""  && formValues.confirmPassword !== "") {
      await ( role?.name === "staff" ? changeStaffPassword(staffResetData) : changePassword(formValues) )
        .then((res) => {
          if (res?.error) {
            ShowToastMessage(
              `${res?.msg}`,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
            return;
          } else {
            ShowToastMessage(
              `${res?.msg}`,
              TOAST_SUCCESS,
              toast.POSITION.BOTTOM_RIGHT
            );
          }

          navigate("/company/home");
        })
        .catch((err) => {
          console.log(err?.msg, "err");
          ShowToastMessage(
            `${err?.msg}`,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        });
    }
  };

  const handleShowHidePwd = () => {
    if (pwdType === "password") {
      setPwdType("text");
    } else {
      setPwdType("password");
    }
  };

  const handleChange = (e, fieldname) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (fieldname) {
      validatePassword(value);
    }
  };

  const validatePassword = (value) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&/#^(){}\[\]])[A-Za-z\d@$!%*?&/#^(){}\[\]]{7,}$/;
    if (!regex.test(value)) {
      setPasswordError('Passwords require 7 characters, 1 uppercase letter, 1 number, and 1 special character');
    } else {
      setPasswordError('');
    }
  };

  const handleShowHideconfPwd = () => {
    if (confpwdType === "password") {
      setconfpwdType("text");
    } else {
      setconfpwdType("password");
    }
  };
  return (
    <div className="container pt-5">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <CustomBreadcrumbs title="Reset Password" />
        </Grid>

        <Grid item xs={12} md={6} lg={6} className="right_login_panel">
          <form
            onSubmit={(e) => {
              OnSubmit(e);
            }}
          >
            <div className="form_group">
              <label htmlFor="newPassword" className="password">
                {" "}
                <span style={{ color: "red" }}>* </span>New Password
                <input
                  type={pwdType}
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  value={formValues.newPassword}
                  onChange={(e) => {
                    handleChange(e, 'password');
                  }}
                />
                <span className="show_pass" onClick={handleShowHidePwd}>
                  {pwdType === "password" && <BsFillEyeSlashFill size={28} />}
                  {pwdType === "text" && <BsFillEyeFill size={28} />}
                </span>{" "}
              </label>
              {formValuesError.newPasswordErr &&
              formValues?.newPassword === "" ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  New Password field is required
                </span>
              ) : passwordError ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  {passwordError}
                </span>
              ) : null}
            </div>
            <div className="form_group">
              <label htmlFor="confirmPassword" className="password">
                <span style={{ color: "red" }}>* </span>Confirm Password{" "}
                <input
                  type={confpwdType}
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <span className="show_pass" onClick={handleShowHideconfPwd}>
                  {confpwdType === "password" && (
                    <BsFillEyeSlashFill size={28} />
                  )}
                  {confpwdType === "text" && <BsFillEyeFill size={28} />}
                </span>
              </label>

              {formValuesError.confirmPasswordErr &&
              formValues?.confirmPassword === "" ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Confirm Password field is required
                </span>
              ) : null}
            </div>
            <div className="form_group clamin_business_left">
              <button type="submit" style={{ marginLeft: "0px" }}>
                Submit
              </button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Password;
