import React from "react";
import "../../assets/css/claimbusiness.css";
import closeIcon from "../../assets/images/close-icon.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function TermPrivacyModal({ open, setOpen, handleModal, handleTermsConditionsModal }) {
  return (
    <div>
      <Modal id="claimmodal" isOpen={open} scrollable={true}>
        <ModalHeader>
          <div className="close_modal_box close_popup">
            <a className="link-common" onClick={() => setOpen(false)}>
              <img
                className="img-fluid close_icon"
                src={closeIcon}
                alt="icon"
              />
            </a>
            {"    "}
            <p className="text-center">
              By continuing, you agree to company's{" "}
              <a href="#!" onClick={handleTermsConditionsModal}>
                Terms and conditions
              </a>{" "}
              and acknowledge our{" "}
              <a href="#!" onClick={handleModal}>
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </ModalHeader>
        <ModalBody className="modal-dialog-scrollable">
          <div>
            <h1>Privacy Policy</h1>
            <div className="section-block-text">
            <p>Last Modified: May 1, 2023 </p>
            <strong>Introduction</strong>
            <p>
              Company Ltd (hereinafter “we”, “us” or “our”) respects your
              right to privacy and takes our responsibilities in relation to
              the processing of personal data seriously. We do not collect
              or process personal data unnecessarily. This privacy policy
              (the “Policy”) together with our terms of service (the “Terms
              of Service”) sets out important information about your rights
              in relation to the processing of your personal data, and the
              basis on which any personal data we collect from you, or that
              you provide to us, will be processed in connection with your
              use of the{" "}
              <a href="http://www.hereiswhatilike.com/">
                http://www.hereiswhatilike.com/
              </a>{" "}
              website (“our Site” or “the Site”) and the mobile application
              (“our App” or “the App”)
            </p>
            <strong>Scope</strong>
            <p>This privacy policy applies to information we collect:</p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  on the Websites and your email communications with the
                  Websites,
                </li>
                <li>
                  on the Mobile application and your communications with the
                  Mobile app.
                </li>
                <li>
                  when you interact with our advertising and applications on
                  third-party websites and services,if those applications or
                  advertising include links to this privacy policy.
                </li>
              </ul>
            </div>
            <strong> Information you give us </strong>
            <p>
              <strong>Your Data.</strong> This is information about you
              which is consensually given by making a registration on our
              Site.
            </p>
            <p>The information you give us may include:</p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  <strong>Identity Data:</strong> your username, phone
                  number and e-mail address;
                </li>
                <li>
                  <strong>Financial Data:</strong> your financial and credit
                  card information, including bank account and payment card
                  details, billing contact email address, and VAT number.
                </li>
                <li>
                  <strong>Your Preferences:</strong> Your personal travel
                  and hospitality likes and dislikes and other personal
                  preference data{" "}
                </li>
              </ul>
            </div>
            <strong>The Data We Collect About You</strong>
            <p>
              {" "}
              We may collect different kinds of personal data about you,
              depending on whether you chose to create an account with us.
            </p>
            <p>
              <strong>Automatically Collected Information.</strong> With
              regard to each of your visits to our Site and/ or App we will
              automatically collect the following information:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  <strong>Technical Data:</strong> technical information,
                  including the Internet protocol (IP) address used to
                  connect your computer to the Internet, browser type, and
                  version, time zone setting, browser plug-in types and
                  versions, operating system and platform, how often you
                  visit the Site and other performance data;
                </li>
                <li>
                  <strong>Usage Data:</strong> information about your visit,
                  including the full Uniform Resource Locators (URL),
                  clickstream to, through, and from our site and our App
                  (including date and time), page response times, length of
                  visits to certain pages, page interaction information
                  (such as scrolling, clicks, and mouse-overs), methods used
                  to browse away from the page
                </li>
                <li>
                  <strong>No special categories of personal data:</strong>{" "}
                  We do not require or collect any personal data that is
                  your sensitive personal data or any special category of
                  personal data under the CCPA and other relevant data
                  privacy acts
                </li>
              </ul>
            </div>
            <strong>What we do with your information</strong>
            <p>
              We will only use your personal information when the law allows
              us to. Most commonly, we will use your personal data in the
              following circumstances:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  Where we need to perform the contract we are about to
                  enter into or have entered into with you.
                </li>
                <li>
                  Where it is necessary for our legitimate interest (or
                  those of a third party) and your interests and fundamental
                  rights do not override those interests.
                </li>
                <li>
                  Where we need to comply with a legal or regulatory
                  obligation.
                </li>
              </ul>
            </div>
            <strong>How long we keep your information</strong>
            <p>
              We will only retain your personal data for as long as
              necessary to fulfill the purposes we collected it for,
              including for the purposes of satisfying any legal,
              accounting, or reporting requirements. This means that the
              period of time for which we store your personal data may
              depend on the type of data we hold.
            </p>
            <strong>Disclosure of your information</strong>
            <p>
              {" "}
              We do not share your personal information to third parties for
              marketing purposes without your explicit consent. To provide
              you with our services and to also meet our regulatory
              requirements, we may need to share your information with our
              business partners, suppliers, and sub- contractors, affiliated
              companies and other third party product and service providers.
            </p>
            <p>
              You have the option to share your personal information stored
              on the website and our App with the users in your friend list
              or with the companies you desire. By doing so, you acknowledge
              that you share your personal information willingly with these
              third parties.{" "}
            </p>
            <strong>Security Measures</strong>
            <p>
              We take our security responsibilities seriously, using the
              most appropriate physical and technical measures, and require
              our partners to use the same standard of care. Once we have
              received your information, we will use strict procedures and
              security features to try to prevent unauthorized access. These
              are described in more detail below.
            </p>
            <strong>Location of Servers and Accessibility</strong>
            <p>
              Your personal data is not stored locally but on a secure
              server. which helps ensure your personal data is secure and
              private.
            </p>
            <strong>Data Storage</strong>
            <p>
              Your personal data is stored on secure servers hosted on
              Amazon AWS secure cloud hosting. These servers are located in
              the United States comply with Standard Contractual Clauses as
              a valid mechanism for transferring data outside the European
              Union.
            </p>
            <strong>Data Encryption & Security</strong>
            <p>
              The company employees best practices for data encryption and
              security and may utilize the following security measures where
              applicable.
            </p>
            <p>
              Data is encrypted using SSL Certification when transmitted
              from our servers to your browser.
            </p>
            <strong>Field Level Encryption </strong> <p>Tokenization </p>
            <p>Intrusion Protection System</p>
            <h4>Your Personal Data and Your Rights</h4>
            <strong>Accessing your Personal Data</strong>
            <p>
              You may request access at any time to a copy of the personal
              data we hold about you. Any such request should be submitted
              to us in writing and sent to support@hereiswhatilke.com We
              will need to verify your identity in such circumstances and
              may request more information or clarifications from you if
              needed to help us locate and provide you with the personal
              data requested. There is usually no charge applied to access
              your personal data (or to exercise any of the other rights).
              However, if your request is clearly unfounded, repetitive, or
              excessive, we may charge a reasonable fee. Alternatively, we
              may refuse to comply with your request in these circumstances.
            </p>
            <h4>Right of Restriction</h4>
            <p>
              You may restrict us from processing your personal data in any
              of the following circumstances:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  You have contested the accuracy of the personal data we
                  hold on record in relation to you or for a period of time
                  to enable us to verify the accuracy of the personal data;
                </li>
                <li>
                  The processing of your personal data is unlawful and you
                  request the restriction of the use of personal data
                  instead of its erasure;
                </li>
                <li>
                  We no longer require your personal data for the purpose of
                  processing but you require this data for the
                  establishment, exercise, or defense of legal claims; or
                </li>
                <li>
                  Where you have contested the processing under the relevant
                  data privacy act pending the verification of our
                  legitimate grounds.
                </li>
              </ul>
            </div>
            <strong>
              Corrections or Erasure (Right to Rectification and Right to Be
              Forgotten)
            </strong>
            <p>
              If we hold personal data concerning you which are no longer
              necessary for the purposes for which they were collected or if
              you withdraw consent for us to process your personal data, you
              can request the deletion of this personal data. This right,
              however, will not apply where we are required to process
              personal data in order to comply with a legal obligation or
              where the processing of this information is carried out for
              reasons of public interest in the area of public{" "}
            </p>
            <p>
              health. If the personal information we hold about you is
              inaccurate, you may request to have your personal information
              updated and corrected.{" "}
            </p>
            <h4>Your Right to Object</h4>
            <p>
              You have the right to object to the processing of your
              personal data at any time:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>For direct marketing purposes</li>
                <li>
                  For profiling, to the extent, it relates to direct
                  marketing
                </li>
                <li>
                  Where we process your personal data for the purposes of
                  legitimate interests pursued by us, except where we can
                  demonstrate compelling legitimate grounds for this
                  processing which would override your interests, rights,
                  and freedoms or in connection with the enforcement or
                  defense of a legal claim
                </li>
              </ul>
            </div>
            <p>
              Should this occur, we will no longer process your personal
              data for these purposes unless doing so is justified by a
              compelling legitimate ground as described above.
            </p>
            <h4>Data Portability</h4>
            <p>
              Where we process your personal data by automated means (i.e.,
              not on paper) and this processing is based on your consent or
              required for the performance of a contract between us, you
              have the right to request from us a copy of your personal data
              in a structured, commonly used machine-readable format and,
              where technically feasible, to request that we transmit your
              personal data in this format to another controller.
            </p>
            <strong>Profiling</strong>
            <p>
              Profiling is an automated form of processing of personal data
              often used to analyze or predict the personal aspects of an
              individual person. This could relate to a person’s performance
              at work, economic situation, health, personal preferences,
              reliability, behavior, location, or movements. An example of
              this would be where a bank uses an automated credit scoring
              system to assess and reject a loan application.
            </p>{" "}
            <p>
              You have the right to be informed if your personal data will
              be subject to automated decision making, including profiling.
              You also have the right not to be subject to a decision based
              solely on the automated process, including profiling, where
              that decision impacts on your legal rights. There are some
              exceptions to this rule, where, for example, the decision is
              necessary for connection with the performance of a contract
              between us, is authorized by law, or where you have given your
              explicit consent to this automated processing. In this case,
              however, we do not engage in profiling or automated processing
              for profiling purposes.
            </p>
            <h4>
              <strong>California Residents Privacy Rights</strong>
            </h4>
            <p>
              Company complies with the California Consumer Privacy Act
              (referred to as “CCPA”) as set forth by the State of
              California. Company does not sell the collected personal
              information. We only share your personal information as
              described in this Privacy Policy.
            </p>
            <p>
              California residents are permitted one time each year to
              request certain details about how their personal information
              is shared with third parties for their direct marketing
              purposes. To make a request, please submit a request, or write
              to us at the address listed below. Indicate in your letter
              that you are a California resident making a “Shine the Light”
              inquiry.
            </p>
            <p>
              California residents may also take advantage of the following
              rights:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  <u>Access.</u> You may request, that we disclose to you
                  the categories and specific pieces of personal information
                  that we have collected about you, the categories of
                  sources from which we collected your personal information,
                  our business or commercial purpose for collecting your
                  personal information, the categories of personal
                  information that we have disclosed for a business purpose,
                  any categories of personal information about you that we
                  have sold, the categories of third parties with whom we
                  have disclosed your personal information, and the business
                  or commercial purpose for collecting, selling, or sharing
                  your personal information, if applicable.
                </li>
                <li>
                  <u>Deletion.</u> You may request that we delete your
                  personal information that we maintain about you, subject
                  to certain exceptions. As described in more detail in our
                  Statement, there are some reasons for which we will not be
                  able to fully address your deletion request, such as if we
                  need to complete a transaction for you, to detect and
                  protect against fraudulent and illegal activity, to
                  exercise our rights, or to comply with a legal obligation.
                  You can also access, update, and remove your information
                  by visiting the Member Profile page on our website.
                </li>
                <li>
                  <u>Correction.</u> You can request that we correct
                  inaccurate personal information that we maintain about
                  you.
                </li>
                <li>
                  Do Not Sell or Share My Personal Information. You may
                  request to opt out of our sale or sharing of your personal
                  information to third parties. For purposes of this
                  California Privacy Statement, “sell” means the sale of
                  your personal information to a third party for monetary or
                  other valuable consideration, subject to certain
                  exceptions set forth in applicable California law. For
                  purposes of this California Privacy Statement, “share”
                  means disclosure of personal information to third parties
                  for cross-context behavior al advertising purposes,
                  subject to certain exceptions in applicable law. To effect
                  the opt out, please click on the “Do Not Sell or Share My
                  Personal Information” button on the bottom of the homepage
                  or submit a request in writing to the contact information
                  below. As described above, we, and certain other
                  companies, place tracking technologies, such as cookies,
                  on our websites which allow us, and those companies, to
                  receive information about your activity on our websites
                  that is associated with your browser or Device. We
                </li>
              </ul>
            </div>
            <p>
              and these companies may use that data to customize content for
              you and to serve you more relevant ads on our websites or
              others. Note that if you would like to opt out of tracking for
              behavior al advertising purposes, you will need to set your
              preferences by clicking the “Cookie consent” link at the
              bottom of the webpage or you may broadcast an opt-out
              preference signal recognized by Company, such as the Global
              Privacy Control opt-out preference signal (on the browsers or
              browser extensions that support such a signal). We do not
              knowingly sell or share the personal information of minors
              under 16 years of age.
            </p>
            <h4>
              <strong>
                Multi-State Privacy Statement for Virginia, Connecticut,
                Colorado
              </strong>
            </h4>
            <p>
              This Multi-State Privacy Statement is provided pursuant to
              applicable state laws and applies to Virginia, Connecticut,
              and Colorado residents, and supplements our overall Statement
              with additional disclosures and rights.
            </p>
            <p>
              If you are a resident of Virginia, Connecticut, or Colorado
              you may take advantage of the following rights in accordance
              with applicable law:
            </p>
            <div className="company-contact-detail-area">
              <ul>
                <li>
                  Confirmation/Access. You can request to confirm whether we
                  process your personal information. Subject to certain
                  exceptions, you can also request a copy of your personal
                  information you provided to us.
                </li>
                <li>
                  Deletion. You can request that we delete personal
                  information we have about you.
                </li>
                <li>
                  Correction. You can request that we correct inaccurate
                  personal information we maintain about you.
                </li>
                <li>
                  Opt Out of Sale. You can request to opt out of the sale of
                  your personal information to a third party.
                </li>
                <li>
                  Opt Out of Targeted Advertising. We may display
                  advertisements to you where the ad was selected based on
                  personal information obtained from (or in some instances
                  inferred) from your activities over time and across
                  unaffiliated websites or online applications to predict
                  your preferences or interests. To the extent permitted by
                  law, you can request that we stop using your personal
                  information for such targeted advertising by clicking on
                  the “Cookie consent” link that may be in the footer of our
                  websites. Additionally, you can opt out of certain uses of
                  cookies for advertising purposes by visiting
                  www.aboutads.info/choices. Your opt-out of cookie-based
                  tracking for advertising purposes is specific to the
                  device, website, and browser you are using; it is deleted
                  whenever you clear your cookies or your browser’s cache.
                </li>
              </ul>
            </div>
            <h4>
              <strong>
                General Data Protection Regulation Privacy Statement (“GDPR
                Statement”)
              </strong>
            </h4>
            <p>
              This GDPR Statement applies to the citizens of European
              Economic Area (“EEA”) countries, including those based in the
              United Kingdom, regardless of their residency.{" "}
            </p>
            <strong>Controller</strong>
            <p>
              Under this Policy, and unless the circumstances otherwise
              require, we will be what’s known under the General Data
              Protection Regulation (EU) 2016/679 (the “GDPR”) as a
              controller of the personal data you provide to us. This means
              that we determine the purposes and means of the processing of
              personal data we collect.{" "}
            </p>
            <strong>Your Rights Under the GDPR</strong>
            <p>
              Please review the section “Your Personal Data and Your Rights”
              above for more information on your data privacy rights.
            </p>
            <strong>Changes to this policy</strong>
            <p>
              Any changes made to this Policy from time to time will be
              published at the Site.
            </p>{" "}
            <p>
              Any material or other change to the data processing operations
              described in this Policy which is relevant to or impacts on
              you or your personal data will be notified to you in advance
              by email. In this way, you will have an opportunity to
              consider the nature and impact of the change and exercise your
              rights under the CCPA and other relevant data privacy acts in
              relation to that change (e.g., to withdraw consent or to
              object to the processing) as you see fit.
            </p>
          </div>
          </div>
          <br />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default TermPrivacyModal;
