/* eslint-disable no-lone-blocks */
import React, { useRef, useState, useEffect } from "react";
import "../../assets/css/claimbusiness.css";
import checkIcon from "../../assets/images/request_submitted.jpg";
import {
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  Spinner,
} from "reactstrap";
import closeIcon from "../../assets/images/close-icon.png";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../helpers/app.constants";
import { ShowToastMessage } from "../../components/toastr";
import { v4 as uuidv4 } from "uuid";
import {
  editCompany,
  verifyToClaimBusinessCompany,
  verifyOtpid,
  resendOtpverify,
  claimBusinessUpdate,
  checkPlaceDetailsExistsAPT,
  getPlaceAutoCompleteAPI,
  getPlaceAutoCompleteForHotel,
  getCategories,
  getSingleCompany,
} from "../../services/api/company.service";
import { useParams, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const Claimbusiness = () => {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const name = new URLSearchParams(search).get("name");
  const mobileNo = new URLSearchParams(search).get("mobileNo");
  const ownerManager = new URLSearchParams(search).get("ownerManager");
  const categoryId = new URLSearchParams(search).get("categoryId");
  const travelAgencyStatus = new URLSearchParams(search).get(
    "travelAgencyStatus"
  );
  
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }
  const branchIoRefId = getCookie('branch_io_ref_id');
  console.log("branch_io_dfdfasf", branchIoRefId);

  const [formValues, setFormValues] = useState({
    name: name,
    email: email,
    mobileNo: mobileNo,
    ownerManager: ownerManager,
    categoryId: categoryId,
    travelAgencyStatus: travelAgencyStatus,
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    googlelocationname: "",
    website: "",
    mobileNoCC: "",
  });
  const [formValuesErr, setFormValuesErr] = useState({
    nameErr: false,
    email: false,
    mobileNo: false,
    categoryId: false,
    address: false,
    website: false,
    ownerManagerName: false,
    googlelocationname: false,
    ownerManagerEmail: false,
    ownerManagerMobileNo: false,
    ownerManagerTitle: false,
  });

  const [oldValues, setOldValues] = useState({
    name: "",
    email: "",
    mobileNo: "",
    ownerManager: "",
    categoryId: "",
    googlelocationname: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    website: "",
    mobileNoCC: "",
  });

  const [isAddModalOpen, setAddModalOpen] = useState(false);
 
  const [focusField, setFocusField] = useState("");
  const [isClaimLoader, setIsClaimLoader] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [otpData, setOtpData] = useState({});
  const [b64image, setb64Image] = useState("");
  const [defaultCategory, setDefaultCategory] = useState(categoryId);
  const [categories, setCategories] = useState([]);
  let { id } = useParams();
  const formRef = useRef(null);
  const owner = useRef(null);
  const manager = useRef(null);
  let web, webMail;
  const [comapnyMail, setCompanyMail] = useState("");
  const [suffixMail, setSuffixMail] = useState("");
  const [manualApproval, setManualApproval] = useState("");
  const [_id, Set_Id] = useState("");
  const [CompanyStatus, setCompanyStatus] = useState("");
  const [isresendOtp, setIsResendOtp] = useState(false);
  /* For Location search */
  const [citydrop, setCityDrop] = useState(false);
  const [cmpydrop, setCmpyDrop] = useState(false);
  const [locations, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [companyName, setCompanyName] = useState(name);
  const [searcher, setSearcher] = useState(0);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [googlelocationId, setGooglelocationId] = useState("");
  const [googlePlaceId, setGooglePlaceId] = useState("");
  const [fetchedImg, setFetchedImage] = useState("");
  const [fetchedBaseFormat, setFetchedBaseFormat] = useState("");
  const [checkedState, setcheckedState] = useState("email");
  const [iscompanyName, setIscompanyName] = useState(false);
  const [companyNameSelect, setCompanyNameSelect] = useState("");
  const [newCompanyData, setNewCompanyData] = useState(name);
  const [filterModalOpen, setfilterModalOpen] = useState(false);
  const [otpModalOpen, setotpModalOpen] = useState(false);
  const apikey = process.env.REACT_APP_GOOGLE_API_KEY;
  const appurl = process.env.REACT_APP_URL;
  /* New Changes in Google place API */
  const [uniqueIds1, setUniqueId1] = useState("");
  const [uniqueIds2, setUniqueId2] = useState("");
  const [placesDetails, setPlaceDetails] = useState([]);
  const [cityloading, setCityLoading] = useState(false);
  const [cmpyloading, setCmpyLoading] = useState(false);
  const [referredId, setReferredId] = useState(null);

  const [locationCity, setLocationCity] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationCountry, setLocationCountry] = useState("");

  let manualStatus = true;
  let notManualStatus = false;

  const refreshUniqueId1 = () => {
    setUniqueId1(uuidv4());
  };


  const refreshUniqueId2 = () => {
    setUniqueId2(uuidv4());
  };

  // form events functions
  const handleChange = (e) => {
    setFormValuesErr({
      nameErr: false,
      email: false,
      mobileNo: false,
      categoryId: false,
      address: false,
      website: false,
      ownerManagerName: false,
      ownerManagerEmail: false,
      ownerManagerMobileNo: false,
      ownerManagerTitle: false,
    });
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const validationErr = (data) => {
    if (data.googlelocationname === "") {
      setFormValuesErr({ formValuesErr, googlelocationname: true });
      setFocusField("googlelocationname");
    } else if (data.address === "") {
      setFormValuesErr({ formValuesErr, address: true });
      setFocusField("address");
    } else if (data.email === "") {
      setFormValuesErr({ formValuesErr, email: true });
      setFocusField("email");
    } else if (data.mobileNo === "") {
      setFormValuesErr({ formValuesErr, mobileNo: true });
      setFocusField("mobileNo");
    } else if (data.website === "") {
      setFormValuesErr({ formValuesErr, website: true });
      setFocusField("website");
    } else if (data.ownerManagerName === "") {
      setFormValuesErr({ formValuesErr, ownerManagerName: true });
      setFocusField("ownerManagerName");
    } else if (data.ownerManagerEmail === "") {
      setFormValuesErr({ formValuesErr, ownerManagerEmail: true });
      setFocusField("ownerManagerEmail");
    } else if (data.ownerManagerMobileNo === "") {
      setFormValuesErr({ formValuesErr, ownerManagerMobileNo: true });
      setFocusField("ownerManagerMobileNo");
    } else if (data.ownerManagerTitle === "") {
      setFormValuesErr({ formValuesErr, ownerManagerTitle: true });
      setFocusField("ownerManagerTitle");
    } else if (data.categoryId[0] === "") {
      setFormValuesErr({ formValuesErr, categoryId: true });
      setFocusField("categoryId");
    } else {
      setFormValuesErr({
        nameErr: false,
        email: false,
        mobileNo: false,
        categoryId: false,
        address: false,
        website: false,
        googlelocationname: false,
        ownerManagerName: false,
        ownerManagerEmail: false,
        ownerManagerMobileNo: false,
        ownerManagerTitle: false,
      });
      setFocusField("");
      return true;
    }
    return false;
  };
  useEffect(() => {
    const handleFocus = () => {
      if (formValuesErr[focusField]) {
        document.getElementsByClassName(focusField)[0].focus();
      }
    };
    handleFocus();
  }, [focusField]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setReferredId(branchIoRefId)
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    data.categoryId = [data.categoryId];
    data.googlelocationId = googlelocationId;
    data.googlePlaceId = googlePlaceId;
    data.categories = [data?.categoryId];
    data.subcategories = [
      categories
        ?.filter((item) => item?._id === data?.categoryId[0])[0]
        ?.subCategory.filter((item) => item.subcategoryName === "General")[0]
        ?._id,
    ];
    data.referredId = branchIoRefId ? branchIoRefId : null;
    console.log(branchIoRefId,"hitdede")
    web = formValues?.website;
    setCompanyWebsite(web);
    if (travelAgencyStatus) {
      data.travelAgencyStatus = travelAgencyStatus;
    }
    if (fetchedImg) {
      data.image = fetchedBaseFormat;
    }

    const NewValidate = validationErr(data);
    if (NewValidate) {
      if (
        !(
          web?.includes("https://") ||
          web?.includes("http://") ||
          web?.includes("www.")
        )
      ) {
        ShowToastMessage(
          `website url must be valid format`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        let regx = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
        webMail = web.match(regx);

        webMail && setCompanyMail(`@${webMail[1]}`);

        let company = "";
        if (data.image === "") {
          delete data.image;
        }

        if (id) {
          setIsClaimLoader(true);
          company = await claimBusinessUpdate(data, id);
          setIsClaimLoader(false);
        } else if (_id && CompanyStatus) {
          setIsClaimLoader(true);
          company = await claimBusinessUpdate(data, (id = _id));
          setIsClaimLoader(false);
        } else {
          setIsClaimLoader(true);
          company = await editCompany(data);
          setIsClaimLoader(false);
        }

        setCompanyStatus(
          company?.data?.companyEmailStatus === "pending" &&
            company?.data?.companyTextStatus === "pending" &&
            company?.data?.companyPhoneCallStatus === "pending"
        );
        Set_Id(company?.data?._id);

        if (company?.error) {
          setAddModalOpen(false);
          ShowToastMessage(
            `${company.msg}`,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        } else if (_id && !CompanyStatus) {
          ShowToastMessage(
            `${company.message}`,
            TOAST_SUCCESS,
            toast.POSITION.BOTTOM_RIGHT
          );
          setAddModalOpen(false);
        } else {
          setAddModalOpen(true);
        }
      }
    }
  };

  const verifyClaimSubmit = async (e, status) => {
    e.preventDefault();
    console.log(status, "anik");
    let listshow = status === true ? manualApproval  : `${suffixMail}${comapnyMail}`;
    setIsClaimLoader(true);
    const company = await verifyToClaimBusinessCompany(listshow, _id, status);
    setIsClaimLoader(false);
    if (company.error) {
      setAddModalOpen(false);
      ShowToastMessage(
        `${company.msg}`,
        TOAST_ERROR,
        toast.POSITION.BOTTOM_RIGHT
      );
    } else {
      setotpModalOpen(true);
      ShowToastMessage(
        `${company.msg}`,
        TOAST_SUCCESS,
        toast.POSITION.BOTTOM_RIGHT
      );
    }
  };

  const resendOtp = async (e) => {
    setIsResendOtp(true);
    ShowToastMessage(
      `Resend OTP has been send in your email`,
      TOAST_SUCCESS,
      toast.POSITION.BOTTOM_RIGHT
    );
    e.preventDefault();
    let listshow = `${suffixMail}${comapnyMail}`;
    const company = await resendOtpverify(listshow, _id);
    if (company.error) {
      setAddModalOpen(false);
      ShowToastMessage(
        `${company.msg}`,
        TOAST_ERROR,
        toast.POSITION.BOTTOM_RIGHT
      );
    } else {
      setotpModalOpen(true);
    }
    setTimeout(() => {
      setIsResendOtp(false);
    }, 6000);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsClaimLoader(true);
    const otp = await verifyOtpid(otpData, _id);
    setIsClaimLoader(false);
    if (otp.error) {
      setAddModalOpen(false);
      ShowToastMessage(`${otp.msg}`, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
    } else {
      setfilterModalOpen(true);
      closeOtpModal();
    }
  };
  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const closeClameModal = () => {
    setfilterModalOpen(false);
    window.open(appurl, "_self", "noopener,noreferrer");
  };

  const closeOtpModal = () => {
    setotpModalOpen(false);
  };

  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await imgtoBase64(file);
    setb64Image(base64);
  };

  const fetchCategories = async () => {
    const res = await getCategories();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCategories(res.data);
    }
  };
  const handleCategoryChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
  };

  const processImage = async (photo) => {
    if (photo) {
      var proxyURL = "https://cors.hereiswhatilike.com/",
        imageLookupURL = `https://maps.googleapis.com/maps/api/place/photo?photoreference=${photo}&key=${apikey}&maxwidth=700&maxheight=700`;
  
      const imageURLQuery = await fetch(proxyURL + imageLookupURL)
        .then((r) => r.blob())
        .then((data) => {
          const image = URL.createObjectURL(data);
          setFetchedImage(image);
          var reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function () {
            var base64data = reader.result;
            setFetchedBaseFormat(base64data);
          };
        })
        .catch((e) => {
          return e;
        });
    }
  };

  
  const SubmitSuffix = (e) => {
    e.preventDefault();
    if (suffixMail === undefined || suffixMail === "") {
      ShowToastMessage(
        "Please enter email address",
        TOAST_ERROR,
        toast.POSITION.BOTTOM_RIGHT
      );
    } else {
      verifyClaimSubmit(e, notManualStatus);
      closeAddModal();
    }
  };

  const SubmitManualApproval = (e) => {
    e.preventDefault();
    if (manualApproval === undefined || manualApproval === "") {
      ShowToastMessage(
        "Please enter email address",
        TOAST_ERROR,
        toast.POSITION.BOTTOM_RIGHT
      );
    } else {
      verifyClaimSubmit(e, manualStatus);
      closeAddModal();
    }
  };

  // onchange city location call
  const getCitySearchDetails = async (srchwrd) => {
    setCityLoading(true);
    let srchkey = srchwrd.trim().toLowerCase();

    const cityDatas = {
      input: srchkey,
      sessionToken: uniqueIds1,
      type: "geocode",
      location: "",
      radius: "",
      strictbounds: false,
      pd: "0",
      requestFrom: "2",
    };

    const resCity = await getPlaceAutoCompleteAPI(cityDatas);
    if (resCity) {
      if (!resCity.error && resCity.data) {
        setPlaceDetails(resCity.data);
        setCityDrop(true);
        setCityLoading(false);
      }
    }
  };

  //  deatil city location and company name call
  const getCityBasedPlaceDetails = async (placeid, selection) => {
    if (placeid) {
      if (selection === "cmpy") {
        setGooglePlaceId(placeid);

        const searchValues1 = {
          place_id: placeid,
          fields:
            "name,place_id,address_components,geometry,formatted_address,international_phone_number,website,photos",
          sessionToken: uniqueIds2,
          requestFrom: "2",
          pd: "3",
        };

        const resCmpy = await checkPlaceDetailsExistsAPT(searchValues1);
        const hasKeyExist = "result" in resCmpy.data;

        var placeDetails = "";
        if (hasKeyExist) {
          placeDetails = resCmpy.data.result;
        } else {
          placeDetails = resCmpy.data.placeDetailResponse;
        }
        if (placeDetails) {
          if (placeDetails?.photos?.[0]?.photo_reference) {
            processImage(placeDetails?.photos?.[0]?.photo_reference);
          } else {
            setFetchedImage("");
          }

          formValues.name = placeDetails?.name;

          if (placeDetails?.terms !== undefined) {
            let TermsValue = placeDetails.terms
              .map((item) => item.value)
              .reverse();
            formValues.country = TermsValue[0];
            formValues.city = TermsValue[2];
            formValues.state = TermsValue[1];
          }

          formValues.address =
            placeDetails?.structured_formatting?.secondary_text !== undefined
              ? placeDetails?.structured_formatting?.secondary_text
              : placeDetails?.formatted_address;
          var str = placeDetails?.international_phone_number;
          if (placeDetails?.international_phone_number) {
            formValues.mobileNoCC = str?.substring(0, str.indexOf(" "));
            formValues.mobileNo = str?.substring(str.indexOf(" ") + 1);
          }

          if (placeDetails?.website) {
            var url = placeDetails?.website;
            var urlonly = new URL(url).origin; //origin
            formValues.website = urlonly + "/";
          }
          setCompanyNameSelect(placeDetails?.name);
          setCompanyName(placeDetails?.name);
          const address_component1 = placeDetails.address_components;

          const ObjIdToFind1 = "postal_code";
          const isObjectPresent1 = address_component1.find(
            (o) => o.types[0] === ObjIdToFind1
          );
          if (!isObjectPresent1) {
            formValues.zipcode = "";
          }

          const ObjIdToFind2 = "locality";
          const isObjectPresent2 = address_component1.find(
            (o1) => o1.types[0] === ObjIdToFind2
          );
          if (!isObjectPresent2) {
            setLocationCity("");
          }

          const ObjIdToFind3 = "administrative_area_level_1";
          const isObjectPresent3 = address_component1.find(
            (o2) => o2.types[0] === ObjIdToFind3
          );
          if (!isObjectPresent3) {
            setLocationCountry("");
          }

          const ObjIdToFind4 = "country";
          const isObjectPresent4 = address_component1.find(
            (o3) => o3.types[0] === ObjIdToFind4
          );
          if (!isObjectPresent4) {
            setLocationState("");
          }

          let lcn = "";
          let lsn = "";
          let lccn = "";

          address_component1.map((element) => {
            if (element.types[0] === "postal_code") {
              var zip = element.long_name;
              formValues.zipcode = zip;
            }

            if (element.types[0] === "locality") {
              lcn = element.long_name + ",";
              setLocationCity(lcn);
              formValues.city = lcn;
            }

            if (element.types[0] === "administrative_area_level_1") {
              lsn = element.long_name + ",";
              setLocationCountry(lsn);
              formValues.state = lsn;
            }

            if (element.types[0] === "country") {
              lccn = element.long_name;
              setLocationState(lccn);
              formValues.country = lccn;
            }
          });
        }
        setUniqueId2("");
        refreshUniqueId2();
      } else if (selection === "city") {
        setGooglelocationId(placeid);
        const searchValues2 = {
          place_id: placeid,
          fields:
            "name,place_id,address_components,geometry,formatted_address,international_phone_number,website,photos",
          sessionToken: uniqueIds1,
          requestFrom: "2",
          pd: "2",
        };

        const res = await checkPlaceDetailsExistsAPT(searchValues2);
        const hasKey = "result" in res?.data;

        if (hasKey) {
          let placeDetailsResponse1 = res?.data?.result;
          setCoordinates(placeDetailsResponse1.geometry.location);
        } else {
          let placeDetailsResponse2 = res?.data?.placeDetailResponse;
          setCoordinates(placeDetailsResponse2?.geometry.location);
        }
        setUniqueId1("");
        refreshUniqueId1();
      }
    }
  };

  // onchange company name call
  const getCmpySearchDetails = async (srchwrd2) => {
    setCmpyLoading(true);
    let exactLocation = "";
    if (srchwrd2?.length >= 3) {
      if (locations.split(",").length > 1) {
        exactLocation = "," + locations.split(",").slice(0, -1).toString();
      } else {
        exactLocation = " ," + locations;
      }
    }
    let srchkey2 = srchwrd2.trim().toLowerCase();

    let cateType = null;
    const cmpyDatas = {
      input: srchkey2 + exactLocation,
      sessionToken: uniqueIds2,
      type: cateType,
      location: coordinates?.lat + "," + coordinates?.lng,
      radius: "50000",
      strictbounds: true,
      pd: "1",
      requestFrom: "2",
    };
    console.log("=======", cmpyDatas.input);

    const resCmpys = await getPlaceAutoCompleteForHotel(cmpyDatas);
    if (resCmpys) {
      if (!resCmpys.error && resCmpys.data) {
        setCompanyFilter(resCmpys.data);
        setCmpyDrop(true);
        setCmpyLoading(false);
      }
    }
  };
  useEffect(() => {
    refreshUniqueId1();

    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // fetch Company detaisl
    const fetchCompanydetail = async (id) => {
      setIsClaimLoader(true);
      const res = await getSingleCompany(id);
      setIsClaimLoader(false);
      if (!res?.error) {
        setFormValues(res?.data);
        setOldValues(res?.data);
        setFetchedImage(res?.data?.image);

        setDefaultCategory(res?.data?.categoryId[0]?._id);

        if (res?.error) {
          var errorMessage = res?.msg ? res?.msg : "Error Loading the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
      }

      setCompanyStatus(
        res?.data?.companyEmailStatus === "pending" &&
          res?.data?.companyTextStatus === "pending" &&
          res?.data?.companyPhoneCallStatus === "pending"
      );
      return res?.data;
    };
    if (id) {
      fetchCompanydetail(id);
    }
    fetchCategories();
  }, [id]);

  useEffect(() => {
    if (companyName) {
    } else {
      formValues.name = "";
      setCompanyName("");
      formValues.email = "";
      formValues.mobileNo = "";
      formValues.website = "";
      formValues.address = "";
      formValues.city = "";
      formValues.state = "";
      formValues.zip = "";
      formValues.country = "";
    }
  }, []);

  const ModalRadioClick = (event) => {
    formRef.current.childNodes.forEach((item, index) => {
      if (
        +event.target.attributes.getNamedItem("selectkey").value - 1 ===
        index
      ) {
        item.className = item.className.split(" ").includes("show_data")
          ? item.className
          : [...item.className.split(" "), "show_data"].join(" ");
      } else {
        item.className = item.className
          .split(" ")
          .filter((name) => name !== "show_data")
          .join(" ");
      }
    });
  };

  
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container claim_container">
        <div className="top-btn-links col-md-12">
          <Link to={process.env.REACT_APP_URL}>Back to Home</Link>
        </div>
        <section
          className="main-layout d-flex"
          onClick={() => {
            setCityDrop(false);
            setCmpyDrop(false);
          }}
        >
          <div className="main">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              className="claim_form"
            >
              {/* <div className="steps_row admin_claim_top">
                <span className="steps">
                  <span className="gray">Step:</span>{" "}
                  <span>(1)Create An Account</span> of{" "}
                  <a href="#!"> (2)Claim A Business</a>
                </span>
              </div> */}
              <div className="claim_business">
                <div className="clamin_business_left">
                  <h1>
                    {travelAgencyStatus
                      ? "Select or Add Travel Agency or Related Company"
                      : "Select or Add a Company Profile"}
                  </h1>
                  <p>Business Not Verified Yet - Waiting On Approval</p>
                </div>
              </div>

              <div className="details_box">
                <input type="hidden" value={id} name="" />
                <div className="row">
                  <div className="left_panel col-md-6 col-12 position-relative">
                    <h2>Company Address</h2>
                    <label>Enter City Location</label>
                    <input
                      type="text"
                      name="googlelocationname"
                      className="form-control googlelocationname"
                      id="citybasedSearch"
                      aria-describedby="emailHelp"
                      placeholder="Enter City Name"
                      autoComplete="off"
                      onChange={(evt) => {
                        handleChange(evt);
                        setLocation(evt.target.value);
                        getCitySearchDetails(evt.target.value);
                      }}
                      readOnly={
                        id
                          ? oldValues?.googlelocationname === ""
                            ? false
                            : true
                          : false
                      }
                      autoFocus={locations === "" ? true : false}
                      value={id ? formValues?.googlelocationname : locations}
                      onClick={() => setCityDrop(true)}
                    />
                    {formValuesErr.googlelocationname && (
                      <p className="errormsg">Location field is required</p>
                    )}
                    {/* city location */}
                    {citydrop && (
                      <div className="autocomplete-dropdown-container">
                        {cityloading ? (
                          <div>Loading...</div>
                        ) : (
                          <ul>
                            {placesDetails &&
                              placesDetails.map((item) => (
                                <li
                                  key={
                                    item?.place_id === undefined
                                      ? item?.placeId
                                      : item?.place_id
                                  }
                                  onClick={(i) => {
                                    if (id) {
                                      setFormValues({
                                        ...formValues,
                                        googlelocationname: item?.description,
                                      });
                                    }
                                    setLocation(item?.description);
                                    getCityBasedPlaceDetails(
                                      item?.place_id === undefined
                                        ? item?.placeId
                                        : item?.place_id,
                                      "city"
                                    );

                                    setCityDrop(false);
                                  }}
                                >
                                  {item?.description}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    )}

                    <div className="">
                      {!id && (
                        <>
                          <h2>
                            Company Name:{" "}
                            <span style={{ color: "red" }}>
                              {!iscompanyName
                                ? name
                                : companyName === ""
                                ? name
                                : companyName}
                            </span>
                          </h2>
                        </>
                      )}
                    </div>
                    <div className="position_relative ">
                      <input
                        type="hidden"
                        name="name"
                        value={
                          id
                            ? formValues?.name
                            : companyName === ""
                            ? name
                            : companyName
                        }
                      />
                      <div className="form-group mainCloseDrop">
                        <label htmlFor="companybasedSearch">
                          {id
                            ? "Search Company Name"
                            : "Or search our database of existing companies to match"}
                          {id ? (
                            <input
                              type="text"
                              id="companybasedSearch"
                              aria-describedby="emailHelp"
                              placeholder="Check if your company is already in the database"
                              autoComplete="off"
                              onChange={(evt) => {
                                if (id) {
                                  handleChange(evt);
                                }
                                setNewCompanyData(evt.target.value);
                                setCompanyNameSelect(evt.target.value);
                                handleChange(evt);
                                setIscompanyName(true);
                                setCompanyName(evt.target.value);
                                setSearcher(searcher + 1);
                              }}
                              value={id ? formValues?.name : newCompanyData}
                              readOnly={
                                id
                                  ? oldValues?.name === ""
                                    ? false
                                    : true
                                  : false
                              }
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id="companybasedSearch"
                              aria-describedby="emailHelp"
                              placeholder="Search by Company Name"
                              autoComplete="off"
                              onChange={(evt) => {
                                setCompanyName(evt.target.value);
                                getCmpySearchDetails(evt.target.value);

                                setNewCompanyData(evt.target.value);
                                setCompanyNameSelect(evt.target.value);
                                handleChange(evt);
                                setIscompanyName(true);
                                setSearcher(searcher + 1);
                              }}
                              value={companyNameSelect}
                              onClick={() => setCmpyDrop(true)}
                            />
                          )}

                          <div className="closeDrop companyname-clear">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setCompanyNameSelect("");
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </label>
                      </div>

                      {cmpydrop && (
                        <div className="company-selection-sec">
                          {cmpyloading ? (
                            <div>Loading...</div>
                          ) : (
                            <ul>
                              {companyFilter &&
                                companyFilter.map((item) => (
                                  <li
                                    key={
                                      item?.place_id === undefined
                                        ? item?.placeId
                                        : item?.place_id
                                    }
                                    onClick={(i) => {
                                      setCompanyName(item?.description);
                                      setCompanyNameSelect(item?.description);
                                      formValues.name = item?.description;
                                      getCityBasedPlaceDetails(
                                        item?.place_id === undefined
                                          ? item?.placeId
                                          : item?.place_id,
                                        "cmpy"
                                      );

                                      setCmpyDrop(false);
                                    }}
                                  >
                                    {item?.description}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="form-group mt-5">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <textarea
                        className="form-control address"
                        id="streetNo"
                        name="address"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        rows={3}
                        onChange={handleChange}
                        value={formValues?.address}
                        readOnly={
                          id
                            ? oldValues?.address === ""
                              ? false
                              : true
                            : locations === ""
                            ? true
                            : false
                        }
                      />
                      {formValuesErr.address && (
                        <p className="errormsg">Address field is required</p>
                      )}
                    </div>
                    <div className="relative">
                      <label>
                        State
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formValues?.state}
                          name="state"
                          placeholder="State"
                          readOnly={
                            id
                              ? oldValues?.state === ""
                                ? false
                                : true
                              : locations === ""
                              ? true
                              : false
                          }
                        />
                      </label>
                    </div>

                    <div className="devide">
                      <label>
                        Zip Code
                        <input
                          type="text"
                          className="form-control"
                          id="zipcode"
                          name="zipcode"
                          aria-describedby="emailHelp"
                          autoComplete="off"
                          onChange={handleChange}
                          value={formValues?.zipcode}
                          maxLength={10}
                          placeholder="Zip Code"
                          readOnly={
                            id
                              ? oldValues?.zipcode === ""
                                ? false
                                : true
                              : locations === ""
                              ? true
                              : false
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          // disabled={locations === "" ? true : false}
                        />
                      </label>
                      <label>
                        Country
                        <input
                          onChange={handleChange}
                          value={formValues?.country}
                          type="text"
                          name="country"
                          placeholder="Country Name"
                          readOnly={
                            id
                              ? oldValues?.country === ""
                                ? false
                                : true
                              : locations === ""
                              ? true
                              : false
                          }
                          // disabled={locations === "" ? true : false}
                        />
                      </label>
                    </div>
                    <h2>Company Details</h2>

                    <label>
                      Email
                      <input
                        type="email"
                        value={formValues?.email}
                        onChange={handleChange}
                        className="email"
                        name="email"
                        placeholder="Enter email address"
                        readOnly={
                          id ? (oldValues?.email === "" ? false : true) : false
                        }
                      />
                      {formValuesErr.email && (
                        <p className="errormsg">Email field is required</p>
                      )}
                    </label>
                    <label className="tel">
                      Company Phone Number
                      <input
                        type="tel"
                        value={formValues?.mobileNo}
                        onChange={handleChange}
                        name="mobileNo"
                        className="mobileNo"
                        placeholder="Company Phone Number"
                        readOnly={
                          id ? (oldValues?.mobileNo === "" ? false : true) : ""
                        }
                      />
                    </label>
                    {formValuesErr.mobileNo && (
                      <p className="errormsg">
                        Company Phone number field is required
                      </p>
                    )}
                    <div className="devide">
                      <div className="devide_subsection">
                        <label>
                          Company Website
                          <input
                            value={formValues?.website}
                            onChange={handleChange}
                            name="website"
                            className="website"
                            placeholder="website address"
                            readOnly={
                              id
                                ? oldValues?.website === ""
                                  ? false
                                  : true
                                : ""
                            }
                          />
                        </label>
                        {formValuesErr.website && (
                          <p className="errormsg">
                            Company website field is required
                          </p>
                        )}
                      </div>
                      <div className="devide_subsection">
                        <label>
                          Company Type
                          {(
                            id
                              ? oldValues?.categoryId === ""
                                ? false
                                : true
                              : false
                          ) ? (
                            <input
                              type="hidden"
                              name="categoryId"
                              value={
                                id
                                  ? defaultCategory === ""
                                    ? categoryId
                                    : defaultCategory
                                  : defaultCategory
                              }
                            />
                          ) : (
                            ""
                          )}
                          <select
                            name="categoryId"
                            className="form-select categoryId"
                            disabled={
                              id
                                ? oldValues?.categoryId === ""
                                  ? false
                                  : true
                                : false
                            }
                            value={
                              id
                                ? defaultCategory === ""
                                  ? categoryId
                                  : defaultCategory
                                : defaultCategory
                            }
                            onChange={(e) => {
                              setDefaultCategory(e.target.value);
                              handleCategoryChange(e);
                            }}
                          >
                            <option value="">Please Select</option>
                            {categories &&
                              categories.map((category) => (
                                <option
                                  key={category._id}
                                  value={category._id}
                                  data-tag={category.categoryName}
                                >
                                  {category.categoryName}
                                </option>
                              ))}
                          </select>
                        </label>
                        {formValuesErr.categoryId && (
                          <p className="errormsg">
                            Company Type field is required
                          </p>
                        )}
                      </div>
                    </div>
                    <input type="hidden" name="image" value={b64image} />

                    {fetchedImg ? (
                      <div className="col-md-12">
                        <div className="form-group position_relative">
                          <label htmlFor="exampleInputEmail1">
                            Existing Company Image
                          </label>{" "}
                          &nbsp;&nbsp;
                          <img src={fetchedImg} width={70} height={70} alt="" />
                        </div>
                      </div>
                    ) : (
                      <label className="upload">
                        Company Image
                        <input
                          type="file"
                          value={formValues?.image}
                          placeholder="images"
                          onChange={(e) => {
                            handleChangeImage(e);
                            handleChange(e);
                          }}
                          className="browse_input"
                        />
                      </label>
                    )}
                  </div>
                  <div className="right_panel col-md-6 col-12">
                    <h2>
                      {formValues?.ownerManager === "Manager"
                        ? "Manager "
                        : "Owner "}
                      Details
                    </h2>
                    <div className="devide">
                      <label className="checkbox">
                        <input
                          type="radio"
                          name="ownerManager"
                          ref={owner}
                          onChange={handleChange}
                          defaultValue="Owner"
                          checked={formValues?.ownerManager === "Owner"}
                        />
                        <span>Owner</span>
                      </label>
                      <label className="checkbox">
                        <input
                          type="radio"
                          name="ownerManager"
                          ref={manager}
                          onChange={handleChange}
                          defaultValue="Manager"
                          checked={formValues?.ownerManager === "Manager"}
                        />
                        <span>Manager</span>
                      </label>
                    </div>
                    <div className="clearfix">
                      <h3>
                        Owner or manager contact information is not displayed to
                        public
                      </h3>
                    </div>
                    <label className="manager-name-label">
                      {formValues?.ownerManager === "Manager"
                        ? "Manager "
                        : "Owner "}
                      Name
                      <input
                        type="text"
                        name="ownerManagerName"
                        onChange={handleChange}
                        className="ownerManagerName"
                        defaultValue={formValues?.ownerManagerName}
                        placeholder="Enter Name"
                      />
                    </label>
                    {formValuesErr.ownerManagerName && (
                      <p className="errormsg">
                        {formValues?.ownerManager === "Manager"
                          ? "Manager "
                          : "Owner "}
                        Name is required
                      </p>
                    )}

                    <label>
                      {formValues?.ownerManager === "Manager"
                        ? "Manager "
                        : "Owner "}
                      Email
                      <input
                        type="email"
                        name="ownerManagerEmail"
                        className="ownerManagerEmail"
                        placeholder="Enter Email Address"
                        onChange={handleChange}
                        defaultValue={formValues?.ownerManagerEmail}
                      />
                    </label>
                    {formValuesErr.ownerManagerEmail && (
                      <p className="errormsg">
                        {formValues?.ownerManager === "Manager"
                          ? "Manager "
                          : "Owner "}
                        Email is required
                      </p>
                    )}
                    <label className="tel">
                      {formValues?.ownerManager === "Manager"
                        ? "Manager "
                        : "Owner "}{" "}
                      Direct Contact Number
                      <input
                        type="tel"
                        name="ownerManagerMobileNo"
                        className="ownerManagerMobileNo"
                        onChange={handleChange}
                        placeholder="Enter Contact Number"
                        defaultValue={formValues?.ownerManagerMobileNo}
                      />
                    </label>
                    {formValuesErr.ownerManagerMobileNo && (
                      <p className="errormsg">
                        {formValues?.ownerManager === "Manager"
                          ? "Manager "
                          : "Owner "}
                        Contact Number is required
                      </p>
                    )}
                    <label>
                      {formValues?.ownerManager === "Manager"
                        ? "Manager "
                        : "Owner "}{" "}
                      Title
                      <input
                        type="text"
                        onChange={handleChange}
                        name="ownerManagerTitle"
                        className="ownerManagerTitle"
                        placeholder="Enter Title"
                        defaultValue={formValues?.ownerManagerTitle}
                      />
                    </label>
                    {formValuesErr.ownerManagerTitle && (
                      <p className="errormsg">
                        {formValues?.ownerManager === "Manager"
                          ? "Manager "
                          : "Owner "}
                        Title is required
                      </p>
                    )}
                  </div>
                  <div className="clamin_business_left clamin_business_center">
                    {travelAgencyStatus ? (
                      <section>
                        <p>
                          A Travel Agency or related account allows you to
                          obtain free access to client preferences, free
                          advertising, free leads and much more.
                        </p>
                        <p>
                          <strong>Important - </strong> Please note, that we
                          will verify your travel industry or other related
                          credentials including IATA, CLIA, Consortium, Host
                          Agency, Meeting Planner ID, Concierge ID or other
                          related credentials or licenses
                        </p>
                      </section>
                    ) : (
                      <p>
                        A business account allows you to obtain access to
                        customer personal preferences, micro feedback, assign
                        perks and other valuable tools.
                      </p>
                    )}

                    <button type="submit">Claim Business</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>

      <Modal
        id="claimmodal"
        isOpen={isAddModalOpen}
        // toggle={closeAddModal}
        className=""
      >
        <ModalHeader>
          <div>
            <div className="close_modal_box close_popup">
              <a className="link-common" onClick={closeAddModal}>
                <img
                  className="img-fluid close_icon"
                  src={closeIcon}
                  alt="icon"
                />
              </a>
            </div>
            <h1 className="text-center">Choose a way to complete your claim</h1>
            <p className="text-center">
             You are about to claim {companyWebsite} <a href="#!">Change?</a>
            </p>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="receive_email">
            <form ref={formRef}>
              <div className="receive_email_inner show_data">
                <label className="checkbox">
                  <input
                    type="radio"
                    name="ra"
                    selectkey={1}
                    value="email"
                    placeholder=""
                    checked={checkedState === "email"}
                    onChange={() => setcheckedState("email")}
                    onClick={(e) => ModalRadioClick(e)}
                  />
                  <span>Receive an email at</span>
                </label>
                <div className="devide_new">
                  <label className="margin">
                    <input
                      type="email"
                      value={suffixMail}
                      name="suffixinput"
                      maxLength="20"
                      onChange={(e) => {
                        setSuffixMail(e.target.value);
                      }}
                      placeholder="_____"
                    />
                    <input
                      type="hidden"
                      name="domain"
                      value={`${suffixMail} ${comapnyMail}`}
                    />
                    <span className="suffixData">{comapnyMail}</span>
                  </label>
                  <span className="submit">
                    <input
                      type="submit"
                      name="domain"
                      value="Submit"
                      onClick={(e) => SubmitSuffix(e)}
                    />
                  </span>
                </div>
              </div>
              <div className="receive_email_inner">
              <label className="checkbox">
                <input
                  type="radio"
                  name="ra2"
                  selectkey={2}
                  value="email2"
                  placeholder=""
                   checked={checkedState === "email2"}
                  onChange={() => setcheckedState("email2")}
                  onClick={(e) => ModalRadioClick(e)}
                />
                <span>Request manual approval</span>
                <span style={{display: 'block', marginLeft: 34, fontSize: 14}}>We will contact you shortly to verify your account</span>
              </label>
              <div className="devide_new">
                <label className="margin">
                  <input
                    type="email"
                    value={manualApproval}
                    name="manualapproval" 
                    onChange={(e) => {
                      setManualApproval(e.target.value);
                    }}
                    placeholder="Enter your email address"
                  />
                </label>
                <span className="submit">
                  <input
                    type="submit"
                    name="domain"
                    value="Submit"
                    onClick={(e) => SubmitManualApproval(e)}
                  />
                </span>
              </div>
            </div>
              <div className="receive_email_inner">
                <label className="checkbox">
                  <input
                    type="radio"
                    name="ra"
                    selectkey={3}
                    value="text"
                    placeholder=""
                    checked={checkedState === "text"}
                    disabled={true}
                    onChange={() => setcheckedState("text")}
                    onClick={(e) => ModalRadioClick(e)}
                  />
                  <span>Receive an text at {formValues?.mobileNo}</span>
                </label>
                <span className="not_available-label">Not Available</span>
                <div className="devide_new">
                  <label className="margin">
                    <input
                      type="text"
                      name="domain"
                      placeholder="__________@yourdomain.com"
                      readOnly
                    />
                  </label>
                  <span className="submit">
                    <input
                      type="submit"
                      name="domain"
                      value="Submit"
                      onClick={(e) => {
                        e.preventDefault();
                        closeAddModal();
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="receive_email_inner">
                <label className="checkbox">
                  <input
                    type="radio"
                    name="ra"
                    selectkey={4}
                    value="call"
                    placeholder=""
                    disabled={true}
                    checked={checkedState === "call"}
                    onChange={() => setcheckedState("call")}
                    onClick={(e) => ModalRadioClick(e)}
                  />
                  <span>Answer an call at {formValues?.mobileNo}</span>
                </label>
                <span className="not_available-label">Not Available</span>
                <div className="devide_new">
                  <label className="margin">
                    <input
                      type="text"
                      name="domain"
                      value=""
                      placeholder="__________@yourdomain.com"
                      readOnly
                    />
                  </label>
                  <span className="submit">
                    <input
                      type="submit"
                      name="domain"
                      value="Submit"
                      onClick={(e) => {
                        e.preventDefault();
                        closeAddModal();
                      }}
                    />
                  </span>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      
      <Modal
        id="otpmodal"
        isOpen={otpModalOpen}
        toggle={closeOtpModal}
        className=""
      >
        <ModalHeader>
          <div>
            <div className="close_modal_box close_popup">
              <a className="link-common" onClick={closeOtpModal}>
                <img
                  className="img-fluid close_icon"
                  src={closeIcon}
                  alt="icon"
                />
              </a>
            </div>
            <h1 className="text-center">OTP Verification</h1>
            <p className="text-center mb-0">
              <strong>Enter the OTP sent to your email address</strong>
            </p>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="receive_email">
            <form>
              <div className="receive_email_inner show_data">
                <div className="devide_new">
                  <label className="margin">
                    <input
                      type="number"
                      name="verifyToken"
                      // defaultValue={formValues?.otp}
                      value={otpData}
                      onChange={(e) => {
                        setOtpData(e.target.value);
                      }}
                      placeholder="Enter OTP"
                    />
                  </label>
                  <span className="submit">
                    <input
                      type="submit"
                      name="domain"
                      value="Verify & Proceed"
                      onClick={(e) => {
                        e.preventDefault();
                        verifyOtp(e);
                        closeAddModal();
                      }}
                    />
                  </span>
                  <div
                    className="resend-otp"
                    onClick={(e) => {
                      e.preventDefault();
                      resendOtp(e);
                    }}
                    disabled={isresendOtp}
                  >
                    Resend OTP ?
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        id="req_submited_popup"
        isOpen={filterModalOpen}
        toggle={closeClameModal}
        className="req_submited_popup"
      >
        <ModalHeader>
          <div className="close_modal_box close_popup">
            <a className="link-common" onClick={closeClameModal}>
              <img className="img-fluid close_icon" src={closeIcon} alt="" />
            </a>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="perks_modal_body">
            <div className="col-md-12">
              <img src={checkIcon} alt="" />
              <h1>Request Submitted!</h1>
              <p>
                Thank you for submitting your request to claim this business
                listing. We will verify your information and be in touch
                shortly.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="" onClick={closeClameModal}>
            Ok
          </button>
        </ModalFooter>
      </Modal>
      {isClaimLoader && (
        <Modal
          id="claim_loader"
          isOpen={isClaimLoader}
          // toggle={true}
          className="req_submited_popup"
        >
          <div className="loading-wrapper">
            <Spinner
              className="load-spinner"
              color="light"
              as="span"
              animation="border"
            ></Spinner>
          </div>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default Claimbusiness;
