import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_ERROR, TOAST_SUCCESS } from "../helpers/app.constants";

const defaultPosition = toast.POSITION.TOP_RIGHT;

export const ShowToastMessage = (
  message,
  type,
  position,
  autoClose,
  className
) => {
  var toast_autoclose = autoClose == null ? 2000 : autoClose;
  var toast_className = className == null ? "primaryColor" : className;
  var toast_position = position == null ? defaultPosition : position;
  if (type === TOAST_SUCCESS) {
    return toast.success(message, {
      autoClose: toast_autoclose,
      className: toast_className,
      position: toast_position,
      hideProgressBar: true,
      transition: Slide,
    });
  } else if (type === TOAST_ERROR) {
    return toast.error(message, {
      autoClose: toast_autoclose,
      className: toast_className,
      position: toast_position,
      hideProgressBar: true,
      transition: Slide,
    });
  }
  // fill up for other types as we go
};
