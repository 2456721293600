import { get, post, patch, destroy } from "./config";
const apiToken = sessionStorage.getItem("userToken");
export const getAllPerks = async () => {
  return get("/perks/admin/allPerks", {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addPerk = async (data) => {
  return post("/perks/admin/addPerks", data, {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const editPerk = async (data) => {
  return patch("/perks/admin/updatePerks", data, {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getSinglePerk = async (id) => {
  return post("/perks/admin/singlePerks", { _id: id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const deletePerk = async (perkId) => {
  return destroy("/perks/admin/deletePerks", {
    data: {
      _id: perkId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSinglePerkRedeems = async (id) => {
  return post("/perks/admin/allPerkRedeem", {perkId: id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
