import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "../../components/TermsAndPrivacyModal";
import { toast, ToastContainer } from "react-toastify";
import { Form, Input } from "reactstrap";
import { useAuth } from "../../hooks/useAuth";
import { loginUser } from "../../services/api/auth.service";
import "../../assets/css/login.css";
import loginbg from "../../assets/images/login_bg.webp";

import {
  ROLE_ADMIN,
  ROLE_SUPERADMIN,
  TOAST_ERROR,
} from "../../helpers/app.constants";
import { SpinButton } from "../../components/spinbutton";
import { ShowToastMessage } from "../../components/toastr";
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import sha256 from "sha256";
import TermsAndConditionsModal from "../../components/TermsAndPrivacyModal/TermsAndConditions";
const Login = () => {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdType, setPwdType] = useState("password");
  const [isTermPrivacyModalOpen, setTermPrivacyModalOpen] = useState(false);
  const [isTermConditionsModalOpen, setTermConditionsModalOpen] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const isFormEmpty = (email, password) => {
    return !email || !password;
  };
  const handleShowHidePwd = () => {
    if (pwdType === "password") {
      setPwdType("text");
    } else {
      setPwdType("password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty(user, pwd)) {
      ShowToastMessage(
        "Fill up the login credentials",
        TOAST_ERROR,
        toast.POSITION.BOTTOM_RIGHT
      );
      return false;
    }
    setLoading(true);
    const userLogin = await loginUser(user, pwd);
    if (userLogin != null) {
      if (userLogin.success) {
        const userhash = sha256(userLogin.userInfo._id);
        window.Intercom("boot", {
          app_id: "fxdppbui",
          name: userLogin.userInfo.ownerManagerName,
          email: user,
          phone: userLogin?.userInfo.mobileNo,
          "Owner email": userLogin?.userInfo?.ownerManagerEmail,
          "Owner phone": userLogin?.userInfo?.ownerManagerMobileNo,
          user_id: userLogin.userInfo._id,
          "Company name": userLogin?.userInfo?.name,
          "Company type": userLogin?.userInfo?.categoryId?.find(
            (item, idx) => idx === 0
          )?.categoryName,

          "Company website": userLogin?.userInfo.website,
          "Company industry": userLogin?.userInfo?.categoryId?.find(
            (item, idx) => idx === 0
          )?.categoryName,
          user_hash: userhash,
        });

        window.Intercom("update");

        auth.setUserToken(userLogin.access_token);
        auth.setUserInfo(JSON.stringify(userLogin.userInfo));
        if (userLogin.userInfo?.role?.name === ROLE_SUPERADMIN)
          navigate("/admin/home");
        else if (
          userLogin.userInfo?.role?.name === ROLE_ADMIN &&
          !userLogin.userInfo?.tempPassword
        )
          navigate("/company/home");
        else if (userLogin.userInfo?.tempPassword) {
          navigate("/company/changePassword");
        } else if (userLogin.userInfo?.role?.name === "staff") {
          auth.setUserToken(userLogin.access_token);
          auth.setUserInfo(
            JSON.stringify({
              ...userLogin?.userInfo,
              _id: userLogin?.userInfo.staffCompanyId._id,
            })
          );
          auth.setUserInfo(
            JSON.stringify({
              ...userLogin?.userInfo,
              staff_id: userLogin?.userInfo._id,
            })
            
          );
          navigate("/company/home");
        } else {
          console.log("elsee==>");
        }
      } else {
        var errorMessage =
          userLogin.error && userLogin.msg
            ? userLogin.msg
            : "Error Logging in the user";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
        setLoading(false);
        return false;
      }
    }
  };

  const handleTermPrivacyModal = () => {
    setTermConditionsModalOpen(false);
    setTermPrivacyModalOpen(true);
  };

  const  handleTermsConditionsModal = () => {
    setTermPrivacyModalOpen(false);
    setTermConditionsModalOpen(true)
  }
  return (
    <React.Fragment>
      <div className="container ">
        <div id="admin_bar">
          <div className="top-btn-links col-md-12">
            <Link to={process.env.REACT_APP_URL}>Back to Home</Link>
          </div>
          <div className="admin_login col-md-12">
            <div className="admin_login_inner col-md-12">
              <div className="left_login_panel col-md-6 col-12">
                <img className="img-fluid" src={loginbg} alt="" />
              </div>
              <div className="right_login_panel col-md-6 col-12">
                <h1>Business Login</h1>
                <div className="new-company">
                  <p>
                    <strong>
                      Do you represent a company? Claim your business now
                    </strong>
                  </p>
                  <div className="claim" style={{ textAlign: "left" }}>
                    <Link style={{fontWeight: 800}} to={`${process.env.REACT_APP_URL}forbusiness`}>
                      Search
                    </Link>
                    <span>
                      &nbsp; for and claim an existing business in our database
                    </span>
                  </div>
                  <div className="claim" style={{ textAlign: "left" }}>
                    <Link to="/register" style={{fontWeight: 800}}>Register For Free</Link>
                    <span>&nbsp; a new account and add a new business</span>
                  </div>
                </div>
                <p className="black" style={{fontWeight: 700, marginTop: "3rem", marginBottom: 0}}>
                  Existing companies and staff login here:
                </p>
                <Form onSubmit={handleSubmit}>
                  <label>
                    Email
                    <Input
                      id="userEmail"
                      name="userEmail"
                      placeholder="Enter your Email"
                      value={user}
                      type="email"
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </label>
                  <label className="password">
                    Password
                    <Input
                      type={pwdType}
                      name="userPassword"
                      placeholder="Enter Password"
                      id="userPassword"
                      value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                    />
                    <span className="show_pass" onClick={handleShowHidePwd}>
                      {pwdType === "password" && (
                        <BsFillEyeSlashFill size={28} />
                      )}
                      {pwdType === "text" && <BsFillEyeFill size={28} />}
                    </span>
                  </label>
                  <div className="input_flex">
                    <label className="checkbox">
                      <input type="checkbox" name="check" value="" />
                      <span>Remember me</span>
                    </label>
                    <label className="forget">
                      <span>Forget Your Password?</span>
                    </label>
                  </div>
                  <div>
                    <p style={{ fontSize: 18, marginTop: 13 }}>
                      By continuing, you agree to company's{" "}
                      <a href="#!" style={{ color: '#e13773' }} onClick={handleTermsConditionsModal}>
                        Terms and conditions
                      </a>{" "}
                      and acknowledge our{" "}
                      <a style={{ color: '#e13773' }} href="#!" onClick={handleTermPrivacyModal}>
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                  <label className="submit">
                    <SpinButton
                      type="submit"
                      text="Login"
                      showSpinner={showLoading}
                    />
                  </label>
                </Form>
                <Modal
                  open={isTermPrivacyModalOpen}
                  setOpen={setTermPrivacyModalOpen}
                  handleModal={handleTermPrivacyModal}
                  handleTermsConditionsModal = {handleTermsConditionsModal}
                />
                <TermsAndConditionsModal 
                 open={isTermConditionsModalOpen}
                 setOpen={setTermConditionsModalOpen}
                 handleModal={handleTermsConditionsModal}
                 handlePrivacyModal={handleTermPrivacyModal}
                />
                {/* <div className="claim">
                  <span>Don't have Account</span>
                  <Link to="/register">Claim a Business</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default Login;
