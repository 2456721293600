import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import keychain from "../../../assets/images/keychain.png";
import building from "../../../assets/images/building.png";
import mule from "../../../assets/images/mule.png";
import zapier from "../../../assets/images/zapier.png";
import CRM from "../../../assets/images/crm.png";
import "../../../assets/css/connections.css";

import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";

const Connections = () => {
  return (
    <div className="container pt-5 connection_container">
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} lg={6}>
          <CustomBreadcrumbs title="Manage Connections" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={6}
          sx={{ display: "flex", gap: "16px", justifyContent: "end" }}
          className="search-section"
        ></Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "15px", width: "100%" }}>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Alert className="text-message" severity="info">
            Automatically connect and synchronize preference data with your
            internal systems -coming soon
          </Alert>
        </Grid>
        {FirstCard.map((item, idx) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={idx}>
              <Typography variant="h6" gutterBottom>
                {item.title}
              </Typography>

              <Card variant="outlined">
                <CardContent>
                  <span
                    style={{
                      color: "#04726D",
                      backgroundColor: "#E3EFEE",
                      padding: "6px 10px",
                      borderRadius: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Comming Soon
                  </span>
                  <div className="image_data">
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      image={item.image}
                      sx={{
                        width: "auto",
                        margin: "0 auto",
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
              <CardActions
                style={{
                  justifyContent: "space-between",
                  paddingInline: "0px",
                  paddingTop: "15px",
                }}
              >
                <Button
                  variant="contained"
                  className="cancel_btn btn custom-mui-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="key_btn btn custom-mui-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                  }}
                >
                  Create a Key
                </Button>
              </CardActions>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "15px", width: "100%" }}>
        {SecondCard.map((item, idx) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={idx}>
              <Typography variant="h6" gutterBottom>
                Connect with hundreds of Apps
              </Typography>

              <Card variant="outlined">
                <CardContent>
                  <span
                    style={{
                      color: "#04726D",
                      backgroundColor: "#E3EFEE",
                      padding: "6px 10px",
                      borderRadius: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Comming Soon
                  </span>
                  <div className="image_data" style={{ minHeight: "110px" }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      image={item.image}
                      sx={{
                        width: "auto",
                        margin: "0 auto",
                        maxWidth: "100px",
                      }}
                    />
                  </div>
                  <h6
                    style={{
                      marginBlock: "20px",
                      fontSize: "16px",
                    }}
                  >
                    Connection Details:
                  </h6>
                  <InputLabel
                    sx={{
                      marginBottom: "10px",
                      fontSize: "14px !important",
                      fontWeight: " 600 !important",
                    }}
                  >
                    API Key
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    fullWidth
                    className="api_input"
                  />
                </CardContent>
              </Card>
              <CardActions
                style={{
                  gap: "15px",
                  paddingInline: "0px",
                  paddingTop: "15px",
                }}
              >
                <Button
                  variant="contained"
                  className="cancel_btn btn custom-mui-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="key_btn btn custom-mui-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                  }}
                >
                  Create a Key
                </Button>
              </CardActions>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
const FirstCard = [
  {
    id: 0,
    title: "Connect Your POS",
    image: keychain,
  },
  {
    id: 1,
    title: "Connect Your PMS",
    image: building,
  },
  {
    id: 2,
    title: "Connect your CRM",
    image: CRM,
  },
];
const SecondCard = [
  {
    id: 0,
    image: zapier,
  },
  {
    id: 1,
    image: mule,
  },
];
export default Connections;
