import React from "react";
import { Spinner } from "reactstrap";

export const LoaderDefault = (props) => {
  const { text } = props;

  return (
    <div className="loading-wrapper-small">
      <Spinner
        className="load-spinner"
        color="success"
        as="span"
        animation="border"
      >
        {text}
      </Spinner>
    </div>
  );
};
