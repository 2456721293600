import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const Authenticate = ({ allowedRole, staffRole }) => {
  const auth = useAuth();
  const user = auth?.user ? JSON.parse(auth.user) : null;

  if (
    user &&
    user?.role &&
    (user?.role?.name === allowedRole || user?.role?.name === staffRole)
  ) {
    return <Outlet />;
  } else if (auth?.token && auth?.token !== "") {
    return <Navigate to="/unauthorized" />;
  } else {
    return <Navigate to="/login" />;
  }
};
export default Authenticate;
