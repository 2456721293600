import { Box, Breadcrumbs, Button, Stack } from "@mui/material";
import React from "react";

const CustomBreadcrumbs = ({ title }) => {
  const breadcrumbs = [
    <Button
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      className="breadcrumb-btn"
      onClick={handleClick}
      sx={{ textTransform: "none" }}
    >
      Home
    </Button>,
    <Button
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      className="breadcrumb-btn"
      onClick={handleClick}
      sx={{ textTransform: "none" }}
    >
      {title}
    </Button>,
  ];

  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <div>
      <Box>
        <h3
          style={{
            margin: 0,
            padding: 0,
            fontSize: "1.25rem",
            fontWeight: "600",
          }}
        >
          {title}
        </h3>
        <Stack spacing={2}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>
    </div>
  );
};

export default CustomBreadcrumbs;
