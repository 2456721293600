import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";

import "../../../assets/css/add_company.css"; // company page css

import {
  updateCustomer,
  getCustomer,
} from "../../../services/api/customer.service";

import importImg from "../../../assets/images/uplod.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader";

const EditCustomer = () => {
  let navigate = useNavigate();

  const { customerid } = useParams();

  const initialValues = {
    name: "",
    email: "",
    city: "",
    state: "",
    status: "",
    image: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [baseImage, setBaseImage] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCustomerDetails(customerid);
  }, []);

  const fetchCustomerDetails = async (customerDeatailId) => {
    const res = await getCustomer(customerDeatailId);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        if (res.data) {
          formValues.name = res.data.name;
          formValues.email = res.data.email;
          formValues.status = res.data.status;

          setLoading(false);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (baseImage && baseImage !== "") {
        formValues.image = baseImage;
      } else {
        delete formValues.image;
      }
      formValues._id = customerid;

      saveCustomer(formValues);
    }
  }, [formErrors]);

  const saveCustomer = async (customerFormValues) => {
    setLoading(true);
    const res = await updateCustomer(customerFormValues);

    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
        setLoading(false);
      } else {
        var successMessage = "Customer updated successfully!";
        ShowToastMessage(
          successMessage,
          TOAST_SUCCESS,
          toast.POSITION.TOP_RIGHT
        );
        setLoading(false);
        setTimeout(function () {
          navigate("/admin/customer");
        }, 2500);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.name) {
      errors.name = "Customer name is required!";
      ShowToastMessage(errors.name, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
    }

    if (!values.email) {
      errors.email = "Email is required!";
      ShowToastMessage(errors.email, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
      ShowToastMessage(errors.email, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
    }

    return errors;
  };

  return (
    <div className="company_edit_top">
      <div className="Scroll">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row dashboard_tittle_box">
              <div className="col-md-8">
                <h3>Add/Edit Customer</h3>
                <h6 className="import_tittle">
                  Companies <span>Edit Customer</span>
                </h6>
              </div>
              <div className="col-md-4 text-right">
                <button type="submit" className="save_button">
                  Save
                </button>
                <Link to={"../../Customer"}>
                  <button className="cancel_button">cancel</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            {!loading && (
              <div className="details_box">
                <div className="row edit_from">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Customer Name<span className="frmErr">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerName"
                        name="name"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.name}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.name}</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Email<span className="frmErr">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="emailId"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.email}</p>
                    </div>
                  </div>
                </div>

                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">State Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        name="state"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.state}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">City Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.city}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Status</label>
                      <select
                        name="status"
                        id="status"
                        className="form-select"
                        value={formValues.status}
                        onChange={handleChange}
                        ariaLabel
                      >
                        <option selected>Please Select</option>
                        <option value="active">Active</option>
                        <option value="pending">Pending</option>
                        <option value="disabled">Disabled</option>
                        {/* <option value="suspend">Suspend</option> */}
                      </select>
                      <p className="frmErr">{formErrors.status}</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group position_relative">
                      <label htmlFor="exampleInputEmail1">
                        Update Customer Image
                      </label>
                      <input
                        type="file"
                        className="form-control pl-60"
                        id="cmpImg"
                        name="image"
                        aria-describedby="emailHelp"
                        placeholder="Drop File Here Or Browse"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <img
                        alt=""
                        className="img-fluid uplod_img"
                        src={importImg}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loading && <Loader />}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditCustomer;
