import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div
          className="footer-text"
          style={{
            color: "#98A6AD",
            textAlign: "center",
            margin: "60px auto 20px",
            fontSize: "14px",
          }}
        >
          <span className="d-none d-sm-inline-block">
            Copyright 2022 Here is What I like. All Rights Reserved
          </span>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
