import { put } from "./config";

export const inviteBranchApi = async (data) => {
    return put("/user/branchAPI", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };