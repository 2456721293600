import React, { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { format } from "date-fns";
import "../../../assets/css/company.css"; // company page css
import {
  getAllCompanies,
  filterCompany,
  companyStatus,
  companyDelete,
  companyVerify,
  getSingleCompany,
} from "../../../services/api/company.service";
import searchIcon from "../../../assets/images/search.png";
import importIcon from "../../../assets/images/downlod_icon.png";
import addIcon from "../../../assets/images/pluss.png";
import moreIcon from "../../../assets/images/dots.png";
import noimg from "../../../assets/images/company_placeholder.webp";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/loader";
import { LoaderDefault } from "../../../components/loaderdefault";
import { confirm } from "react-confirm-box";

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [afterDelete, setafterDelete] = useState(0);
  const [statusLoading, setStatusLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [isClaimInfoModalOpen, setClaimInfoModalOpen] = useState(false);
  const [claimInfo, setClaimInfo] = useState({
    CompanyEmail: "",
    EmailStatus: "",
    ManualEmailStatus: "",
    CompanyText: "",
    TextStatus: "",
    CompanyPhoneCall: "",
    PhoneStatus: "",
  });

  const [filterStatus, setFilterStatus] = useState("");
  const handleFilterStatusChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);
  };

  useEffect(() => {
    setLoading(true);
    dataTable();
    fetchCompanies();
  }, [afterDelete]);

  const dataTable = () => {
    $(document).ready(function () {
      $("#companyListing").DataTable({
        destroy: true,
        paging: false,
        info: false,
        searching: false,
        aoColumnDefs: [
          {
            bSortable: false,
            aTargets: [5],
          },
          { type: "date", targets: [3] },
        ],
        order: [[3, "desc"]],
      });
    });
  };

  const fetchCompanies = useCallback(async () => {
    const res = await getAllCompanies();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCompanies(res.data);
      dataTable();
    }
    setLoading(false);
  }, [afterDelete]);

  const claimVerifiedColor = {
    color: "green",
  };

  const claimPendingColor = {
    color: "red",
  };

  const statusStyle = {
    marginTop: "25px",
    height: "45px",
  };

  const renderCompanyListing = () => {
    let recordCompanyList = [];
    if (companies) {
      dataTable();
      companies.map((company, index) => {
        const style = {
          float: "left",
        };

        const imgstyle = {
          width: "40px",
          height: "40px",
          backgroundColor: "#cbe2e1",
        };

        const insetstyle = {
          inset: "0px",
        };

        let statusval = company?.status?.toLowerCase();
        const statusColumn = (
          <div className="dropdown">
            {statusval === "active" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_active_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Active
              </button>
            ) : statusval === "pending" || statusval === "donotaccept" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Pending
              </button>
            ) : statusval === "verifypending" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Verify Pending
              </button>
            ) : statusval === "verifymanually" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Verify Manually
              </button>
            ) : statusval === "disabled" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_disable_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Disabled
              </button>
            ) : statusval === "claimed" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_claim_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Claimed
              </button>
            ) : (
              <button
                className="btn btn-secondary dropdown-toggle drop_active_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Active
              </button>
            )}
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("active", `${company._id}`)}
                >
                  Active
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("pending", `${company._id}`)}
                >
                  Pending
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() =>
                    changeStatus("verifypending", `${company._id}`)
                  }
                >
                  Verify Pending
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("disabled", `${company._id}`)}
                >
                  Disabled
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("donotaccept", `${company._id}`)}
                >
                  Do Not Accept
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("verifymanually", `${company._id}`)}
                >
                Verify Manually
                </a>
              </li>
            </ul>
          </div>
        );

        const statusAction = (
          <div className="dropdown dots_dropdown">
            <img
              alt="more"
              className="img-fluid pr-15 pb-4 center_img"
              src={moreIcon}
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              ariaExpanded="false"
            />
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              style={insetstyle}
            >
              <li>
                <Link
                  className="dropdown-item"
                  to={"./editcompany/" + company._id}
                >
                  Edit
                </Link>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => handleDeleteCompany(`${company._id}`)}
                >
                  Delete
                </a>
              </li>

              {(statusval === "verifypending" || statusval === "claimed" || statusval === 'verifymanually') && (
                <hr />
              )}
              {(statusval === "verifypending" || statusval === "verifymanually") && (
                <li>
                  <a
                    className="dropdown-item"
                    href="#!"
                    onClick={() => handleVerifyCompany(`${company._id}`)}
                  >
                    Verify
                  </a>
                </li>
              )}


              {(statusval === "verifypending" || statusval === "claimed" || statusval === "verifymanually") && (
                <li>
                  <a
                    className="dropdown-item"
                    href="#!"
                    onClick={() => handleClaimStaus(`${company._id}`)}
                  >
                    View Claim Status
                  </a>
                </li>
              )}
            </ul>
          </div>
        );

        recordCompanyList.push(
          <tr key={company._id} id={"div_" + company._id}>
            <td>
              <div style={style}>
                {company.image ? (
                  <img
                    alt=""
                    style={imgstyle}
                    className="img-fluid"
                    src={company.image}
                  />
                ) : (
                  <img
                    alt=""
                    style={imgstyle}
                    className="img-fluid"
                    src={noimg}
                    // style={{objectFit: "contain", backgroundColor: "#cbe2e1" }}
                  />
                )}
              </div>
              <div>
                {company.name}
                <br />
                {company.googlePlaceId ? (
                  <span className="is_google_place">Google Place</span>
                 
                ) : (
                  ""
                )}
                </div>
                <div style={{marginTop: 7, color: '#000000', fontSize: 15}} >
                <strong>Referred by: {company?.referredId?.name} / {company?.referredId?.email}</strong>
                </div>
            </td>
            <td>{company?.googlelocationname}</td>
            <td>
              {company.ownerManagerName}
              <br />
              {company.ownerManager ? (
                <span className="is_google_place">{company.ownerManager}</span>
              ) : (
                ""
              )}
            </td>
            <td>{format(new Date(company.date), "MM/dd/yyyy")}</td>
            <td>
              {statusLoading ? (
                rowId === company._id ? (
                  <LoaderDefault />
                ) : (
                  statusColumn
                )
              ) : (
                statusColumn
              )}
            </td>

            <td className="action_drop">
              {actionLoading ? (
                rowId === company._id ? (
                  <LoaderDefault />
                ) : (
                  statusAction
                )
              ) : (
                statusAction
              )}
            </td>
          </tr>
        );
      });
    } else {
      recordCompanyList.push();
    }
    if (companies?.length === 0) {
      recordCompanyList.push();
    }
    return recordCompanyList;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (searchKey || filterStatus) {
      let value = searchKey.trim().toLowerCase();
      let filterstatus = filterStatus.toLowerCase();
      if ((value !== "" && value.length > 2) || filterstatus !== "") {
        setLoading(true);
        const searchCompany = await filterCompany(value, filterstatus);
        if (searchCompany != null) {
          if (searchCompany.data) {
            setLoading(false);
            setCompanies(searchCompany.data);
          } else {
            var errorMessage =
              searchCompany.error && searchCompany.msg
                ? searchCompany.msg
                : "Error searching in the company";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
            setLoading(false);
            return false;
          }
        }
      }

      if (value === "" && filterstatus === "") {
        fetchCompanies();
      }
    }

    if (searchKey === "" && filterStatus === "") {
      fetchCompanies();
    }
  };

  const changeStatus = async (status, companyId) => {
    if (status && companyId) {
      setStatusLoading(true);
      setRowId(companyId);

      const statusChange = await companyStatus(status, companyId);

      if (statusChange != null) {
        if (statusChange.data) {
          setStatusLoading(true);
          fetchCompanies();
          setTimeout(function () {
            setStatusLoading(false);
            setRowId("");
          }, 2000);
        } else {
          var errorMessage =
            statusChange.error && statusChange.msg
              ? statusChange.msg
              : "Error in status changing";
          ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
          setLoading(false);
          setRowId("");
          return false;
        }
      }
    }
  };

  const handleDeleteCompany = async (companyId) => {
    var options = {
      closeOnOverlayClick: false,
      labels: {
        confirmable: "Delete",
        cancellable: "Cancel",
      },
    };
    const result = await confirm(
      "Do you wish to delete this Company?",
      options
    );
    if (result) {
      setActionLoading(true);
      setRowId(companyId);

      companyDelete(companyId)
        .then((response) => {
          setActionLoading(false);
          setRowId("");

          if (response.error) {
            var errorMessage = response.msg
              ? response.msg
              : "Error deleting the company";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
          } else {
            ShowToastMessage("Successfully deleted the company", TOAST_SUCCESS);
            setafterDelete(afterDelete + 1);
          }
        })
        .catch((err) => {
          var errorMessage = err ? err : "Error deleting the company";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        });
    }
  };

  const handleVerifyCompany = async (companyId) => {
    var options = {
      closeOnOverlayClick: false,
      labels: {
        confirmable: "Verify",
        cancellable: "Cancel",
      },
    };
    const result = await confirm(
      "Are you sure, you want to verify this Company?",
      options
    );
    if (result) {
      setActionLoading(true);
      setRowId(companyId);

      companyVerify(companyId)
        .then((response) => {
          setActionLoading(false);
          setRowId("");

          if (response.error) {
            var errorMessage = response.message
              ? response.message
              : "Error verifying the company";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.TOP_RIGHT
            );
          } else {
            ShowToastMessage(response.msg, TOAST_SUCCESS);
            fetchCompanies();
          }
        })
        .catch((err) => {
          var errorMessage = err ? err : "Error verifying the company";
          ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
        });
    }
  };

  const handleClaimStaus = async (companyId) => {
    setActionLoading(true);
    setRowId(companyId);
    const cmpyDetail = await getSingleCompany(companyId);

    if (cmpyDetail.data) {
      setClaimInfo({
        CompanyEmail: cmpyDetail.data.email,
        EmailStatus: cmpyDetail.data.companyEmailStatus,
        ManualEmailStatus: cmpyDetail.data.companyManualStatus,
        CompanyText: cmpyDetail.data.mobileNo,
        TextStatus: cmpyDetail.data.companyTextStatus,
        CompanyPhoneCall: cmpyDetail.data.mobileNo,
        PhoneStatus: cmpyDetail.data.companyPhoneCallStatus,
      });
      setClaimInfoModalOpen(true);
      setActionLoading(false);
      setRowId("");
    }
  };

  const claimStatusClose = async () => {
    setClaimInfo({
      CompanyEmail: "",
      EmailStatus: "",
      ManualEmailStatus: "",
      CompanyText: "",
      TextStatus: "",
      CompanyPhoneCall: "",
      PhoneStatus: "",
    });
    setClaimInfoModalOpen(false);
  };

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setLoading(true);

      let value = e.target.value.trim().toLowerCase();
      let filterstatus = filterStatus.toLowerCase();

      if ((value !== "" && value.length > 2) || filterstatus !== "") {
        const searchCompany = await filterCompany(value, filterstatus);
        if (searchCompany != null) {
          if (searchCompany.data) {
            setLoading(false);
            setCompanies(searchCompany.data);
            dataTable();
          } else {
            var errorMessage =
              searchCompany.error && searchCompany.msg
                ? searchCompany.msg
                : "Error searching in the company";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
            setLoading(false);
            return false;
          }
        }
      }

      if (!value && value === "" && filterstatus === "") {
        fetchCompanies();
      }
    }
  };

  const refereshSearch = () => {
    setSearchKey("");
    setFilterStatus("");
    setafterDelete(afterDelete + 1);
  };

  return (
    <div className="company_listing_top">
      <div className="container">
        <div className="row dashboard_tittle_box">
          <div className="col-md-2">
            <h3>Companies</h3>
            <h6 className="import_tittle">
              Home<span>Companies</span>
            </h6>
          </div>
        </div>
        <div className="row dashboard_tittle_box">
          <div className="col-md-2">
            <select
              name="mobileNoCC"
              id="mobileCC"
              className="form-select"
              onChange={handleFilterStatusChange}
              value={filterStatus}
              style={statusStyle}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
              <option value="verifypending">VerifyPending</option>
              <option value="Claimed">Claimed</option>
              <option value="Disabled">Disabled</option>
              <option value="verifymanually">Verify Manually</option>
            </select>
          </div>

          <div className="col-md-2">
            <form>
              <div className="form-group serchbar">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="searchKeyword"
                  autoComplete="off"
                  aria-describedby="emailHelp"
                  placeholder="search keyword"
                  value={searchKey}
                  onKeyDown={keyPress}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <img alt="company" className="img-fluid" src={searchIcon} />
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <button className="company_serch_btn" onClick={handleSearch}>
              Search
            </button>
            &nbsp;&nbsp;
            <button className="cancel_button" onClick={refereshSearch}>
              Reset
            </button>
          </div>

          <div className="col-md-4 right_align company_btn_container">
            <button className="company_import_btn">
              <img alt="import " className="img-fluid" src={importIcon} />
              Import
            </button>
            <Link to={"./AddCompany"}>
              <button className="add_compant_btn">
                <img alt="add" className="img-fluid w-14" src={addIcon} /> Add
              </button>
            </Link>
          </div>
        </div>

        <div className="col-md-12 company_table">
          {!loading && (
            <div className="Scroll">
              <table id="companyListing">
                <thead>
                  <tr>
                    <th width="28%">Name</th>
                    <th width="20%">Location</th>
                    <th>Owner / Manager</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderCompanyListing()}</tbody>
              </table>
            </div>
          )}
          {loading && <Loader />}
        </div>
      </div>
      <ToastContainer />

      <Modal
        id="viewClaimModal"
        isOpen={isClaimInfoModalOpen}
        toggle={claimStatusClose}
      >
        <ModalHeader toggle={claimStatusClose}></ModalHeader>
        <ModalBody>
          <div className="col-md-12 p-0">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 loading-wrapper-small">
                <h4>
                  <b>Verification Status</b>
                </h4>
              </div>
              <div className="col-md-2"></div>
            </div>
            <hr />
            <br />
            {claimInfo.PhoneStatus ? (
              <>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <h5>
                      <b>Company Email ID</b>
                    </h5>
                    <h6>{claimInfo.CompanyEmail}</h6>
                  </div>
                  <div className="col-md-5">
                    <h5>
                      <b>Status : </b>{" "}
                      <span
                        style={
                          claimInfo.EmailStatus === "verified"
                            ? claimVerifiedColor
                            : claimPendingColor
                        }
                      >
                        {claimInfo.EmailStatus[0].toUpperCase() +
                          claimInfo.EmailStatus.slice(1)}{" "}
                      </span>
                    </h5>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <h5>
                      <b>Manual Company Email Id</b>
                    </h5>
                    <h6>{claimInfo.CompanyEmail}</h6>
                  </div>
                  <div className="col-md-5">
                    <h5>
                      <b>Status : </b>{" "}
                      <span
                        style={
                          claimInfo.ManualEmailStatus === "verified"
                            ? claimVerifiedColor
                            : claimPendingColor
                        }
                      >
                        {claimInfo.ManualEmailStatus[0].toUpperCase() +
                          claimInfo.ManualEmailStatus.slice(1)}{" "}
                      </span>
                    </h5>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <h5>
                      <b>Company Text</b>
                    </h5>
                    <h6>{claimInfo.CompanyText}</h6>
                  </div>
                  <div className="col-md-5">
                    <h5>
                      <b>Status : </b>{" "}
                      <span
                        style={
                          claimInfo.TextStatus === "verified"
                            ? claimVerifiedColor
                            : claimPendingColor
                        }
                      >
                        {claimInfo.TextStatus[0].toUpperCase() +
                          claimInfo.TextStatus.slice(1)}{" "}
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <h5>
                      <b>Company Phone Call</b>
                    </h5>
                    <h6>{claimInfo.CompanyPhoneCall}</h6>
                  </div>
                  <div className="col-md-5">
                    <h5>
                      <b>Status : </b>{" "}
                      <span
                        style={
                          claimInfo.PhoneStatus === "verified"
                            ? claimVerifiedColor
                            : claimPendingColor
                        }
                      >
                        {claimInfo.PhoneStatus[0].toUpperCase() +
                          claimInfo.PhoneStatus.slice(1)}{" "}
                      </span>
                    </h5>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <p> </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Company;
