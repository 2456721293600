import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  CardActions,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Modal,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { RiCloseFill } from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import { Stack } from "@mui/system";

import { toast } from "react-toastify";
import {GrCurrency} from 'react-icons/gr';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
// images
import user1 from "../assets/images/company_placeholder.webp";
import { BsPerson, BsGear, BsArrowRightSquare, BsBell } from "react-icons/bs";
import { BiLockOpen } from "react-icons/bi";
import { getUserInfo } from "../helpers/helper";
import { ShowToastMessage } from "./toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../helpers/app.constants";
import { LoaderWhite } from "./LoaderWhite";
import {
  addStaff,
  editStaff,
  getSingleStaff,
  resendInvite,
  getAllStaff,
} from "../services/api/staff.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProfileMenu = (props) => {
  const { role, image } = props;
  const { staff_id, travelAgencyStatus, email } = getUserInfo();
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();

  
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [id, setId] = useState(0);
  const [isImage, setIsImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allStaff, setAllStaff] = useState([]);
  const [formValue, setFormvalue] = useState({
    name: "",
    email: "",
    mobileNo: "",
    managerView: false,
    image: "",
  });
  
  let data = allStaff?.find((staff) => staff?.email === email)
   const UserId = data?._id


  const handleChange = (e) => {
    setFormvalue({ ...formValue, [e.target.name]: e.target.value });
  };
  const formref = useRef();
  const ResendInvite = async (email) => {
    const response = await resendInvite(email);
    if (!response?.error) {
      ShowToastMessage(
        `${response.message}`,
        TOAST_SUCCESS,
        toast.POSITION.BOTTOM_RIGHT
      );
      setAddModalOpen(false);
    }
  };
  const closeAddModal = () => {
    setId("");
    setAddModalOpen(false);
  };
  const staffEdit = async (staffEditId) => {
    setIsLoading(true);
    setId(staffEditId);
    const response = await getSingleStaff(staffEditId);
    if (!response?.error) {
      setAddModalOpen(true);
      setFormvalue({
        name: response?.data?.name,
        email: response?.data?.email,
        mobileNo: response?.data?.mobileNo,
        managerView: response?.data?.managerView,
        image: response?.data?.image,
      });
    }
    setIsLoading(false);
    setIsSubmit(false);
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    let newFormValue = {};
    if (id) {
      if (!isImage) {
        newFormValue = Object.fromEntries(
          Object.entries(formValue).filter(([key]) => !key.includes("image"))
        );
      } else {
        newFormValue = formValue;
      }
      const AddStaff = await editStaff({ ...newFormValue, _id: id });
      if (!AddStaff.error) {
        setAddModalOpen(false);
        ShowToastMessage(
          `${AddStaff.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        GetAllStaff();
      } else {
        setAddModalOpen(true);
        ShowToastMessage(
          `${AddStaff.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
        setIsSubmit(false);
      }
    } else {
      const AddStaff = await addStaff(formValue);
      if (!AddStaff.error) {
        setAddModalOpen(false);
        ShowToastMessage(
          `${AddStaff.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        GetAllStaff();
      } else {
        setAddModalOpen(true);
        ShowToastMessage(
          `${AddStaff?.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
        setIsSubmit(false);
      }
    }
  };

  const handleImageChange = async (e) => {
    setIsImage(true);
    const file = await e?.target?.files[0];

    const ImageFile = await toBase64(file);
    if (ImageFile) {
      setFormvalue({ ...formValue, image: ImageFile });
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    });

  const GetAllStaff = useCallback(async () => {
    setIsImage(false);
    setIsSubmit(false);
    const response = await getAllStaff();
    if (response) {
      if (response.error) {
        var errorMessage = response?.msg
          ? response?.msg
          : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else if (!response?.error) {
        setAllStaff(response?.data);
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    GetAllStaff();
  }, []);

  const handleLogout = () => {
    // For quick test to logout .. should be replaced by api call
    sessionStorage.setItem("userToken", "");
    sessionStorage.setItem("userInfo", "");
    navigate("/");
    window.Intercom("shutdown");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect no-shadow"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="img-fluid user_img"
            src={image || user1}
            alt="Header Avatar"
            height={42}
          />
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown">
          {/* {role !== "staff" && travelAgencyStatus !== true ? (
            <Link to={`/${role}/settings`}>
              <DropdownItem className="dropdown-menu-item">
                <BsPerson className="p-icon" />
                <span>Profile</span>
              </DropdownItem>
            </Link>
          ) : ( */}
            <Link to="#" onClick={() => staffEdit(staff_id ? staff_id : UserId)}>
              <DropdownItem className="dropdown-menu-item">
                <BsPerson className="p-icon" />
                <span>Profile</span>
              </DropdownItem>
            </Link>
          
          {role !== "staff" ? (
            <Link to={`/${role}/settings`}>
              <DropdownItem className="dropdown-menu-item">
                <BsGear className="p-icon" />
                <span className="p-text">Settings</span>
              </DropdownItem>
            </Link>
          ) : (
            <Link to='/company/settings'>
              <DropdownItem className="dropdown-menu-item">
                <BsGear className="p-icon" />
                <span className="p-text">Settings</span>
              </DropdownItem>
            </Link>
          )}
          {(role === "company" || role === "staff") && (
            <Link to='/company/changePassword'>
              <DropdownItem className="dropdown-menu-item">
                <BiLockOpen className="p-icon" />
                <span className="p-text">Password</span>
              </DropdownItem>
            </Link>
          )}
         {travelAgencyStatus && <Link to="/company/referralcommissions">
              <DropdownItem className="dropdown-menu-item">
                <GrCurrency sx={{marginTop: 10}} className="p-icon" />
                <span className="p-text">Earn Referral <br /> Commissions</span>
              </DropdownItem>
            </Link> }
          {(role === "company" || role === "staff") && (
            <Link to={`/${role === "staff" ? "company" : role}/notifications`}>
              <DropdownItem className="dropdown-menu-item">
                <BsBell className="p-icon" />
                <span className="p-text">Notifications</span>
              </DropdownItem>
            </Link>
          )}
          <div className="dropdown-divider" />
          <Link onClick={handleLogout} to="/">
            <DropdownItem className="dropdown-menu-item">
              <BsArrowRightSquare className="p-icon" />
              <span className="p-text">Logout</span>
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal
        open={isAddModalOpen}
        onClose={closeAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="staff_modal"
      >
        <Box
          sx={style}
          className="staff_modal"
          style={{
            width: "100%",
            maxWidth: "750px",
            border: "none",
            padding: "0",
          }}
        >
          <ValidatorForm
            ref={formref}
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
          >
            <CardActions
              style={{
                justifyContent: "space-between",
                color: "rgb(108, 117, 125)",
                border: "none",
                padding: "20px 25px",
                fontWeight: "700",
                borderBottom: "1px solid #CED4DA",
              }}
            >
              <h6
                style={{
                  fontSize: "1rem",
                  color: "#343A40",
                  margin: "0",
                }}
              >
                New Staff Member <span style={{ fontWeight: 400, lineHeight: 1.2, color: 'black'}}>
                (Can log in to staff mobile app only)
                </span>
              </h6>
              <RiCloseFill
                onClick={() => closeAddModal()}
                style={{ fontSize: "20px", color: "#808080", cursor: "pointer" }}
              />
            </CardActions>
            <Grid container spacing={2} className="modal_body">
              <Grid item xs={12} sm={3}>
                <Stack className="modal_file" spacing={2}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    style={{
                      margin: 0,
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    className="upload_icon_button"
                  >
                    {formValue.image ? (
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={formValue.image}
                        className="perk_upload_image"
                      />
                    ) : (
                      <FiUpload />
                    )}

                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} style={{marginLeft: '20px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name:</InputLabel>
                    <TextValidator
                      fullWidth
                      name="name"
                      value={formValue.name}
                      onChange={handleChange}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Email Address:</InputLabel>
                    <TextValidator
                      fullWidth
                      name="email"
                      value={formValue.email}
                      onChange={handleChange}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "Email is not valid",
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Phone Number:</InputLabel>
                    <TextValidator
                      fullWidth
                      name="mobileNo"
                      value={formValue?.mobileNo}
                      onChange={handleChange}
                      validators={["required", "matchRegexp:^[0-9*#+]+$"]}
                      errorMessages={[
                        "This field is required",
                        "Only numbers are allowed",
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel style={{ visibility: "hidden" }}>
                      Not Visible:
                    </InputLabel>
                    <FormGroup>
                      <FormControlLabel
                        style={{marginBottom:0}}
                        control={<Checkbox checked={formValue?.managerView} />}
                        label="Manager View"
                        name="managerView"
                        value={formValue?.managerView}
                        onChange={(e) => {
                           setFormvalue({
                            ...formValue,
                            managerView: e.target.checked,
                          });
                        }}
                      />
                      <b style={{paddingLeft: 30, marginTop:-8, fontWeight: 400, lineHeight: 1.2, color: 'black'}}>
                      (Can log in to both staff mobile app and web app)
                      </b>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CardActions
              style={{
                justifyContent: "center",
                color: "rgb(108, 117, 125)",
                border: "none",
                padding: "20px 25px",
                fontWeight: "700",
                gap: "15px",
              }}
            >
              <button
                className="staff_modal_btn edit_btn"
                type="submit"
                disabled={isSubmit}
              >
                Update
              </button>

              <button
                className="staff_modal_btn delete_btn"
                onClick={() => closeAddModal()}
              >
                Cancel
              </button>
              {id && (
                <button
                  className="staff_modal_btn edit_btn"
                  type="button"
                  onClick={() => {
                    ResendInvite(formValue.email);
                  }}
                >
                  Resend Invite
                </button>
              )}
            </CardActions>
          </ValidatorForm>
        </Box>
      </Modal>
      {isLoading && <LoaderWhite />}
    </React.Fragment>
  );
};
export default ProfileMenu;
