import React, { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { format } from "date-fns";

import "../../../assets/css/customers.css"; // customer page css

import {
  getAllCustomers,
  filterCustomer,
  customerStatus,
  customerDelete,
} from "../../../services/api/customer.service";

import searchIcon from "../../../assets/images/search.png";
import moreIcon from "../../../assets/images/dots.png";
import noimg from "../../../assets/images/avtar.webp";

import { Loader } from "../../../components/loader";
import { LoaderDefault } from "../../../components/loaderdefault";

import $ from "jquery";

import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [afterDelete, setafterDelete] = useState(0);

  const [statusLoading, setStatusLoading] = useState(false);
  const [rowId, setRowId] = useState("");

  useEffect(() => {
    setLoading(true);
    dataTable();
    fetchCustomers();
  }, [afterDelete]);

  const dataTable = () => {
    $(document).ready(function () {
      $("#customersListing").DataTable({
        destroy: true,
        paging: false,
        info: false,
        searching: false,
        aoColumnDefs: [
          {
            bSortable: false,
            aTargets: [5],
          },
          { type: "date", targets: [1] },
        ],
        order: [[1, "desc"]],
      });
    });
  };

  const fetchCustomers = useCallback(async () => {
    const res = await getAllCustomers();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCustomers(res.data);
      dataTable();
    }
    setLoading(false);
  }, [afterDelete]);

  const buttonStatus = (status) => {
    return (
      <button
        className="btn btn-secondary dropdown-toggle drop_btn"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {status}
      </button>
    );
  };

  const renderListing = () => {
    let recordList = [];
    if (customers) {
      dataTable();
      customers.map((customer) => {
        const imgstyle = {
          width: "40px",
          height: "40px",
        };

        const statusColumn = (
          <div className="dropdown">
            {customer.status === "active" ? (
              <button
                className="btn btn-secondary dropdown-toggle drop_active_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Active
              </button>
            ) : customer.status === "pending" ? (
              buttonStatus("Pending")
            ) : customer.status === "disabled" ? (
              buttonStatus("Disabled")
            ) : customer.status === "suspend" ? (
              buttonStatus("Suspend")
            ) : (
              <button
                className="btn btn-secondary dropdown-toggle drop_active_btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Active
              </button>
            )}
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("active", `${customer._id}`)}
                >
                  Active
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("pending", `${customer._id}`)}
                >
                  Pending
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("suspend", `${customer._id}`)}
                >
                  Suspend
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => changeStatus("disabled", `${customer._id}`)}
                >
                  Disabled
                </a>
              </li>
            </ul>
          </div>
        );

        recordList.push(
          <tr key={customer._id}>
            <td>
              {customer.image ? (
                <img
                  alt="comp_image"
                  style={imgstyle}
                  className="img-fluid"
                  src={customer.image}
                />
              ) : (
                <img
                  alt="comp_image"
                  style={imgstyle}
                  className="img-fluid"
                  src={noimg}
                />
              )}

              {customer.name}
              <br /> 
              <div style={{marginLeft: "55px", color: '#000000'}} > 
                 <strong>{customer?.referredBy ? "Referred by: / " + customer?.referredId?.name : null}</strong> 
                </div> 
            </td>
            <td>{format(new Date(customer.date), "MM/dd/yyyy")}</td>
            <td>{customer.city}</td>
            <td>{customer.country}</td>
            <td>{customer.email}</td>

            <td>
              {statusLoading ? (
                rowId === customer._id ? (
                  <LoaderDefault />
                ) : (
                  statusColumn
                )
              ) : (
                statusColumn
              )}
            </td>
            <td className="action_drop">
              <div className="dropdown dots_dropdown">
                <img
                  alt="more_image"
                  className="img-fluid pr-15 pb-4 center_img"
                  src={moreIcon}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  ariaExpanded="false"
                />
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to={"./editcustomer/" + customer._id}
                    >
                      Edit
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#!;"
                      onClick={() => handleDeleteCustomer(`${customer._id}`)}
                    >
                      Delete
                    </a>
                  </li>
                  {/* <li>
                              <a className="dropdown-item" href="#">
                                See Profile
                              </a>
                            </li> */}
                </ul>
              </div>
            </td>
          </tr>
        );
      });
    } else {
      recordList.push();
    }
    if (customers.length === 0) {
      recordList.push();
    }
    return recordList;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    let value = searchKey.trim().toLowerCase();

    if (value && value != "" && value.length > 2) {
      const searchCustomer = await filterCustomer(value);
      if (searchCustomer != null) {
        if (searchCustomer.data) {
          setLoading(false);
          setCustomers(searchCustomer.data);
        } else {
          var errorMessage =
            searchCustomer.error && searchCustomer.msg
              ? searchCustomer.msg
              : "Error searching in the customers";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
          setLoading(false);
          return false;
        }
      }
    }

    if (value === "") {
      fetchCustomers();
    }
  };

  const refereshSearch = () => {
    setSearchKey("");
    setafterDelete(afterDelete + 1);
  };

  const changeStatus = async (status, customerId) => {
    if (status && customerId) {
      setStatusLoading(true);
      setRowId(customerId);

      const statusChange = await customerStatus(status, customerId);
      if (statusChange != null) {
        if (statusChange.data) {
          setStatusLoading(true);
          fetchCustomers();
          setTimeout(function () {
            setStatusLoading(false);
            setRowId("");
          }, 2000);
        } else {
          var errorMessage =
            statusChange.error && statusChange.msg
              ? statusChange.msg
              : "Error in status changing";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
          setLoading(false);
          setRowId("");
          return false;
        }
      }
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    var options = {
      closeOnOverlayClick: false,
      labels: {
        confirmable: "Delete",
        cancellable: "Cancel",
      },
    };
    const result = await confirm(
      "Do you wish to delete this Customer?",
      options
    );
    if (result) {
      customerDelete(customerId)
        .then((response) => {
          if (response.error) {
            var errorMessage = response.msg
              ? response.msg
              : "Error deleting the customer";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
          } else {
            ShowToastMessage(
              "Successfully deleted the customer",
              TOAST_SUCCESS
            );
            //setafterDelete(1)
            setafterDelete(afterDelete + 1);
          }
        })
        .catch((err) => {
          var errorMessage = err ? err : "Error deleting the company";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        });
    }
  };

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setLoading(true);
      let value = e.target.value.trim().toLowerCase();

      if (value && value != "" && value.length > 3) {
        const searchCustomer = await filterCustomer(value);
        if (searchCustomer != null) {
          if (searchCustomer.data) {
            setLoading(false);
            setCustomers(searchCustomer.data);
          } else {
            var errorMessage =
              searchCustomer.error && searchCustomer.msg
                ? searchCustomer.msg
                : "Error searching in the customer";
            ShowToastMessage(
              errorMessage,
              TOAST_ERROR,
              toast.POSITION.BOTTOM_RIGHT
            );
            setLoading(false);
            return false;
          }
        }
      }

      if (!value && value === "") {
        fetchCustomers();
      }
    }
  };

  return (
    <div className="customer_listing_top">
      <div className="container">
        <div className="row dashboard_tittle_box">
          <div className="col-md-2">
            <h3>Customers</h3>
            <h6 className="import_tittle">
              Home<span>Customers</span>
            </h6>
          </div>
          <div className="col-md-4">
            <form>
              <div className="form-group serchbar">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  autoComplete="off"
                  aria-describedby="emailHelp"
                  placeholder="search customer name..."
                  onKeyDown={keyPress}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <img
                  alt="company_image"
                  className="img-fluid"
                  src={searchIcon}
                />
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <button className="company_serch_btn" onClick={handleSearch}>
              Search
            </button>
            &nbsp;&nbsp;
            <button className="cancel_button" onClick={refereshSearch}>
              Reset
            </button>
          </div>
        </div>

        <div className="col-md-12 company_table">
          {!loading && (
            <div className="Scroll">
              <table id="customersListing">
                <thead>
                  <tr>
                    <th width="25%">Name</th>
                    <th>Signup Date</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Email Address</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderListing()}</tbody>
              </table>
            </div>
          )}
          {loading && <Loader />}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Customer;
