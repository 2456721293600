import React from "react";
import {
  BsGrid,
  BsBuilding,
  BsPeople,
  BsGift,
  BsFillGearFill,
  BsPinMap,
  BsLink45Deg,
  BsMegaphone,
  BsShop,
  BsFileEarmarkMedical,
} from "react-icons/bs";
import { HiOutlineAdjustments } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";

export const superadminData = [
  {
    title: "Dashboard",
    icon: <BsGrid />,
    link: "/admin/home",
  },
  {
    title: "Companies",
    icon: <BsBuilding />,
    link: "/admin/company",
  },
  {
    title: "Customers",
    icon: <BsPeople />,
    link: "/admin/customer",
  },
  {
    title: "Preferences",
    icon: <HiOutlineAdjustments />,
    link: "/admin/preference",
  },
  {
    title: "Micro Feedback",
    icon: <FaRegEdit />,
    link: "/admin/feedback",
  },
];

export const adminData = [
  {
    title: "Dashboard",
    icon: <BsGrid />,
    link: "/company/home",
  },
  {
    title: "Customers",
    icon: <BsPeople />,
    link: "/company/customer",
  },
  {
    title: "Invite",
    icon: <BsMegaphone />,
    link: "/company/promote",
  },
  {
    title: "Preferences",
    icon: <HiOutlineAdjustments />,
    link: "/company/preference",
  },
  {
    title: "Micro Feedback",
    icon: <FaRegEdit />,
    link: "/company/feedback",
  },
  {
    title: "Perks",
    icon: <BsGift />,
    link: "/company/perks",
  },
  {
    title: "Staff",
    icon: <BsPeople />,
    link: "/company/staff",
  },
  {
    title: "Settings & Profile",
    icon: <BsFillGearFill />,
    link: "/company/settings",
  },
  {
    title: "Locations",
    icon: <BsPinMap />,
    link: "/company/locations",
  },
  {
    title: "Manage Connections",
    icon: <BsLink45Deg />,
    link: "/company/connections",
  },
  {
    title: "Store",
    icon: <BsShop />,
    link: "/company/store",
  },
  {
    title: "Billing",
    icon: <BsFileEarmarkMedical />,
    link: "/company/billing",
  },
];

export const travelAgencySidebar = [
  {
    title: "Dashboard",
    icon: <BsGrid />,
    link: "/company/home",
  },
  {
    title: "Customers",
    icon: <BsPeople />,
    link: "/company/customer",
  },
  {
    title: "Invite",
    icon: <BsMegaphone />,
    link: "/company/promote",
  },
  {
    title: "Preferences",
    icon: <HiOutlineAdjustments />,
    link: "/company/preference",
  },
  {
    title: "Staff",
    icon: <BsPeople />,
    link: "/company/staff",
  },
  {
    title: "Settings & Profile",
    icon: <BsFillGearFill />,
    link: "/company/settings",
  },
  {
    title: "Billing",
    icon: <BsFileEarmarkMedical />,
    link: "/company/billing",
  },
];
