import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
Chart.register(CategoryScale);

const LineChart = ({ unlockCustomers, selectedYear }) => {
  // Initialize an array to store the count of users for each month
  const userCounts = Array(12).fill(0);

  // Loop through the unlockCustomers data and update the userCounts array for the selected year
  if (unlockCustomers) {
    unlockCustomers.forEach((customer) => {
      const unlockYear = new Date(customer.unLockedDate).getFullYear();
      if (unlockYear === selectedYear) {
        const month = new Date(customer.unLockedDate).getMonth();
        userCounts[month] += 1;
      }
    });
  }

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        label: `Unlocked Customers Count`,
        data: userCounts,
        fill: true,
        backgroundColor: "#ffeaf1",
        lineTension: 0.4,
        borderColor: "#d24071",
      }
    ]
  };

  return (
    <div className="dashboard_line_Chart">
      <Line data={data} />
    </div>
  );
};
export default LineChart;
