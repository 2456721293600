import { get, post } from "./config";

export const loginUser = async (userEmail, userPassword) => {
  return post("/auth/superadmin/loginUser", {
    email: userEmail,
    password: userPassword,
    role: "company",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const registerUser = async (datas, navigate) => {
  return post("/user/admin/registerCompany", datas)
    .then((response) => {
      const searchParams = new URLSearchParams(response.data.data).toString();

      navigate("/claimbusiness/?" + searchParams);
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getUserProfile = () => {
  const apiToken = sessionStorage.getItem("userToken");
  return get("/user/me", {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
