import { get, post, destroy } from "./config";

export const getAllSubCategories = async () => {
  return get("/category/superadmin/allSubcategory")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getFilterSubcategory = async (catgId) => {
  return post("/subcategory/getFilterSubcategory", { categoryId: catgId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getFilterSubcategory1 = async (catgId) => {
  return post("/subcategory/superadmin/allSubcategory")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addSubcategory = async (catgId, subCategoryName) => {
  return post("/subcategory/superadmin/addSubcategory", {
    categoryId: catgId,
    subcategoryName: subCategoryName,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteSubcategory = async (catgId) => {
  return destroy("/subcategory/superadmin/deleteSubcategory", {
    data: {
      _id: catgId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
