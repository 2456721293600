/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import hotelImage from "../../../assets/images/perk_placeholder.webp";
import moment from 'moment';
import "../../../assets/css/perks.css";
import { HiGift } from "react-icons/hi";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CardActions,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Stack } from "@mui/system";
import { RiCloseFill } from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import {
  addPerk,
  deletePerk,
  editPerk,
  getAllPerks,
  getSinglePerkRedeems,
  getSinglePerk,
} from "../../../services/api/perks.service";
import dayjs from "dayjs";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../../components/loader";
import { LoaderWhite } from "../../../components/LoaderWhite";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  maxHeight: "60vh",
  overflowY: "auto",
};

const Perks = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const formref = useRef();
  const [formShow, setformShow] = useState(false);
  const [allPerks, setallPerks] = useState([]);
  const [perkId, setPerkId] = useState("");
  const [redeemsPerk, setRedeemsPerk] = useState([]);
  const [benefitsList, setBenefitsList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [newList, setNewList] = useState([]);
  const [fileLoader, setFileLoader] = useState(false);
  const initialState = {
    userId: [],
    categoryId: [],
    title: "",
    discription: "",
    discount: "",
    price: "",
    benefits: "",
    start: "",
    expire: "",
    image: "",
    termsAndConditions: "",
  };
  const [isImage, setIsImage] = useState(false);
  const [formValues, setFormValues] = useState(initialState);

  const ShowForm = () => {
    setformShow(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const handleStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleExpireDate = (newValue) => {
    setExpireDate(newValue);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e?.target?.name]: e?.target?.value });
  };

  const handleSubmit = async (e) => {
    setisLoading(true);
    e.preventDefault();
    if (formValues?.benefits?.trim()?.length === 0) {
      formValues.benefits = benefitsList;
    } else {
      formValues.benefits = [formValues?.benefits?.trim(), ...benefitsList];
    }
    formValues.expire = expireDate;
    formValues.start = startDate;

    let PerkData = await addPerk(formValues);

    try {
      if (PerkData?.error) {
        ShowToastMessage(
          `${PerkData.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        company();
        ShowToastMessage(
          `${PerkData.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        setformShow(false);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSinglePerkRedeems = async (id) => {
    setisLoading(true);
    let RedeemsPerk = await getSinglePerkRedeems(id);
    if (!RedeemsPerk?.error) {
      setAddModalOpen(true);
      setRedeemsPerk(RedeemsPerk?.data);
    }
    setisLoading(false);
  }
  console.log(redeemsPerk, "fdfafsad");

  const GetSinglePerk = async (id) => {
    setisLoading(true);
    const SingleData = await getSinglePerk(id);
    if (!SingleData?.error) {
      setformShow(true);
      setPerkId(id);
      setFormValues({
        _id: SingleData?.data._id,
        userId: [],
        categoryId: [],
        title: SingleData?.data.title,
        discription: SingleData?.data.discription,
        discount: SingleData?.data.discount,
        price: SingleData?.data.price,
        benefits: "",
        image: SingleData?.data.image,
        termsAndConditions: SingleData?.data.termsAndConditions,
      });

      setStartDate(dayjs(new Date(SingleData?.data.start)));
      setExpireDate(dayjs(new Date(SingleData?.data.expire)));
      setBenefitsList(SingleData?.data.benefits);
      setisLoading(false);
    }
  };

  const EditPerk = async (id) => {
    setisLoading(true);
    setformShow(true);
    let newFormValue = formValues;
    if (!isImage) {
      newFormValue = Object.fromEntries(
        Object.entries(formValues).filter(([key]) => !key.includes("image"))
      );
    } else {
      newFormValue = formValues;
    }

    if (newFormValue?.benefits?.trim()?.length === 0) {
      newFormValue.benefits = benefitsList;
    } else {
      newFormValue.benefits = [formValues?.benefits?.trim(), ...benefitsList];
    }
    newFormValue.expire = expireDate;
    newFormValue.start = startDate;
    let editdata = await editPerk(newFormValue);
    try {
      if (editdata.error) {
        ShowToastMessage(
          `${editdata.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        ShowToastMessage(
          `${editdata.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        company();
        setformShow(false);
      }
      setisLoading(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const addBenefits = (data) => {
    setFormValues({ ...formValues, benefits: "" });
    let newData = data?.trim();
    if (newData !== "") {
      setBenefitsList([...benefitsList, newData]);
    }
  };

  const DeletePerk = async (id) => {
    setisLoading(true);
    let DeletePerkData = await deletePerk(id);
    try {
      if (DeletePerkData.error) {
        ShowToastMessage(
          `${DeletePerkData.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        ShowToastMessage(
          `${DeletePerkData.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        company();
      }
      setisLoading(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleImagechange = async (e) => {
    setIsImage(true);
    setFileLoader(true);
    const imageFile = e.target.files[0];
    if (imageFile !== undefined) {
      setFormValues({ ...formValues, image: "" });
    }
    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const ImageDatav = await getBase64(compressedFile);
      setFormValues({ ...formValues, image: ImageDatav });
      setFileLoader(false);
    } catch (error) {
      console.log(error);
      setFileLoader(false);
    }

    function getBase64(filen) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(filen);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  const DeleteBenefit = async (index) => {
    let newBenefitsList = [...benefitsList];
    newBenefitsList.splice(index, 1);
    setBenefitsList(newBenefitsList);
  };

  const company = async () => {
    setisLoading(true);
    setIsImage(false);
    let perks = await getAllPerks();
    if (!perks?.error) {
      setallPerks(perks?.data);
      setNewList(perks?.data);
      setFormValues(initialState);
      setBenefitsList([]);
      setPerkId("");
      setStartDate("");
      setExpireDate("");
      setisLoading(false);
    }
  };

  const NumberOnly = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    company();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [formShow]);

  return (
    <>
      {isLoading && <LoaderWhite />}
      <div className="container perks_container pt-5">
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={4} md={3} lg={2}>
            <CustomBreadcrumbs title="Perks" />
          </Grid>
          <Grid
            item
            xs={5}
            md={3}
            lg={3}
            className="search-section top_Section"
          >
            <TextField
              id="search-customer"
              value={searchVal}
              className="search-customer perk_input"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsSearch />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(e) => {
                setSearchVal(e.target.value);
                setNewList(
                  allPerks?.filter((itm) =>
                    itm?.title.toLowerCase().includes(searchVal.toLowerCase())
                  )
                );
              }}
              onBlur={() => {
                if (searchVal === "") {
                  setNewList(allPerks);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            md={6}
            lg={7}
            sx={{ display: "flex", gap: "16px", justifyContent: "end" }}
            className="search-section top_Section"
          >
            {!formShow && (
              <Button
                variant="outlined"
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  gap: "5px",
                }}
                onClick={() => ShowForm()}
              >
                Add Perk
              </Button>
            )}
          </Grid>
        </Grid>
        <ValidatorForm
          ref={formref}
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          {formShow && (
            <Grid
              container
              className="details_box"
              sx={{
                marginTop: "15px",
                marginLeft: "0px",
                width: "100%",
                paddingLeft: "0px",
              }}
              spacing={2}
            >
              <Grid item xs={12} md={12} lg={12}>
                <h6
                  style={{
                    fontSize: "1.125rem",
                    color: "#343A40",
                  }}
                >
                  Create Perks
                </h6>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Title Of Perk:</InputLabel>
                <TextValidator
                  name="title"
                  value={formValues.title}
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Description of Perk:</InputLabel>
                <TextValidator
                  multiline
                  rows={1}
                  name="discription"
                  value={formValues.discription}
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <InputLabel>Upload Image of Perk:</InputLabel>
                <Stack className="input_file" spacing={2}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    style={{ margin: 0, flexDirection: "column", gap: "10px" }}
                    className="upload_icon_button"
                  >
                    {formValues.image && (
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={formValues.image}
                        className="perk_upload_image"
                      />
                    )}
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => handleImagechange(e)}
                    />
                    {fileLoader && formValues.image === "" ? (
                      <Loader />
                    ) : (
                      <FiUpload />
                    )}
                    <span> (JPG or PNG format)</span>
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Discount:</InputLabel>
                <TextValidator
                  name="discount"
                  value={formValues.discount}
                  onChange={handleChange}
                  label=""
                  onKeyPress={(event) => {
                    NumberOnly(event);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Price:</InputLabel>
                <TextValidator
                  name="price"
                  value={formValues.price}
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    NumberOnly(event);
                  }}
                  label=""
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Start Date:</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    label=""
                    value={startDate}
                    onChange={handleStartDate}
                    name="start"
                    renderInput={(params) => (
                      <TextValidator {...params} fullWidth />
                    )}
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Expire Date:</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    label=""
                    value={expireDate}
                    onChange={handleExpireDate}
                    name="expire"
                    renderInput={(params) => (
                      <TextValidator {...params} fullWidth />
                    )}
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={11} lg={11}>
                <InputLabel>Benefits:</InputLabel>
                <TextValidator
                  name="benefits"
                  value={formValues.benefits}
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={1} lg={1} style={{ textAlign: "center" }}>
                <InputLabel style={{ visibility: "hidden" }}>
                  ADD Benefits:
                </InputLabel>

                <Button
                  variant="outlined"
                  className="custom-mui-button cancel_btn"
                  sx={{
                    fontSize: "22px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                    borderRadius: "100% !important",
                    minWidth: "37px !important",
                    height: "37px !important",
                    padding: "0px !important",
                  }}
                  onClick={() => addBenefits(formValues.benefits)}
                >
                  +
                </Button>
              </Grid>
              <Grid item xs={12} md={11} lg={11}>
                <ul>
                  {benefitsList.map((itm, index) => (
                    <li key={index}>
                      <div className="benefits_list">
                        <InputLabel>{itm}</InputLabel>
                        <div>
                          <Button onClick={() => DeleteBenefit(index)}>
                            Delete
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className="form_group">
                <InputLabel id="termsAndConditions">
                  Terms and Conditions for perks
                </InputLabel>
                <TextValidator
                  name="termsAndConditions"
                  variant="outlined"
                  label=""
                  fullWidth
                  multiline
                  value={formValues.termsAndConditions}
                  errorMessages={["This field is required"]}
                  onChange={(e) => handleChange(e)}
                  rows={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                {perkId ? (
                  <Button
                    variant="outlined"
                    className="custom-mui-button"
                    sx={{
                      fontSize: "14px !important",
                      whiteSpace: "nowrap",
                      gap: "5px",
                    }}
                    onClick={() => EditPerk(perkId)}
                  >
                    update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className="custom-mui-button"
                    sx={{
                      fontSize: "14px !important",
                      whiteSpace: "nowrap",
                      gap: "5px",
                    }}
                    type="submit"
                  >
                    Create Perk
                  </Button>
                )}
                <Button
                  variant="outlined"
                  className="custom-mui-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                  }}
                  type="submit"
                  onClick={() => {
                    setformShow(false);
                    setFormValues(initialState);
                    setBenefitsList([]);
                    setStartDate("");
                    setExpireDate("");
                    setPerkId("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <hr />
            </Grid>
          )}
        </ValidatorForm>
        <div className="details_box">
          {!formShow && (
            <Grid item xs={12} sx={{ p: 1 }}>
              <Alert className="text-message" severity="info">
                Give out perks to your best customers for providing micro
                feedback and insight into their personal preferences
              </Alert>
            </Grid>
          )}
          <h6 style={{ padding: "15px 10px", margin: "0" }}>
            {newList?.length} Perks Created
          </h6>
          {newList?.map((itm) => {
            return (
              <Grid
                container
                spacing={2}
                className="details_box"
                sx={{
                  marginTop: "1px",
                  marginLeft: "0px",
                  width: "100%",
                  paddingLeft: "0px",
                }}
                key={itm._id}
              >
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <div className="image_container">
                    <img
                      src={itm?.image ? itm?.image : hotelImage}
                      alt="hotel"
                      className="hotel_image img-fluid"
                    />
                  { itm.discount > 0 && <button className="custom-mui-button image_btn">
                      {itm.discount > 0 ? itm.discount : ''}% off your next dinner
                    </button> }
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ paddingLeft: "25px" }}
                >
                  <div className="perks_top_section">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <h6
                        style={{
                          fontSize: "1.125rem",
                          color: "#1B1B1B",
                          margin: 0,
                        }}
                      >
                        {itm.title}
                      </h6>
                      <span
                        style={{
                          color: "#04726D",
                          backgroundColor: "#E3EFEE",
                          padding: "2px 10px",
                          borderRadius: "2px",
                          fontSize: "13px",
                        }}
                      >
                        Active
                      </span>
                    </div>
                    <div>
                      <h6 style={{ fontSize: "14px" }}>
                        Perk sent to
                        <Link
                          to="/company/customer"
                          style={{ paddingLeft: "5px", fontWeight: 700 }}
                        >
                          {itm.perkSendCount} people
                        </Link>
                      </h6>
                      <Button
                        className="custom-mui-button invite_link_btn"
                        onClick={() => GetSinglePerkRedeems(itm?._id)}
                      >
                        <HiGift
                          style={{
                            marginRight: "10px",
                            fontSize: "15px",
                            marginBottom: "3px",
                          }}
                        />
                        <span>{itm?.perkRedeemCount} People Redeemed Perk</span>
                      </Button>
                    </div>
                  </div>
                  <div className="perk_bottom_section">
                    <div className="perk_date_section">
                      <Typography variant="subtitle1" component="div">
                        Start Date:
                        {new Date(`${itm.createdAt}`).toDateString()}
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Expire Date:
                        {new Date(`${itm.expire}`).toDateString()}
                      </Typography>
                    </div>
                    {itm.price > 0 && (
                      <Typography variant="subtitle1" component="div">
                        $ {itm.price}/ person
                      </Typography>
                    )}
                    <p className="paragraph">{itm.discription}</p>
                    <ul className="perks_benefits_list">
                      {itm.benefits.map((list, ind) => {
                        return <li key={ind}>{list}</li>;
                      })}
                    </ul>
                  </div>

                  <CardActions
                    style={{
                      gap: "15px",
                      padding: "0",
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="custom-mui-button save_btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                      onClick={() => GetSinglePerk(itm._id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      className="custom-mui-button cancel_btn"
                      sx={{
                        fontSize: "14px !important",
                        whiteSpace: "nowrap",
                        gap: "5px",
                      }}
                      onClick={() => DeletePerk(itm._id)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            );
          })}
        </div>
        <Modal
          open={isAddModalOpen}
          onClose={closeAddModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="perk_modal_container">
            <Box>
              <CardActions
                style={{
                  justifyContent: "space-between",
                  padding: "0px",
                  marginBottom: "10px",
                }}
                stickeyHeader
              >
                <Typography variant="h6">Redeemed Perks Details</Typography>
                <RiCloseFill
                  onClick={() => closeAddModal()}
                  style={{
                    fontSize: "20px",
                    color: "#808080",
                    cursor: "pointer",
                  }}
                />
              </CardActions>
            </Box>
            <Divider />
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
              className="perks_list"
            >
              {redeemsPerk?.map((itm) => {
                console.log(itm?.redeemDate)
                let redeemDate = moment(itm.redeemDate).format('MMM D, YYYY / h:mm A');
                return (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      className="list_item"
                      key={itm}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt="Remy Sharp"
                          src={itm?.userId?.image}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Customer : ${itm?.userId?.name}`}
                        secondary={
                          <React.Fragment>
                            Redeemed Date/Time: {redeemDate}
                            <b
                              style={{
                                color: "black",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Redeemed by Staff Member: {itm?.redeemStaffId?.name}
                            </b>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider fullWidth />
                  </>
                );
              })}
            </List>
          </Box>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default Perks;
