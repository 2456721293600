import { get } from "./config";

export const getAdminDashboardData = async () => {
    return get("/user/companyDashboradAPI")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };