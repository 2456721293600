import {get, post, destroy, patch } from "./config";

export const filterPreference = async (prefCatg, prefSubCatg, prefType) => {
  return post("/preference/superadmin/getPreferenceByCategory", {
    categoryId: prefCatg,
  })
    .then((response) => {
      var prefData = response.data;

      if (prefSubCatg !== "0") {
        prefData.data = prefData.data.filter(
          (val, idx) => val.subcategoryId === prefSubCatg
        );
      }
      var prefCount = { YesNo: 0, MultipleChoice: 0, FreeFlow: 0 };
      prefCount.YesNo = prefData.data.filter(
        (val, idx) => val.type === "yesNo"
      ).length;
      prefCount.MultipleChoice = prefData.data.filter(
        (val, idx) => val.type === "multipleChoice"
      ).length;
      prefCount.FreeFlow = prefData.data.filter(
        (val, idx) => val.type === "freeFlow"
      ).length;

      response.data.PrefCount = prefCount;

      response.data.data = prefData.data.filter(
        (val, idx) => val.type === prefType
      );
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const filterPreferenceCompany = async (selCategory, type) => {
  return post("/preference/admin/allTypePreferencesForCompany", {
    categoryId: selCategory,
  })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getPreferencesByType = async (prefType) => {
  return post("/preference/superadmin/getFilterPreferenceType", {
    type: prefType,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const deletePreference = async (prefId) => {
  return destroy("/preference/superadmin/deletePreference", {
    data: {
      _id: prefId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCategoriesWithSubAndPrefCount = async () => {
  return post("/preference/superadmin/preferenceCountAndCategorySubcategory", {
    categoryId: "",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCategoriesWithSubAndPrefCountCompany = async () => {
  return post(
    "/preference/admin/preferenceCountAndCategorySubcategoryForCompany",
    {
      categoryId: "",
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewPreference = async (request) => {
  return post("/preference/superadmin/addPreference", request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewPreferenceCompany = async (request) => {
  return post("/preference/admin/addPreference", request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePreference = async (request) => {
  return patch(
    "/preference/superadmin/updatePreference",
    request
    // {
    //     categoryId: catgId,
    //     subcategoryId: subCatgId,
    //     type: "yesNo",
    //     title: title,
    //     image: imageUrl
    // }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewYesNoPreference = async (
  catgId,
  subCatgId,
  title,
  imageUrl
) => {
  return post("/preference/superadmin/addPreference", {
    categoryId: catgId,
    subcategoryId: subCatgId,
    type: "yesNo",
    title: title,
    image: imageUrl,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewMultipleChoicePreference = async (
  catgId,
  subCatgId,
  title,
  mcOptions,
  imageUrl
) => {
  return post("/preference/superadmin/addPreference", {
    categoryId: catgId,
    subcategoryId: subCatgId,
    type: "multipleChoice",
    title: title,
    mcOption: mcOptions,
    image: imageUrl,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const addNewFreeFlowPreference = async (
  catgId,
  subCatgId,
  title,
  isCompulsory,
  imageUrl
) => {
  return post("/preference/superadmin/addPreference", {
    categoryId: catgId,
    subcategoryId: subCatgId,
    type: "freeFlow",
    compulsory: isCompulsory,
    title: title,
    image: imageUrl,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUnlockedPreferencesForUser = async (companyId, userId) => {
  return get(`/preference/getUnlockedPreferencesForUser/${companyId}/${userId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};