import { get, post, patch, destroy } from "./config";
export const getAllCompanies = async () => {
  return post("/user/superadmin/allActiveUsers", {
    role: "company",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const filterCompany = async (companyName, status) => {
  return post("/user/superadmin/getFilterByKeywordAndStatus", {
    name: companyName,
    status: status,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const companyStatus = async (status, companyId) => {
  return post("/user/superadmin/changeStatus", {
    userId: companyId,
    status: status,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const companyDelete = async (companyId) => {
  return destroy("/user/superadmin/deleteUser", {
    data: {
      _id: companyId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCategories = async () => {
  return get("/category/superadmin/allCategory")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubCategories = async (categoryId) => {
  return post("/subcategory/superadmin/getFilterSubcategory", {
    categoryId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addCompany = async (datas) => {
  return post("/user/superadmin/addCompany", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCompany = async (cid) => {
  return post("/user/superadmin/singleCompany", {
    _id: cid,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCompany = async (datas) => {
  return patch("/user/superadmin/updateCompany", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const searchNearbyCompanies = async (datas) => {
  return post("/user/nearByCompaniesGooglePlaces", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPlaceAutoCompleteAPI = async (datas) => {
  return post("/user/getPlaceAutoComplete", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getPlaceAutoCompleteForHotel = async (datas) => {
  return post("/user/getPlaceAutoCompleteForHotel", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const checkPlaceDetailsExistsAPT = async (datas) => {
  return post("/user/getGooglePlaceDetail", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const editCompany = async (data) => {
  return post("/user/admin/editCompany", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const claimBusinessUpdate = async (data, _id) => {
  return patch("/user/admin/claimBusinessUpdate", {
    ...data,
    _id,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const companyProfileUpdate = async (data, _id) => {
  return patch("/user/admin/companyProfileUpdate", {
    ...data,
    _id,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const verifyToClaimBusinessCompany = async (email, id, manually) => {
  return post("/user/admin/verifyToClaimBusiness", {
    email: email,
    _id: id,
    manually: manually,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const resendOtpverify = async (email, id) => {
  return post("/user/admin/resendVerifyToken", {
    email: email,
    _id: id,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const verifyOtpid = async (otp, id) => {
  return post("/user/admin/verifyToken", {
    verifyToken: otp,
    _id: id,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSingleCompany = async (id) => {
  return post("/user/admin/singleCompany", {
    _id: id,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const companyVerify = async (companyId) => {
  return post("/user/superadmin/verifyBusinessClaimRequest", {
    _id: companyId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
