import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import SwipeableViews from "react-swipeable-views";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Typography from "@mui/material/Typography";
import "../../assets/css/customer_modal.css";

import Preference from "./Preference";
import Feedback from "./Feedback";
import Bio from "./Bio";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <Typography
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            cursor: 'pointer',
            margin: '3px'
          }}
        >
         <NavigateBeforeIcon />Back
        </Typography>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      className="custom-modal-outer"
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`
  };
}

export default function CustomerModal({open, handleClose, rowData}) {
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        open={open}
        className="customer-modal"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
        </BootstrapDialogTitle>
        <Box
          sx={{
            width: 500,
            position: "relative",
          }}
          className="cutomer-modal-box"
        >
          <AppBar position="static" color="" className="appBar">
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              variant="fullWidth"
              aria-label="action tabs example"
            >
              <Tab label="Bio" {...a11yProps(0)} />
              <Tab label="Preference" {...a11yProps(1)} />
              <Tab label="Feedback" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
         
        </Box>
        <DialogContent style={{height: '450px'}}>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} className="customer-modal-tabpanel">
          <Bio rowData={rowData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
         <Preference rowData={rowData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Feedback rowData={rowData} />
        </TabPanel>
      </SwipeableViews>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
