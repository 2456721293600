export const getUserInfo = () => {
  var userInfoString = sessionStorage.getItem("userInfo");
  if (userInfoString && userInfoString !== "") {
    return JSON.parse(userInfoString);
  }
};

export const getUserName = () => {
  var userInfoString = sessionStorage.getItem("userInfo");
  if (userInfoString && userInfoString !== "") {
    return JSON.parse(userInfoString)?.name;
  }
};

export const getUserRole = () => {
  var userInfoString = sessionStorage.getItem("userInfo");
  if (userInfoString && userInfoString !== "") {
    return JSON.parse(userInfoString)?.role?.name;
  }
};

