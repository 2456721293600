/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useRef, useEffect } from "react";
import { BsPlusCircle } from "react-icons/bs";
import userImage from "../../../assets/images/avtar.webp";

import "../../../assets/css/staff.css";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import {
  Box,
  Button,
  CardActions,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Modal,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { DataGrid } from "@mui/x-data-grid";
import { TbPencil } from "react-icons/tb";
import { RiCloseFill } from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import { Stack } from "@mui/system";
import { getUserInfo } from "../../../helpers/helper";

import {
  addStaff,
  deleteStaff,
  editStaff,
  getAllStaff,
  getSingleStaff,
  resendInvite,
} from "../../../services/api/staff.service";

import { ShowToastMessage } from "../../../components/toastr";
import { toast } from "react-toastify";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { LoaderWhite } from "../../../components/LoaderWhite";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Staff = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [id, setId] = useState(0);
  const [isImage, setIsImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allStaff, setAllStaff] = useState([]);
  const [formValue, setFormvalue] = useState({
    name: "",
    email: "",
    mobileNo: "",
    managerView: false,
    image: "",
  });

  const userInfo = getUserInfo();

  const handleChange = (e) => {
    setFormvalue({ ...formValue, [e.target.name]: e.target.value });
  };
  const formref = useRef();
  const openAddModal = () => {
    setAddModalOpen(true);
  };
  const ResendInvite = async (email) => {
    const response = await resendInvite(email);
    if (!response?.error) {
      ShowToastMessage(
        `${response.message}`,
        TOAST_SUCCESS,
        toast.POSITION.BOTTOM_RIGHT
      );
      setAddModalOpen(false);
    }
  };
  const closeAddModal = () => {
    setId("");
    setAddModalOpen(false);
  };
  const staffEdit = async (staffEditId) => {
    setIsLoading(true);
    setId(staffEditId);
    const response = await getSingleStaff(staffEditId);
    if (!response?.error) {
      setAddModalOpen(true);
      setFormvalue({
        name: response?.data?.name,
        email: response?.data?.email,
        mobileNo: response?.data?.mobileNo,
        managerView: response?.data?.managerView,
        image: response?.data?.image,
      });
    }
    setIsLoading(false);
    setIsSubmit(false);
  };
  const staffDelete = async (staffDeleteId) => {
    setIsLoading(true);
    const response = await deleteStaff(staffDeleteId);
    if (!response?.error) {
      ShowToastMessage(
        `${response.message}`,
        TOAST_SUCCESS,
        toast.POSITION.BOTTOM_RIGHT
      );
      GetAllStaff();

      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    let newFormValue = {};
    if (id) {
      if (!isImage) {
        newFormValue = Object.fromEntries(
          Object.entries(formValue).filter(([key]) => !key.includes("image"))
        );
      } else {
        newFormValue = formValue;
      }
      const AddStaff = await editStaff({ ...newFormValue, _id: id });
      if (!AddStaff.error) {
        setAddModalOpen(false);
        ShowToastMessage(
          `${AddStaff.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        GetAllStaff();
      } else {
        setAddModalOpen(true);
        ShowToastMessage(
          `${AddStaff.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
        setIsSubmit(false);
      }
    } else {
      const AddStaff = await addStaff(formValue);
      if (!AddStaff.error) {
        setAddModalOpen(false);
        ShowToastMessage(
          `${AddStaff.message}`,
          TOAST_SUCCESS,
          toast.POSITION.BOTTOM_RIGHT
        );
        GetAllStaff();
      } else {
        setAddModalOpen(true);
        ShowToastMessage(
          `${AddStaff?.msg}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
        setIsSubmit(false);
      }
    }
  };
  const handleImageChange = async (e) => {
    setIsImage(true);
    const file = await e?.target?.files[0];

    const ImageFile = await toBase64(file);
    if (ImageFile) {
      setFormvalue({ ...formValue, image: ImageFile });
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    });
  const columns = [
    {
      headerName: "Name",
      field: "name",
      width: 260,
      renderCell: (data) => {
        return (
          <>
            <img
              src={data?.row?.image ? data?.row?.image : userImage}
              className="users-img"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <div className="MuiDataGrid-cellContent">{data?.row?.name}</div>
          </>
        );
      },
    },
    {
      headerName: "Mobile Number",
      field: "mobileNo",
      width: 230,
    },
    {
      headerName: "Email Address",
      field: "email",
      width: 260,
    },

    {
      headerName: "Actions",
      field: "actions",
      renderCell: (newData) => {
        return (
          <>
            <div className="action_container">
              <button
                className="staff_btn edit_btn"
                onClick={() => {
                  staffEdit(newData?.row?._id);
                  console.log(newData?.row?._id);
                }}
              >
                <TbPencil />
              </button>
              {userInfo?.role?.name === "company" &&
              userInfo?.email !== newData?.row?.email ? (
                <button
                  className="staff_btn delete_btn"
                  onClick={() => staffDelete(newData?.row?._id)}
                >
                  <RiCloseFill />
                </button>
              ) : userInfo?.managerView &&
                userInfo?.email !== newData?.row?.email &&
                userInfo?.staffCompanyId?.email !== newData?.row?.email ? (
                <button
                  className="staff_btn delete_btn"
                  onClick={() => staffDelete(newData?.row?._id)}
                >
                  <RiCloseFill />
                </button>
              ) : null}
            </div>
          </>
        );
      },
      width: 250,
      sortable: false,
    },
  ];

  const GetAllStaff = useCallback(async () => {
    setIsImage(false);
    setIsSubmit(false);
    const response = await getAllStaff();
    if (response) {
      if (response.error) {
        var errorMessage = response?.msg
          ? response?.msg
          : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else if (!response?.error) {
        setAllStaff(response?.data);
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    GetAllStaff();
  }, []);

  return (
    <>
      <div className="staff_container container pt-5">
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={5} md={8} lg={6}>
            <CustomBreadcrumbs title="Staff" />
          </Grid>
          <Grid
            item
            xs={7}
            md={4}
            lg={6}
            sx={{ display: "flex", gap: "16px", justifyContent: "end" }}
            className="search-section"
          >
            <Button
              variant="outlined"
              className="custom-mui-button add-tags-btn"
              sx={{
                fontSize: "14px !important",
                whiteSpace: "nowrap",
                gap: "5px",
              }}
              onClick={() => {
                openAddModal();
                setFormvalue({
                  name: "",
                  email: "",
                  mobileNo: "",
                  managerView: false,
                  image: "",
                });
                setIsSubmit(false);
                setId("");
              }}
            >
              <BsPlusCircle style={{ marginRight: "4px" }} />
              Invite New Staff Members
            </Button>
          </Grid>
        </Grid>

        <DataGrid
          autoHeight
          getRowId={(row) => row._id}
          rows={allStaff}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoPageSize
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          style={{ padding: "10px 20px" }}
        />
        <Modal
          open={isAddModalOpen}
          onClose={closeAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="staff_modal"
        >
          <Box
            sx={style}
            className="staff_modal"
            style={{
              width: "100%",
              maxWidth: "750px",
              border: "none",
              padding: "0",
            }}
          >
            <ValidatorForm
              ref={formref}
              onSubmit={handleSubmit}
              onError={(errors) => console.log(errors)}
            >
              <CardActions
                style={{
                  justifyContent: "space-between",
                  color: "rgb(108, 117, 125)",
                  border: "none",
                  padding: "20px 25px",
                  fontWeight: "700",
                  borderBottom: "1px solid #CED4DA",
                }}
              >
                <h6
                  style={{
                    fontSize: "1rem",
                    color: "#343A40",
                    margin: "0",
                  }}
                >
                  New Staff Member{" "}
                  <span
                    style={{ fontWeight: 400, lineHeight: 1.2, color: "black" }}
                  >
                    (Can log in to staff mobile app only)
                  </span>
                </h6>
                <RiCloseFill
                  onClick={() => closeAddModal()}
                  style={{
                    fontSize: "20px",
                    color: "#808080",
                    cursor: "pointer",
                  }}
                />
              </CardActions>
              <Grid container spacing={2} className="modal_body">
                <Grid item xs={12} sm={3}>
                  <Stack className="modal_file" spacing={2}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      style={{
                        margin: 0,
                        flexDirection: "column",
                        gap: "10px",
                      }}
                      className="upload_icon_button"
                    >
                      {formValue.image ? (
                        <CardMedia
                          component="img"
                          alt="green iguana"
                          height="140"
                          image={formValue.image}
                          className="perk_upload_image"
                        />
                      ) : (
                        <FiUpload />
                      )}

                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Name:</InputLabel>
                      <TextValidator
                        fullWidth
                        name="name"
                        value={formValue.name}
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Email Address:</InputLabel>
                      <TextValidator
                        fullWidth
                        name="email"
                        value={formValue.email}
                        onChange={handleChange}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "This field is required",
                          "Email is not valid",
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Phone Number:</InputLabel>
                      <TextValidator
                        fullWidth
                        name="mobileNo"
                        value={formValue?.mobileNo}
                        onChange={handleChange}
                        validators={["required", "matchRegexp:^[0-9*#+]+$"]}
                        errorMessages={[
                          "This field is required",
                          "Only numbers are allowed",
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={{ visibility: "hidden" }}>
                        Not Visible:
                      </InputLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={formValue?.managerView} />
                          }
                          label="Manager View"
                          name="managerView"
                          value={formValue?.managerView}
                          onChange={(e) => {
                            setFormvalue({
                              ...formValue,
                              managerView: e.target.checked,
                            });
                          }}
                        />
                        <span
                          style={{
                            paddingLeft: 30,
                            marginTop: -8,
                            fontWeight: 400,
                            lineHeight: 1.2,
                            color: "black",
                          }}
                        >
                          (Can log in to both staff mobile app and web app)
                        </span>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <CardActions
                style={{
                  justifyContent: "center",
                  color: "rgb(108, 117, 125)",
                  border: "none",
                  padding: "20px 25px",
                  fontWeight: "700",
                  gap: "15px",
                }}
              >
                <button
                  className="staff_modal_btn edit_btn"
                  type="submit"
                  disabled={isSubmit}
                >
                  {id ? "Update" : "Invite"}
                </button>

                <button
                  className="staff_modal_btn delete_btn"
                  onClick={() => closeAddModal()}
                >
                  Cancel
                </button>
                {id && (
                  <button
                    className="staff_modal_btn edit_btn"
                    type="button"
                    onClick={() => {
                      ResendInvite(formValue.email);
                    }}
                  >
                    Resend Invite
                  </button>
                )}
              </CardActions>
            </ValidatorForm>
          </Box>
        </Modal>
      </div>
      {isLoading && <LoaderWhite />}
    </>
  );
};

export default Staff;
