import { get, post, patch, destroy } from "./config";

const apiToken = sessionStorage.getItem("userToken");

export const getAllCustomers = async () => {
  return post("/user/superadmin/allActiveUsers", {
    role: "user",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllUsers = async (tagsfiltr) => {
  const userIds = sessionStorage.getItem("userInfo");

  return post("/user/admin/unlockUsers", {
    _id: JSON.parse(userIds)._id,
    tagId: tagsfiltr === undefined ? [] : tagsfiltr,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserBioDatas = async (UserId) => {
  return post("/user/admin/userBioData", 
  {userId: UserId},
  {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const getUserMicroFeedbackDatas = async (UserId) => {
  return post("/user/admin/userMicrofeedbackAndTags", 
  {userId: UserId},
  {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const getPreferenceCategorySubcategory = async (data) => {
  return post("/preference/admin/categorySubcategory", 
   data,
  {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const getPreferenceTabsDatas = async (data) => {
  return post("/user/admin/userPreferenceAndPreferenceReply", 
  data,
  {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const getAllTags = async () => {
  return get("/tag/admin/getAllCompanyTags", {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewTags = async (selection, valueAddTagsId, newTagVal) => {
  return post("/userTags/assignTagToUser", {
    userId: selection,
    tagId: valueAddTagsId === undefined ? "" : valueAddTagsId,
    tag: newTagVal,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewPerks = async (selection, valueAddPerks) => {
  return post("/perks/admin/sendPerksToUser", {
    _id: valueAddPerks,
    userId: selection,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCustomerTags = async (UserId, TagIds) => {
  return post("/userTags/removeAssignTagToUser", {
    userId: UserId,
    tagId: TagIds === undefined ? [] : TagIds,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCustomerPerks = async (UserId, PerksIds) => {
  return post("/perks/admin/removePerksToUser", {
    _id: PerksIds,
    userId: UserId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllPerks = async () => {
  return get("/perks/admin/allPerks")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteFeedbackTags = async (FeedbackId, TagIds) => {
  return post("/userTags/removeMicrofeedbackTags", {
    microfeedbackId: FeedbackId,
    tagId: TagIds === undefined ? [] : TagIds,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addNewTagsFeedback = async (
  selection,
  valueAddTagsId,
  newTagVal
) => {
  return post("/userTags/assignTagsToMicrofeedbacks", {
    microfeedbackId: selection,
    tagId: valueAddTagsId === undefined ? "" : valueAddTagsId,
    tag: newTagVal,
    microfeedbackTags: true,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const filterCustomer = async (customerName) => {
  return post("/user/superadmin/getFilterByCustomerName", {
    name: customerName,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const filterCustomerName = async (customerName) => {
  return post("/user/admin/getFilterByCustomerName", {
    name: customerName,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const customerStatus = async (status, customerId) => {
  return post("/user/superadmin/changeStatus", {
    userId: customerId,
    status: status,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const customerDelete = async (customerId) => {
  return destroy("/user/superadmin/deleteUser", {
    data: {
      _id: customerId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCustomer = async (cid) => {
  return post("/user/superadmin/singleUser", {
    _id: cid,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCustomer = async (datas) => {
  return patch("/user/superadmin/updateUser", datas)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
