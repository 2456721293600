import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserProfile } from "../../../services/api/auth.service";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import "../../../assets/css/promote.css";
import { IoIosCopy } from "react-icons/io";
import { getUserInfo } from "../../../helpers/helper";
import { inviteBranchApi } from "../../../services/api/invite.services";
import { Link } from "react-router-dom";
import promote1 from '../../../assets/images/promote1.png';
import promote2 from '../../../assets/images/promote2.png';
import promote3 from '../../../assets/images/promote3.png';

const a1 = "https://stage.hereiswhatilike.com/images/promote1.png"
const a2 = "https://stage.hereiswhatilike.com/images/promote2.png"
const a3 = "https://stage.hereiswhatilike.com/images/promote3.png"

const checkedUrlArray2 = [a1, a2, a3];



const Promote = () => {

  const { travelAgencyStatus, _id } = getUserInfo();

  const [copyText, setCopyText] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [inviteRes, setInviteRes] = useState("");
  const [travelAgentInviteLink, setTravelAgentInviteLink] = useState("");


  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteRes ? inviteRes : copyText);
  };

  const copyToClipboardCompanyReffer = () => {
    navigator.clipboard.writeText(travelAgentInviteLink);
  };
  
  const getLinkUrl  = async () => {
    let linkUrl = await getUserProfile();
    try {
      setCopyText(linkUrl?.data?.branchLink);
      setTravelAgentInviteLink(linkUrl?.data?.travelAgentInviteCompanyBranchLink);
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleCheckboxChange = (checkboxValue, e) => {
    setSelectedCheckbox(checkboxValue === selectedCheckbox ? null : checkboxValue);
  };
  const handleOnClickCheckbox = async (url, isChecked) => {
    let data = {
      companyId: _id,
      imageURL: url,
    }
     if(isChecked === true) {
     const response = await inviteBranchApi(data);
      if (response) {
        if (!response.error && response.data) {
          setInviteRes(response?.data);
        }
      }
     }
  }

  useEffect(() => {
    getLinkUrl();
  }, [])

  return (
    <div className="connection_container pt-5 container">
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} lg={6}>
          <CustomBreadcrumbs title="Invite" />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, mb: 1 }}>
          <Alert className="text-message" severity="info">
            Invite your <u>existing customers</u> to share preferences with your
            company. They will always see your profile on the app home page if
            they click your link.
          </Alert>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <CardActions
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ marginBlock: "15px", color: "#000", fontWeight: "700" }}
            >
              Select an Option
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  size="large"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    "::before": {
                      padding: "10px",
                      top: "9px",
                      left: "8px",
                    },
                  }}
                  checked={selectedCheckbox === ''}
                  onChange={(e) => handleCheckboxChange('', e)}
                  onClick={(e) => handleOnClickCheckbox('', e.target.checked)}
                />
              }
              label="Do not include an image or use my own"
              className="mui-label"
              labelPlacement="start"
            />
          </CardActions>
        </Grid>
      </Grid>
      <Typography sx={{ margin: '8px', color: '#000', fontWeight: 600}}>or select a template below:</Typography>
      <Grid container spacing={5}>
        {checkedUrlArray2?.map((item, i) => {
          let url = i === 0 ? promote1 : i === 1 ? promote2 : promote3 
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={item}>
              <Card variant="outlined" sx={{ padding: "15px" }}>
                <CardMedia
                  component="img"
                  alt="loading..."
                  image={item}
                  style={{ onjectFit: "cover", height: "100%" }}
                />
                <CardContent sx={{ padding: "0px" }}>
                  <CardActions
                    style={{
                      padding: "0px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ marginBlock: "15px", color: "#343A40" }}
                    >
                      <a href={url}
                      download
                      >Download Image</a> or Select
                    </Typography>
                    <Checkbox
                      size="large"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "::before": {
                          padding: "10px",
                          top: "9px",
                          left: "8px",
                        },
                        marginLeft: "15px",
                      }}
                      checked={selectedCheckbox === item}
                      onChange={(e) => handleCheckboxChange(item, e)}
                      onClick={(e) => handleOnClickCheckbox(item, e.target.checked)}
                    />
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={5}>
        <Grid item lg={12}>
          <div className="invite_container">
            <Card
              sx={{ marginTop: "2px", maxWidth: "720px", margin: "30px auto" }}
            >
              <CardContent sx={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ marginBlock: "5px", color: "#000", fontWeight: "700" }}
                >
                  Share your Unique Link With Customers
                </Typography>

                <Typography
                  variant="paragraph"
                  style={{ fontSize: "16px", lineHeight: "1.5" }}
                >
                  Copy your invite link. Paste the link into your social media
                  pages, posts, marketing emails, websites, blogs, or within
                  confirmation notifications.
                </Typography>
               {travelAgencyStatus && <Typography
                  variant="paragraph"
                  style={{ fontSize: "16px", color: '#000000', lineHeight: "1.5", marginTop: "30px" }}
                >
                  <b>Note -</b> Invite a minimum of (10) clients to activate your profile 
                  advertisements within the app.
                </Typography> }
                <CardActions style={{ paddingInline: " 0", gap: "15px" }}>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    fullWidth
                    sx={{
                      "marginBlock": "15px",
                    }}
                    value={inviteRes ? inviteRes : copyText}
                    className="invite_link_input"
                    aria-readonly
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#097B75",
                      textTransform: "none",
                      fontSize: "15px",
                      padding: "2px 10px",
                    }}
                    className="custom-mui-button invite_link_btn"
                    onClick={() => copyToClipboard()}
                  >
                    <IoIosCopy size={28} />
                    &nbsp; Copy
                  </Button>
                </CardActions>
              </CardContent>
            </Card>

            { travelAgencyStatus &&     <Card
              sx={{ marginTop: "2px", maxWidth: "720px", margin: "30px auto" }}
            >
              <CardContent sx={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ marginBlock: "5px", color: "#000", fontWeight: "700" }}
                >
                  Refer companies and earn commissions
                </Typography>

                <Typography
                  variant="paragraph"
                  style={{ fontSize: "16px", lineHeight: "1.5" }}
                >
                  Leverage your industry relationships with airlines, cruiselines, hotels , restaurants, tour provides, car rental 
                  companies, and other companies. 
                </Typography>
                
               {travelAgencyStatus && <Typography
                  variant="paragraph"
                  style={{ fontSize: "16px", color: '#000000', lineHeight: "1.5", marginTop: "30px" }}
                >
                  <b>Note -</b> Ask the manager, owner, or key staff for their contact info or post the link below on the companies social 
                  media
                </Typography> }
                <CardActions style={{ paddingInline: " 0", gap: "15px" }}>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    fullWidth
                    sx={{
                      "marginBlock": "15px",
                    }}
                    value={travelAgentInviteLink}
                    className="invite_link_input"
                    aria-readonly
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#097B75",
                      textTransform: "none",
                      fontSize: "15px",
                      padding: "2px 10px",
                    }}
                    className="custom-mui-button invite_link_btn"
                    onClick={() => copyToClipboardCompanyReffer()}
                  >
                    <IoIosCopy size={28} />
                    &nbsp; Copy
                  </Button>
                </CardActions>
                <Typography
                 variant="paragraph"
                 style={{ fontSize: "16px", color: '#000000'}}
                ><Link to="/company/referralcommissions">Learn more</Link> about earning commission revenue</Typography>
              </CardContent>
            </Card> }
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Promote;
