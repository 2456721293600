/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR } from "../../../helpers/app.constants";
import { getUserInfo } from "../../../helpers/helper";
import CustomerModal from "../../../components/CustomerModal/CustomerModal";
import { DataGrid } from "@mui/x-data-grid";

import "../../../assets/css/customers.css"; // customer page css

import {
  addNewTags,
  addNewPerks,
  getAllTags,
  getAllUsers,
  getAllPerks,
  deleteCustomerTags,
  deleteCustomerPerks,
  getPreferenceCategorySubcategory,
} from "../../../services/api/customer.service";

import filterIcon from "../../../assets/images/fillters.png";
import IconButton from "@mui/material/IconButton";

import { FiMoreVertical } from "react-icons/fi";
import { Loader } from "../../../components/loader";
import {
  Autocomplete,
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import { BsSearch } from "react-icons/bs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MdAddCircleOutline } from "react-icons/md";
import Typography from "@mui/material/Typography";
import { FiCalendar } from "react-icons/fi";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import UserImg from "../../../assets/images/avtar.webp";
import { getSinglePerkRedeems } from "../../../services/api/perks.service";
const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [tagsfiltr, setTagsFiltr] = useState([]);
  const [valueAddTags, setValueAddTags] = useState("");
  const [valueAddPerks, setValueAddPerks] = useState("");
  const [newTagVal, setNewTagVal] = useState("");
  const [errAlrt, setErrAlrt] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValuePerks, setInputValuePerks] = useState("");
  const [tags, setTags] = useState([]);
  const [perks, setPerks] = useState([]);
  const [selection, setSelection] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [endDateEnd, setEndDateEnd] = useState("");
  const [preferenceDate, setPreferenceDate] = useState("");
  const [preferenceDateEnd, setPreferenceDateEnd] = useState("");
  const [tagget, setTagGet] = useState(0);
  const [filterStrings, setFilterStrings] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPerks, setOpenPerks] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [openPerksPopover, setOpenPerksPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");
  const openMenu = Boolean(anchorEl);
  const [openCustomerModal, setOpenCustomerModal] = React.useState(false);
  const [handlePerks, setHandlePerks] = useState(null);
  const [tagsPopover, setTagsPopover] = useState(null);
  const [category, setCategory] = useState([]);
  const [filterData, setFilterData] = useState({
    unlockStart: "",
    unlockEnd: "",
    arrivalStart: "",
    arrivalEnd: "",
  });
  const [array, setArray] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [editForm, setEditform] = useState({
    name: "",
    arrival_date: "",
    arr_time: "",
    assignedTo: "",
  });
  const id = openEdit ? "simple-popover" : undefined;
  const { travelAgencyStatus } = getUserInfo();
  const [customerIds, setCustomerIds] = useState([])
  const [allPerks, setAllPerks] = useState([])
  const [filteredPerks, setFilteredPerks] = useState([]);
  const [allPerkRedeems, setAllPerkRedeems] = useState([]);
  const newData = customers
    ?.filter((itm) =>
      itm?.userId?.name.toLowerCase().includes(searchVal.toLowerCase())
    )
    .filter((items) =>
      filterData.arrivalStart !== ""
        ? new Date(
          new Date(items?.userId.date).toLocaleDateString()
        ).valueOf() >=
        new Date(
          new Date(dayjs(filterData.arrivalStart)).toLocaleDateString()
        ).valueOf() &&
        new Date(
          new Date(items?.userId.date).toLocaleDateString()
        ).valueOf() <=
        new Date(
          new Date(dayjs(filterData.arrivalEnd)).toLocaleDateString()
        ).valueOf()
        : "2022-12-24T21:11:54"
    )
    .filter((el) =>
      filterData.unlockStart !== ""
        ? new Date(
          new Date(dayjs(el?.unLockedDate)).toLocaleDateString()
        ).valueOf() >=
        new Date(
          new Date(dayjs(filterData.unlockStart)).toLocaleDateString()
        ).valueOf() &&
        new Date(
          new Date(dayjs(el?.unLockedDate)).toLocaleDateString()
        ).valueOf() <= new Date(dayjs(filterData.unlockEnd)).valueOf()
        : "2022-12-24T21:11:54"
    )

    .map((item) => {
      return {
        ...item,
        name: item.userId.name,
        id: item.userId._id,
        newDate: new Date(item.userId.date).toLocaleDateString(),
        time: new Date(item.userId.date).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        actions: item.userId,
        newUnLockedDate: new Date(item.unLockedDate).toLocaleDateString(),
        tags: item.tags,
      };
    });


  const handleClose = () => {
    setOpenCustomerModal(false);
  };

  const HandleTags = (handleTagData) => {
    setTagsPopover(handleTagData);
    setOpenTags(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchTags = async () => {
    await getAllTags().then((res) => {
      if (res) {
        if (res.error) {
          var errorMessage = res.msg ? res.msg : "Error Loading the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        } else {
          const dt = res?.data
            ?.filter(
              (itm, index) =>
                index ===
                res?.data?.findIndex(
                  (other) => itm.tagId?.tag === other.tagId?.tag
                )
            )
            .map((el) => ({
              label: el?.tagId?.tag,
              id: el?.tagId?._id,
            }));
          setTags(dt);
        }
      }
    });
    setLoading(false);
  };

  const AddTagsHandle = () => {
    if (newTagVal === "" && valueAddTags === "") {
      setErrAlrt(true);
    } else {
      setLoading(true);
      addNewTags(
        selection[0],
        valueAddTags?.id,
        valueAddTags?.id !== undefined ? "" : newTagVal
      ).then(() => {
        fetchTags()
          .then(() => {
            setInputValue("");
            setNewTagVal("");
            setSelection([]);
            setValueAddTags("");
          })
          .then(() => {
            fetchCustomers();
          });
      });
      setTagGet(1);
      setOpen(!open);
      setErrAlrt(false);
    }
  };

  //  for check is Perk Redeemed or not
  //  start
  const checkisPerkRedeemed = (Perk) => {

    const redeemUsers = allPerkRedeems.find(x => x.perkId === Perk).data
    const customerId = selection[0][0]
    const user = redeemUsers.find(x => x?.userId?._id === customerId)

    const sendPerkTocustomer = () => {
      if (valueAddPerks === "") {
        setErrAlrt(true);
      } else {
        setLoading(true);
        addNewPerks(selection[0], valueAddPerks).then(() => {
          fetchCustomers().then(() => {
            setSelection([]);
            setInputValuePerks("");
            setValueAddPerks("");
          });
        });
        setOpenPerks(false);
        setErrAlrt(false);
        getAllUsers();
        getAllPerks()
      }
    }

    if (user && user.redeemDate) {
      const currentDate = new Date();
      const timeDifference = currentDate - new Date(user.redeemDate);
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference < 24) {
        ShowToastMessage(
          `${`You can't assign This Perk for 24 hours.`}`,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        sendPerkTocustomer()
      }
    } else {
      sendPerkTocustomer()
    }
  }

  const AddPerksHandle = () => {
    checkisPerkRedeemed(valueAddPerks)
  };
// end

  const disableGetTag = () => {
    setTagGet(0);
  };

  const TagsRemove = (idx) => {
    tagsfiltr.splice(idx, 1);
    setTagsFiltr([...tagsfiltr]);
    fetchCustomers(tagsfiltr);
  };

  const handleChangePreferenceDate = (newValue) => {
    setPreferenceDate(newValue);
  };
  const handleChangePreferenceDateEnd = (newValue) => {
    setPreferenceDateEnd(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };
  const handleChangeEndDateEnd = (newValue) => {
    setEndDateEnd(newValue);
  };

  const dltTags = (itm) => {
    if (array.indexOf(itm.tagId._id) !== -1) {
      array.splice(array.indexOf(itm.tagId._id), 1);
      setArray([...array]);
    } else {
      array.push(itm.tagId._id);
      setArray([...array]);
    }
  };

  const dltPerks = (itm) => {
    if (array.indexOf(itm._id) !== -1) {
      array.splice(array.indexOf(itm._id), 1);
      setArray([...array]);
    } else {
      array.push(itm._id);
      setArray([...array]);
    }
  };

  const ApplyFilter = () => {
    setFilterData({
      ...filterData,
      arrivalStart: endDate,
      arrivalEnd: endDateEnd,
      unlockStart: preferenceDate,
      unlockEnd: preferenceDateEnd,
    });
    fetchCustomers(tagsfiltr);
  };

  const handleClickMenu = (event, data) => {
    setEditData(data.row);
    setAnchorEl(event.currentTarget);
    setOpenCustomerModal(true);

  };

  const fetchCustomers = useCallback(async (tagsCustomerFiltr) => {
    setLoading(true);
    const res = await getAllUsers(tagsCustomerFiltr);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCustomers(res.data);
      setCustomerIds(res.data.map((item) => item?.userId?._id));
    }
    setLoading(false);
  }, []);

  const fetchPerks = useCallback(async () => {
    setLoading(true);
    const res = await getAllPerks();
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      const perksData = res?.data?.map((el) => ({
        label: el?.title,
        id: el?._id,
      }));
      setPerks(perksData);
      setAllPerks(res.data)
    }
    setLoading(false);
  }, []);

  const deleteCustomersTags = useCallback(async (id, tagslist) => {
    setLoading(true);
    const res = await deleteCustomerTags(id?.row?.id, tagslist);
    if (res) {
      setOpenTags(false);
      setArray([]);
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      fetchCustomers().then(() => {
        setLoading(false);
      });
    }
  }, []);

  const fetchPreferenceCategorySubcategory = async () => {
    if (customerIds) {
      setLoading(true);
      const tabs = ["company", "admin", "user"];
      for (let i = 0; i < customerIds.length; i++) {
        for (let j = 0; j < tabs.length; j++) {
          let data = {
            userId: customerIds[i],
            tab: tabs[j],
          };
          const res = await getPreferenceCategorySubcategory(data);
          if (res) {
            if (res.error) {
              var errorMessage = res.msg ? res.msg : "Error Loading the data";
              ShowToastMessage(
                errorMessage,
                TOAST_ERROR,
                toast.POSITION.BOTTOM_RIGHT
              );
            } else {
              res.data.forEach((item) => {
                if (category.length === 0) {
                  item.userId = customerIds[i];
                  setCategory((prevCategory) => [...prevCategory, item]);
                }
              });
            }
          }
        }
      }
      setLoading(false)
    }
  };
  const deleteCustomersPerks = useCallback(async (id, Perkslist) => {
    setLoading(true);
    const res = await deleteCustomerPerks(id, Perkslist);
    if (res) {
      setOpenPerksPopover(false);
      setArray([]);
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      fetchCustomers().then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (tagget === 1) {
      getAllTags();
      disableGetTag();
    }
  }, [tagget]);

  useEffect(() => {
    fetchCustomers();
    fetchPerks();
    setLoading(true);
    fetchTags();
  }, []);

  useEffect(() => {
    fetchPreferenceCategorySubcategory()
  }, [customers])

  useEffect(() => {
    const filterString = tagsfiltr
      ?.filter((val, id, filterItm) => {
        return filterItm.indexOf(val) === id;
      })
      .map((itm) => ({
        ...tags?.find((tg) => tg.id === itm),
      }));
    setFilterStrings(filterString);
  }, [tagsfiltr]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (data) => {
        return (
          <>
            <img
              src={!data.row.userId.image ? UserImg : data.row.userId.image}
              className="users-img"
              alt=""
            />
            <div className="MuiDataGrid-cellContent">{data.row.name}</div>
          </>
        );
      },
      sortable: false,
    },
    {
      field: "newDate",
      headerName: "Arrival Date",
      width: 150,
      renderCell: (data) => {
        let assigned_Date = data?.row?.assignedDate?.find(
          (itm, idx) => idx === 0
        );
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {assigned_Date?.arrivalDate !== undefined ? (
                <>
                  <span>
                    {new Date(assigned_Date?.arrivalDate)?.toLocaleDateString()}
                  </span>{" "}
                  <span>
                    {new Date(assigned_Date?.arrivalTime)?.toLocaleTimeString(
                      "en-US",
                      {
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </span>
                </>
              ) : (
                <span>-NA-</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "newUnLockedDate",
      headerName: "Unlock Date",
      width: 170,
    },
    {
      field: "tags",
      headerName: "Tags",
      renderCell: (tagData) => {
        let tagDataLength = tagData?.row?.tags?.length;
        return (
          <>
            <div>
              {tagDataLength > 0 ? (
                <span
                  className="tags-cell"
                  style={
                    tagDataLength === 1
                      ? { cursor: "pointer" }
                      : { cursor: "initial" }
                  }
                  onClick={() => {
                    if (tagDataLength === 1) HandleTags(tagData);
                  }}
                >
                  {tagData?.row?.tags[0]?.tagId?.tag}
                </span>
              ) : null}
              {tagDataLength > 1 ? (
                <Button
                  variant="outlined"
                  onClick={() => HandleTags(tagData)}
                  className="custom-mui-button tags-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                    marginLeft: "5px",
                  }}
                >
                  +{tagDataLength - 1}
                </Button>
              ) : null}
              <Dialog open={openTags} className="tags-dialog">
                <DialogTitle>
                  Tags<span className="apply-alert">Click a tag to remove</span>
                </DialogTitle>
                <DialogContent>
                  <>
                    <List className="tags-listing">
                      {tagsPopover?.row?.tags?.map((item, index, arr) => {
                        return (
                          <ListItem
                            sx={{
                              display: "inline-block",
                              width: "fit-content",
                              margin: "4px 6px",
                              padding: "0",
                            }}
                            key={index}
                            onClick={() => dltTags(item)}
                          >
                            <ListItemText
                              className="tags-cell"
                              primary={item?.tagId?.tag}
                              onClick={({ target }) =>
                                target.classList.toggle("added-tag-remove")
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                </DialogContent>
                <DialogActions>
                  {array?.length > 0 ? (
                    <Button
                      className="custom-mui-button"
                      onClick={() => deleteCustomersTags(tagsPopover, array)}
                    >
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setOpenTags(false);
                      setArray([]);
                    }}
                    className="custom-mui-button"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      },
      width: 170,
      sortable: false,
    },
    {
      field: "perks",
      headerName: "Perks",
      width: 170,
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            <div className="perks-listing-outer">
              {data?.row?.perks?.length > 0 ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setHandlePerks(data);
                    setOpenPerksPopover(true);
                  }}
                  className="custom-mui-button tags-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {data?.row?.perks?.length}
                </Button>
              ) : null}
              <Dialog open={openPerksPopover} className="tags-dialog">
                <DialogTitle>
                  Perks
                  <span className="apply-alert">Click a Perk to remove</span>
                </DialogTitle>
                <DialogContent>
                  <>
                    <List className="tags-listing">
                      {handlePerks?.row?.perks.map((item, index, arr) => {
                        return (
                          <ListItem
                            sx={{
                              display: "inline-block",
                              width: "fit-content",
                              margin: "4px 6px",
                              padding: "0",
                            }}
                            key={index}
                            onClick={() => dltPerks(item)}
                          >
                            <ListItemText
                              className="tags-cell"
                              primary={item?.title}
                              onClick={({ target }) =>
                                target.classList.toggle("added-tag-remove")
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                </DialogContent>
                <DialogActions>
                  {array?.length > 0 ? (
                    <Button
                      className="custom-mui-button"
                      onClick={() =>
                        deleteCustomersPerks(handlePerks?.row?.id, array)
                      }
                    >
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setOpenPerksPopover(false);
                      setArray([]);
                    }}
                    className="custom-mui-button"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      },
    },
    {
      field: "totalPreferences",
      headerName: "Total Preferences",
      width: 200,
      renderCell: (data) => {
        return (
          <>
            <div className="MuiDataGrid-cellContent">
              <span>
                {category.reduce((total, catg) => (data.id === catg.userId ? total + parseInt(catg?.count) : total), 0)}
              </span>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2, param1, param2) => {
        // Custom sorting logic for totalPreferences
        const total1 = category.reduce((total, catg) => (param1.id === catg.userId ? total + parseInt(catg?.count) : total), 0);
        const total2 = category.reduce((total, catg) => (param2.id === catg.userId ? total + parseInt(catg?.count) : total), 0);
        return total1 - total2;
      },
    },
    
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (newActionData) => {
        return (
          <>
            <div>
              <IconButton
                aria-label="more"
                id="abc"
                aria-controls={openMenu ? "long-menu" : undefined}
                aria-expanded={openMenu ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClickMenu(e, newActionData)}
              >
                <FiMoreVertical />
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "abc",
                }}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    width: "10ch",
                  },
                }}
              >
                <MenuItem
                  aria-describedby={id}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenEdit(true);
                    setEditform({
                      ...editForm,
                      name: editData.name,
                      arrival_date: editData.newDate,
                      arr_time: editData.time,
                      assignedTo: editData.assignedTo,
                    });
                  }}
                >
                  Edit
                </MenuItem>
              </Menu>
              <Popover
                id={editForm.id}
                open={openEdit}
                onClose={() => console.log("closed")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <form action="">
                  <FormGroup sx={{ padding: "20px" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "center", justifyContent: "flex-end" }}
                    >
                      <Grid item xs={8}>
                        <TextField
                          id="search-customer"
                          className="edit-data-form"
                          placeholder="Name"
                          variant="outlined"
                          value={editForm.assignedTo}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          type="submit"
                          variant="outlined"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenEdit(false);
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </form>
              </Popover>
            </div>
          </>
        );
      },
      width: 100,
      sortable: false,
    },
  ];

  const travelAgencyColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (data) => {
        return (
          <>
            <img
              src={!data.row.userId.image ? UserImg : data.row.userId.image}
              className="users-img"
              alt=""
            />
            <div className="MuiDataGrid-cellContent">{data.row.name}</div>
          </>
        );
      },
      sortable: false,
    },
    {
      field: "newUnLockedDate",
      headerName: "Unlock Preferences Date",
      width: 430,
    },
    {
      field: "tags",
      headerName: "Tags",
      renderCell: (tagData) => {
        let tagDataLength = tagData?.row?.tags?.length;
        return (
          <>
            <div>
              {tagDataLength > 0 ? (
                <span
                  className="tags-cell"
                  style={
                    tagDataLength === 1
                      ? { cursor: "pointer" }
                      : { cursor: "initial" }
                  }
                  onClick={() => {
                    if (tagDataLength === 1) HandleTags(tagData);
                  }}
                >
                  {tagData?.row?.tags[0]?.tagId?.tag}
                </span>
              ) : null}
              {tagDataLength > 1 ? (
                <Button
                  variant="outlined"
                  onClick={() => HandleTags(tagData)}
                  className="custom-mui-button tags-button"
                  sx={{
                    fontSize: "14px !important",
                    whiteSpace: "nowrap",
                    gap: "5px",
                    marginLeft: "5px",
                  }}
                >
                  +{tagDataLength - 1}
                </Button>
              ) : null}
              <Dialog open={openTags} className="tags-dialog">
                <DialogTitle>
                  Tags<span className="apply-alert">Click a tag to remove</span>
                </DialogTitle>
                <DialogContent>
                  <>
                    <List className="tags-listing">
                      {tagsPopover?.row?.tags?.map((item, index, arr) => {
                        return (
                          <ListItem
                            sx={{
                              display: "inline-block",
                              width: "fit-content",
                              margin: "4px 6px",
                              padding: "0",
                            }}
                            key={index}
                            onClick={() => dltTags(item)}
                          >
                            <ListItemText
                              className="tags-cell"
                              primary={item?.tagId?.tag}
                              onClick={({ target }) =>
                                target.classList.toggle("added-tag-remove")
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                </DialogContent>
                <DialogActions>
                  {array?.length > 0 ? (
                    <Button
                      className="custom-mui-button"
                      onClick={() => deleteCustomersTags(tagsPopover, array)}
                    >
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setOpenTags(false);
                      setArray([]);
                    }}
                    className="custom-mui-button"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      },
      width: 280,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (newActionData) => {
        return (
          <>
            <div>
              <IconButton
                aria-label="more"
                id="abc"
                aria-controls={openMenu ? "long-menu" : undefined}
                aria-expanded={openMenu ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClickMenu(e, newActionData)}
              >
                <FiMoreVertical />
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "abc",
                }}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    width: "10ch",
                  },
                }}
              >
                <MenuItem
                  aria-describedby={id}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenEdit(true);
                    setEditform({
                      ...editForm,
                      name: editData.name,
                      arrival_date: editData.newDate,
                      arr_time: editData.time,
                      assignedTo: editData.assignedTo,
                    });
                  }}
                >
                  Edit
                </MenuItem>
              </Menu>
              <Popover
                id={editForm.id}
                open={openEdit}
                onClose={() => console.log("closed")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <form action="">
                  <FormGroup sx={{ padding: "20px" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "center", justifyContent: "flex-end" }}
                    >
                      <Grid item xs={8}>
                        <TextField
                          id="search-customer"
                          className="edit-data-form"
                          placeholder="Name"
                          variant="outlined"
                          value={editForm.assignedTo}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          type="submit"
                          variant="outlined"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenEdit(false);
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </form>
              </Popover>
            </div>
          </>
        );
      },
      width: 100,
      sortable: false,
    },
  ];

  useEffect(() => {
    const selectedCustomerIds = selection[0] || [];
    const perkIdsToRemove = allPerks
      .filter((perk) => selectedCustomerIds.some((customerId) => perk?.userId?.includes(customerId)))
      .map((perk) => perk?._id);

    let updatedFilteredPerks = perks.filter((perk) => !perkIdsToRemove.includes(perk.id));
    const uniquePerkIds = new Set(updatedFilteredPerks.map((perk) => perk.id));

    // Iterate over allPerkRedeems and check redemption conditions
    allPerkRedeems.forEach((redeemData) => {
      redeemData.data.forEach((redeem) => {
        if (redeem && selectedCustomerIds.includes(redeem?.userId?._id)) {
          // Check if redemption date is not before 24 hours
          const selectPerk = perks.find((item) => item.id === redeem.perkId);

          // Check if the perk is not already present
          if (selectPerk && !uniquePerkIds.has(selectPerk.id)) {
            uniquePerkIds.add(selectPerk.id);
          }
        }
      });
    });

    setFilteredPerks(updatedFilteredPerks);
  }, [selection, allPerks, perks, allPerkRedeems]);


  const getAllPerksRedeems = async (perkIds) => {
    const AllPerkRedeems = [];
    for (const perk of perkIds) {
      const redeemData = await getSinglePerkRedeems(perk.id);
      AllPerkRedeems.push({ ...redeemData, perkId: perk.id });
    }
    return AllPerkRedeems;
  };

  const main = async () => {
    const AllPerkRedeems = await getAllPerksRedeems(perks);
    setAllPerkRedeems(AllPerkRedeems);
  };

  useEffect(() => {
    main();
  }, [perks]);



  return (
    <div className="customer_listing_top">
      <div className="container customers-table pt-5">
        <Box sx={{ flexGrow: 1 }} className="customers-header">
          <Grid container spacing={2} sx={{ alignItems: "end" }}>
            <Grid item xs={6} sm={12} md={3} lg={2}>
              <CustomBreadcrumbs title="Customers" />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={6}
              sx={{ display: "flex", gap: "16px", flexDirection: "column", position: 'relative' }}
              className="search-section"
            >
              {travelAgencyStatus && <Typography
                className="cutomer-note-text">
                <b>Note -</b> Invite 10 clients to activate profile advertisements.
              </Typography>}
              <TextField
                id="search-customer"
                value={searchVal}
                className="search-customer"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  gap: "5px",
                }}
              >
                <MdAddCircleOutline style={{ minWidth: "15px" }} />
                Add Tags
              </Button>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="add-tags-modal"
              >
                {selection[0]?.length > 0 ? (
                  <DialogTitle> Add Tags</DialogTitle>
                ) : (
                  <DialogTitle>No customer Selected</DialogTitle>
                )}
                <DialogContent>
                  {selection[0]?.length > 0 ? (
                    <>
                      <Autocomplete
                        disablePortal
                        id="combo-box"
                        options={tags}
                        sx={{ width: "100%" }}
                        // onChange={(options) => onTagsChange(options)}
                        onChange={(event, newValue) => {
                          setValueAddTags(newValue);
                          document.getElementsByClassName(
                            "MuiAutocomplete-popperDisablePortal"
                          )[0].style.display = "none";
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          setNewTagVal(newInputValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.label === undefined ? "" : option.label
                        }
                        clearOnBlur={false}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                          </Box>
                        )}
                        open={inputValue.length > 2}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </>
                  ) : (
                    <Typography color="tomato">
                      Please select a customer
                    </Typography>
                  )}
                  {errAlrt ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "tomato",
                        paddingTop: "10px",
                      }}
                    >
                      Error: No tags added
                    </Typography>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setErrAlrt(false);
                      setNewTagVal("");
                      setValueAddTags("");
                    }}
                    sx={{ fontSize: "16px", color: "#808080" }}
                  >
                    Cancel
                  </Button>
                  {selection[0]?.length > 0 ? (
                    <Button
                      onClick={AddTagsHandle}
                      sx={{
                        background: "#04736D !important",
                        fontSize: "14px",
                        color: "#FEFFFF",
                        borderRadius: 0,
                        maxWidth: "100px",
                        width: "100%",
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </DialogActions>
              </Dialog>

              {!travelAgencyStatus && <Button
                variant="outlined"
                onClick={() => setOpenPerks(true)}
                className="custom-mui-button add-tags-btn"
                sx={{ fontSize: "14px !important", whiteSpace: "nowrap" }}
              >
                Send Perks
              </Button>}
              <Dialog
                open={openPerks}
                onClose={() => setOpenPerks(false)}
                className="add-tags-modal"
              >
                {selection[0]?.length > 0 ? (
                  <DialogTitle>Select Perk</DialogTitle>
                ) : (
                  <DialogTitle>No customer Selected</DialogTitle>
                )}
                <DialogContent>
                  {selection[0]?.length > 0 ? (
                    <>
                      <Autocomplete
                        disablePortal
                        id="combo-box"
                        options={filteredPerks}
                        sx={{ width: "100%" }}
                        onChange={(event, newValue) => {
                          // Handle the selected perk
                          setValueAddPerks(newValue?.id);
                          // document.getElementsByClassName(
                          //   "MuiAutocomplete-popperDisablePortal"
                          // )[0].style.display = "none";
                        }}
                        inputValue={inputValuePerks}
                        onInputChange={(event, newInputValue) => {
                          setInputValuePerks(newInputValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.label === undefined ? "" : option.label
                        }
                        clearOnBlur={false}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                          </Box>
                        )}
                        // open={inputValuePerks.length >= 2}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </>
                  ) : (
                    <Typography color="tomato">
                      Please select a customer
                    </Typography>
                  )}
                  {errAlrt ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "tomato",
                        paddingTop: "10px",
                      }}
                    >
                      Error: No Perks added
                    </Typography>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenPerks(false);
                      setErrAlrt(false);
                      setInputValuePerks("");
                      setValueAddPerks("");
                    }}
                    sx={{ fontSize: "16px", color: "#808080" }}
                  >
                    Cancel
                  </Button>
                  {selection[0]?.length > 0 ? (
                    <Button
                      onClick={AddPerksHandle}
                      sx={{
                        background: "#04736D !important",
                        fontSize: "14px",
                        color: "#FEFFFF",
                        borderRadius: 0,
                        maxWidth: "100px",
                        width: "100%",
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </DialogActions>
              </Dialog>
              <Button
                variant="outlined"
                className="custom-mui-button add-tags-btn"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  background: "#fff !important",
                  color: "#6C757D !important",
                  border: "1px solid #CED4DA !important",
                }}
                onClick={() => setOpenFilters(true)}
              >
                <img alt="" className="img-fluid pe-2" src={filterIcon} />
                Filters
              </Button>
              <Dialog
                open={openFilters}
                onClose={() => setOpenFilters(false)}
                className="filters-modal"
              >
                <DialogTitle
                  sx={{ padding: "8px", color: "#02736D", fontSize: "18px" }}
                >
                  {" "}
                  Filters
                </DialogTitle>
                <Button
                  onClick={() => setOpenFilters(false)}
                  sx={{
                    maxWidth: "20px",
                    position: "absolute",
                    right: 0,
                    color: "#808080",
                    fontSize: "18px",
                  }}
                >
                  x
                </Button>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <label>
                    Tags
                    <span className="apply-alert">
                      Please 'Apply' after selecting tags
                    </span>
                  </label>
                  <div className="tags-filter-section">
                    {tags?.map((item, indx) => {
                      return (
                        <p
                          key={indx}
                          className="tags-cell filter"
                          onClick={() => {
                            if (tagsfiltr?.some((el) => el === item.id)) {
                              setTagsFiltr(tagsfiltr);
                            } else {
                              setTagsFiltr((prev) => [...prev, item.id]);
                            }
                          }}
                        >
                          {item.label}
                        </p>
                      );
                    })}
                  </div>

                  <label>Unlock Preferences Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: "flex" }}>
                      <DesktopDatePicker
                        label=""
                        className="date-pickers-customers"
                        inputFormat="MM/DD/YYYY"
                        value={preferenceDate}
                        onChange={handleChangePreferenceDate}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: FiCalendar,
                        }}
                      />
                      <span style={{ color: "#6C757D", padding: "10px" }}>
                        To
                      </span>

                      <DesktopDatePicker
                        label=""
                        className="date-pickers-customers"
                        inputFormat="MM/DD/YYYY"
                        value={preferenceDateEnd}
                        onChange={handleChangePreferenceDateEnd}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: FiCalendar,
                        }}
                      />
                    </Box>

                    {!travelAgencyStatus && <div><label>Arrival Date</label>
                      <Box sx={{ display: "flex" }}>
                        <DesktopDatePicker
                          label=""
                          className="date-pickers-customers"
                          inputFormat="MM/DD/YYYY"
                          value={endDate}
                          onChange={handleChangeEndDate}
                          renderInput={(params) => <TextField {...params} />}
                          components={{
                            OpenPickerIcon: FiCalendar,
                          }}
                          sx={{ display: "inline-block" }}
                        />
                        <span style={{ color: "#6C757D", padding: "10px" }}>
                          To
                        </span>

                        <DesktopDatePicker
                          label=""
                          className="date-pickers-customers"
                          inputFormat="MM/DD/YYYY"
                          value={endDateEnd}
                          onChange={handleChangeEndDateEnd}
                          renderInput={(params) => <TextField {...params} />}
                          components={{
                            OpenPickerIcon: FiCalendar,
                          }}
                          sx={{ display: "inline-block" }}
                        />
                      </Box> </div>}
                  </LocalizationProvider>
                </FormControl>
                <DialogActions sx={{ justifyContent: "flex-start" }}>
                  <Button
                    onClick={ApplyFilter}
                    sx={{
                      background: "#035450!important",
                      color: "#fff",
                      borderRadius: 0,
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    onClick={() => {
                      setEndDate("");
                      setEndDateEnd("");
                      setPreferenceDate("");
                      setPreferenceDateEnd("");
                      setOpenFilters(false);
                      setFilterData({
                        arrivalStart: "",
                        arrivalEnd: "",
                        unlockStart: "",
                        unlockEnd: "",
                      });
                      setTagsFiltr([]);
                      fetchCustomers([]);
                    }}
                    sx={{
                      background: "#E13773 !important",
                      color: "#fff",
                      borderRadius: 0,
                    }}
                  >
                    Reset Filters
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
          {filterStrings?.length > 0 ? (
            <div>
              <span className="tags-filter-label">Tags: </span>
              {filterStrings?.map((itm, idx) => {
                return (
                  <>
                    <p className="tags-cell filter listing" key={idx}>
                      {itm.label}{" "}
                      <span
                        onClick={() => {
                          TagsRemove(idx);
                        }}
                      >
                        x
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
          ) : null}
        </Box>
        <CustomerModal
          open={openCustomerModal}
          handleClose={handleClose}
          rowData={editData}
        />
        <div
          className="col-md-12 company_table"
          style={{ height: "100vh", width: "100%" }}
        >
          {!loading && (
            <DataGrid
              rows={newData}
              columns={!travelAgencyStatus ? columns : travelAgencyColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              onSelectionModelChange={(newSelectionModel) => {
                setSelection([newSelectionModel]);
              }}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "totalPreferences",
                      sort: "desc",
                    },
                    {
                      field: "newDate",
                      sort: "desc",
                    },
                  ],
                },
              }}
            />
          )}

          {loading && <Loader />}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Customer;
