/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import { Container, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import { getUserProfile } from "../../../services/api/auth.service";

import { ShowToastMessage } from "../../../components/toastr";
import {
  PREF_TAB_FREEFLOW,
  PREF_TAB_MULTIPLE,
  PREF_TAB_YESNO,
  TOAST_ERROR,
} from "../../../helpers/app.constants";
import "../../../assets/css/preference.css";
import {
  filterPreferenceCompany,
  getCategoriesWithSubAndPrefCountCompany,
} from "../../../services/api/preference.service";
import { Loader } from "../../../components/loader";
import { getAllCategories } from "../../../services/api/category.service";
import { sortBy } from "lodash";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { Link, useNavigate } from "react-router-dom";

const Preference = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [selCategory, setCategory] = useState("");
  const [addPRefData, setAddPrefData] = useState([]);
  const [prefCount, setPrefCounts] = useState({
    YesNo: 0,
    MultipleChoice: 0,
    FreeFlow: 0,
  });
  const [prefCardId, setPrefCard] = useState(null);

  const [companyCategories, setCompanyCategories] = useState([]);

  const getSubCategories = async () => {
    const newSubCat = await getUserProfile();
    setCompanyCategories(newSubCat?.data?.subcategories);
  };

  const handleTabOnCategoryChange = useCallback(async (selCategoryData) => {
    setLoading(true);
    if (selCategoryData !== "") {
      var type = "yesNo";
      filterPreferenceCompany(selCategoryData, type).then((response) => {
        if (response.error) {
          var errorMessage = response.msg
            ? response.msg
            : "Error Loadng the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
        setPrefCounts({
          ...prefCount,
          YesNo: response?.data?.filter(
            (el) => el.type === "yesNo" || el.type === "yesNO"
          ).length,
          MultipleChoice: response?.data?.filter(
            (el) => el.type === "multipleChoice"
          ).length,
          FreeFlow: response?.data?.filter((el) => el.type === "freeFlow")
            .length,
        });
      });
      setLoading(false);
    }
  }, []);

  const fillCategories = useCallback(async () => {
    const response = await getAllCategories();
    if (response) {
      if (response?.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error Loadng the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      if (response?.data?.length > 0) {
        var foodCategory = response.data.filter(
          (val, idx) => val.categoryName === "Food"
        );
        if (foodCategory?.length > 0) {
          setCategory(foodCategory[0]._id);
        }
      }
    }
  }, []);

  const loadPreferenceData = useCallback(async () => {
    if (selCategory !== "") {
      var type = "";
      if (tabIndex === PREF_TAB_YESNO) {
        type = "yesNo";
      } else if (tabIndex === PREF_TAB_MULTIPLE) {
        type = "multipleChoice";
      } else if (tabIndex === PREF_TAB_FREEFLOW) {
        type = "freeFlow";
      }

      const response = await filterPreferenceCompany(selCategory, type);
      if (response) {
        if (response.error) {
          var errorMessage = response.msg
            ? response.msg
            : "Error Loadng the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
        setLoading(false);
      }
    }
  }, [tabIndex]);

  const getInfoForAddPref = useCallback(async () => {
    const response = await getCategoriesWithSubAndPrefCountCompany();
    if (response) {
      if (response.error) {
        var errorMessage = response.msg
          ? response.msg
          : "Error Loadng the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setAddPrefData(response.data);
      setLoading(false);
    }
  }, []);

  const toggle = (Id) => {
    if (prefCardId === Id) {
      setPrefCard(null);
    } else {
      setPrefCard(Id);
    }
  };

  const renderAddPreferencePanel = () => {
    var prefList = [];
  
    sortBy(addPRefData, ["categoryName"]).map((record, idx) => {
      return prefList.push(
        <Card style={{ marginBottom: "1rem" }} key={idx}>
          <CardHeader data-event={idx} data-type="collapseBanner">
            <div className="category_of_preferences_left">
              <div className="category_of_preferences_box">
                <div className="category_content_left_box">
                  <img className="img-fluid" src={record.image} alt="" />
                </div>
                <div className="category_content_right_box">
                  <span>{record.categoryName}</span>
                  <Link
                    className="link-common"
                    to="/company/preferencelist"
                    state={{
                      categoryId: record._id,
                      categoryName: record.categoryName,
                      prefCardId: idx,
                    }}
                  >
                    <h5 className="pt-1 mb-0">
                      {record.count} Preference Prompts - Add Prompt
                    </h5>
                  </Link>
                 
                  <h5
                    className="pt-0"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      toggle(idx);
                      setCategory(record?._id);
                    }}
                  >
                    {
                      record?.subcategory?.filter((el) =>
                        companyCategories?.includes(el?._id)
                      ).length
                    }{" "}
                    Sub Categories
                  </h5>
                </div>
              </div>
            </div>
            <div className="category_of_preferences_right">
              <button className="preference_save open_save_popup">Save</button>
              <button className="preference_cancel_btn">Cancel</button>
            </div>
          </CardHeader>
          <Collapse isOpen={prefCardId === idx}>
            <CardBody>
              <div className="clear-both">
                <h2>{record?.categoryName} Sub Categories:</h2>
                <div className="food_category_buttons">
                  {sortBy(record.subcategory, [
                    (o) => o.subcategoryName !== "General",
                    (o) => o.subcategoryName,
                  ])
                    .filter((el) => companyCategories?.includes(el?._id))
                    .map((subR, sIdx) => (
                      <label
                        className={
                          subR.subcategoryName !== "General" ? null : "general"
                        }
                        key={sIdx}
                      >
                        {subR.subcategoryName}
                      </label>
                    ))}
                </div>
              </div>
            </CardBody>
          </Collapse>
        </Card>
      );
    });
    return prefList;
  };

  useEffect(() => {
    setLoading(true);
    fillCategories();
    getInfoForAddPref();
    getSubCategories();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selCategory !== "") {
      loadPreferenceData();
    }
  }, [tabIndex]);

  useEffect(() => {
    setLoading(true);
    setTabIndex(0);
    handleTabOnCategoryChange(selCategory);
  }, [selCategory]);
  return (
    <React.Fragment>
      <Container className="container">
        <div className="row dashboard_tittle_box pt-5">
          <div className="col-md-6">
            <CustomBreadcrumbs title="Preferences" />
          </div>
          <div className="col-md-6">
            <button onClick={() => navigate('/company/settings') } className="manage-btn">Manage Categories</button>
          </div>
          <div className="col-md-12 preferences-company">
            {!loading ? renderAddPreferencePanel() : <Loader />}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default Preference;
