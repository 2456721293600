import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-TD7XGKJ7',
};

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  TagManager.initialize(tagManagerArgs);
}

Sentry.init({
  dsn: "https://770d4854760c4c80a3339546ab432998@o1281258.ingest.sentry.io/4504013942816768",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  autoSessionTracking: false,
  tracingOrigins: ["*"],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
  </>
);
