import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Autocomplete,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  createFilterOptions,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import { MdPhotoCamera } from "react-icons/md";
import profile from "../../../assets/images/company_placeholder.webp";
import "../../../assets/css/profile.css";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import {
  companyProfileUpdate,
  getCategories,
  getPlaceAutoCompleteAPI,
} from "../../../services/api/company.service";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";
import { toast, ToastContainer } from "react-toastify";
import { getUserProfile } from "../../../services/api/auth.service";
import { Loader } from "../../../components/loader";
import { handleImagechange } from "../../../components/ImageCompress";
import { getUserInfo } from "../../../helpers/helper";
import { v4 as uuidv4 } from "uuid";

const Profile = () => {
  const formref = useRef();
  const [uniqueIds1, setUniqueId1] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [isnewImage, setIsnewImage] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [fixedCategory, setFixedCategory] = useState([]);
  const [allCategory, setAllCategoryID] = useState([]);
  const [allSubCategory, setAllSubCategoryID] = useState([]);
  const [placesDetails, setPlaceDetails] = useState([]);
  const [locations, setLocation] = useState("");
  const [googlelocationname, setGooglelocationname] = useState([]);
  const [citydrop, setCityDrop] = useState(false);
  const [cityloading, setCityLoading] = useState(false);
  const [isindexNo, setIsIndexNo] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [imageFile, setImageFile] = useState(false);
  const [validationMsg, setValidationMsg] = useState("")
  const [formValues, setFormValues] = useState({
    _id: "",
    name: "",
    city: "",
    zipcode: "",
    mobileNo: "",
    email: "",
    address: "",
    state: "",
    country: "",
    website: "",
    ownerManagerName: "",
    ownerManagerEmail: "",
    ownerManagerMobileNo: "",
    ownerManagerTitle: "",
    shortBio: "",
    image: "",
    categoryId: [],
    buttonLable: "",
    categories: [],
    subcategories: [],
    bookingLink: "",
    travelAgentCity:[],
  });

  const { travelAgencyStatus } = getUserInfo();

  const handleChipDelete = (itm) => {
    const filteredLocations = googlelocationname?.filter((item) => item !== itm);
    setGooglelocationname(filteredLocations);
    console.log(filteredLocations);
  };

  const toOptions = (category, depth = 0, parentId = null) => {
    const { _id, categoryName, subcategoryName, subCategory = [] } = category;
    const children = subCategory.flatMap((child) => {
      return child.subcategoryName === "General"
        ? toOptions(child, depth + 1, _id)
        : toOptions(child, depth + 2, _id);
    });
    const option = {
      _id,
      categoryName: categoryName ? categoryName : subcategoryName,
      depth,
      parentId,
      matchTerms: [categoryName].concat(
        children.map((obj) => obj.categoryName)
      ),
    };
    return [option].concat(children);
  };

  const optionsList = allCategory
    ?.flatMap((category) => toOptions(category))
    .filter((item) => item.depth !== 1);
  const GeneralOptionsList = allCategory
    ?.flatMap((category) => toOptions(category))
    .filter((item) => item.depth === 1);

  const handleChange = (e) => {
    if (e.target.name === "categoryId") {
      setFormValues({
        ...formValues,
        categoryId: [e.target.value],
      });
      setUpdateFormData({
        ...updateFormData,
        categoryId: [e.target.value],
      });
    } else {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
      setUpdateFormData({
        ...updateFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

    // onchange city location call
    const getCitySearchDetails = async (srchwrd) => {
      setCityLoading(true);
      let srchkey = srchwrd.trim().toLowerCase();
  
      const cityDatas = {
        input: srchkey,
        sessionToken: uniqueIds1,
        type: "geocode",
        location: "",
        radius: "",
        strictbounds: false,
        pd: "0",
        requestFrom: "2",
      };
  
      const resCity = await getPlaceAutoCompleteAPI(cityDatas);
      if (resCity) {
        if (!resCity.error && resCity.data) {
          setPlaceDetails(resCity.data);
          setCityDrop(true);
          setCityLoading(false);
        }
      }
    };


  const handleSubmit = async (e) => {
    let main_Category = allSubCategory
      .filter((item) => (item.depth === 0 ? item : null))
      .map((item) => item._id);
    let sub_Category = allSubCategory
      .filter((item) => (item.depth === 2 ? item : null))
      .map((item) => item._id);
    const newGeneral = GeneralOptionsList.filter((item) =>
      main_Category.includes(item.parentId)
    ).map((item) => item._id);
    setisLoading(true);
    e.preventDefault();
    formValues.categories = main_Category;
    formValues.travelAgentCity = googlelocationname;
    formValues.subcategories = [...newGeneral, ...sub_Category];
    updateFormData.categories = main_Category;
    updateFormData.subcategories = [...newGeneral, ...sub_Category];
    updateFormData.address = formValues.address;

    if (formref.current) {
      let newFormValue;
      if (!isnewImage) {
        newFormValue = Object.fromEntries(
          Object.entries(formValues).filter(([key]) => !key.includes("image"))
        );
      } else {
        updateFormData.image = formValues.image;
        newFormValue = formValues;
      }

      let company = await companyProfileUpdate(newFormValue, userInfo._id);
      try {
        setisLoading(false);
        if (company?.error) {
          ShowToastMessage(
            `${company?.msg}`,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        } else {
          ShowToastMessage(
            `${company?.message}`,
            TOAST_SUCCESS,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleImageChange = async (e) => {
    setIsnewImage(true);
    const file = e.target.files[0];
    if (!file) return;

    setImageFile(true);
    await handleImagechange(e)
      .then((base64) => {
        setFormValues({
          ...formValues,
          image: base64,
        });
        setImageFile(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getCompanyProfile = async () => {
    setisLoading(true);
    let userProfile = await getUserProfile();
    try {
      setFormValues({
        name: userProfile?.data.name,
        city: userProfile?.data.city
          ? userProfile?.data.city
          : userProfile?.data.googlelocationname,
        zipcode: userProfile?.data.zipcode,
        mobileNo: userProfile?.data.mobileNo,
        email: userProfile?.data.email,
        address: userProfile?.data.address,
        state: userProfile?.data.state,
        country: userProfile?.data.country,
        website: userProfile?.data.website,
        ownerManagerName: userProfile?.data.ownerManagerName,
        ownerManagerEmail: userProfile?.data.ownerManagerEmail,
        ownerManagerMobileNo: userProfile?.data.ownerManagerMobileNo,
        ownerManagerTitle: userProfile?.data.ownerManagerTitle,
        shortBio: userProfile?.data.shortBio,
        image: userProfile?.data.image,
        categoryId: [userProfile?.data?.categoryId[0]?._id],
        buttonLable: userProfile?.data.buttonLable,
        categories: userProfile?.data.categories,
        subcategories: userProfile?.data.subcategories,
        bookingLink: userProfile?.data.bookingLink,
      });
      setGooglelocationname(userProfile?.data?.travelAgentCity);
      setUserInfo(userProfile?.data);
      setIsIndexNo(true);
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isindexNo) {
      let result = optionsList?.filter((o1) =>
        [
          ...formValues?.categoryId,
          ...formValues?.categories,
          ...formValues?.subcategories,
        ].some((o2) => o1?._id === o2)
      );
      optionsList.map((item, index) => {
        return item._id === formValues?.categoryId[0]
          ? (setAllSubCategoryID([...new Set([optionsList[index], ...result])]),
            setFixedCategory([optionsList[index]]))
          : null;
      });
    }
  }, [isindexNo, formValues]);

  useEffect(() => {
    const getCompanyType = async () => {
      const categoryID = await getCategories();
      try {
        setAllCategoryID(categoryID.data);
      } catch (error) {
        console.log(error, "error");
      }
    };

    getCompanyType();
    getCompanyProfile();
  }, []);

  return (
    <>
      <div className="container pt-5 profile_container">
        <ValidatorForm
          ref={formref}
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
          onClick={() => {
            setCityDrop(false);
          }}
        >
          <input hidden name="_id" value={formValues._id} />
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={8} md={8} lg={6}>
              <CustomBreadcrumbs title="Settings & Company Profile" />
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              lg={6}
              sx={{ display: "flex", gap: "16px", justifyContent: "end" }}
              className="search-section"
            >
              <Button
                variant="outlined"
                className="custom-mui-button add-tags-btn"
                type="submit"
                sx={{
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  gap: "5px",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid
              container
              spacing={2}
              className="details_box"
              sx={{
                marginTop: "15px",
                marginLeft: "0px",
                width: "100%",
                paddingLeft: "0px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputLabel style={{ paddingBottom: "10px" }}>
                  Company Profile Image
                </InputLabel>
                <Card variant="outlined" className="image_section">
                  {imageFile ? (
                    <Loader />
                  ) : (
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      image={formValues.image || profile}
                    />
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: "#143E3C",
                      justifyContent: "center",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      padding: "6px",
                      gap: "20px",
                    }}
                  >
                    <MdPhotoCamera className="Photo_icon" />
                    <span className="small_text">Change Profile Image</span>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </Button>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="CompanyName">Company Name</InputLabel>
                  <TextValidator
                    name="name"
                    label=""
                    variant="filled"
                    disabled={true}
                    value={formValues.name}
                    validators={["required", "minStringLength:2"]}
                    errorMessages={[
                      "This field is required",
                      "Minimium Two characters required",
                    ]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className="form_group">
                    <InputLabel id="city">City</InputLabel>
                    <TextValidator
                      name="city"
                      label=""
                      variant="filled"
                      disabled={true}
                      value={formValues.city}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) => handleChange(e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="form_group">
                    <InputLabel id="zipcode">Zip Code</InputLabel>
                    <TextValidator
                      name="zipcode"
                      label=""
                      variant="filled"
                      disabled={true}
                      value={formValues.zipcode}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) => handleChange(e)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="mobileNo">Company Phone</InputLabel>
                  <TextValidator
                    name="mobileNo"
                    label=""
                    variant="filled"
                    disabled={true}
                    value={formValues.mobileNo}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="categoryId">Company Type</InputLabel>
                  <TextValidator
                    select
                    fullWidth
                    label=""
                    variant="filled"
                    disabled={true}
                    placeholder="Parent Menu"
                    name="categoryId"
                    errorMessages={["This field is required"]}
                    style={{ padding: "0" }}
                    SelectProps={{
                      value: [formValues?.categoryId],
                    }}
                    value={[formValues?.categoryId]}
                    validators={["required"]}
                    onChange={(e) => handleChange(e)}
                  >
                    {allCategory.map((item) => {
                      return (
                        <MenuItem value={item._id} key={item._id}>
                          {item.categoryName}
                        </MenuItem>
                      );
                    })}
                  </TextValidator>
                </Grid>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="email">Email</InputLabel>
                  <TextValidator
                    name="email"
                    label=""
                    variant="filled"
                    disabled={true}
                    value={formValues.email}
                    validators={["required", "isEmail"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="address">Address</InputLabel>
                  <TextValidator
                    name="address"
                    label=""
                    value={formValues.address}
                    variant="filled"
                    disabled={true}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="form_group">
                    <InputLabel id="state">State</InputLabel>

                    <TextValidator
                      name="state"
                      label=""
                      variant="filled"
                      disabled={true}
                      value={formValues.state}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) => handleChange(e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} className="form_group">
                    <InputLabel id="country">Country</InputLabel>

                    <TextValidator
                      name="country"
                      label=""
                      variant="filled"
                      disabled={true}
                      value={formValues.country}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) => handleChange(e)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="website">Website Link</InputLabel>
                  <TextValidator
                    name="website"
                    label=""
                    variant="outlined"
                    value={formValues.website}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="form_group">
                  <div className="">
                    <InputLabel
                      id=""
                      sx={{ width: "auto", paddingTop: "10px" }}
                    >
                      Sub Categories{" "}
                    </InputLabel>
                    <Alert
                      className="text-message"
                      severity="info"
                      sx={{ padding: "0px", backgroundColor: "transparent" }}
                    >
                      Select categories you would like to received preferences
                      for
                    </Alert>
                  </div>

                  {isindexNo && formValues.subcategories && (
                    <Autocomplete
                      multiple
                      id="multiselect"
                      options={optionsList}
                      name="subCategory"
                      value={allSubCategory}
                      onChange={(event, newValue) => {
                        const newValueParent = newValue?.map((itm) => {
                          return itm.parentId;
                        });
                        const newValueId = newValue?.map((itm) => {
                          return itm._id;
                        });
                        const selectedValueParent = optionsList?.filter((el) =>
                          newValueParent?.includes(el._id)
                        );

                        setAllSubCategoryID([
                          ...fixedCategory,
                          ...newValue.filter((option) => {
                            return fixedCategory.indexOf(option) === -1;
                          }),
                          ...selectedValueParent?.filter(
                            (el) => !newValueId?.includes(el._id)
                          ),
                        ]);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value?._id ||
                        allSubCategory.filter((item) => {
                          return item.parentId === option._id
                            ? item.parentId
                            : null;
                        })[0]?.parentId === option._id
                      }
                      getOptionLabel={(option) =>
                        option?.depth !== 1
                          ? `${option?.categoryName} / General`
                          : option?.categoryName
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          return (
                            <>
                              <Chip
                                label={
                                  option && option?.depth === 0
                                    ? `${option?.categoryName} / General`
                                    : option?.categoryName
                                }
                                {...getTagProps({ index })}
                                disabled={fixedCategory.indexOf(option) !== -1}
                              />
                            </>
                          );
                        })
                      }
                      renderOption={(
                        props,
                        option,
                        { selected, inputValue }
                      ) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              checked={selected}
                              sx={{ ml: 3 * option.depth }}
                            />

                            <div>
                              {option.depth === 0
                                ? `${option.categoryName} / General`
                                : option.categoryName}
                            </div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      filterOptions={createFilterOptions({
                        // join with some arbitrary separator to prevent matches across adjacent terms
                        stringify: (option) => option.matchTerms.join("//"),
                      })}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="form_group"
                style={{ paddingTop: "0px" }}
              >
                <InputLabel id="shortBio">
                  Company Profile Description (Shown To Public)
                </InputLabel>
                <TextValidator
                  name="shortBio"
                  variant="outlined"
                  label=""
                  fullWidth
                  multiline
                  value={formValues.shortBio}
                  errorMessages={["This field is required"]}
                  onChange={(e) => handleChange(e)}
                  rows={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="form_group"
                style={{ paddingTop: "0px" }}
              >
                <InputLabel
                  style={{ marginBottom: "0px ", paddingTop: "10px" }}
                >
                  <span
                    style={{
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "#6C757D",
                      marginTop: "20px",
                    }}
                  >
                    Profile Link
                  </span>{" "}
                  (promote your preferred booking or resevation link, web site,
                  or other application)
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="form_group"
                style={{ paddingTop: "0px" }}
              >
                <InputLabel id="buttonLable">Button Label</InputLabel>
                <TextValidator
                  name="buttonLable"
                  variant="outlined"
                  label=""
                  fullWidth
                  value={formValues.buttonLable}
                  errorMessages={["This field is required"]}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="form_group"
                style={{ paddingTop: "0px" }}
              >
                <InputLabel id="bookingLink">Link URL</InputLabel>
                <TextValidator
                  name="bookingLink"
                  variant="outlined"
                  label=""
                  fullWidth
                  type="url"
                  value={formValues.bookingLink}
                  errorMessages={["This field is required"]}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>

              {travelAgencyStatus && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="form_group"
                  style={{ paddingTop: "20px" }}
                >
                  <Grid sx={{ display: "flex", flexDirection: 'row', marginTop: "30px" }}>
                    <span style={{
                      fontSize: "1.125rem",
                      fontWeight: "700",
                      paddingRight: '12px'
                    }}>Select Advertising Destinations</span>{" "}
                    <span style={{ paddingRight: '10px', color: 'red' }}>(Your profile advertisements will not show in search results until you invite 10 customers)</span>
                  </Grid>
                  <p style={{
                    fontSize: "1rem",
                    fontWeight: "700",
                    color: "#6C757D",
                    marginTop: "20px",
                  }}>
                    Promote your expertise and services to consumers searching
                    specific destinations or locations. Your profile and
                    contact information will show as advertisements within the
                    search results of the locations you select.
                  </p>

                  <div style={{position:'relative'}}>
                  <input
                      type="text"
                      name="googlelocationname"
                      className="form-control googlelocationnameprofile"
                      id="citybasedSearch"
                      aria-describedby="emailHelp"
                      autoComplete="off"
                      onChange={(evt) => {
                        setLocation(evt.target.value);
                        getCitySearchDetails(evt.target.value);
                      }}
                      value={locations}
                      onClick={() =>{
                         setCityDrop(true);
                         setLocation('');
                         setValidationMsg('');
                      }}
                    />
                    {googlelocationname?.length === 10 && (
                      <p style={{color: "red", fontSize: 15}}>{validationMsg}</p>
                    )}
                    {citydrop && (
                      <div className="autocomplete-dropdown-container" style={{left: 5, top: 53}}>
                        {cityloading ? (
                          <div>Loading...</div>
                        ) : (
                          <ul>
                            {placesDetails &&
                              placesDetails.map((item) => (
                                <li
                                  key={
                                    item?.place_id === undefined
                                      ? item?.placeId
                                      : item?.place_id
                                  }
                                  onClick={(i) => {
                                    setLocation(item?.description);
                                   googlelocationname?.length < 10 && setGooglelocationname(prevLocations => [...prevLocations, item?.description]);
                                   googlelocationname?.length === 10 && setValidationMsg("A maximum of ten locations may be selected")
                                    setCityDrop(false);
                                    setUniqueId1(uuidv4());
                                  }}
                                >
                                  {item?.description}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    )}
                    </div>
                  <Grid className="chip-grid">
                  {googlelocationname?.map((chip, i) => {
                    return (
                      <Chip
                       label={chip}
                       key={i}
                       onDelete={() => handleChipDelete(chip)}
                      />
                    )
                  })
                  }
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h6
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "700",
                    color: "#6C757D",
                    marginTop: "20px",
                  }}
                >
                  Owner Details
                </h6>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} className="form_group">
                  <InputLabel id="ownerManagerName">Owner Name</InputLabel>
                  <TextValidator
                    name="ownerManagerName"
                    label=""
                    variant="outlined"
                    value={formValues.ownerManagerName}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="ownerManagerEmail"> Owner Email</InputLabel>
                  <TextValidator
                    validators={["required", "isEmail"]}
                    name="ownerManagerEmail"
                    label=""
                    variant="filled"
                    disabled={true}
                    value={formValues.ownerManagerEmail}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} className="form_group">
                  <InputLabel id="ownerManagerMobileNo">
                    Owner Direct Contact Number
                  </InputLabel>
                  <TextValidator
                    name="ownerManagerMobileNo"
                    label=""
                    variant="outlined"
                    value={formValues.ownerManagerMobileNo}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className="form_group">
                  <InputLabel id="ownerManagerTitle">Owner Title</InputLabel>
                  <TextValidator
                    name="ownerManagerTitle"
                    label=""
                    variant="outlined"
                    value={formValues.ownerManagerTitle}
                    errorMessages={["This field is required"]}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </ValidatorForm>

        <ToastContainer />
      </div>
    </>
  );
};

export default Profile;
