import { Button, Card, CardActions, Grid, Typography } from "@mui/material";
import React from "react";
import { GrRefresh } from "react-icons/gr";

import ButtonGroup from "@mui/material/ButtonGroup";
import "../../../assets/css/notifications.css";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";

const Notification = () => {
  const active = true;
  return (
    <div className="notification_container container pt-5">
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} lg={6}>
          <CustomBreadcrumbs title="Notifications" />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        className="details_box"
        sx={{ marginTop: "15px" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CardActions
            style={{
              justifyContent: "space-between",
              textTransform: "none",
              padding: "0px",
            }}
          >
            <Typography variant="subtitle1">Notification Settings</Typography>
            <Typography variant="subtitle1">
              <GrRefresh style={{ fontSize: "19px", marginRight: "4px" }} />
              Reset Settings
            </Typography>
          </CardActions>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {notify.map((item, idx) => {
            return (
              <Card variant="outlined" sx={{ marginBlock: "15px" }} key={idx}>
                <CardActions
                  style={{
                    justifyContent: "space-between",
                    textTransform: "none",
                  }}
                >
                  <Typography variant="subtitle1">{item.title}</Typography>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined button group"
                    style={{ boxShadow: "none" }}
                  >
                    <Button
                      className={`${
                        active
                          ? "notify_btn yes_btn"
                          : "notify_btn yes_btn active"
                      }  `}
                    >
                      Yes
                    </Button>
                    <Button
                      className={`${
                        active
                          ? "notify_btn yes_btn active"
                          : "notify_btn no_btn"
                      }  `}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </CardActions>
              </Card>
            );
          })}
          <CardActions
            style={{
              gap: "15px",
              padding: "0",
            }}
          >
            <Button
              variant="outlined"
              className="custom-mui-button save_btn"
              sx={{
                fontSize: "14px !important",
                whiteSpace: "nowrap",
                gap: "5px",
              }}
            >
              Save Settings
            </Button>
            <Button
              variant="outlined"
              className="custom-mui-button cancel_btn"
              sx={{
                fontSize: "14px !important",
                whiteSpace: "nowrap",
                gap: "5px",
              }}
            >
              Cancel
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </div>
  );
};
const notify = [
  {
    title:
      "Staff members should be notified when a customer shares their preferences",
  },
  {
    title:
      "Staff members should be notified when a customer is at our location",
  },
  { title: "Share members who will receive micro feedback" },
];
export default Notification;
