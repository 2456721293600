import { useState, createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const setUserInfo = (userInfo) => {
    sessionStorage.setItem("userInfo", userInfo);
    setUser(userInfo);
  };
  const clearUserInfo = () => {
    setUser(null);
  };

  const setUserToken = (userToken) => {
    sessionStorage.setItem("userToken", userToken);
    setToken(userToken);
  };

  const clearUserToken = () => {
    setToken(null);
  };

  const getUserToken = () => {
    return sessionStorage.getItem("userToken");
  };

  const getUserInfo = () => {
    return sessionStorage.getItem("userInfo");
  };
  const [token, setToken] = useState(getUserToken());
  const [user, setUser] = useState(getUserInfo());

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUserToken,
        clearUserToken,
        setUserInfo,
        clearUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
