import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import Smartlook from 'smartlook-client';
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { getAdminDashboardData } from "../../services/api/admin_dashboard.service";
import { getUserInfo } from "../../helpers/helper";
import { HiAdjustments } from "react-icons/hi";
import {BiFilterAlt} from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import { FiUser, FiHeart } from "react-icons/fi";
import { AiOutlineGift } from "react-icons/ai";
import LineChart from "../../components/Chart/chart";
import "../../assets/css/dashboard.css";
import moment from "moment";
import { Button } from "@mui/material";

const Home = () => {
  const [timeFrame, setTimeFrame] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const userInfo = getUserInfo();
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handlePrevYear = () => {
    setSelectedYear(selectedYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear(selectedYear + 1);
  };

  const { travelAgencyStatus } = getUserInfo();

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const getDashboardData = async () => {
    let data = await getAdminDashboardData();
    try {
      setDashboardData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const initializeSmartlook = () => {
    // Check if userInfo is defined and has the required properties
    if (userInfo && userInfo.name && userInfo.email) {
      Smartlook.init('4a4a03b16daead3b6578042051bf69eeea4545e7');
      Smartlook.identify('-', {
        name: userInfo.name,
        email: userInfo.email,
      });
    } else {
      console.error('Error: userInfo is not defined or does not contain the required properties.');
    }
  };
  
  useEffect(() => {
    if(userInfo){
      initializeSmartlook();
    }
  }, [userInfo]);

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="container pt-4">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <CustomBreadcrumbs title="Dashboard" />
        </Grid>
      </Grid>
      <div className="select-box">
        <select
          id="timeFrame"
          value={timeFrame}
          onChange={handleTimeFrameChange}
        >
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
          }}
        >
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6} lg={3} sm={6}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 150,
                }}
              >
                <div className="dashboard_top_card">
                  <h1 className="dashboard-card-number-color">
                    {dashboardData?.favouriteCount}
                  </h1>
                  <span>
                    <FiHeart size={25} color="#d83d6f" />
                  </span>
                </div>
                <b className="card-1">Total Number of Favorites</b>
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={6} lg={3} sm={6}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 150,
                }}
              >
                {" "}
                <div className="dashboard_top_card">
                  <h1 className="dashboard-card-number-color">
                    {dashboardData?.unlockCustomerCount}
                  </h1>
                  <span>
                    <HiAdjustments size={25} color="#d83d6f" />
                  </span>
                </div>
                <b className="card-1">Total Number of Unlocked Customers</b>
              </Paper>
            </Grid>
            {!travelAgencyStatus && (
              <Grid item xs={12} md={6} lg={3} sm={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                  }}
                >
                  {" "}
                  <div className="dashboard_top_card">
                    <h1 className="dashboard-card-number-color">
                      {dashboardData?.microfeedbackCount}
                    </h1>
                    <span>
                      <TbEdit size={25} color="#d83d6f" />
                    </span>
                  </div>
                  <b className="card-1">Micro Feedbacks Submitted </b>
                </Paper>
              </Grid>
            )}
            {!travelAgencyStatus && (
              <Grid item xs={12} md={6} lg={3} sm={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                  }}
                >
                  <div className="dashboard_top_card">
                    <h1 className="dashboard-card-number-color">
                      {dashboardData?.perkRedeemedCount}
                    </h1>
                    <span>
                      <AiOutlineGift size={25} color="#d83d6f" />
                    </span>
                  </div>
                  <b className="card-1">Number of Perks Redeemed </b>
                </Paper>
              </Grid>
            )}{" "}
            {travelAgencyStatus && (
              <Grid item xs={12} md={6} lg={3} sm={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                    position: 'relative'
                  }}
                >
                  <div className="dashboard_top_card">
                    <h1 className="dashboard-card-number-color">
                      {dashboardData?.profileViewsCount || 0}
                    </h1>
                    <span>
                      <FiUser size={25} color="#d83d6f" />
                    </span>
                  </div>
                  <b className="card-1">Number of Profile Ad Views </b>
                  <div className="coomingSoon-btn">Invite 10 clients</div>
                </Paper>
              </Grid>
            )}
            {travelAgencyStatus && (
              <Grid item xs={12} md={6} lg={3} sm={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                    position: 'relative'
                  }}
                >
                  <div className="dashboard_top_card">
                    <h1 className="dashboard-card-number-color">
                      {/* {dashboardData?.profileViewsCount || 0} */}
                      {0}
                    </h1>
                    <span>
                      <BiFilterAlt size={25} color="#d83d6f" />
                    </span>
                  </div>
                  <b className="card-1">Number of Leads </b>
                  <div className="coomingSoon-btn">Coming Soon</div>
                </Paper>
              </Grid>
            )}
            {!travelAgencyStatus && (
              <Grid item xs={12} lg={6} md={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "row",
                    height: 80,
                  }}
                >
                  {" "}
                  <h1 className="dashboard-card-number-color">
                    {dashboardData?.customersArrivingTodayCount}
                  </h1>
                  <div className="dashboard_bottom_card">
                    <b className="card-2">Customers Arriving Today</b>
                    <span>
                      <FiUser size={25} color="#d83d6f" />
                    </span>
                  </div>
                </Paper>
              </Grid>
            )}
            {!travelAgencyStatus && (
              <Grid item xs={12} lg={6} md={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "row",
                    height: 80,
                  }}
                >
                  {" "}
                  <h1 className="dashboard-card-number-color">
                    {dashboardData?.customersArrivingTomorrowCount}
                  </h1>
                  <div className="dashboard_bottom_card">
                    <b className="card-2">Customers Arriving Tomorrow</b>
                    <span>
                      <FiUser size={25} color="#d83d6f" />
                    </span>
                  </div>
                </Paper>
              </Grid>
            )}
            {/* Recent Orders */}
            <Grid item xs={12} sx={{ justify: "flex-end" }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <div className="d-flex justify-content-between border-none">
              <b> Unlocked Customers by Timeframe</b>
              <div className="d-flex align-items-center gap-2">
                <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#097B75",
                      textTransform: "none",
                      fontSize: "15px",
                      padding: "2px 10px",
                      alignItems: "baseline"
                    }}
                    className="custom-mui-button invite_link_btn"
                    onClick={handlePrevYear}
                  >
                   Prev
                </Button>
                <span>{selectedYear}</span>
                <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#097B75",
                        textTransform: "none",
                        fontSize: "15px",
                        padding: "2px 10px",
                        alignItems: "baseline"
                      }}
                      className="custom-mui-button invite_link_btn"
                      onClick={handleNextYear}
                    >
                    Next
                </Button>
              </div>
            </div>
            <LineChart
              unlockCustomers={dashboardData?.unlockCustomers ? dashboardData?.unlockCustomers : []}
              selectedYear={selectedYear}
            />
          </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};
export default Home;
