import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ShowToastMessage } from "../../../components/toastr";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../helpers/app.constants";

import "../../../assets/css/add_company.css"; // company page css

import {
  getCategories,
  updateCompany,
  getCompany,
  getPlaceAutoCompleteAPI,
  checkPlaceDetailsExistsAPT,
} from "../../../services/api/company.service";

import importImg from "../../../assets/images/uplod.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader";

import { v4 as uuidv4 } from "uuid";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

const EditCompany = () => {
  let navigate = useNavigate();

  const { companyid } = useParams();
  const initialValues = {
    name: "",
    ownerManager: "",
    email: "",
    ownerManagerName: "",
    mobileNoCC: "",
    mobileNo: "",
    website: "",
    ownerManagerEmail: "",
    categoryId: "",
    ownerManagerMobileNoCC: "",
    ownerManagerMobileNo: "",
    googlePlaceId: "",
    googlelocationId: "",
    googlelocationname: "",
    ownerManagerTitle: "",
    image: "",
    highlightCompany: false,
    topBrandCompany: false,
    address: "",
    city: "",
    state: "",
    country: "",
    shortBio: "",
    zipcode: "",
    categories: "",
    subcategories: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectType, setSelectType] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [isindexNo, setIsIndexNo] = useState(false);
  const [uploadedImg, setUploadedImg] = useState("");

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [locationId, setLocationId] = useState("");
  const [locations1, setLocation1] = useState("");

  const [city, setCity] = useState("");
  const [state, setStates] = useState("");
  const [country, setCountry] = useState("");
  const [travel_checked, setTravel_checked] = useState(false);
  const [wasCheckboxChecked, setWasCheckboxChecked] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  /* New Changes in Google place API */
  const [uniqueIdEdit, setUniqueIdEdit] = useState("");
  const [placesDetailsEdit, setPlaceDetailsEdit] = useState([]);
  const [cityloadingEdit, setCityLoadingEdit] = useState(false);
  const [citydropEdit, setCityDropEdit] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [fixedValue, setFixedValue] = useState([]);

  const refreshUniqueIdEdit = () => {
    setUniqueIdEdit(uuidv4());
  };

  const topOption = (category, depth = 0, parentId = null) => {
    const { _id, categoryName, subcategoryName, subCategory = [] } = category;
    const children = subCategory.flatMap((child) =>
      child.subcategoryName === "General"
        ? topOption(child, depth + 2, _id)
        : topOption(child, depth + 1, _id)
    );
    const option = {
      _id,
      categoryName: categoryName ? categoryName : subcategoryName,
      subCategory,
      depth,
      parentId,
    };
    return [option].concat(children);
  };

  const optionList = categories
    .flatMap((item) => topOption(item))
    .filter((item) => item.depth !== 2);

  const GeneralOptionsList = categories
    ?.flatMap((category) => topOption(category))
    .filter((item) => item.depth === 2);

  const getEditCitySearchDetails = async (srchwrd) => {
    if (srchwrd.length > 3 && srchwrd.length % 2 != 0) {
      setCityLoadingEdit(true);
      let srchkeyedit = srchwrd.trim().toLowerCase();

      const cityDatasEdit = {
        input: srchkeyedit,
        sessionToken: uniqueIdEdit,
        type: "geocode",
        location: "",
        radius: "",
        strictbounds: false,
        pd: "0",
        requestFrom: "1",
      };

      const resCityEdit = await getPlaceAutoCompleteAPI(cityDatasEdit);

      if (!resCityEdit.error && resCityEdit?.data) {
        setPlaceDetailsEdit(resCityEdit?.data);
        setCityDropEdit(true);
        setCityLoadingEdit(false);
      }
    }
  };

  const getCityBasedPlaceDetailsEdit = async (placeid) => {
    if (placeid) {
      const searchValuesEdit = {
        place_id: placeid,
        fields:
          "name,place_id,address_components,formatted_address,geometry,vicinity",
        sessionToken: uniqueIdEdit,
        requestFrom: "1",
        pd: "2",
      };

      const res = await checkPlaceDetailsExistsAPT(searchValuesEdit);
      const hasKey = "result" in res?.data;

      let placeDetailsResponse = "";

      if (hasKey) {
        placeDetailsResponse = res.data.result;
      } else {
        placeDetailsResponse = res.data.placeDetailResponse;
      }
      if (placeDetailsResponse) {
        const address_component = placeDetailsResponse.address_components;
        const ObjIdToFind = "locality";
        const isObjectPresent = address_component.find(
          (o) => o.types[0] === ObjIdToFind
        );
        if (!isObjectPresent) {
          setCity("");
          formValues.city = "";
        }

        address_component.map((element) => {
          if (element.types[0] === "country") {
            let countryNa = element.long_name;
            setCountry(countryNa);
            formValues.country = countryNa;
          }

          if (element.types[0] === "locality") {
            let cityNa = element.long_name;
            setCity(cityNa);
            formValues.city = cityNa;
          }

          if (element.types[0] === "administrative_area_level_1") {
            let stateNa = element.long_name;
            setStates(stateNa);
            formValues.state = stateNa;
          }
          return null;
        });
      }

      setUniqueIdEdit("");
      refreshUniqueIdEdit();
    }
  };

  const fetchCompanyDetails = async (companyDetailId) => {
    const res = await getCompany(companyDetailId);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        if (res.data) {
          formValues.name = res.data.name;
          formValues.email = res.data.email;
          formValues.mobileNoCC = res.data.mobileNoCC;
          formValues.mobileNo = res.data.mobileNo;
          formValues.website = res.data.website;
          formValues.categoryId = res.data.categoryId[0]?._id;

          formValues.address = res.data.address;
          formValues.googlePlaceId = res.data.googlePlaceId;
          formValues.googlelocationId = res.data.googlelocationId;

          formValues.googlelocationname =
            (typeof res.data.city !== "undefined" && res.data.city
              ? res.data.city + ", "
              : "") +
            (typeof res.data.state !== "undefined" && res.data.state
              ? res.data.state + ", "
              : "") +
            (typeof res.data.country !== "undefined" ? res.data.country : "");
          formValues.ownerManagerName = res.data.ownerManagerName;
          formValues.ownerManagerEmail = res.data.ownerManagerEmail;
          formValues.ownerManagerMobileNoCC = res.data.ownerManagerMobileNoCC;
          formValues.ownerManagerMobileNo = res.data.ownerManagerMobileNo;
          formValues.ownerManagerTitle = res.data.ownerManagerTitle;
          setUploadedImg(res.data.image);
          setSelectType(res.data.ownerManager);
          setLoading(false);
          setLocation1(res.data.googlelocationname);
          formValues.shortBio = res?.data?.shortBio;
          formValues.zipcode = res.data.zipcode;

          formValues.city = res.data.city;
          formValues.state = res.data.state;
          formValues.country = res.data.country;
          formValues.categories = res.data.categories;
          formValues.topBrandCompany = res?.data?.topBrandCompany;
          formValues.highlightCompany = res?.data?.highlightCompany;
          formValues.subcategories = res.data.subcategories;
          setIsIndexNo(true);

          setCity(res.data.city);
          setStates(res.data.state);
          setCountry(res.data.country);

          setTravel_checked(res.data.travelAgencyStatus);
          setShowCheckbox(res.data.travelAgencyStatus);
        }
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "shortBio") {
      // replace space with next js's space variable
      let shortBio = value?.replaceAll("\n", " ");
      setFormValues({ ...formValues, shortBio });
    } else {
      setFormValues({ ...formValues, [name]: value });
      if (name === "categoryId") {
        const selecedCompanyType = optionList.filter(
          (item) => item._id === value
        );
        setFixedValue(selecedCompanyType);
        setSubCategory(selecedCompanyType);
        const catgtype = e?.target[e?.target.selectedIndex]?.getAttribute("data-tag")?.toLowerCase();
        if (catgtype === "travel agency") {
          setShowCheckbox(true);
          setTravel_checked(wasCheckboxChecked);
        } else {
          setShowCheckbox(false);
          setTravel_checked(false);
        }
        setShowCheckbox(catgtype === "travel agency");
      }
    }
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const saveCompany = async (formCompanyValues) => {
    setLoading(true);
    let main_Category = subCategory
      .filter((item) => (item.depth === 0 ? item : null))
      .map((item) => item._id);
    let sub_Category = subCategory
      .filter((item) => (item.depth === 1 ? item : null))
      .map((item) => item._id);
    const newGeneral = GeneralOptionsList.filter((item) =>
      main_Category.includes(item.parentId)
    ).map((item) => item._id);

    formCompanyValues.categories = main_Category;
    formCompanyValues.subcategories = [...newGeneral, ...sub_Category];
    formCompanyValues.travelAgencyStatus = travel_checked;

    const res = await updateCompany(formCompanyValues);

    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
        setLoading(false);
      } else {
        var successMessage = "Company updated successfully!";
        ShowToastMessage(
          successMessage,
          TOAST_SUCCESS,
          toast.POSITION.TOP_RIGHT
        );
        setLoading(false);
        setTimeout(function () {
          navigate("/admin/company");
        }, 2500);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Company name is required!";
      ShowToastMessage(errors.name, TOAST_ERROR, toast.POSITION.TOP_RIGHT);
    }

    if (!values.categoryId) {
      errors.categoryId = "Company type is required!";
      ShowToastMessage(
        errors.categoryId,
        TOAST_ERROR,
        toast.POSITION.TOP_RIGHT
      );
    }
    return errors;
  };

  const onlyNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      formValues.ownerManager = selectType;

      if (baseImage && baseImage !== "") {
        formValues.image = baseImage;
      } else {
        delete formValues.image;
      }
      formValues._id = companyid;
      formValues.googlelocationId = locationId;
      formValues.googlelocationname = locations1;
      formValues.city = city;
      formValues.state = state;
      formValues.country = country;

      saveCompany(formValues);
    }
  }, [formErrors]);

  useEffect(() => {
    if (isindexNo) {
      let result = optionList?.filter((o1) =>
        [
          ...formValues?.categoryId,
          ...formValues.categories,
          ...formValues.subcategories,
        ].some((o2) => o1?._id === o2)
      );
      optionList.map((item, index) => {
        return item._id === formValues?.categoryId
          ? (setSubCategory([...new Set([optionList[index], ...result])]),
            setFixedValue([optionList[index]]))
          : null;
      });
    }
  }, [isindexNo, categories, formValues?.categoryId]);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getCategories();
      if (res) {
        if (res.error) {
          var errorMessage = res.message
            ? res.message
            : "Error Loading the data";
          ShowToastMessage(
            errorMessage,
            TOAST_ERROR,
            toast.POSITION.BOTTOM_RIGHT
          );
        }
        setCategories(res.data);
      }
    };
    setLoading(true);
    fetchCompanyDetails(companyid);
    fetchCategories();
    refreshUniqueIdEdit();
  }, []);
  return (
    <div className="company_edit_top company_container">
      <div className="Scroll">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row dashboard_tittle_box">
              <div className="col-md-5">
                <h3>Add/Edit Company</h3>
                <h6 className="import_tittle">
                  Companies <span>Edit Company</span>
                </h6>
              </div>
              <div className="col-md-7 text-right">
                {formValues.googlePlaceId ? "Linked to google places" : ""}{" "}
                &nbsp;
                <button type="submit" className="save_button">
                  Update
                </button>
                <Link to={"../../Company"}>
                  <button className="cancel_button">cancel</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            {!loading && (
              <div
                className="details_box"
                onClick={() => {
                  setCityDropEdit(false);
                }}
              >
                <div className="row edit_from">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Company Name<span className="frmErr"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        name="name"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.name}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.name}</p>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex">
                    <div className="form-check pr-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="ownerManager"
                        id="exampleRadios1"
                        defaultValue="Owner"
                        checked={selectType === "Owner"}
                        onChange={handleChange}
                        onClick={(e) => setSelectType(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Owner
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="ownerManager"
                        id="exampleRadios2"
                        defaultValue="Manager"
                        checked={selectType === "Manager"}
                        onChange={handleChange}
                        onClick={(e) => setSelectType(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Manager
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Email<span className="frmErr"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="emailId"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.email}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        {selectType} Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="ownerManagerName"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.ownerManagerName}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.ownerManagerName}</p>
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group position_relative">
                      <label htmlFor="exampleInputEmail1">
                        Phone Number<span className="frmErr"></span>
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <select
                            name="mobileNoCC"
                            id="mobileCC"
                            className="form-select"
                            onChange={handleChange}
                            ariaLabel
                            value={formValues.mobileNoCC}
                          >
                            <option value="+1">+1</option>
                            <option value="+44">+44</option>
                            <option value="+52">+52</option>
                            <option value="+91">+91</option>
                          </select>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            name="mobileNo"
                            aria-describedby="emailHelp"
                            placeholder=""
                            autoComplete="off"
                            value={formValues.mobileNo}
                            onChange={handleChange}
                            maxLength={15}
                            onKeyPress={(event) => {
                              onlyNumber(event);
                            }}
                          />
                          <p className="frmErr">{formErrors.mobileNo}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        {selectType} Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ownerEmail"
                        name="ownerManagerEmail"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.ownerManagerEmail}
                        onChange={handleChange}
                      />
                      <p className="frmErr">{formErrors.ownerManagerEmail}</p>
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Company Website
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="website"
                        name="website"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.website}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group position_relative">
                      <label htmlFor="exampleInputEmail1">
                        {selectType} Number
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <select
                            name="ownerManagerMobileNoCC"
                            id="ownerManagerMobileNoCC"
                            className="form-select"
                            onChange={handleChange}
                            ariaLabel
                            value={formValues.ownerManagerMobileNoCC}
                          >
                            <option value="+1">+1</option>
                            <option value="+44">+44</option>
                            <option value="+52">+52</option>
                            <option value="+91">+91</option>
                          </select>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="ownerPhone"
                            name="ownerManagerMobileNo"
                            aria-describedby="emailHelp"
                            placeholder=""
                            autoComplete="off"
                            value={formValues.ownerManagerMobileNo}
                            onChange={handleChange}
                            maxLength={15}
                            onKeyPress={(event) => {
                              onlyNumber(event);
                            }}
                          />
                          <p className="frmErr">
                            {formErrors.ownerManagerMobileNo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Company Type<span className="frmErr">*</span>
                      </label>
                      <select
                        name="categoryId"
                        id="Business"
                        className="form-select"
                        value={formValues?.categoryId}
                        onChange={handleChange}
                        ariaLabel
                      >
                        <option selected>Please Select</option>
                        {categories &&
                          categories.map((category) => (
                            <option key={category._id} value={category._id} data-tag={category.categoryName}>
                              {category.categoryName}
                            </option>
                          ))}
                      </select>
                      <p className="frmErr">{formErrors.categoryId}</p>
                    </div>
                    {showCheckbox && (
                        <div className="confirmation">
                          <input
                            name="travelAgencyStatus"
                            type="checkbox"
                            checked={travel_checked}
                            onChange={(event) => {
                              setTravel_checked(event.target.checked);
                              setWasCheckboxChecked(event.target.checked);
                            }}
                            className="confirmation_input"
                            required
                          />
                          <label id="travelAgencyStatus">
                            I confirm I am a travel agent or agency and would like a
                            special view of the platform
                          </label>
                        </div>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        {selectType} Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ownerTitle"
                        name="ownerManagerTitle"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        value={formValues.ownerManagerTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10 cmpy-location-srch">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Sub Category Type<span className="frmErr">*</span>
                      </label>
                      <Autocomplete
                        multiple
                        id="multiselect"
                        options={optionList}
                        value={subCategory}
                        onChange={(event, newValue) => {
                          const selectedValue = newValue.map(
                            (item) => item._id
                          );
                          const selectedParentId = newValue.map(
                            (item) => item.parentId
                          );
                          const getParentId = optionList.filter((itm) =>
                            selectedParentId.includes(itm._id)
                          );

                          setSubCategory([
                            ...fixedValue,
                            ...newValue.filter(
                              (item) => fixedValue.indexOf(item) === -1
                            ),
                            ...getParentId.filter(
                              (itm) => !selectedValue.includes(itm._id)
                            ),
                          ]);
                        }}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(option) => option.categoryName}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={
                                option.depth === 0
                                  ? `${option.categoryName} / Genreral`
                                  : option.categoryName
                              }
                              {...getTagProps({ index })}
                              disabled={fixedValue.indexOf(option) !== -1}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                              sx={{ ml: 2 * option.depth }}
                            />
                            {option.categoryName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </div>
                  </div>
                  {citydropEdit && (
                    <div className="autocomplete-dropdown-container">
                      {cityloadingEdit ? (
                        <div>Loading...</div>
                      ) : (
                        <ul>
                          {placesDetailsEdit &&
                            placesDetailsEdit.map((item) => (
                              <li
                                key={item?.place_id}
                                onClick={(i) => {
                                  setLocation1(item?.description);
                                  setLocationId(item?.place_id);
                                  getCityBasedPlaceDetailsEdit(
                                    item?.place_id === undefined
                                      ? item?.placeId
                                      : item?.place_id
                                  );
                                  setCityDropEdit(false);
                                }}
                              >
                                {item?.description}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Description</label>
                      <textarea
                        className="form-control"
                        id="shortdesc"
                        name="shortBio"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        rows={2}
                        onChange={handleChange}
                        value={formValues.shortBio}
                      />
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="citybasedSearch1"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        onChange={(evt) => {
                          setLocation1(evt.target.value);
                          getEditCitySearchDetails(evt.target.value);
                        }}
                        value={locations1}
                        onClick={() => setCityDropEdit(true)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group position_relative">
                      <label htmlFor="exampleInputEmail1">
                        Update Company Image
                      </label>
                      <input
                        type="file"
                        className="form-control pl-60"
                        id="cmpImg"
                        name="image"
                        aria-describedby="emailHelp"
                        placeholder="Drop File Here Or Browse"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <img
                        alt=""
                        className="img-fluid uplod_img"
                        src={importImg}
                      />
                    </div>
                  </div>
                </div>
                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <textarea
                        className="form-control"
                        id="streetNo"
                        name="address"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        onChange={handleChange}
                        rows={3}
                        value={formValues.address}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {!baseImage && uploadedImg && (
                      <div className="col-md-6">
                        <div className="form-group position_relative">
                          <label htmlFor="exampleInputEmail1">
                            Uploaded Company Image
                          </label>{" "}
                          &nbsp;&nbsp;
                          <img
                            src={uploadedImg}
                            width={70}
                            height={70}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row edit_from pt-0 pt-10">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Zipcode</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zipcode"
                        name="zipcode"
                        aria-describedby="emailHelp"
                        placeholder=""
                        autoComplete="off"
                        onChange={handleChange}
                        value={formValues.zipcode}
                        maxLength={10}
                        onKeyPress={(event) => {
                          onlyNumber(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="checkbox-container">
                      <div
                        className="form-group position_relative"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        &nbsp;&nbsp;
                        <input
                          className="check-box"
                          name="topBrandCompany"
                          type="checkbox"
                          checked={formValues.topBrandCompany}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              topBrandCompany: e.target.checked,
                            });
                            // setIsTopBrandCompany(e.target.checked)
                          }}
                        />
                      </div>
                      <div className="checkbox-label">
                        <span className="checkbox-span">
                          We are global brand and /or do not have a specific
                          arrival location
                        </span>
                        <span>
                          (Customers would typically search for our company by
                          name rather than specific location or city)
                        </span>
                      </div>
                    </div>
                    <div
                      className="checkbox-container"
                      style={{ marginTop: 16 }}
                    >
                      <div
                        className="form-group position_relative"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        &nbsp;&nbsp;
                        <input
                          className="check-box"
                          name="highlightCompany"
                          type="checkbox"
                          checked={formValues.highlightCompany}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              highlightCompany: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <span
                        className="checkbox-span"
                        style={{ marginLeft: 18 }}
                      >
                        Highlight
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loading && <Loader />}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditCompany;
