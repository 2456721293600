import { get, post, patch, destroy } from "./config";
const apiToken = sessionStorage.getItem("userToken");
export const getAllStaff = async () => {
  return get("/staff/admin/getAllStaffMembers", {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err-=", error);
      return error;
    });
};

export const addStaff = async (data) => {
  return post("/staff/admin/addStaff", data, {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const resendInvite = async (email) => {
  return post(
    "/staff/admin/resendInviteStaff",
    { email },
    {
      Authorization: `Bearer ${apiToken}`,
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const editStaff = async (data) => {
  return patch("/staff/admin/updateStaff", data, {
    Authorization: `Bearer ${apiToken}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getSingleStaff = async (id) => {
  return post("/staff/admin/getSingleStaffMember", { _id: id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const deleteStaff = async (staffId) => {
  return destroy("/staff/admin/deleteStaff", {
    data: {
      _id: staffId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
