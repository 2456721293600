import React from "react";
import "../../assets/css/index.css";
import logo from "../../assets/images/logo.svg";
import { BsList, BsBell } from "react-icons/bs";
import ProfileMenu from "../profilemenu";

export const Header = (props) => {
  const closeSidebar = () => {
    const sidebar = document.getElementById("mySidebar");
    const header_bar = document.getElementById("header_bar");
    sidebar.classList.toggle("sidebar-active");
    header_bar.classList.toggle("header-condese");
  };

  return (
    <React.Fragment>
      <div className="header-wrapper">
        <div className=" header_container d-flex align-items-center justify-content-between">
          <div className="header-left d-flex" id="header_bar">
            <div className="logo">
              <img alt="" className="img-fluid" src={logo} />
            </div>
            <div className="menu-icon">
              <div onClick={() => closeSidebar()}>
                <BsList
                  size={28}
                  style={{
                    marginBottom: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Search"
            className="search"
            hidden
            id="toggler"
          />
          <div className="header-right ml-auto">
            <div className="float-end">
              <BsBell size={20} />
              <ProfileMenu {...props} />
              <div className="d-inline-block">{props.name}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
