import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../assets/css/customer_modal.css";
import { Link } from "react-router-dom";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import { getUserBioDatas } from "../../services/api/customer.service";
import { ShowToastMessage } from "../toastr";
import { TOAST_ERROR } from "../../helpers/app.constants";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../loader";
import avatar from "../../assets/images/avtar.webp";
import moment from 'moment';

const Bio = ({ rowData }) => {
  const [customerBioData, setCustomerBioData] = useState([]);
  const { specialdateData, companyTagsData, hobbies } = customerBioData || [];
  const [loading, setLoading] = useState(true);
  const currentDate = moment();

  const fetchCustomerBioData = async () => {
    setLoading(true);
    const res = await getUserBioDatas(rowData?.id);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setCustomerBioData(res.data[0]);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchCustomerBioData();
  }, []);

  return (
    <>
     {!loading ? <Box className="bio-main-div">
        <Box sx={{ width: "50%" }}>
          <img src={customerBioData?.image ? customerBioData?.image : avatar} 
          className="img-fluid" alt="customer-img" style={{maxWidth: 180, maxHeight: 240}} 
          />
          <Typography sx={{ color: "#000000", fontSize: "14px", mt: 3 }}>
           <b>About Me:</b>
          </Typography>
          <Typography sx={{ mt: 2, color: "#3F3F3F", fontSize: "14px" }}>
            {customerBioData?.shortBio}
          </Typography>
          <Typography sx={{ mt: 4, color: "#000000", fontSize: "14px" }}>
           <b>Professions:</b>
          </Typography>
          <Typography sx={{ mt: 1, color: "#3F3F3F", fontSize: "14px" }}>
            {customerBioData?.profession}
          </Typography>
          <Typography sx={{ mt: 3, color: "#000000", fontSize: "14px" }}>
           <b>LinkedIn:</b>
          </Typography>
          <Typography sx={{ mt: 1, fontSize: "14px" }}>
            <Link to={customerBioData?.linkedInId}>
              {customerBioData?.linkedInId}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Box sx={{ backgroundColor: "#F5F9F9", p: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
              {specialdateData?.length > 0 ? (specialdateData?.map((itm) => {
                return (
                  <Box sx={{paddingY:"8px"}} className="special-date-div overflow-auto" key={itm?._id} style={{width: '50%'}}>
                 <Box>
                   <Typography sx={{ color: "#000000", fontSize: "13px" }}>
                     <b>{itm?.title}</b>
                    </Typography> 
                   <Typography sx={{ color: "#3F3F3F", fontSize: "13px" }}>
                   {itm?.reoccurring?.value === "everyMonth" || itm?.reoccurring?.value === "everyYear" ? "Next date: " : ""}
                   {moment(itm?.date).format("MM/DD/YYYY")}
                   <br/>
                    {itm.title === "My Birthday" ? `(Age ${currentDate.diff(itm?.date, 'years')})` : ""}
                    </Typography> 
                   <Typography sx={{ color: "#000000", fontSize: "13px" }}>
                       <i>{itm?.reoccurring?.name}</i>
                    </Typography> 
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Typography>No Important dates</Typography>
            )
            }
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "#000000", fontSize: "14px" }}>
              <b>Hobbies:</b>
            </Typography>
          </Box>
          <Box className="overflow-auto align-items-center" sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
            {hobbies?.map((itm, i) => {
              return (
                <Typography
                key={i}
                  style={{
                    backgroundColor: "#E2EFEE",
                    borderRadius: "4px",
                    padding: "2px 10px",
                    fontSize: "13px",
                    marginRight: 9
                  }}
                >
                  {itm}
                </Typography>
              );
            })}
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "#000000", fontSize: "14px" }}>
             <b>Company Tags:</b>
            </Typography>
          </Box>
          <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
            {companyTagsData?.map((itm, i) => {
              return (
                <Typography
                key={i}
                  style={{
                    backgroundColor: "#E2EFEE",
                    borderRadius: "4px",
                    padding: "2px 10px",
                    fontSize: "13px",
                    marginRight: 9
                  }}
                >
                  {itm?.tagId[0].tag}
                </Typography>
              );
            })}
          </Box>
          <Box sx={{ mt: 1 }}>
            <Box sx={{ color: "#000000", fontSize: "14px" }}><b>Social Media:</b></Box>
            <Stack direction="row" spacing={1} style={{filter: 'grayscale(1)'}}>
              <Avatar
                alt="instagram-img"
                src={instagram}
                sx={{ width: 30, height: 30 }}
              ></Avatar>
              <Avatar
                alt="facebook-img"
                src={facebook}
                sx={{ width: 30, height: 30 }}
              ></Avatar>
              <Avatar
                alt="twitter-img"
                src={twitter}
                sx={{ width: 30, height: 30 }}
              ></Avatar>
            </Stack>
          </Box>
        </Box>
        <ToastContainer />
      </Box> : <> <Loader /> </> }
    </>
  );
};
export default Bio;
