import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import moment from 'moment';
import Typography from "@mui/material/Typography";
import "../../assets/css/customer_modal.css";
import { toast } from "react-toastify";
import { TOAST_ERROR } from "../../helpers/app.constants";
import { ShowToastMessage } from "../toastr";
import { getUserMicroFeedbackDatas } from "../../services/api/customer.service";
import { Loader } from "../loader";

const Feedback = ({ rowData }) => {
  const [microFeedbackData, setMicroFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCustomerMicroFeedbackDatas = async () => {
    setLoading(true)
    const res = await getUserMicroFeedbackDatas(rowData?.userId?._id);
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(
          errorMessage,
          TOAST_ERROR,
          toast.POSITION.BOTTOM_RIGHT
        );
      }
      setMicroFeedbackData(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCustomerMicroFeedbackDatas();
  }, []);

  return (
    <>
      <div>
        {microFeedbackData.length > 0 ? (
          microFeedbackData?.map((user) => (
            <List
              sx={{ width: "100%", maxWidth: 560, bgcolor: "background.paper" }}
            >
              <ListItem alignItems="flex-start" className="list-item">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <b>{user?.userId?.name}</b>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography style={{ fontSize: 13, color: "#3F3F3F" }}>
                     {moment.utc(user?.createdAt).local().startOf('seconds').fromNow() }
                      </Typography>
                      <Typography style={{ fontSize: 13, color: "#3F3F3F" }}>
                        {user?.feedback}
                      </Typography>
                      {user?.microfeedbackTagsData[0]?.tagId?.tag && (
                        <Typography
                        component="span"
                          style={{
                            backgroundColor: "#E2EFEE",
                            color: "#2D938E",
                            borderRadius: "4px",
                            padding: "2px 10px",
                            fontSize: "14px",
                            marginTop:"5px",
                            marginBottom: "5px",
                            display:"inline-block"
                          }}
                        >
                          {user?.microfeedbackTagsData[0]?.tagId?.tag || null}
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                  
                />
              </ListItem>
            
              <Divider variant="middle" component="li" />
            </List>
          ))
        ) : loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div style={{fontSize: 20, textAlign: 'center', marginTop: 100}}>No Feedbacks Found</div>
          </>
        )}
      </div>
    </>
  );
};
export default Feedback;
