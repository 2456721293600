import { patch, post } from "./config";

export const changePassword = async (data) => {
  const apiToken = sessionStorage.getItem("userToken");
  return patch(
    "/auth/company/changePasswordForCompany",

    data,

    {
      Authorization: `Bearer ${apiToken}`,
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const changeStaffPassword = async (data) => {
  return post(
    "/staff/resetPasswordForStaff",
    data,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

