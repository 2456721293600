import React, { Component } from "react";
import { Container } from "reactstrap";

export default class MicroFeedback extends Component {
  render() {
    return (
      <Container className="container">
        <div className="row dashboard_tittle_box">
          <div className="col-md-6">
            <h3>Micro Feedback</h3>
            <h6 className="import_tittle">
              Home <span>MicroFeedback</span>
            </h6>
          </div>
        </div>
      </Container>
    );
  }
}
