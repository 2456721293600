import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../assets/css/referralcommissions.css';
import { Loader } from '../loader';
import { getAllCompanies } from '../../services/api/company.service';
import { ShowToastMessage } from '../toastr';
import { TOAST_ERROR } from '../../helpers/app.constants';
import { toast } from 'react-toastify';
import { useAuth } from "../../hooks/useAuth";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ReferralCommissions() {
  const [value, setValue] = React.useState(0);
  const [companies, setCompanies] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const user = auth?.user ? JSON.parse(auth.user) : null;
  const userId = user._id

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchCompanies = React.useCallback(async () => {
    setLoading(true);
    const res = await getAllCompanies();
  
    if (res) {
      if (res.error) {
        var errorMessage = res.msg ? res.msg : "Error Loading the data";
        ShowToastMessage(errorMessage, TOAST_ERROR, toast.POSITION.BOTTOM_RIGHT);
      } else {
        const filteredCompanies = res.data.filter(company => company.referredId?._id === userId);
        setCompanies(filteredCompanies);
      }
    }
    setLoading(false);
  }, [userId]);

  React.useEffect(() => {
    fetchCompanies();
  }, []);

    const renderListing = () => {
      let recordList = [];
      if (companies) {
        companies.map((customer) => {
          recordList.push(
            <tr key={customer._id}>
              <td className='p-4'>{customer.name}</td>
              <td>
                <button
                style={{backgroundColor: "#157E77", color: 'white'}}
                  className="btn"
                  type="button"
                >
                  Signed Up
                </button>
              </td>
            </tr>
          );
        });
      } else {
        recordList.push();
      }
      if (companies.length === 0) {
        recordList.push();
      }
      return recordList;
    };

  return (
    <Box sx={{ width: '100%', marginTop: '30px' }} className="main_box">
      <Box>
        <Tabs className='referral-tabs' value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab sx={{textTransform: 'capitalize', fontSize: 21, color: "#000000"}} label="Learn" {...a11yProps(0)} />
          <Tab sx={{textTransform: 'capitalize', fontSize: 21, color: "#000000"}} label="Referrals" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       <b style={{fontSize: '19px'}}>Referral Program Details</b>

        <Typography sx={{marginTop: '15px', marginBottom: '15px'}}>Invite your favorite companies and brands. For each company that purchases a paid subscription you will earn money!</Typography>

        <b style={{fontSize: '19px'}}>Requirements</b>

        <Typography sx={{marginTop: '20px'}}>Company staff must click your referral link </Typography>
        <Typography>Company staff must sign up for an account</Typography>
        <Typography>Company must subscribe to a paid plan</Typography>
        <Typography>Earn 25% recurring commission</Typography>
        <Typography>25% of all paid monthly subscriptions</Typography>
        <Typography>Earn commission for up to six months</Typography>
        <Typography>Paid subscriptions from $99 - $5000+/month</Typography>
        <Typography>Company must purchase paid plan within 60 days</Typography>
        <Typography>Company cannot request a refund within 60 days</Typography>
        <Typography>Referral payments start after 60 days of purchase</Typography>
        <Typography>Commissions will be paid by Paypal or Stripe</Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
      {loading && <Loader />}
        {companies.length !== 0 ? 
      <div className="col-md-12 company_table">
          {!loading && (
            <div className="Scroll">
              <table id="customersListing">
                <thead>
                  <tr>
                    <th className='p-4'>Company Name</th>
                    <th className='p-4'>Status</th>
                  </tr>
                </thead>
                <tbody>{renderListing()}</tbody>
              </table>
            </div>
          )}
        </div>: <p>
        No Referrals yet
        </p>}
      </TabPanel>
    </Box>
  );
}