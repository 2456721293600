import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ROLE_ADMIN } from "../helpers/app.constants";
import Company from "../views/superadmin/company/company";
import Footer from "./layout/footer";
import { Header } from "./layout/header";
import { SideBar } from "./layout/sidebar";
import Preference from "../views/admin/preference/preference";
import Customer from "../views/admin/customer/customer";
import MicroFeedback from "../views/admin/feedback/feedback";
import Perks from "../views/admin/perks/perks";
import Profile from "../views/admin/settings/profile";
import { getUserInfo, getUserName, getUserRole } from "../helpers/helper";
import Staff from "../views/admin/staff/staff";
import Locations from "../views/admin/locations/locations";
import Connections from "../views/admin/connections/connections";
import Notification from "../views/admin/notification/notification";
import Promote from "../views/admin/promote/promote";
import Store from "../views/admin/store/store";
import Billing from "../views/admin/billing/billing";
import { createTheme, ThemeProvider } from "@mui/material";
import Home from "../views/admin/home";
import PreferenceListCompany from "../views/admin/preference/preferenceListCompany";
import Password from "../views/admin/password/Password";
import sha256 from "sha256";
import ReferralCommissions from "./ReferralCommissions/RefferalCommissions";

const AdminMainLayout = () => {
  const userName = getUserName();
  const userRole = getUserRole();
  const userInfo = getUserInfo();

  if (userInfo) {
    const userhash = sha256(userInfo._id);
    window.Intercom("boot", {
      app_id: "fxdppbui",
      name: userInfo?.ownerManagerName,
      email: userInfo?.email,
      user_id: userInfo?._id,
      "Owner email": userInfo?.ownerManagerEmail,
      "Owner phone": userInfo?.ownerManagerMobileNo,
      "company name": userInfo?.name,
      "Mobile user" : "Manager",
      "Company type": userInfo?.categoryId?.find((item, idx) => idx === 0)
        ?.categoryName,
      phone: userInfo.mobileNo,
      "Company website": userInfo?.website,
      "Company industry": userInfo?.categoryId?.find((item, idx) => idx === 0)
        ?.categoryName,
      user_hash: userhash,
    });
    window.Intercom("update");
  }
  const theme = createTheme({
    typography: {
      fontFamily: [
        "nunito",
        "Helvetica",
        "Arial",
        "Verdana",
        "Tahoma",
        "sans-serif",
      ].join(","),
    },
  });
  theme.typography.h6 = {
    fontSize: "1.25rem",
    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="main-content toggle">
        <Header name={userName} role={userRole} image={userInfo?.image} />
        <div className="main-layout d-flex">
          <SideBar userRole={ROLE_ADMIN} travelAgencyStatus={userInfo?.travelAgencyStatus} />
          <div className="main">
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="settings" element={<Profile />} />
              <Route path="company" element={<Company />} />
              <Route path="home" element={<Home />} />
              <Route path="preference" element={<Preference />} />
              <Route path="customer" element={<Customer />} />
              <Route path="feedback" element={<MicroFeedback />} />
              <Route path="perks" element={<Perks />} />
              <Route path="connections" element={<Connections />} />
              <Route path="notifications" element={<Notification />} />
              <Route path="promote" element={<Promote />} />
              <Route path="store" element={<Store />} />
              <Route path="billing" element={<Billing />} />
              <Route path="changePassword" element={<Password />} />
              <Route path="staff" element={<Staff />} />
              <Route path="locations" element={<Locations />} />
              <Route path="referralcommissions" element={<ReferralCommissions />} />
              <Route
                path="preferencelist"
                element={<PreferenceListCompany />}
              />
            </Routes>
            <Footer />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AdminMainLayout;
