import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { AuthProvider } from "./hooks/useAuth";
import Authenticate from "./routes/middleware/Authenticate";

import {
  ROLE_ADMIN,
  ROLE_STAFF,
  ROLE_SUPERADMIN,
} from "./helpers/app.constants";

import Login from "./views/authentication/login";
import SuperAdminMainLayout from "./components/SuperAdminMainLayout";
import AdminMainLayout from "./components/AdminMainLayout";
import NotAuthorizedLayout from "./components/nonauth.layout";
import Register from "./views/authentication/register";
import Claimbusiness from "./views/authentication/claimbusiness";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

function App() {

  return (
    <HelmetProvider>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="claimbusiness" element={<Claimbusiness />} />
          <Route path="claimbusiness/:id" element={<Claimbusiness />} />
          {/* <Route path="company/*" element={<AdminMainLayout />} /> */}

          {/* // Super Admin Layout */}
          <Route element={<Authenticate allowedRole={ROLE_SUPERADMIN} />}>
            <Route path="admin/*" element={<SuperAdminMainLayout />} />
          </Route>

          {/* // Admin Layout - Company */}
          <Route
            element={
              <Authenticate allowedRole={ROLE_ADMIN} staffRole={ROLE_STAFF} />
            }
          >
            <Route path="company/*" element={<AdminMainLayout />} />
          </Route>

          {/* catch other unknown paths */}
          <Route path="*" element={<NotAuthorizedLayout />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
